import { IonInput } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import type { InputProps } from './Input.interface';
import { ValidationBadge } from './ValidationBadge';

const Input: React.FC<InputProps<E2U.V1.Models.User | string>> = (props) => {
  const methods = useFormContext();
  const label = props.label || '';

  const inputStyles = useMemo(() => {
    return {
      '--color': 'var(--ion-color-dark)',
      ...(props.lines !== false && !props.helperText && {
        borderBottom: '1px solid var(--ion-color-medium)',
      }),
    };
  }, [props.lines, props.helperText]);

  const error = useMemo(() => {
    return props.forceError || methods.formState.errors[props.register]?.message;
  }, [methods.formState.errors, props.forceError]);

  const setServerErrors = () => {
    methods.setError(props.register, {
      type: 'server',
      message: props.serverError?.toString(),
    }, {
      shouldFocus: true,
    });
  };

  useEffect(() => {
    if (props.serverError) {
      setServerErrors();
    }
  }, [methods.formState.errors, props.serverError]);

  return (
    <>
      <IonInput
        style={inputStyles}

        label={label}
        labelPlacement={props.labelPlacement || 'stacked'}
        className={'ion-margin-left'}
        autoCapitalize='sentences'
        clearInput={false}
        type={props.type}
        inputmode={props.inputMode}
        placeholder={props.placeholder}
        debounce={300}
        helperText={props.helperText && props.helperText}
        {...props}
        {...props.register && methods.register(props.register, {
          ...props.validation,
        })}

      />
      {error && (
        <div className='ion-no-margin' style={{ height: 20, paddingTop: 5, display: 'flex' }}>
          <ValidationBadge>
            {error}
          </ValidationBadge>
        </div>
      )}
    </>
  );
};

export default Input;
