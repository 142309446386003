import { IonAccordionGroup, IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonModal, IonRow, IonSelectOption, IonSpinner, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import { t } from 'i18next';
import { alert, checkmark, chevronForward, clipboard, closeCircleOutline, contractOutline, document, globe, heart, information, personAddOutline, refresh, shareSharp, shareSocialSharp, star, warning } from 'ionicons/icons';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { SwiperSlide } from 'swiper/react';

import styles from './Examples.module.scss';
import type { AccordionItemInterface } from '../components/Accordion/Accordion';
import AccordionItem from '../components/Accordion/AccordionItem';
import PieChart from '../components/Graphs/PieChart';
import PaginateData from '../components/Pagination/PaginationData';
import RelatedMultiSelectList from '../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectList';
import RelatedSelect from '../components/Search/RelatedSelect/RelatedSelect';
import SkeletonTextThreeLines from '../components/SkeletonComponents/SkeletonTextThreeLines';
import SwiperContainer from '../components/Swiper/SwiperContainer';
import SwiperController from '../components/Swiper/SwiperController';
import toasters from '../components/Toasts/Toasts';
import StepButtons from '../components/Toolbar/StepButtons';
import { BigUp } from '../components/UI';
import type { SegmentButtonProps } from '../components/UI/Buttons/Button';
import ButtonCTA from '../components/UI/Buttons/CTA/ButtonCTA';
import ButtonRound from '../components/UI/Buttons/Round/ButtonRound';
import { SegmentButtons } from '../components/UI/Buttons/SegmentButtons';
import ShareButton from '../components/UI/Buttons/Share/ShareButton';
import InputLabel from '../components/UI/InputLabel';
import CurrencyInput from '../components/UI/Inputs/CurrencyInput';
import InputFloatingLabel from '../components/UI/Inputs/InputFloatingLabel';
import { ValidationBadge } from '../components/UI/Inputs/ValidationBadge';
import ItemBorderContainer from '../components/UI/Items/components/ItemBorderContainer';
import ItemShadowNoIcons from '../components/UI/Items/components/ItemShadowNoIcons';
import ProgressBar from '../components/UI/Loaders/ProgressBar';
import ReturnChevronText from '../components/UI/Return/ReturnChevronText';
import SearchAndSortRow from '../components/UI/SearchAndSort/SearchAndSortRow';
import SelectOptions from '../components/UI/Select/SelectOption';
import { ionicColours } from '../components/UI/variables';
import { useAppSelector } from '../hooks';
import useModal from '../hooks/useModal';
import i18n from '../i18n';

interface ToasterButtons {
  backgroundColour: string;
  content: string;
  icon: string;
  clickHandler: () => void;
}

const ECONOMY_TOOLS_ACCORDION_LIST: (
  AccordionItemInterface & {
    component?: ReactElement
  })[] = [
    {
      subpanelTitle: i18n.t('Panel one'),
      subpanelIcon: clipboard,
      subpanelActiveColour: '#ee780f',
      subpanelDisabled: false,
      component:
        <>
          <div className='ion-padding'>
            Panel one content
          </div>
        </>,
      value: 'three',
    },
    {
      subpanelTitle: i18n.t('Panel two'),
      subpanelIcon: personAddOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      component: <>
        <div className='ion-padding'>
          Panel two content
        </div>
      </>,
      value: 'two',
    },
    {
      subpanelTitle: i18n.t('Panel three'),
      subpanelIcon: contractOutline,
      subpanelActiveColour: 'var(--ion-color-primary)',
      subpanelDisabled: false,
      component: <>
        <div className='ion-padding'>
          Panel three content
        </div>
      </>,
      value: 'four',
    },
  ];

const CHART_DATA = [
  {
    value: 10,
    name: 'Value one',
    color: 'var(--ion-color-primary)',
    onClick: () => console.log('click')
  },
  {
    value: 20,
    name: 'Value two',
    color: 'var(--ion-color-secondary)',
    onClick: () => console.log('click')
  },
  {
    value: 30,
    name: 'Value three',
    color: 'var(--ion-color-tertiary)',
    onClick: () => console.log('click')
  },
  {
    value: 40,
    name: 'Value four',
    color: 'var(--ion-color-success)',
    onClick: () => console.log('click')
  },

];

const COLOURS = [
  { name: 'primary', variable: 'var(--ion-color-primary)', icon: shareSharp, hex: '#5d7292' },
  { name: 'secondary', variable: 'var(--ion-color-secondary)', icon: document, hex: 'edac53' },
  { name: 'tertiary', variable: 'var(--ion-color-tertiary)', icon: chevronForward, hex: '#181d3d' },
  { name: 'success', variable: 'var(--ion-color-success)', icon: globe, hex: '#60c65e' },
  { name: 'warning', variable: 'var(--ion-color-warning)', icon: heart, hex: '#ffc409' },
  { name: 'danger', variable: 'var(--ion-color-danger)', icon: star, hex: '#eb445a' },
  { name: 'dark', variable: 'var(--ion-color-dark)', icon: refresh, hex: '#222428' },
  { name: 'medium', variable: 'var(--ion-color-medium)', icon: warning, hex: '#8b8989' },
  { name: 'mediumShade', variable: 'var(--ion-color-medium-shade)', icon: information, hex: '#3f3f3f' },
  { name: 'light', variable: 'var(--ion-color-light)', icon: checkmark, hex: '#f4f5f8' },
  { name: 'none', variable: 'none' },
];

export const NavWrapper = ({ children, title }: { children: ReactElement, title: string }) => {
  return (
    <>
      <IonHeader>
        <IonToolbar
          style={{
            '--min-height': '80px',
            '--padding-top': '20px',
            '--padding-bottom': '20px',
            '--padding-start': '10px',
          }}
        >
          <IonButtons slot="start">
            <IonBackButton defaultHref="/components" />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='ion-padding'>
          {children}
        </div>
      </IonContent>
    </>
  );
};
const Examples: React.FC = () => {
  const modalOne = useModal();
  return (
    <>
      <div className='ion-padding'>

        {/*    <IonNavLink component={() => (
          <NavWrapper title={'Theming'}>
            <Theming />
          </NavWrapper>
        )}>
          <Panel panelTitle={'Theming'} iconColour={ionicColours.primary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title={'Buttons'}>
            <Buttons />
          </NavWrapper>
        )}>
          <Panel panelTitle={'Buttons'} iconColour={ionicColours.secondary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title='Items'>
            <Items />
          </NavWrapper>
        )}
        >
          <Panel panelTitle={'Items'} iconColour={ionicColours.primary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title='Select and search'>
            <SelectAndSearch />
          </NavWrapper>
        )}
        >
          <Panel panelTitle={'Select and search'} iconColour={ionicColours.secondary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title='Pagination'>
            <Pagination />
          </NavWrapper>
        )}>
          <Panel panelTitle={'Pagination'} iconColour={ionicColours.primary} icon={ellipse} hasInnerIcon={true}></Panel>
        </ IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title='Loaders'>
            <Loaders />
          </NavWrapper>
        )}
        >
          <Panel panelTitle={'Loading and progression'} iconColour={ionicColours.secondary} icon={ellipse} hasInnerIcon={true}></Panel>
        </ IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title='Charts and graphs'>
            <Charts />
          </NavWrapper>
        )}
        >
          <Panel panelTitle={'Charts'} iconColour={ionicColours.primary} icon={ellipse} hasInnerIcon={true}></Panel>
        </ IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title='Accordion'>
            <Accordion />
          </NavWrapper>
        )}
        >
          <Panel panelTitle={'Accordion'} iconColour={ionicColours.secondary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title={'Inputs & Labels'}>
            <InputAndLabels />
          </NavWrapper>

        )}>
          <Panel panelTitle={'Inputs & Labels'} iconColour={ionicColours.primary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title={'Modals'}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <ButtonCTA
                    backgroundColour={ionicColours.secondary}
                    content={'Open modal'}
                    clickHandler={() => modalOne.openModal()}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </NavWrapper>
        )}>
          <Panel panelTitle={'Modals'} iconColour={ionicColours.secondary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title={'Swiper'}>
            <Swiper />
          </NavWrapper>
        )} >
          <Panel panelTitle={'Swiper'} iconColour={ionicColours.primary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonNavLink component={() => (
          <NavWrapper title='Toasters'>
            <Toasts />
          </NavWrapper>)}>
          <Panel panelTitle={'Toasters'} iconColour={ionicColours.secondary} icon={ellipse} hasInnerIcon={true}></Panel>
        </IonNavLink>

        <IonModal style={{ zIndex: 250 }} isOpen={modalOne.isModalOpen} onDidDismiss={() => { modalOne.closeModal(); }}>
          <HeaderBorderLeft borderColour={ionicColours.secondary} title={i18n.t('Header')} clickHandler={() => modalOne.closeModal()} />
          <IonContent className="ion-padding">
            <IonText>
              <h5>Content</h5>
              <span>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit veritatis vero laudantium ipsam asperiores, nemo facilis natus incidunt corrupti quos ut vel voluptatibus eos, fugit repellendus delectus sit libero. Officia sint asperiores magni, tempora saepe quod facilis molestias ab voluptatem aliquid. Sit aliquid doloribus expedita, quisquam, ipsum explicabo libero quo iste quas vel recusandae voluptate? Aperiam cum impedit ipsa quibusdam debitis tenetur vero totam!
              </span>
            </IonText>
          </IonContent>
        </IonModal> */}
      </div>
    </>
  );
};

export default Examples;
const Theming = () => {
  return (
    <div className='ion-padding'>
      <a style={{ fontSize: 18, color: 'black' }} href="https://ionicframework.com/docs/layout/css-utilities"><h1 style={{ fontWeight: 800 }}>Utilities</h1></a>
      <p>The default padding and margin in ionic are 16px</p>
      <br />
      <a style={{ fontSize: 18, color: 'black' }} href="https://ionicframework.com/docs/v6/api/button"><h1 style={{ fontWeight: 800 }}>COLOURS</h1></a>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', width: '100%', flexWrap: 'wrap', gap: 10 }}>
        {COLOURS.map((colour, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h2>{colour.name}</h2>
            <div style={{ backgroundColor: colour.variable, width: '50px', height: '50px', marginBottom: 10 }}>
            </div>
            <h4>{colour.hex}</h4>
          </div>
        ))}

      </div>
      <h2>Gradients</h2>
      <div style={{ background: 'var(--ion-color-primary-gradient)', maxWidth: 500, width: '100%', height: 200 }}></div>
      <p> linear-gradient(180deg, #2b446a 0%, rgba(43, 68, 106, 0.66) 100%);</p>

      <h2>Box shadow</h2>
      <div style={{ boxShadow: 'var(--ion-color-primary-box-shadow)', maxWidth: 500, width: '100%', height: 200 }}></div>
      <p>1px 1px 1px 1px rgba(0, 0, 0, 0.2)</p>
    </div>
  );
};

const Buttons = () => {
  const [segmentValue, setSegmentValue] = useState('default');
  const handleSegmentValue = (value: string) => {
    setSegmentValue(value);
  };

  const BUTTON_LIST: SegmentButtonProps['buttons'] = [
    {
      text: i18n.t('Segment one'),
      value: 'one',
      color: 'none',
      icon: clipboard,
    },
    {
      text: i18n.t('Segment two'),
      value: 'two',
      color: 'none',
      icon: personAddOutline,
    },
    {
      text: i18n.t('Segment three'),
      value: 'three',
      color: 'none',
      icon: contractOutline,
    },
  ];

  const segmentButtons: SegmentButtonProps['buttons'] = [
    {
      text: 'Default segment',
      value: 'default'
    },
    ...BUTTON_LIST.map((item, index) => ({
      text: item.text,
      value: item.value,
      color: 'none',
      icon: item.icon,
      key: index,
    }))
  ];

  return (
    <div className='ion-padding'>
      <BigUp.Buttons.Regular shape='round' title='Regular button' color={'dark'} icon={{ slot: 'start', icon: shareSharp }} />
      <BigUp.Buttons.Regular title='Regular button Danger' color={'danger'} icon={{ slot: 'end', icon: shareSharp }} />
      <BigUp.Buttons.Icon icon={{ icon: closeCircleOutline, color: 'primary' }} color='light' shape='round' padding={24} />
      <div>
        <SegmentButtons buttons={segmentButtons} value={segmentValue} onChange={(value: any) => handleSegmentValue(value)} />

      </div>

      <a style={{ fontSize: 18, color: 'black' }} href="https://ionicframework.com/docs/v6/api/button"><h1 style={{ fontWeight: 800 }}>Ionic documentation</h1></a>
      <br />
      {COLOURS.map((colour, index) => <ButtonCTA key={index} content={colour.name} backgroundColour={colour.variable}></ButtonCTA>)}
      <br />
      <h3>With icons:</h3>
      <br />
      {COLOURS.map((colour, index) => <ButtonCTA key={index} content={colour.name} backgroundColour={colour.variable} icon={colour.icon}></ButtonCTA>)}

      <div style={{ maxWidth: 300 }}>
        <StepButtons
          rightIonColor='secondary'
          leftIonColor='none'
          leftOnClick={() => console.log('left')}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <ButtonRound backgroundColour='none' icon={shareSocialSharp} textColour={ionicColours.secondary} />
        <ShareButton icon={shareSocialSharp} backgroundColour={'none'} buttonSizePixels={'30'} textColour={'secondary'} shareTitle={''} shareText={''} shareUrl={''} shareDialogTitle={''}
        />
      </div>
    </div>
  );
};

const Items = () => {
  return (
    <div className='ion-padding'>

      <a style={{ fontSize: 18, color: 'black' }} href="https://ionicframework.com/docs/v6/api/item"><h1 style={{ fontWeight: 800 }}>Items</h1></a>
      <br />      <ItemBorderContainer >
        <InputLabel>{t('Label')}</InputLabel>
        <IonItem >
          <p className='ion-no-margin' style={{ color: 'var(--ion-color-medium)' }}>
            Content
          </p>
        </IonItem>
      </ItemBorderContainer>
      <ItemShadowNoIcons
        label={'Label'} description={'Description'}
        subLabel={'Sub label'} onItemClick={() => console.log('click')}
        hasBorder={true} borderColour={ionicColours.secondary} />
      <br />
      <div style={{ maxWidth: 400 }}>
        {/*       <Panel
          icon={document}
          onClick={() => console.log('click')}
          panelTitle={'Share'}
          iconColour={'var(--ion-color-tertiary)'}
          hasInnerIcon={true}
        /> */}
      </div>
      <br />
      <ReturnChevronText color={'medium'} text={i18n.t('Return')} fill={'clear'} routeTo='#' />
      <br />
    </div>
  );
};

const SelectAndSearch = () => {
  const { closeModal, isModalOpen, openModal, toggleModal } = useModal();
  return (
    <div className='ion-padding'>
      <a style={{ fontSize: 18, color: 'black' }} href="https://ionicframework.com/docs/v6/api/button"><h1 style={{ fontWeight: 800 }}>SELECTS AND SEARCH</h1></a>
      <br />
      <IonGrid className={'ion-padding'}>
        <IonRow>
          <IonCol size='12'>
            <SearchAndSortRow onSearch={(e: any) => console.log(e)} onSort={(e: any) => console.log(e)} value={'search'}>
              <IonSelectOption value={'created_at'}>{t('Created at')}</IonSelectOption>
              <IonSelectOption value={'updated_at'}>{t('Last modified')}</IonSelectOption>
            </SearchAndSortRow>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div style={{ maxWidth: 200, display: 'flex' }}>
        <RelatedSelect
          color='secondary'
          infiniteScroll={true}
          value={''}
          model="activity_codes"
          onSelect={(selected) => console.log(selected)}
          title={i18n.t('Related select')}
          displayFields={['code', 'name']}
          fetchFields={['activityCategory', 'code_suffix']}
        />
        <ButtonCTA backgroundColour={ionicColours.secondary} content={i18n.t('Multiselect')} clickHandler={() => openModal()} />
        <IonModal isOpen={isModalOpen} onIonModalDidDismiss={() => closeModal()}>
          <RelatedMultiSelectList
            toggleModal={toggleModal}
            modalTitle={i18n.t('Multi select')}
            onChange={
              (selected) => console.log(selected)
            }
            value={COLOURS as any}
            model={'activity_codes'}
            displayFields={['code', 'name']}
            records={COLOURS as any}
          />
        </IonModal>
      </div>
      <br />
      <div style={{ display: 'flex', maxWidth: 700, flexWrap: 'wrap' }}>
        <div style={{ width: 200 }}>
          <SelectOptions
            selectInterfaceDesktop={{ options: 'action-sheet' }}
            rest={{ mode: 'md' } as 'md' | 'ios' | any}
            label={i18n.t('Select android')}
            options={typeof COLOURS !== 'undefined' ? COLOURS : []}
            getId={(option) => option.variable}
            getName={(option) => option.name}
            selectedOption={COLOURS?.find((e) => e.name === 'primary')}
            setSelectedOption={() => console.log('oi')}
          />
        </div>
        <div style={{ width: 200 }}>
          <SelectOptions
            selectInterfaceDesktop={{ options: 'action-sheet' }}
            rest={{ mode: 'ios' } as 'md' | 'ios' | any}
            label={i18n.t('Select iOS')}
            options={typeof COLOURS !== 'undefined' ? COLOURS : []}
            getId={(option) => option.variable}
            getName={(option) => option.name}
            selectedOption={COLOURS?.find((e) => e.name === 'primary')}
            setSelectedOption={() => console.log('oi')}
          />
        </div>
        <div style={{ width: 200 }}>
          <SelectOptions
            rest={{ mode: 'core' } as 'md' | 'ios' | 'core' | any}

            label={i18n.t('Select Web')}
            options={typeof COLOURS !== 'undefined' ? COLOURS : []}
            getId={(option) => option.variable}
            getName={(option) => option.name}
            selectedOption={COLOURS?.find((e) => e.name === 'primary')}
            setSelectedOption={() => console.log('oi')}
          />
        </div>
      </div>
      <br />
    </div>
  );
};

const Pagination = () => {
  return (
    <>
      <div style={{ maxWidth: 500 }}>
        <a style={{ fontSize: 18, color: 'black' }} href="#"><h1 style={{ fontWeight: 800 }}>PAGINATION</h1></a>
        <br />
        <PaginateData
          pageStepper={1 as any}
          currentPage={1}
          totalPages={10}
        />
      </div>
    </>
  );
};

const Loaders = () => {
  const [present, dismiss] = useIonLoading();
  return (
    <>
      <a style={{ fontSize: 18, color: 'black' }} href="#"><h1 style={{ fontWeight: 800 }}>LOADING AND PROGRESSION </h1></a>
      <br />
      <IonGrid>
        <IonRow className='ion-margin-bottom'>          <IonCol>
          <a href='https://ionicframework.com/docs/v6/api/spinner'><h2>Spinner</h2></a>
          <IonSpinner />
        </IonCol>

        </IonRow>
        <IonRow className='ion-margin-bottom'>
          <IonCol size='12'>
            <a href='https://ionicframework.com/docs/v6/api/progress-bar'><h2>Progress bar</h2></a>
            <ProgressBar />
          </IonCol>

        </IonRow>
        <IonRow className='ion-margin-bottom'>
          <IonCol >
            <a href='https://ionicframework.com/docs/v6/api/loading'><h2>Loading</h2></a>
            <IonButton
              onClick={() => {
                present({
                  message: 'Dismissing after 3 seconds...',
                  duration: 3000,
                });
              }}
            >
              Click to show loading

            </IonButton>

          </IonCol>

        </IonRow>
        <IonRow className='ion-margin-bottom'>
          <IonCol>
            <a href='https://ionicframework.com/docs/api/skeleton-text'><h2>Skeleton loaders</h2></a>
            <SkeletonTextThreeLines />
            <SkeletonTextThreeLines />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};
const Charts = () => {
  return (
    <div className='ion-padding'>

      <a style={{ fontSize: 18, color: 'black' }} href="https://recharts.org/en-US/"><h1 style={{ fontWeight: 800 }}>CHARTS (recharts library)</h1></a>
      <IonGrid>
        <IonRow className='ion-justify-content-center ion-align-items-center'>
          <IonCol>
            <h3>Pie chart</h3>
            <PieChart height={300} width={300} data={CHART_DATA} />
          </IonCol>
          <IonCol >
            <h3>Line chart</h3>
            <LineChart
              width={300}
              height={250}
              data={CHART_DATA}
            >
              <Tooltip />
              <XAxis dataKey="name" />
              <YAxis />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </IonCol>
        </IonRow>
      </IonGrid>
      <br />
    </div>
  );
};

const Accordion = () => {
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  return (
    <div className='ion-padding'>
      <a style={{ fontSize: 18, color: 'black' }} href="https://ionicframework.com/docs/api/accordion"><h1 style={{ fontWeight: 800 }}>ACCORDION</h1></a>

      <IonAccordionGroup
        className={isDesktop ? styles['accordion-group-container-desktop'] : styles['accordion-group-container']} >
        {ECONOMY_TOOLS_ACCORDION_LIST.map((item) => (
          <AccordionItem
            key={item.value}
            subpanelTitle={item.subpanelTitle}
            subpanelIcon={item.subpanelIcon}
            subpanelActiveColour={item.subpanelActiveColour}
            subpanelDisabled={item.subpanelDisabled}
            value={item.value}
            isLockable={false}
            locked={item.locked}
            onToggle={() => console.log('')}
          >
            {item.component}
          </AccordionItem>
        ))}
      </IonAccordionGroup>
    </div>
  );
};
// TODO: Add textarea
const InputAndLabels = () => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      currency_input: '',
    },
  });
  return (
    <>
      <IonGrid>
        <FormProvider {...methods}>
          <IonRow className='ion-margin-top'>
            <IonCol>
              <a href="https://ionicframework.com/docs/v6/api/input"><h2>Inputs</h2></a>
            </IonCol>
          </IonRow>

          <IonRow className='ion-margin-top'>
            <IonCol size='12' style={{ borderLeft: `4px solid ${ionicColours.secondary}` }}>
              <CurrencyInput
                padding='ion-no-padding'
                margin='ion-no-margin'
                label={i18n.t('Currency input')}
                register={'currency_input'}
                requiredMessage={i18n.t('Cost per unit is required.') || ''}
                inputMode={'numeric'}
                inputType={'text'}
                placeholder={i18n.t('Currency input')}
                min={{ value: 0, message: i18n.t('Currency can\'t be smaller than 0') }}
              />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-top'>
            <IonCol>
              <InputFloatingLabel
                inputValue=''
                inputMode='text'
                label='Input w/ floating label'
                labelProperty='label'
                id='id'
                inputPlaceholder='Placeholder'
                inputType='text'
                required={false}
                maxLength={10}
                minLength={2}
                validate={() => console.log('validate')}
                pattern={false}
                validationMessage={''}
              />
            </IonCol>
          </IonRow>

          <h3>Text area</h3>
        </FormProvider>
        <IonRow>
          <IonCol>
            <a href='https://ionicframework.com/docs/v6/api/label'><h2>Labels</h2></a>
          </IonCol>
        </IonRow>
        <IonRow className='ion-margin-top'>
          <IonCol>
            <InputLabel>{t('Label')}</InputLabel>
          </IonCol>

        </IonRow>

        <IonRow className='ion-margin-top'>
          <IonCol>
            <a href="https://ionicframework.com/docs/v6/api/badge"><h2>Error messages (badges)</h2></a>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ValidationBadge color='danger'>
              Error message here
            </ValidationBadge>
          </IonCol>
          <IonCol>
            <ValidationBadge color='warning'>
              Warning message here
            </ValidationBadge>
          </IonCol>
          <IonCol>
            <ValidationBadge color='success'>
              Success message here
            </ValidationBadge>
          </IonCol>
          <IonCol>
            <ValidationBadge color='medium'>
              Medium message here
            </ValidationBadge>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

const Swiper = () => {
  const [swiperController, setSwiperController] = useState<SwiperController>(new SwiperController());
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  return (
    <>
      <h3>DESKTOP (even/odd)</h3>
      <SwiperContainer controller={swiperController} slidesPerView={isDesktop ? 4 : 4}>
        {COLOURS.map((colour, index) => {
          return (
            <SwiperSlide key={index} className={styles[`swiper-hover`]}>
              <IonList className={styles[`swipe-list-${index % 2 ? 'odd' : 'even'}`]}>
                <IonItem>
                  <IonLabel style={{ color: colour.variable }}>{colour.name}</IonLabel>
                </IonItem>
              </IonList>
            </SwiperSlide>
          );
        }
        )}
      </SwiperContainer>
      <h3>MOBILE:</h3>
      <SwiperContainer controller={swiperController} slidesPerView={!isDesktop ? 1 : 1}>
        {COLOURS.map((colour, index) => {
          return (
            <SwiperSlide key={index} className={styles[`swiper-hover`]}>
              <IonList
                className={styles[`swipe-list-${isDesktop && (index % 2 ? 'odd' : 'even')}`]}>
                <IonItem>
                  <IonLabel style={{ color: colour.variable }}>{colour.name}</IonLabel>
                </IonItem>
              </IonList>
            </SwiperSlide>
          );
        })}
      </SwiperContainer>
    </>
  );
};

const Toasts = () => {
  const TOASTER_BUTTONS: ToasterButtons[] = [
    {
      backgroundColour: ionicColours.primary,
      content: 'Info',
      icon: information,
      clickHandler: () => toggleToasters('info'),
    },
    {
      backgroundColour: ionicColours.success,
      content: 'Success',
      icon: checkmark,
      clickHandler: () => toggleToasters('success'),
    },
    {
      backgroundColour: ionicColours.danger,
      content: 'Error',
      icon: warning,
      clickHandler: () => toggleToasters('error'),
    },
    {
      backgroundColour: ionicColours.warning,
      content: 'Warning',
      icon: alert,
      clickHandler: () => toggleToasters('warning'),
    },
    {
      backgroundColour: ionicColours.medium,
      content: 'Pending',
      icon: refresh,
      clickHandler: () => toggleToasters('pending'),
    },
  ];

  const toggleToasters = (type: 'info' | 'warning' | 'success' | 'error' | 'pending') => {
    if (type === 'info') {
      return toasters.info('Info toaster');
    }
    if (type === 'warning') {
      toasters.warning('Warning toaster');
    }
    if (type === 'success') {
      return toasters.success('Success toaster');
    }
    if (type === 'error') {
      return toasters.error('Error toaster');
    }
    if (type === 'pending') {
      return toasters.promise(
        new Promise((resolve) => setTimeout(resolve, 2000)),
        {
          success: 'Promise resolved',
          pending: 'Promise pending',
          error: 'Promise rejected',
        }
      );
    }
  };
  return (
    <IonGrid style={{ marginTop: '150px' }}>
      <IonRow className='ion-justify-content-center'>
        <IonCol>
          {TOASTER_BUTTONS.map((button, index) => (
            <ButtonCTA
              key={index}
              backgroundColour={button.backgroundColour}
              content={button.content}
              icon={button.icon}
              clickHandler={button.clickHandler}
            />
          ))}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
