import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import { warningOutline } from 'ionicons/icons';
import React from 'react';

import ButtonCTA from '../../../components/UI/Buttons/CTA/ButtonCTA';
import { ionicColours } from '../../../components/UI/variables';
import SiteAccessRequestStatus from '../../../constants/enums/SiteAccessRequestStatus';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';

interface SiteAccessToggleProps {
  onClick: () => void;
}

const RejectedSiteAccessRequest: React.FC<SiteAccessToggleProps> = ({ onClick }) => {
  const selectedProject: E2U.V1.Models.Project | undefined = useAppSelector((state) => {
    return state.project.selectedProject;
  });

  return (
    <React.Fragment>
      {selectedProject?.site_access_status === SiteAccessRequestStatus.REJECTED && (
        <React.Fragment>
          <IonGrid>
            <IonRow>
              <IonCol size='12'>
                <IonLabel color={'tertiary'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 600 }}>

                  <IonIcon icon={warningOutline} /> {`${t('Your site access request has been rejected for project: ')}`}
                </IonLabel>
                <IonLabel color={'danger'} className='ion-no-margin'>
                  {selectedProject.name}
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='12'>
                <IonLabel color={'tertiary'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 800 }}>
                  {t('Message: ')}
                </IonLabel>
                <br />
                <IonLabel className='ion-no-margin' style={{ fontSize: 15, fontWeight: 600 }}>
                  {selectedProject.site_access_status_message}
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonRow>
                <IonCol className='ion-text-right ion-margin-top'>
                  <ButtonCTA
                    backgroundColour={ionicColours.danger}
                    clickHandler={onClick}
                    content={i18n.t('Update request')}
                  />
                </IonCol>
              </IonRow>
            </IonRow>

          </IonGrid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default RejectedSiteAccessRequest;
