import { IonCol, IonGrid, IonIcon, IonRow, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { cloudUpload } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import styles from './Settings.module.scss';
import { networking } from '../../../../../api/networking';
import FileList from '../../../../../components/FileList/FileList';
import BigUp from '../../../../../components/UI';
import DragAndDrop from '../../../../../components/UI/DragAnddrop/DragAndDrop';
import toasters from '../../../../../components/UI/Toasts';
import useFileUpload from '../../../../../hooks/useFileUpload';
import i18n from '../../../../../i18n';
import FileSelectionButton from '../../ControlOfExecution/AddFilesModal/FileSelectionButton';

const SiteAccessRequestsSettings = () => {
  const [accessRequirements, setAccessRequirements] = useState<E2U.V1.Models.ProjectAccessRequirement[]>([]);
  const { uuid } = useParams<{ uuid: string }>();
  const { getUploadedFiles, handleFileSelection, onlyUploadFiles, removeFile, setUploadedFiles } = useFileUpload();
  const files = getUploadedFiles() ?? [];
  const [alert] = useIonAlert();

  const getRequirements = () => {
    networking.get(`/api/v1/projects/${uuid}/access_requirements`)
      .then((response) => {
        setAccessRequirements(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.createToast({
          message: i18n.t('Failed to get project requirements'),
          background: 'var(--ion-color-light)',
        }, 'error');
      });
  };

  const uploadRequirements = () => {
    const promise = onlyUploadFiles().map((file) =>
      file.then((response) =>
        networking.post(`api/v1/project_access_requirements`, {
          project_id: uuid,
          file: response.data.data.id,
          name: response.data.data.name,
          file_id: response.data.data.id
        })
          .then(() => {
            getRequirements();
          })
          .catch((error) => {
            Sentry.captureException(error);
          })
      )
    );
    const allUploads = Promise.allSettled(promise);

    const handleToasters = (successful: boolean) => {
      if (successful) {
        setUploadedFiles([]);
      }
    };

    toasters.promiseToast(
      allUploads,
      {
        pending: {
          message: i18n.t('Uploading files'),
          background: 'var(--ion-color-light)',
        },
        success: {
          message: i18n.t('All files uploaded successfully'),
          background: 'var(--ion-color-light)',
          textColour: 'var(--ion-color-dark)',
        },
        error: {
          message: i18n.t('Some files could not be uploaded'),
          background: 'var(--ion-color-light)',
          textColour: 'var(--ion-color-dark)',
        },
      }
    )
      .then((results: PromiseSettledResult<E2U.V1.Models.Document>[]) => {
        const successful = results.every((result) => result.status === 'fulfilled');
        handleToasters(successful);
      })
      .catch((error) => {
        handleToasters(false);
        Sentry.captureException(error);
      });
  };

  const getRequirementFiles = () => {
    return accessRequirements.map((requirement) => {
      return {
        name: requirement.file?.name,
        file_hash: requirement.file?.file_hash,
        id: requirement.file?.id,
        type: requirement.file?.type,
        export_url: requirement.file?.export_url,
      };
    });
  };

  const deleteRequirement = (id: string | undefined) => {
    networking.delete(`/api/v1/project_access_requirements/${id}`)
      .then(() => {
        getRequirements();
        toasters.createToast({
          message: i18n.t('File deleted successfully'),
          background: 'var(--ion-color-light)',
        }, 'success');
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.createToast({
          message: i18n.t('Failed to delete file'),
          background: 'var(--ion-color-light)',
        }, 'error');
      });
  };

  const confirmDelete = (id: string | undefined) => {
    alert({
      header: i18n.t('Delete file'),
      message: i18n.t('Are you sure you want to delete this file?'),
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel',
        },
        {
          text: i18n.t('Delete'),
          handler: () => deleteRequirement(id),
        },
      ],
    });
  };

  useEffect(() => {
    getRequirements();
  }, []);

  return (
    <IonGrid className='ion-padding-horizontal'>
      <IonRow className='ion-margin-bottom'>
        <IonCol>
          <BigUp.Title label={i18n.t('Project documents')} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <>
            <IonRow className='ion-margin-vertical ion-justify-content-center' >
              <IonCol sizeSm={'10'}>
                <DragAndDrop onFilesSelect={(files: File[]) => handleFileSelection(files)}>
                  <div className={styles['file-upload-container']}>
                    <IonIcon
                      icon={cloudUpload}
                      style={{ fontSize: 40 }}
                      color='medium'
                    />
                    <BigUp.Label.Regular
                      label={i18n.t('Drop your files here to upload')}
                      color={'medium'} />
                    <BigUp.Label.Thick
                      className='ion-no-margin'
                      label={i18n.t('or')}
                      color={'medium'} />
                    <FileSelectionButton
                      label={files.length === 0
                        ? i18n.t('Select files')
                        : i18n.t('Add more files')
                      }
                      custom={false}
                      expand='block'
                      responsiveButton={false}
                      onFilesSelect={(files: File[]) => {
                        handleFileSelection(files);
                      }}
                    />
                  </div>
                </DragAndDrop>
              </IonCol>
            </IonRow>
            {files && files.length > 0 && (
              <>
                <IonRow>
                  <IonCol>
                    <BigUp.Label.Regular
                      className='ion-no-margin'
                      label={i18n.t('Selected files for upload')}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FileList files={files} removeFile={removeFile} photos={[]} />
                  </IonCol>
                </IonRow>
              </>
            )}
          </>
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-end ion-margin-bottom'>
        <IonCol className='ion-text-end' size='5' sizeXs='5' sizeMd='2'>
          {files.length > 0 && (
            <BigUp.Buttons.Regular
              expand='block'
              disabled={files.length === 0}
              title={i18n.t('Upload')}
              onClick={uploadRequirements}
            />
          )}
        </IonCol>
      </IonRow>

      <IonRow className='ion-margin-vertical'>
        <IonCol>
          <BigUp.Label.Regular className='ion-no-margin' label={i18n.t('Project documents')} />
        </IonCol>
      </IonRow>
      <FileList
        photos={[]}
        files={getRequirementFiles() as E2U.V1.Models.File[]}
        handleDeleteRequest={(file) => {
          const id = accessRequirements.find((requirement) => requirement.file?.file_hash === file.file_hash)?.id;
          confirmDelete(id);
        }}
      />
    </IonGrid>
  );
};

export default SiteAccessRequestsSettings;
