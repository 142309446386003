import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router';

import RequestSuccessfulPage from './RequestSuccessful';
import CreateAccountForm from '../../components/ClientAccount/CreateAccountForm';
import { WrappedRoute } from '../../routes/WrappedRoute';

const ClientAccountPage: React.FC = () => {
  return (
    <Switch>
      <WrappedRoute path={'/client-account/create'} exact={true}>
        <CreateAccountForm />
      </WrappedRoute>
      <WrappedRoute path={'/client-account/success'} exact={true}>
        <RequestSuccessfulPage />
      </WrappedRoute>
      <WrappedRoute path={'*'}>
        <Redirect to={'/'} />
      </WrappedRoute>
    </Switch>
  );
};

export default ClientAccountPage;
