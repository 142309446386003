import { FormatIcu } from '@tolgee/format-icu';
import { I18nextPlugin, withTolgee } from '@tolgee/i18next';
import { DevTools, ObserverPlugin, Tolgee } from '@tolgee/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_gb from './lang/en-gb/translation.json';
import sv_se from './lang/sv-se/translation.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en_gb
  },
  sv: {
    translation: sv_se
  }
};

export const tolgee = Tolgee()
  .use(DevTools())
  .use(I18nextPlugin())
  .use(ObserverPlugin())
  .init({
    language: 'sv',
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
    staticData: resources,
    defaultNs: 'translation',
    ns: ['translation'],
    defaultLanguage: 'sv',
    availableLanguages: ['sv', 'en'],
  });

tolgee.addPlugin(FormatIcu());

withTolgee(i18n, tolgee)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'sv',
    supportedLngs: ['sv', 'en'],
    interpolation: {
      escapeValue: false, // react already safes from xss,
      prefix: '{',
      suffix: '}'
    },
    defaultNS: 'translation',
    ns: ['translation'],
    resources
  });

export default i18n;
