import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import type { IonicColSize } from './variables';

interface DeleteRecordProps {
  record: string;
  onDelete: (id: string) => void;
  label: string;
  colSize?: IonicColSize;
  hasPadding?: boolean;
}

const DeleteRecord: React.FC<DeleteRecordProps> = ({ colSize, hasPadding = true, label, onDelete, record }) => {
  const { t } = useTranslation();
  return (
    <IonGrid className={hasPadding ? 'ion-padding' : ''}>
      <IonRow>
        <IonCol size={colSize || '8'}>
          <IonLabel color={'medium'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 800 }}>
            {label}
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={colSize || '8'} >
          <IonLabel
            className={'ion-no-margin'}
            style={{ fontSize: 15, fontWeight: 800, cursor: 'pointer' }}
            color={'danger'}
            onClick={() => { onDelete && onDelete(record); }}
          >
            {t('Delete')}
          </IonLabel>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DeleteRecord;
