import Description from './Description';
import Equipment from './Equipment';
import FollowUp from './FollowUp';
import Project from './Project';
import Risks from './Risks';

const Section = {
  Project,
  Description,
  Risks,
  Equipment,
  FollowUp,
};

export default Section;
