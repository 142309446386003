import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonSearchbar } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { filterOutline } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderBorderLeft from '../../../../../components/UI/modals/HeaderBorderLeft';
import PaddedModalContent from '../../../../../components/UI/modals/PaddedModalContent';
import { ionicColours } from '../../../../../components/UI/variables';
import i18n from '../../../../../i18n';

interface AllControls {
  toggleControls: () => void;
  records: E2U.V1.Models.ControlOfExecution[];
}

const AllControls: React.FC<AllControls> = ({ records, toggleControls }) => {
  // TODO: Show all controls assigned to the user
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <HeaderBorderLeft
        title={i18n.t('All controls')}
        clickHandler={() => toggleControls()}
        borderColour={ionicColours.secondary}
      />
      <PaddedModalContent>
        <IonGrid className='ion-no-padding'>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <IonCol size="6" className='ion-no-padding'>
              <IonItem className="ion-no-padding" lines='none'>
                <IonSearchbar
                  placeholder={i18n.t('search')}
                  mode="ios"
                  color={'none'}
                  className='ion-no-padding'
                />
              </IonItem>
            </IonCol>

            <IonCol size="6" className='ion-no-padding ion-text-right ' >
              <IonItem className='ion-no-padding' lines='none'>
                <IonButton
                  size='default'
                  fill='clear'
                  mode='ios'
                  color='dark'
                  id='bottom-start'
                >
                  <IonLabel >
                    {t('show all')}
                  </IonLabel>
                  <IonIcon
                    slot='end'
                    icon={filterOutline}
                    color={'dark'}
                  />
                </IonButton>
              </IonItem>
              <IonPopover
                trigger="bottom-start"
                dismissOnSelect={true}
                side='bottom'
                alignment='center'
                size='cover'
              >
                <IonContent className='ion-no-padding'>
                  <IonList style={{ height: '100px' }}>
                    <IonItem lines='none' style={{ cursor: 'pointer' }}>{t('attested')}</IonItem>
                    <IonItem lines='none' style={{ cursor: 'pointer' }}>{t('unattested')}</IonItem>
                  </IonList>
                </IonContent>
              </IonPopover>
            </IonCol>
          </IonRow>
        </IonGrid>
      </PaddedModalContent>
    </React.Fragment>
  );
};

export default AllControls;
