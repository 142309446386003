import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NoSearchResults: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonGrid>
      <IonRow className='ion-justify-content-center ion-align-items-center'>
        <IonCol className='ion-text-center'>

          <span style={{ textAlign: 'center', width: '90%', fontSize: '20px' }}>
            {t('No results found.')}
          </span>

        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default NoSearchResults;
