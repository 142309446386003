import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow, useIonRouter } from '@ionic/react';
import classNames from 'classnames';
import { t } from 'i18next';
import { add, calculatorOutline } from 'ionicons/icons';
import React from 'react';
import { useParams } from 'react-router';

import { BigUp } from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import usePermissionHandler from '../../../hooks/usePermissions';
import ProjectList from '../ProjectList';
import styles from '../ProjectList/ProjectList.module.scss';

interface ListPageProps {
  title?: string | undefined;
  description: string;
}

const ProjectListPage: React.FC<ListPageProps> = ({ description }) => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { checkPermission } = usePermissionHandler();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const history = useIonRouter();

  const handleRedirect = (route: string, param: 'control-of-execution' | 'project-information') => {
    if (isDesktop) {
      history.push(`/${route}/${uuid}/${param}`);
    } else {
      history.push(`/${route}/${uuid}`);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
      <div className={classNames('ion-padding', styles['project-list-page-check-information-wrapper'])} >
        <IonGrid className='ion-no-margin'>
          <IonRow className={'ion-no-padding ion-no-margin'} >
            <IonCol size={'12'} className={'ion-no-padding'}>
              <BigUp.Title label={t('Tools')} className={'ion-no-margin ion-no-padding'} />
            </IonCol>
            <IonCol size={'12'} className={'ion-no-padding'}>
              <IonList mode='ios'>
                <IonItem button className='ion-no-padding' routerLink='/economy-tools/precalculations'>
                  <IonIcon icon={calculatorOutline} slot="start"></IonIcon>
                  <IonLabel>{t('My precalculations')}</IonLabel>
                </IonItem>
                {/*
                TODO: Keep until logic has been implemented to work preparation form
                */}
                {/*
                <IonItem button className='ion-no-padding' onClick={() => history.push('/work-preparation', 'none')}>
                  <IonIcon icon={clipboardOutline} slot="start"></IonIcon>
                  <IonLabel>{t('Work preparations')}</IonLabel>
                </IonItem>
                */}
                <IonItem button className='ion-no-padding' onClick={() => history.push('/tools/create-project')}>
                  <IonIcon icon={add} slot="start"></IonIcon>
                  <IonLabel>{t('Create a new project')}</IonLabel>
                </IonItem>
                {/* TODO: Keep until this for the tab bar implementation

                  {uuid && (
                        <>
                          {checkPermission('project_tools_access', 'read') && (
                            <IonItem button className='ion-no-padding'
                              onClick={() => handleRedirect('project-tools', 'control-of-execution')}>
                              <IonIcon icon={hammerOutline} slot="start"></IonIcon>
                              <IonLabel>{t('Project tools')}</IonLabel>
                            </IonItem>
                          )}

                        </>
                      )}
                      {checkPermission('economy_tools_access', 'read') && (
                        <IonItem button className='ion-no-padding'
                          onClick={() => handleRedirect('economy-tools', 'project-information')}
                        >
                          <IonIcon icon={statsChartOutline} slot="start"></IonIcon>
                          <IonLabel>{t('Economy tools')}</IonLabel>
                        </IonItem>
                      )}
                 */}
              </IonList>
            </IonCol>
          </IonRow>

        </IonGrid>
        <ProjectList />
      </div>
    </div>
  );
};

export default ProjectListPage;
