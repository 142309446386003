import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import HeaderBackground from '../../../components/UI/Header/HeaderBackground';
import i18n from '../../../i18n';
import PasswordResetForm from '../PasswordResetForm';

const PasswordResetPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent>
        <HeaderBackground
          title={i18n.t('Reset password')}
          subtitle={i18n.t('Enter your email address below to reset your password')}
        />
        <PasswordResetForm />
      </IonContent>
    </IonPage>
  );
};

export default PasswordResetPage;
