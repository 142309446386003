import { IonItem, IonLabel } from '@ionic/react';
import classNames from 'classnames';

import styles from './Titles.module.scss';

interface ListTitleProps {
  label: string;
  children?: React.ReactNode;
  width?: string | number;
}

const ListTitle: React.FC<ListTitleProps> = (
  { children, label, width }
) => {
  return (
    <IonItem
      className={classNames(styles['support-header'], 'ion-no-padding  ion-no-margin ion-text-wrap')}
      style={{ width: width || '80%' }}
      color={'none'}
      lines='none'>
      <IonLabel className='ion-no-margin ion-no-padding'>
        <div style={{ hyphens: 'auto' }}>
          <h1 >{label}</h1>
        </div>
      </IonLabel>
      {children}
    </IonItem>
  );
};

export default ListTitle;
