import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import ListTitle from '../../../../components/UI/Titles/ListTitle';
import i18n from '../../../../i18n';
import PasswordInputs from '../../../Registration/PasswordInputs';

const PasswordChange: React.FC = () => {
  const { t } = useTranslation();
  const methods = useFormContext();

  const toastMessages = {
    pending: i18n.t('Saving changes'),
    success: i18n.t('Your information has been updated.'),
    error: i18n.t('Something went wrong, try again later.')
  };

  const updateUserSettings: SubmitHandler<FieldValues> = (data: FieldValues) => {
    toasters.promise(networking.post(`/api/v1/users/reset_password/change`, data), toastMessages);
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(updateUserSettings)}>
          <IonGrid className='ion-margin-start ion-margin-end'>
            <IonCol size='9' className='ion-margin-bottom'>
              <ListTitle label={i18n.t('Change password')} width={'100%'} />
            </IonCol>
            <PasswordInputs required />
            <IonRow>
              <IonCol className='ion-text-end'>
                <BigUp.Buttons.Regular
                  title={t('Save')}
                  color='secondary'
                  type='submit'
                  disabled={methods.formState.isSubmitting || !methods.formState.isValid}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default PasswordChange;
