import { useIonRouter } from '@ionic/react';
import { chatboxOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { WorkPreparation } from '..';
import BigUp from '../../../../components/UI';
import i18n from '../../../../i18n';

interface RiskProps {
  id: string;
  name: string;
  value: string;
}

const risks: RiskProps[] = [
  {
    id: '1',
    name: 'Fall from height',
    value: 'fall_from_height',
  },
  {
    id: '2',
    name: 'Heavy lifting',
    value: 'heavy_lifting',
  },
  {
    id: '3',
    name: 'Electricity',
    value: 'electricity',
  },
  {
    id: '5',
    name: 'Hot work',
    value: 'hot_work',
  }
]; const Risks: React.FC = () => {
  const [riskState, setRiskState] = useState(
    Object.fromEntries(
      risks.map(risk => [risk.id, { isSelected: false, isModalOpen: false }])
    ) as Record<string, { isSelected: boolean; isModalOpen: boolean }>
  );

  const methods = useFormContext<WorkPreparation>();
  const router = useIonRouter();
  const toggleRiskSelection = (riskId: string, riskValue: string) => {
    setRiskState((prevState) => {
      const newSelectedState = !prevState[riskId].isSelected;

      let updatedRisks = methods.getValues('risks') || [];
      if (newSelectedState) {
        updatedRisks.push(riskValue);
      } else {
        updatedRisks = updatedRisks.filter((selectedRisk) => selectedRisk !== riskValue);
      }
      methods.setValue('risks', updatedRisks);

      return {
        ...prevState,
        [riskId]: {
          ...prevState[riskId],
          isSelected: newSelectedState,
        },
      };
    });
  };

  const toggleModalForRisk = (riskId: string, isOpen: boolean) => {
    setRiskState((prevState) => ({
      ...prevState,
      [riskId]: {
        ...prevState[riskId],
        isModalOpen: isOpen,
      },
    }));
  };

  const items = risks.map((inputProp) => ({
    id: inputProp.id,
    content: (
      <>
        <BigUp.Checkbox
          style={{ marginRight: 8 }}
          labelPlacement="start"
          labelProps={{
            label: inputProp.name ?? '',
          }}
          handleCheckbox={() => toggleRiskSelection(inputProp.id, inputProp.value)}
        />
        {riskState[inputProp.id]?.isSelected && (
          <BigUp.Buttons.Icon
            onClick={() => toggleModalForRisk(inputProp.id, true)}
            fill='clear'
            className='ion-no-margin'
            padding={20}
            icon={{
              icon: chatboxOutline,
              color: 'primary',
              size: 'large',
            }}
            color={'light'}
          />
        )}
        <BigUp.MediaUploader
          title={inputProp.name}
          id={inputProp.id}
          isOpen={riskState[inputProp.id]?.isModalOpen}
          dismiss={() => toggleModalForRisk(inputProp.id, false)}
        />
      </>
    ),
  }));

  return <BigUp.ItemList
    header={{ title: i18n.t('Risks') }}
    items={items}
    actionButton={{
      title: i18n.t('Add risk assessment'),
      onClick: () => router.push('/work-preparation/risk-assessment')
    }}
  />;
};

export default Risks;
