import { IonButton, IonCol, IonGrid, IonItem, IonList, IonRow, IonTitle } from '@ionic/react';
import { useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import TeamInviteInputs from './TeamInviteInputs';
import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import type { Permissions } from '../../../components/UI/Permissions/config';
import { defaultValues, permissions } from '../../../components/UI/Permissions/config';
import PermissionSelection from '../../../components/UI/Permissions/PermissionSelection';
import { useAppSelector } from '../../../hooks';
import usePermissionSelect from '../../../hooks/usePermissionSelect';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import type { TeamsPromises } from '../interfaces';

const TeamInviteForm: React.FC<TeamsPromises> = ({ fetchTeam }) => {
  const { team_id } = useParams<{ team_id: string | undefined }>();
  const { handlePermissionSelection, selectedPermissions, setSelectedPermissions } = usePermissionSelect();

  const [error, setError] = useState<string | null>(null);
  const pending = useAppSelector(state => state.loading.isLoading.teamPost);
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      roles: {},
    }
  });

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setIsLoading({ name: 'teamPost', value: true }));
    toasters.promise(networking.post(`/api/v1/teams/${team_id}/invite`, {
      ...data,
      project_tools_access: selectedPermissions.project_tools,
      economy_tools_access: selectedPermissions.economy_tools
    }), {
      error: error?.toString() || t('Error inviting user'),
      success: i18n.t('User invited successfully'),
      pending: i18n.t('Inviting user')
    })
      .catch((error) => setError(error.message))
      .finally(() => {
        fetchTeam && fetchTeam();
        store.dispatch(setIsLoading({ name: 'teamPost', value: false }));
        setSelectedPermissions(defaultValues);
        methods.reset();
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem className='ion-no-padding' lines='none'>
                <IonTitle className='ion-no-margin ion-no-padding' style={{ fontWeight: 600 }}>
                  {t('User information')}
                </IonTitle>
              </IonItem>
              <TeamInviteInputs />
              <IonList className='ion-no-margin'>
                <IonItem className='ion-no-padding' lines='none' >
                  <IonTitle className='ion-no-margin ion-no-padding' style={{ fontWeight: 600 }}>
                    {t('Permissions')}
                  </IonTitle>
                </IonItem>
                <PermissionSelection
                  data={permissions}
                  handleSelection={(value: Permissions) => handlePermissionSelection(value, 'project_tools')}
                  selected={selectedPermissions.project_tools}
                  title={i18n.t('Project tools')}
                />
                <PermissionSelection
                  data={permissions}
                  handleSelection={(value: Permissions) => handlePermissionSelection(value, 'economy_tools')}
                  selected={selectedPermissions.economy_tools}
                  title={i18n.t('Economy tools')}
                />
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className='ion-text-right'>
              <IonButton
                color={'secondary'}
                disabled={pending || !methods.formState.isValid || methods.formState.isSubmitting}
                type="submit"
              >
                {t('Invite')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </FormProvider>
  );
};

export default TeamInviteForm;
