import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonProgressBar, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { checkmark, chevronBack } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import { BigUp } from '../../../UI';
import type { EventType, SelectList, SelectListEntities } from '../MultiSelectList';

const RelatedMultiSelectList: React.FC<SelectList> = ({
  action,
  callbackName,
  callbackResults,
  category,
  displayFields,
  keyField = 'id',
  modalTitle,
  model,
  onChange,
  postSelected,
  queryArgs = {
    per_page: '9999',
  },
  records,
  toggleModal,
  value
}) => {
  const [results, setResults] = useState<SelectListEntities[`${typeof model}`][]>([]);
  const [resultsDisabled, setDisabledResults] = useState<SelectListEntities[`${typeof model}`][]>([]);
  const [selected, setSelected] = useState<SelectListEntities[`${typeof model}`][]>(value ?? []);
  const [searchValue, setSearchValue] = useState<string>();
  const ionContentRef = useRef<HTMLIonContentElement | null>(null);
  const searchBarRef = useRef<HTMLIonSearchbarElement | null>(null);
  const { t } = useTranslation();

  const isLoading = useAppSelector(state => state.loading.isLoading.multiSelectList);

  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(queryArgs).forEach(([key, value]) => {
    searchParams.append(key, value);
  });

  if (searchValue) {
    searchParams.append('search', searchValue);
  }

  const fetchRecords = () => {
    if (records && records.length > 0) {
      setResults(records);
      return;
    }

    store.dispatch(setIsLoading({ name: 'multiSelectList', value: true }));

    networking.get(`/api/v1/${model}?${searchParams}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<SelectListEntities[`${typeof model}`]> | SelectListEntities[`${typeof model}`]>) => {
        if (callbackResults) {
          callbackResults(response.data.data.records ?? response.data.data, setResults, setDisabledResults);
        } else {
          setResults(response.data.data.records ?? response.data.data);
        }
      })
      .catch(() => {
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'multiSelectList', value: false
        }));
      });
  };

  const isDisabled = (result: SelectListEntities[`${typeof model}`]) => {
    return resultsDisabled.findIndex((resultDisabled) => resultDisabled.id === result.id) !== -1;
  };

  const findSelected = (key?: string) => {
    if (!key) {
      return -1;
    }
    return selected.findIndex((selectedResult) => {
      return selectedResult[keyField] === key;
    });
  };

  const handleSelect = (result: SelectListEntities[`${typeof model}`]) => {
    const foundIndex = findSelected(result[keyField]);
    if (foundIndex !== -1) {
      const selectedClone = [...selected];
      delete selectedClone[foundIndex];
      setSelected(selectedClone.filter((item) => typeof item !== 'undefined'));
    } else {
      setSelected([
        ...selected, result
      ]);
    }
  };

  const confirmSelection = (e: EventType<HTMLIonButtonElement, React.MouseEvent<HTMLIonButtonElement>>) => {
    if (action === 'submit') {
      postSelected && postSelected(selected, e.preventDefault());
    }
    onChange(selected);
    if (toggleModal) {
      toggleModal();
    }
  };

  const checkDisplayArray = (input: string | string[]) => {
    return Array.isArray(input)
      ? input.join(', ')
      : input;
  };

  const getDisplayValue = (result: SelectListEntities[`${typeof model}`] | any): string => {
    if (callbackName) {
      return callbackName(result);
    }

    return displayFields?.map((field: string | string[]) => Array.isArray(field)
      ? checkDisplayArray(result[field[0]][field[1]])
      : checkDisplayArray(result[field])).join(' ') ?? '';
  };

  const getCategoryDisplay = (result: SelectListEntities[`${typeof model}`] | any): string => {
    return category?.map((field: string | string[]) => Array.isArray(field)
      ? checkDisplayArray(result[field[0]][field[1]])
      : checkDisplayArray(result[field])).join(' ') ?? '';
  };

  const handleChange = (ev: Event) => {
    const target = ev.target as HTMLIonSearchbarElement;
    if (target && typeof target.value === 'string') {
      setSearchValue(target.value);
    } else {
      setSearchValue('');
    }
  };

  const selectAllInList = () => {
    if (results.length === selected.length) {
      setSelected([]);
    } else {
      setSelected(results);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [searchValue]);

  return (
    <React.Fragment>
      <IonHeader className='ion-no-border ion-margin-top ion-margin-bottom' style={{ background: 'var(--ion-color-none)' }}>
        <IonToolbar color={'none'}>
          <IonButtons slot='start'>
            <IonButton onClick={toggleModal}>
              <IonIcon icon={chevronBack} color={'dark'} />
            </IonButton>
          </IonButtons>
          <IonTitle color={'dark'}>{modalTitle}</IonTitle>
          <IonButtons slot='end'>
            <IonButton strong={true} onClick={(e) => confirmSelection(e as EventType<HTMLIonButtonElement, React.MouseEvent<HTMLIonButtonElement>>)}>
              <IonIcon icon={checkmark} color={'dark'} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-no-padding" ref={ionContentRef} scrollY style={{ '--ion-background-color': 'none' }}>
        <IonSearchbar
          color={'light'}
          debounce={500}
          onIonInput={(ev) => handleChange(ev)}
          ref={searchBarRef}
        />
        <IonList>
          {isLoading
            ? <IonProgressBar type="indeterminate" />
            : (
              <>
                <IonItem>
                  <BigUp.Checkbox
                    labelPlacement='start'
                    itemProps={{ className: 'ion-margin-bottom' }}
                    checked={results.length === selected.length}
                    handleCheckbox={selectAllInList}
                  >
                    {t('Select all')}
                  </BigUp.Checkbox>
                </IonItem>
                {results.map((result) => {
                  return (
                    <IonItem key={result[keyField]}>
                      <BigUp.Checkbox
                        labelPlacement='start'
                        checked={findSelected(result[keyField]) !== -1}
                        handleCheckbox={() => handleSelect(result)}
                        disabled={isDisabled(result)}>
                        <BigUp.Label.Thick label={getCategoryDisplay(result)} />
                        {getDisplayValue(result)}
                      </BigUp.Checkbox>
                    </IonItem>
                  );
                })}
              </>
            )
          }
        </IonList>
      </IonContent>
    </React.Fragment>
  );
};

export default RelatedMultiSelectList;
