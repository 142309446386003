import { useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { close } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../../components/UI';
import SiteAccessRequestStatus from '../../../../constants/enums/SiteAccessRequestStatus';
import i18n from '../../../../i18n';
import type { SiteAccessIndicator } from '../../../../tools/getSiteAccessIndicator';
import getSiteAccessStatusIndicator from '../../../../tools/getSiteAccessIndicator';

interface SiteAccessButtonInterface {
  project: E2U.V1.Models.Project;
  onClick: (project: E2U.V1.Models.Project) => void;
}

const SiteAccessButton: React.FC<SiteAccessButtonInterface> = ({ onClick, project }) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const [siteAccessIndicator, setSiteAccessIndicator] = useState<SiteAccessIndicator>({
    icon: close,
    color: 'none'
  });

  const workoutDisplay = () => {
    setSiteAccessIndicator(
      getSiteAccessStatusIndicator(project.site_access_status as SiteAccessRequestStatus)
    );
  };

  const handleClick = () => {
    if (project.site_access_status === SiteAccessRequestStatus.REJECTED) {
      return presentAlert({
        header: i18n.t('Your request was denied'),
        message: project.site_access_status_message,
        subHeader: i18n.t('Reason:'),
        buttons: [
          {
            text: i18n.t('Cancel'),
            role: 'cancel',
          },
          {
            text: i18n.t('Ok'),
            role: 'confirm',
            handler: () => {
              onClick(project);
            }
          }
        ],
      });
    }

    onClick(project);
  };

  useEffect(() => {
    workoutDisplay();
  }, [project]);

  return (
    <>
      <BigUp.Buttons.Icon
        onClick={handleClick}
        color={siteAccessIndicator.color}
        fill='solid'
        disabled={project.has_site_access}
        icon={{
          icon: siteAccessIndicator.icon,
          size: 'large'
        }}
      />
      <p className='ion-no-margin'>{t('Access')}</p>
    </>
  );
};

export default SiteAccessButton;
