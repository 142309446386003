import type { AlertOptions } from '@ionic/react';
import { IonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import type { InputProps } from '../../../../components/UI/Inputs/Input.interface';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import useFetchProjects from '../../../../hooks/useFetchProjects';
import usePermissionHandler from '../../../../hooks/usePermissions';
import i18n from '../../../../i18n';
import { setSelectedProject } from '../../../../reducers/project';
import store from '../../../../store';

const inputProps: InputProps<any>[] = [
  {
    label: i18n.t('Company'),
    placeholder: i18n.t('Company'),
    disabled: true,
    labelPlacement: 'start',
    register: 'company',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Project'),
    placeholder: i18n.t('Project'),
    labelPlacement: 'start',
    disabled: true,
    register: 'project',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Contact person'),
    placeholder: i18n.t('Contact person'),
    labelPlacement: 'start',
    register: 'contact_person',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Activity'),
    placeholder: i18n.t('Activity'),
    labelPlacement: 'start',
    register: 'activity',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Work activities'),
    labelPlacement: 'start',
    register: 'work_activities',
    placeholder: i18n.t('Work activities'),
    validation: {
      required: false,
    },
  },
];

const Project: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const fetchProjects = useFetchProjects();
  const methods = useFormContext();
  const projects = useAppSelector(state => state.project.projects);
  const project = useAppSelector(state => state.project.selectedProject);
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  const items = inputProps.map((inputProp, index) => ({
    id: (index + 1).toString(),
    content: <BigUp.Input {...inputProp} lines={false} />,
  }));

  const handleAlert = () => {
    fetchProjects();
    setIsOpen(true);
  };

  const inputs = checkPermissionsForProjectAndTools('read', 'read')?.map(project => ({
    label: project.name || '',
    type: 'radio',
    value: project.id || '',
  })) || [];

  const handleSelectedProject = (projectId: string) => {
    networking.get(`/api/v1/projects/${projectId}`)
      .then((response) => {
        store.dispatch(setSelectedProject(response.data.data));
        setIsOpen(false);
      })
      .catch((error) => {
        toasters.createToast({
          message: i18n.t('Error while looking for project'),
          background: 'var(--ion-color-light)',
        }, 'error');
      });
  };

  useEffect(() => {
    methods.setValue('project', project?.name);
    methods.setValue('project_id', project?.id);
  }, [project?.id]);

  return (
    <FormProvider {...methods}>
      <BigUp.ItemList
        header={{ title: i18n.t('Project') }}
        items={items}
        actionButton={{
          id: 'present-alert',
          title: i18n.t('Select project'),
          onClick: () => handleAlert(),
        }}
      />
      <IonAlert
        isOpen={isOpen}
        inputs={inputs as AlertOptions[]}
        buttons={[
          {
            text: i18n.t('Cancel'),
            role: 'cancel',
          },
          {
            text: i18n.t('Select'),
            role: 'confirm',
            handler: (projectId) => {
              handleSelectedProject(projectId);
            },
          },
        ]}
        onDidDismiss={() => setIsOpen(false)}
      />
    </FormProvider>
  );
};

export default Project;
