import * as Sentry from '@sentry/capacitor';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { NoticeFormValues, Visibility } from './Form/form.interface';
import type { NoticeStatus } from './notice.models';
import { networking } from '../../api/networking';
import i18n from '../../i18n';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';

const useNoticeBoardList = () => {
  const [filterValues, setFilterValues] = useState<NoticeFormValues['level'][]>(['danger', 'warning', 'info']);
  const [visibilityValues, setInternalVisibilityValues] = useState<NoticeFormValues['visibility'][]>(['unseen']);
  const [notices, setNotices] = useState<NoticeFormValues[]>([]);
  const [sortNoticesBy, setSortNoticesBy] = useState<{
    date: 'publish_at' | 'expire_at',
    direction: 'asc' | 'desc'
  }>({
    date: 'publish_at',
    direction: 'desc'
  });
  const [projectNotices, setProjectNotices] = useState<NoticeFormValues[]>([]); // TODO: Implement once available from server
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslation();

  const getUsersNotices = () => {
    store.dispatch(setIsLoading({ name: 'notices', value: true }));
    networking.get('/api/v1/notices?with=teams')
      .then((response) => {
        setNotices(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        return store.dispatch(setIsLoading({ name: 'notices', value: false }));
      });
  };

  const getProjectNotices = () => {
    const filters = new URLSearchParams();
    filters.append('direction', sortNoticesBy.direction);
    filters.append('sort_by', sortNoticesBy.date);
    if (visibilityValues.length > 0) {
      visibilityValues.forEach((value) => {
        filters.append('status[]', value);
      });
    }
    networking.get(`/api/v1/projects/${uuid}/notices?${filters.toString()}`)
      .then(response => {
        setProjectNotices(response.data.data.records);
      });
  };

  const setVisibilityValues = (values: NoticeFormValues['visibility'][]) => {
    if (values.includes('all')) {
      setInternalVisibilityValues(['all', 'unseen', 'seen', 'archived']);
    } else {
      setInternalVisibilityValues(values);
    }
  };

  const toggleVisibility = (key: Visibility) => {
    let updatedValues: Visibility[] = [];

    if (key === 'all') {
      if (visibilityValues.includes('all')) {
        updatedValues = [];
      } else {
        updatedValues = ['all', 'unseen', 'seen', 'archived'];
      }
    } else {
      updatedValues = visibilityValues.includes(key)
        ? visibilityValues.filter(item => item !== key)
        : [...visibilityValues, key];
      if (updatedValues.includes('all')) {
        updatedValues = updatedValues.filter(item => item !== 'all');
      }
    }
    setVisibilityValues(updatedValues);
  };

  const findStatus = (status: NoticeStatus | boolean, noticeType: 'by-project' | 'by-user') => {
    const selectedLevels = filterValues.length > 0 ? filterValues : ['danger', 'warning', 'info'];
    if (filterValues.length === 0) {
      return memoizedNotices;
    } else {
      /*
      TODO: Implement once available from server
      const meetsStatusCondition =
      (status === statusVariables.unseen && !item.has_seen) ||
      (status === statusVariables.seen && item.has_seen) ||
      (status === statusVariables.archived && item.archived);
      */
      /*  return (() => {
         if(noticeType === 'by-project')
         (memoizedProjectNotices && memoizedProjectNotices.filter((item) => selectedLevels.includes(item.level))) ||
         (memoizedNotices.filter((item) => selectedLevels.includes(item.level))))

       }  */

      if (noticeType === 'by-project') {
        return memoizedProjectNotices.filter((item) => selectedLevels.includes(item.level));
      }
      if (noticeType === 'by-user') {
        return memoizedNotices.filter((item) => selectedLevels.includes(item.level));
      }
    }
  };

  const getEmptyListMessage = (): string => {
    if (filterValues.length > 0) {
      return t('No notices found with the selected filters');
    }
    return t('No notices found');
  };

  const visibilityInputs = [
    {
      level: 'all',
      type: 'checkbox',
      label: i18n.t('All'),
      value: 'all',
      checked: visibilityValues.length === 4,
    },
    {
      level: 'unseen',
      type: 'checkbox',
      label: i18n.t('Unseen'),
      value: 'unseen',
      checked: visibilityValues.includes('unseen'),
    },
    {
      level: 'seen',
      type: 'checkbox',
      label: i18n.t('Seen'),
      value: 'seen',
      checked: visibilityValues.includes('seen'),
    },
    {
      level: 'archived',
      type: 'checkbox',
      label: i18n.t('Archived'),
      value: 'archived',
      checked: visibilityValues.includes('archived'),
    },
  ];

  const filterInputs = [
    {
      level: 'danger',
      type: 'checkbox',
      label: i18n.t('Danger'),
      value: 'danger',
      checked: filterValues.includes('danger'),
    },
    {
      level: 'warning',
      type: 'checkbox',
      label: i18n.t('Important'),
      value: 'warning',
      checked: filterValues.includes('warning'),
    },
    {
      level: 'info',
      type: 'checkbox',
      label: i18n.t('Information'),
      value: 'info',
      checked: filterValues.includes('info'),
    },
  ];

  const memoizedNotices = useMemo(() => {
    return notices;
  }, [notices]);

  const memoizedProjectNotices = useMemo(() => {
    return projectNotices;
  }, [projectNotices]);

  return {
    filterInputs,
    notices: findStatus(true, 'by-user'),
    getEmptyListMessage,
    findStatus,
    setSortNoticesBy,
    filterValues,
    setFilterValues,
    getProjectNotices,
    projectNotices: findStatus(true, 'by-project'),
    getUsersNotices,
    visibilityValues,
    setVisibilityValues,
    toggleVisibility,
    visibilityInputs,
  };
};

export default useNoticeBoardList;
