import { IonPage, IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';

import Profile from './Profile';
import SettingsPage from './Settings';
import { NetworkInterceptor } from '../../components/NetworkInterceptors/NetworkInterceptor';

const ProfilePage: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <NetworkInterceptor />
        <Route path={'/profile'} component={Profile} exact />
        <Route path={`/profile/settings`} component={SettingsPage} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ProfilePage;
