import { IonCol, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { documentAttach, image } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../api/networking';
import i18n from '../../i18n';
import storage from '../../storage';
import ItemShadowNoIcons from '../UI/Items/components/ItemShadowNoIcons';

interface FileListProps {
  files: E2U.V1.Models.File[];
  photos: E2U.V1.Models.File[];
  removeFile?: (file: E2U.V1.Models.File) => void;
  removePhoto?: (file: E2U.V1.Models.File) => void;
  handleDeleteRequest?: (file: E2U.V1.Models.File) => void;
}
const FileList: React.FC<FileListProps> = (props) => {
  const { files, photos, removeFile, removePhoto } = props;

  const [photoContent, setPhotoContent] = React.useState<{
    [fileHash: string]: string
  }>({});

  const { t } = useTranslation();

  const downloadFile = (file: E2U.V1.Models.File) => {
    if (file.id === undefined) {
      if (file.file_hash && photoContent[`pending-${file.file_hash}`]) {
        const downloadLink = document.createElement('a');
        downloadLink.href = photoContent[`pending-${file.file_hash}`];
        downloadLink.download = file.name;
        downloadLink.click();
      }
      return;
    }
    networking.get(`/api/v1/files/${file.id}/share`)
      .then((response) => {
        window.location.assign(response.data.data.url);
      });
  };

  const deleteFile = (e: React.MouseEvent<HTMLIonButtonElement>, file: E2U.V1.Models.File) => {
    e.stopPropagation();

    if (file.type === 'image') {
      removePhoto && removePhoto(file);
    } else {
      removeFile && removeFile(file);
    }
  };

  const updatePhotoPreviews = () => {
    photos.forEach((photo: E2U.V1.Models.File) => {
      storage.get(`pending-${photo.file_hash}`).then((data) => {
        if (data) {
          setPhotoContent((prev) => ({
            ...prev,
            [`pending-${photo.file_hash}`]: data
          }));
        }
      });
    });
  };

  useEffect(() => {
    updatePhotoPreviews();
  }, [photos]);

  return (
    <>
      {files.concat(photos).map((file: E2U.V1.Models.File, i) => {
        return (
          <IonRow key={i} >
            <IonCol size='12' className='ion-margin-bottom ion-no-padding'>
              <ItemShadowNoIcons
                hasIconLeft={true}
                iconLeft={file.type === 'image' ? image : documentAttach}
                label={file.type === 'image' ? t('Image') : i18n.t('File')}
                description={file.name}
                subLabel={''}
                hasBorder={false}
                onItemClick={() => downloadFile(file)}
                backgroundLeft={
                  (file.type === 'image')
                    ? (
                      (file.export_url)
                        ? `${file.export_url}?base64=true`
                        : (file.file_hash && photoContent[`pending-${file.file_hash}`])
                          ? photoContent[`pending-${file.file_hash}`]
                          : undefined
                    )
                    : undefined
                }
                noMarginTop={true}
                {
                  ...(removeFile || removePhoto || props.handleDeleteRequest) && {
                    deleteHandler: (e) => props.handleDeleteRequest ? props.handleDeleteRequest(file) : deleteFile(e, file),
                  }
                }
              />
            </IonCol>
          </IonRow>
        );
      })
      }
    </>
  );
};

export default FileList;
