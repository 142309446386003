import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonRow, IonToolbar } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { arrowForward, closeOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import styles from './styles/OnboardingStart.module.scss';
import e2cWhiteLogo from '../../../../assets/images/e2c_vit.png';
import toasters from '../../../../components/Toasts/Toasts';
import StepButtons from '../../../../components/Toolbar/StepButtons';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import i18n from '../../../../i18n';
import type { OnboardingInterface } from '../../interfaces/Onboarding.interface';

const OnboardingStart: React.FC<OnboardingInterface> = (props) => {
  const { toggleModal } = useModal();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const selected: E2U.V1.Models.Project | undefined = useAppSelector(state => state.onboarding.selectedProject);
  const project: E2U.V1.Models.Project | undefined = useAppSelector(state => state.project.selectedProject);
  const selectedProject = selected || project;

  const toggleOnboardingModal = () => {
    if (!selectedProject) {
      toasters.error('No project selected');
      if (props.dismiss) {
        props.dismiss();
      }
      return;
    }
    if (props.dismiss) {
      props.dismiss();
    }

    toggleModal!();
    const sanitisedPath = (
      location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname
    );
    history.push(`${sanitisedPath}/onboarding/${selectedProject?.id}`);
  };

  return (
    <React.Fragment>
      <div className={styles['onboarding-start-header-container']}>
        <IonHeader class="ion-no-border">
          <IonToolbar>
            <IonGrid>
              <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-horizontal ion-margin-top">
                <IonCol className='ion-text-start '>
                  <img src={e2cWhiteLogo} alt="white logo" />
                </IonCol>
                <IonCol className='ion-text-right'>
                  <IonIcon size='large' icon={closeOutline} onClick={() => props.dismiss!()} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
      </div>

      <div className={styles['content-container-start']}>
        <h2>{t('Project')}<br />{t('onboarding')}</h2>
        <IonContent className={styles['onboarding-start-content']}>
          <div>
            <span>
              {t('Complete this onboarding to be admitted to the work site for {project_name}', 'Complete this onboarding to be admitted to the work site for {project_name}', {
                project_name: selectedProject?.name || selectedProject?.name
              })}
            </span>
          </div>
        </IonContent>
        <IonFooter className='ion-margin-end ion-margin-start'>
          <StepButtons
            leftTitle={i18n.t('cancel')}
            leftIonColor={'none'}
            leftOnClick={() => props.dismiss!()}
            rightTitle={i18n.t('start')}
            rightIonColor={'danger'}
            rightClassName={styles['onboarding-start-button']}
            rightIcon={arrowForward}
            rightOnClick={() => toggleOnboardingModal!()}
          />
        </IonFooter>
      </div>
    </React.Fragment>
  );
};

export default OnboardingStart;
