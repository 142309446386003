import { IonCol, IonLabel, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';

const SummaryRows: React.FC<{
  value: E2U.V1.Models.Precalculation | number | string | undefined; label: string;
}> = ({ label, value }) => {
  return (
    <IonRow>
      <IonCol className='ion-text-left'>
        <IonLabel className='ion-text-left ion-no-margin' style={{ fontSize: '12px', fontWeight: 'bold' }}>
          {label}:
        </IonLabel>
        <IonLabel className='ion-no-margin'>
          {value}
        </IonLabel>
      </IonCol>
    </IonRow>
  );
};

export default SummaryRows;
