import {
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';

import styles from './PaymentSchedule.module.scss';
import type { PaymentScheduleRowProps } from './PaymentScheduleTypings';
import SwiperContainer from '../../../../components/Swiper/SwiperContainer';
import SwiperNav from '../../../../components/Swiper/SwiperNav';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import formatNumber from '../../../../tools/formatNumber';

const PaymentScheduleRows: React.FC<PaymentScheduleRowProps> = (
  props: PaymentScheduleRowProps
) => {
  const { t } = useTranslation();

  const isDesktop = useAppSelector(
    (state) => state.desktopView.isDesktop);

  const totalIndexes: number = props.paymentSchedule.rows.length - 1;
  if (props.swiperController) {
    props.swiperController.setTotalIndexes(totalIndexes);
  }

  const rowItems: { [key: string]: string } = {
    invoice_date: i18n.t('Invoice date'),
    payment_date: i18n.t('Payment date'),
    total_net: i18n.t('Quantity net'),
    total_vat: i18n.t('Vat 25%'),
    total_gross: i18n.t('am. + VAT'),
    total_paid_net: i18n.t('Invoice ack net'),
    total_paid_vat: i18n.t('Invoice ack + VAT')
  };
  const maxSlidesToShow = isDesktop ? Math.min(props.paymentSchedule.rows.length, 10) : 1;

  return (
    <>
      <SwiperNav
        slideBtnClassName={'payment-schedule-slide-btn'}
        controller={props.swiperController}
        sliderTitle={i18n.t('Invoice & Sum')}
        slidesPerView={isDesktop ? 4 : 1}
        navColSize={'4'}
        iconColor={'primary'}
      />
      <IonGrid>
        <IonRow>
          <IonCol size="6" sizeMd='2'>
            <IonList>
              {Object.keys(rowItems).map((item, i) => {
                return <IonItem key={i} className='ion-no-padding'>{rowItems[item]}</IonItem>;
              })}
            </IonList>
          </IonCol>

          <IonCol size="6" sizeMd='10'>
            <SwiperContainer controller={props.swiperController!} slidesPerView={maxSlidesToShow}>
              {props.paymentSchedule.rows.map((r, i) => {
                return (
                  <SwiperSlide key={i} className={styles[`swiper-hover`]} >
                    <IonList className={styles[`swipe-list-${i % 2 ? 'odd' : 'even'}`]}>
                      <IonItem>{r.invoice_date}</IonItem>
                      <IonItem>{r.payment_date}</IonItem>
                      <IonItem>{formatNumber(r.total_net)}</IonItem>
                      <IonItem>{r.total_vat}</IonItem>
                      <IonItem>{formatNumber(r.total_gross)}</IonItem>
                      <IonItem>{r.total_paid_net}</IonItem>
                      <IonItem>{r.total_paid_vat}</IonItem>
                    </IonList>
                  </SwiperSlide>
                );
              })}
            </SwiperContainer>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default PaymentScheduleRows;
