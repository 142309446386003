import {
  IonCol,
  IonGrid, IonLabel,
  IonRow
} from '@ionic/react';
import {
  chevronBack,
  chevronForward
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { SwiperNavInterface } from './SwiperInterfaces';
import styles from './SwiperNav.module.scss';
import { useAppSelector } from '../../hooks';
import BigUp from '../UI';

const SwiperNav: React.FC<SwiperNavInterface> = ({
  children,
  controller,
  iconColor,
  labelText,
  navColSize,
  slideBtnClassName,
  sliderIndexDesc,
  sliderTitle,
  slidesPerView
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(
    controller!.getCurrentIndex()
  );
  const [compLoaded, setCompLoaded] = useState<boolean>(true);

  const isDesktop: boolean = useAppSelector(
    (state) => state.desktopView.isDesktop
  );
  const { t } = useTranslation();

  const nextIdx = () => {
    controller?.nextSlide();
  };

  const prevIdx = () => {
    controller?.previousSlide();
  };

  const setCurrentIndexWrapper = (prop: any) => {
    if (compLoaded) {
      setCurrentIndex(prop);
    }
  };

  const getSlidesPerView = () => {
    return controller?.getSlidesPerView() ?? 1;
  };

  const totalIndexesOfSwipe = controller!.getTotalIndexes() + 1;

  controller?.addIndexHook(setCurrentIndexWrapper);

  useEffect(() => {
    setCompLoaded(true);

    return () => {
      setCompLoaded(false);
    };
  }, []);

  return (
    <IonGrid className={styles['swiper-nav-container']}>
      <IonRow className="ion-justify-content-end ion-align-items-center">
        <IonCol size="2" class="ion-text-center">
          <BigUp.Buttons.Icon
            {...(controller?.isAtStart()
              ? { disabled: true }
              : { disabled: false, onClick: () => prevIdx() }
            )}
            padding={20}
            color={'none'}
            icon={{ icon: chevronBack, color: iconColor }}
          />
        </IonCol>
        <IonCol
          className={styles['select-month']}
          size={navColSize}
          sizeMd={'4'}
          class="ion-text-center"
        >
          <IonLabel>
            {sliderTitle}
          </IonLabel>
          <br />
          <IonLabel color={'medium'} style={{ fontSize: '16px' }}>
            {isDesktop
              ? (
                <React.Fragment>
                  {currentIndex + 1}
                  {' - '}
                  {getSlidesPerView() + currentIndex}
                  {' of '}
                  {totalIndexesOfSwipe}
                </React.Fragment>
              )
              : (
                <React.Fragment>
                  {currentIndex! + 1}
                  {t(' of ')}
                  {totalIndexesOfSwipe}
                </React.Fragment>
              )
            }
          </IonLabel>

          <br />
          <IonLabel style={{ fontSize: '16px' }} color={'medium'}>
            {labelText}
          </IonLabel>
          {children}
        </IonCol>

        <IonCol size="2" class="ion-text-center">
          <BigUp.Buttons.Icon
            {...(controller!.isAtEnd() && currentIndex! > 0
              ? { disabled: true, }
              : { disabled: false, onClick: () => nextIdx() }
            )}
            padding={20}
            color={'none'}
            icon={{ icon: chevronForward, color: iconColor }}
          />

        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default SwiperNav;
