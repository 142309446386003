import React from 'react';

import NoticeBoardContent from './NoticeBoardContent';
import DesktopWrapper from '../../components/DesktopWrapper';
import useFlashNotice from '../../hooks/useFlashNotice';
import FlashNotice from '../Notice/FlashNotice';

const NoticeBoard: React.FC = () => {
  const { dummy } = useFlashNotice();
  return (
    <>
      <DesktopWrapper alignment={'center'}>
        <NoticeBoardContent />
      </DesktopWrapper>
      {dummy && <FlashNotice />}
    </>
  );
};

export default NoticeBoard;
