import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRow, IonSearchbar, IonTitle, IonToggle, IonToolbar, useIonRouter } from '@ionic/react';
import { addCircle, menu } from 'ionicons/icons';
import { useState } from 'react';

import routeTitle from './utils';
import BigUp from '../../../components/UI';
import type { BreadcrumbData } from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import i18n from '../../../i18n';
interface ITEM {
  item: string;
}

interface DUMMY_LIST {
  category_title: string;
  inner_categories: ITEM[];
}

const DUMMY_LIST: DUMMY_LIST[] = [
  { category_title: 'Category 1', inner_categories: [{ item: 'Item 1' }, { item: 'Item 2' },], },
  { category_title: 'Category 2', inner_categories: [{ item: 'Item 3' }, { item: 'Item 4' },], },
];

const AssessmentList: React.FC = () => {
  const [isEdit, setIsEdit] = useState(false);
  const router = useIonRouter();
  const pageTitle = routeTitle.get(router.routeInfo.pathname.split('/').pop() || '');

  const breadcrumbs: BreadcrumbData[] = [
    { label: i18n.t('Work Preparation'), path: '/work-preparation', direction: 'back' },
    { label: i18n.t('Assessment List'), path: router.routeInfo.pathname, disabled: true },
  ];

  const extraRowProps = {
    hasExtraRow: true,
    item: {
      detail: false,
      onClick: () => console.log('Add new item'),
      button: true
    }
  };

  const storeSelection = () => {
    router.push('/work-preparation', 'back');
  };

  const handleViewChange = () => {
    setIsEdit(!isEdit);
  };

  return (
    <IonPage>
      <IonHeader className='ion-no-border' translucent>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={handleViewChange} style={{ textTransform: 'capitalize' }}>
              {!isEdit ? i18n.t('Handle') : i18n.t('Cancel')}
            </IonButton>
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot='end' >
            <IonButton onClick={() => storeSelection()} style={{ textTransform: 'capitalize' }}>
              {i18n.t('Save')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} style={{ '--background': 'var(--ion-color-light-shade)' }}>
        <Breadcrumbs data={Array.from(breadcrumbs)} />
        {DUMMY_LIST.map((category, index) => (
          <BigUp.ItemList
            key={index}
            header={{ title: category.category_title }}
            extraRow={{ ...extraRowProps, content: ItemExtraRow() }}
            items={category.inner_categories.map((item, index) => ({
              id: (index + 1).toString(),
              content: !isEdit ? ItemCheckbox(item) : ItemIsEdit(item)
            }))}
          >
            <IonItem>
              <IonToggle color={'success'} justify='space-between'>
                {i18n.t('Mandatory assessment? (comment)')}
              </IonToggle>
            </IonItem>
          </BigUp.ItemList>
        ))}
      </IonContent>
    </IonPage>
  );
};

const ItemCheckbox = (item: ITEM, onChange?: () => void) => (
  <BigUp.Checkbox
    style={{ '--checkbox-background-checked': 'var(--ion-color-success)', }}
    title={item.item}
    handleCheckbox={() => onChange || console.log('Checkbox clicked')}
    labelProps={{ label: item.item }}
    labelPlacement='start'
  />
);

const ItemIsEdit = (item: ITEM) => (
  <IonGrid>
    <IonRow className='ion-align-items-center ion-justify-content-between' >
      <IonCol size='auto'>
        <IonLabel className='ion-no-margin'>{item.item}</IonLabel>
      </IonCol>
      <IonCol size='auto'>
        <IonIcon icon={menu} />
      </IonCol>
    </IonRow>
  </IonGrid>
);
const ItemExtraRow = () => {
  return (
    <>
      <IonIcon className='ion-no-margin' aria-hidden='true' icon={addCircle} color='success' slot='start'></IonIcon>
      <IonLabel>{i18n.t('Create new risk')}</IonLabel>
    </>
  );
};
export default AssessmentList;
