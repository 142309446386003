import {
  IonCol,
  IonGrid,
  IonIcon, IonImg,
  IonItem, IonItemOption, IonItemOptions,
  IonItemSliding,
  IonList,
  IonRow,
  IonSkeletonText, useIonAlert,
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { alertCircle, checkmarkCircle, chevronForward, informationCircle, trash } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import logo from '../../../../assets/fortnox/logo.svg';
import InitiateAuthorization from '../../../../components/Fortnox/InitiateAuthorization';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';

const FortnoxPage: React.FC = () => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isFetchingFortnoxIntegrations = useAppSelector((state) => state.loading.isLoading.fetchingFortnoxIntegrations);
  const [fortnoxProjectIntegrations, setFortnoxProjectIntegrations] = useState<E2U.V1.Models.FortnoxProjectIntegration[]>([]);
  const [presentAlert] = useIonAlert();
  const intervalId = useRef<(NodeJS.Timeout|null)>(null);

  const fetchFortnoxIntegrations = () => {
    if (!selectedProject?.id || isFetchingFortnoxIntegrations) return;
    store.dispatch(setIsLoading({ isLoading: true, key: 'fetchingFortnoxIntegrations' }));
    setFortnoxProjectIntegrations([]);
    if (intervalId.current !== null) {
      clearTimeout(intervalId.current);
    }
    networking.get(`/api/v1/projects/${selectedProject?.id}/fortnox_project_integrations`)
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.FortnoxProjectIntegration>>) => {
        setFortnoxProjectIntegrations(res.data.data.records);
        intervalId.current = setTimeout(() => checkIntegrationCount(res.data.data.total), 5000);
      })
      .catch(() => {
        toasters.error(t('Failed to fetch Fortnox integrations. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ isLoading: false, key: 'fetchingFortnoxIntegrations' }));
      });
  };

  const handleDisconnectIntegration = (integration: E2U.V1.Models.FortnoxProjectIntegration) => {
    presentAlert({
      header: t('Disconnect Fortnox Integration'),
      message: t('Are you sure you want to disconnect this integration?'),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: t('Disconnect'),
          handler: () => {
            store.dispatch(setIsLoading({ isLoading: true, key: 'disconnectingIntegration' }));
            networking.delete(`/api/v1/fortnox_project_integrations/${integration.id}`)
              .then(() => {
                toasters.success(t('Successfully disconnected Fortnox integration.'));
                fetchFortnoxIntegrations();
              })
              .catch(() => {
                toasters.error(t('Failed to disconnect Fortnox integration. Please try again later or contact support.'));
              })
              .finally(() => {
                store.dispatch(setIsLoading({ isLoading: false, key: 'disconnectingIntegration' }));
              });
          }
        }
      ]
    });
  };

  const checkIntegrationCount = (existingCount = 0) => {
    networking.get(`/api/v1/projects/${selectedProject?.id}/fortnox_project_integrations/count`)
      .then((res: E2U.V1.Response.Success<number>) => {
        if (res.data.data !== existingCount) {
          fetchFortnoxIntegrations();
        } else {
          if (intervalId.current !== null) {
            clearTimeout(intervalId.current);
          }
          intervalId.current = setTimeout(() => checkIntegrationCount(existingCount), 5000);
        }
      });
  };

  useEffect(() => {
    fetchFortnoxIntegrations();
    return () => {
      if (intervalId.current !== null) {
        clearTimeout(intervalId.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchFortnoxIntegrations();
  }, [selectedProject]);

  return (
    <div className={'ion-padding'}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonImg src={logo} style={{ maxWidth: '150px' }} className={'ion-margin-bottom'} alt={t('Fortnox logo')} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <BigUp.Title label={ t('Settings for {projectName}', {
              projectName: selectedProject?.name
            }) } />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {isFetchingFortnoxIntegrations
              ? <IonSkeletonText />
              : (fortnoxProjectIntegrations.length > 0 &&
                <IonGrid className={'ion-no-padding'}>
                  <IonRow>
                    <IonCol>
                      <BigUp.Label.Thick label={ t('Existing Integrations') } />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonList>
                        {fortnoxProjectIntegrations.map((integration, i) => (
                          <IonItemSliding key={i}>
                            <IonItem
                              key={i}
                              routerLink={`/project-tools/${selectedProject?.id}/settings/fortnox/integration/${integration?.id}`}
                              className={'ion-no-padding'}
                              button={true}
                            >
                              <IonIcon aria-hidden="true" icon={chevronForward} slot="end"></IonIcon>
                              {integration.external_company_details
                                ? <>
                                  {
                                    integration.is_healthy
                                      ? <IonIcon aria-hidden="true" icon={checkmarkCircle} slot="start" color={'success'} />
                                      : <IonIcon aria-hidden="true" icon={informationCircle} slot="start" color={'warning'} />
                                  }
                                  <BigUp.Label.Regular
                                    label={integration.is_healthy
                                      ? integration.external_company_details?.Name
                                      : t('{name} (Connected - Has not yet matched any invoices)', {
                                        name: integration.external_company_details?.Name
                                      })
                                    } />
                                </>
                                : <>
                                  <IonIcon aria-hidden="true" icon={alertCircle} slot="start" color={'danger'} />
                                  <BigUp.Label.Regular label={t('Integration initiated {date} (Not connected)', {
                                    date: DateTime.fromISO(integration.created_at ?? '').toLocaleString(DateTime.DATETIME_MED)
                                  })} />
                                </>}
                            </IonItem>
                            <IonItemOptions slot="end">
                              <IonItemOption color="danger" onClick={() => handleDisconnectIntegration(integration)}>
                                <IonIcon slot="icon-only" icon={trash}></IonIcon>
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        ))}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>)}
            <IonGrid className={'ion-no-padding ion-margin-top'}>
              <IonRow>
                <IonCol>
                  <BigUp.Label.Thick label={ t('Get started with our Fortnox Integration') } />
                  <p className={'ion-no-margin ion-margin-bottom'}>{ t('In order to communicate with Fortnox, we need you to authorize BigUp through Fortnox. Press the button below to get started.') }</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <InitiateAuthorization
                    onBrowserFinished={() => fetchFortnoxIntegrations()}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default FortnoxPage;
