import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import type { SelectOption } from './interface';
import i18n from '../../../i18n';
import type { CountryList } from '../../../tools/countryList';
import countries from '../../../tools/countryList';

interface CountrySelectProps {
  default: CountryList['name'] | undefined;
  register?: string;
  label?: string;
  validation?: Record<string, string>;
  placeholder?: string;
}

const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const value = methods.watch(props.register ?? 'country');
  const getCountries = () => {
    return countries.map((country) => {
      return {
        value: country.name,
        id: country.code
      };
    });
  };

  const handleCountrySelection = (value: SelectOption | string) => {
    methods.setValue(props.register ?? 'country', value);
  };

  const countrySelect = {
    data: getCountries(),
    selected: value,
    placeholder: props.placeholder ?? t('Select country'),
    handleSelection: handleCountrySelection,
    label: props.label ?? i18n.t('Country'),
    register: props.register ?? 'country',
    validation: props.validation ?? { required: i18n.t('Country is required') },
    itemProps: { className: 'ion-no-padding ion-margin-bottom' },
  };

  return <BigUp.Select {...countrySelect} />;
};

export default CountrySelect;
