import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonButtons, IonContent, IonFooter, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { attachSharp, close } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import imagePlaceholder from '../../../assets/img-placeholder.webp';
import BigUp from '..';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFileUpload from '../../../hooks/useFileUpload';
import storage from '../../../storage';
import type { ActionSheetItem } from '../ActionSheets';
import i18n from '../../../i18n';
import styles from './MediaUploader.module.scss';
interface MediaUpload {
  id: string;
  dismiss: () => void;
  isOpen: boolean;
  title: string;
}

const MediaUploader: React.FC<MediaUpload> = ({ dismiss, id, isOpen, title }) => {
  const { getUploadedPhotos, handleTakenPhoto, removePhoto, setUploadedPhotos, uploadSelectedPhotos } = useCameraUpload();
  const { getUploadedFiles, handleFileSelection, removeFile, setUploadedFiles, uploadSelectedFiles } = useFileUpload();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const files = getUploadedFiles() ?? [];
  const photos = getUploadedPhotos() ?? [];
  const [photoContent, setPhotoContent] = useState<{
    [fileHash: string]: string
  }>({});

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      handleFileSelection(Array.from(files));
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const takePhoto = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        quality: 100,
      });
      handleTakenPhoto([photo]);
    } catch (error) {
      return (
        console.log(error)
      );
    }
  };

  const actionButtonList: ActionSheetItem[] = [
    {
      value: 'photo',
      label: i18n.t('Take a photo'),
      onClick: takePhoto,
    },
    {
      value: 'file',
      label: i18n.t('Select a file'),
      onClick: openFileSelector,
    },
    {
      value: 'document',
      label: i18n.t('Attach document'),
      onClick: () => { }, // TODO: Implement logic for selecting existing documents
    },
    {
      value: 'cancel',
      label: i18n.t('Cancel'),
      onClick: () => undefined,
    },
  ];

  const updatePhotoPreviews = () => {
    photos.concat(files).forEach((photo: E2U.V1.Models.File) => {
      storage.get(`pending-${photo.file_hash}`).then((data) => {
        if (data) {
          setPhotoContent((prev) => ({
            ...prev,
            [`pending-${photo.file_hash}`]: data
          }));
        }
      });
    });
  };

  useEffect(() => {
    updatePhotoPreviews();
  }, [photos]);

  return (
    <IonModal
      className={styles['media-upload-modal']}
      style={{
        '--width': '400px',
        '--height': '250px',
        padding: '18px'
      }}
      isOpen={isOpen} onIonModalDidDismiss={dismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-text-center">{title}</IonTitle>
          <IonButtons slot="end">
            <BigUp.Buttons.Icon
              onClick={dismiss}
              fill='clear'
              className='ion-no-margin'
              padding={20}
              icon={{
                icon: close,
                color: 'primary',
                size: 'large',
              }}
              color={'none'}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false} className='ion-padding'>
        <BigUp.Textarea
          withValidation={false}
          itemProps={{
            lines: 'none',
            className: 'ion-no-padding'
          }}
          register={`risks.${id}.description`}
          placeholder={i18n.t('Description')}
          rows={6}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar style={{ paddingLeft: 8, paddingBottom: 8 }}>
          <IonButtons slot='start'>
            <BigUp.Buttons.Icon
              fill='solid'
              id={'open-risk-actions'}
              className={`ion-no-margin ${styles['media-upload-actions']}`}
              padding={20}
              icon={{
                icon: attachSharp,
                color: 'primary',
                size: 'large',
              }}
              color={'none'}
            />
          </IonButtons>
          <div className={styles['media-upload-preview']}>
            {files.concat(photos).map((file, index) => {
              return (
                <img
                  className={styles['media-upload-preview-image']}
                  key={index}
                  src={
                    (file.type === 'image')
                      ? (
                        (file.export_url)
                          ? `${file.export_url}?base64=true`
                          : (file.file_hash && photoContent[`pending-${file.file_hash}`])
                            ? photoContent[`pending-${file.file_hash}`]
                            : imagePlaceholder
                      )
                      : undefined
                  }
                />
              );
            })}

            {[...Array(8)].map((_, index) => (
              // TODO: Dummy image for now, replace with actual images when available above
              <img key={index} src={imagePlaceholder} className={styles['media-upload-preview-image']} />
            ))}

          </div>
          <IonButtons slot="end"
            style={{
              padding: '0 4px 0 18px'
            }}
          >
            <BigUp.Buttons.Regular
              size='small'
              title={i18n.t('Save')}
            />
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={handleFileChange}
        multiple
      />
      <BigUp.ActionSheet items={actionButtonList} actionId="open-risk-actions" />
    </IonModal>
  );
};

export default MediaUploader;
