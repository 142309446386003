import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonLoading,
  IonRow,
  useIonAlert, useIonRouter
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';

const JourneySelection: React.FC = () => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const user = useAppSelector(state => state.authentication.user);
  const isLoading = useAppSelector(state => state.loading.isLoading.joinProjectForm);
  const { t } = useTranslation();
  const isRequestCallbackLoading = useAppSelector(state => state.loading.isLoading.requestCallback);

  const requestCallback = (phone_number: string) => {
    store.dispatch(setIsLoading({ name: 'requestCallback', value: true }));
    networking.post('/api/v1/request_callback', {
      phone_number
    }).then((response) => {
      toasters.success(t('We will call you within 30 seconds to help you get started with BigUp.'));
    }).catch(() => {
      toasters.error(t('Failed to request a call-up. Please try again later.'));
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'requestCallback', value: false }));
    });
  };

  const openClientAccountCreation = () => {
    router.push('/client-account/create');
  };

  const openRequestCallbackAlert = () => {
    presentAlert({
      header: t('Request a call-up'),
      message: t('We will call you within 30 seconds to help you get started with BigUp.'),
      inputs: [
        {
          name: 'phone',
          type: 'tel',
          placeholder: t('Phone number'),
          value: user?.phone_number ?? '',
        }
      ],
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: t('Request call-up'),
          handler: (data: { phone: string }) => {
            requestCallback(data.phone);
          }
        }
      ]
    });
  };

  return isRequestCallbackLoading
    ? <IonLoading />
    : (<IonCard className={'ion-no-margin'}>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <BigUp.Label.Thick label={ t('Select your preferred method of onboarding:') } color={'primary'} />
            </IonCol>
          </IonRow>
          <IonRow className={'ion-align-items-center ion-margin-top'}>
            <IonCol size={'auto'}>
              <BigUp.Buttons.Regular type={'submit'} disabled={isLoading} title={ t('Get a call-up') } onClick={() => openRequestCallbackAlert()} />
            </IonCol>
            <IonCol size={'auto'} className={'ion-padding-horizontal'}>
              <p className={'ion-text-center'}><i>{ t('or') }</i></p>
            </IonCol>
            <IonCol size={'auto'}>
              <BigUp.Buttons.Regular type={'submit'} disabled={isLoading} title={ t('Self-service') } onClick={() => openClientAccountCreation()} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>);
};

export default JourneySelection;
