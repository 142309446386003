import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface PrecalculationsReducer {
  selectedPrecalculation: E2U.V1.Models.Precalculation | undefined;
  selectedVersion: E2U.V1.Models.Precalculation & { precalculation_id: string } | undefined;
  selectedRow: E2U.V1.Models.PrecalculationRow | undefined;
  precalculations: E2U.V1.Models.Precalculation[] | undefined;
  currentPage: number;
  totalPages: number;
}

export const precalculationsSlice = createSlice<
  PrecalculationsReducer,
  SliceCaseReducers<PrecalculationsReducer>,
  string
>({
  name: 'precalculations',
  initialState: {
    selectedPrecalculation: undefined,
    selectedVersion: undefined,
    selectedRow: undefined,
    precalculations: undefined,
    currentPage: 1,
    totalPages: 1,
  },

  reducers: {
    setSelectedPrecalculation: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<E2U.V1.Models.Precalculation | undefined>
    ) => {
      state.selectedPrecalculation = action.payload;
    },

    setSelectedVersion: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<E2U.V1.Models.Precalculation & { precalculation_id: string } | undefined>
    ) => {
      state.selectedVersion = action.payload;
    },

    setSelectedRow: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<E2U.V1.Models.PrecalculationRow | undefined>
    ) => {
      state.selectedRow = action.payload;
    },

    setPrecalculations: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<E2U.V1.Models.Precalculation[] | undefined>
    ) => {
      state.precalculations = action.payload;
    },

    setPaginationData: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<{ currentPage: number; totalPages: number }>
    ) => {
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
    }
  }
});

export const {
  setPaginationData
  , setPrecalculations
  , setSelectedPrecalculation,
  setSelectedRow,
  setSelectedVersion
} =
  precalculationsSlice.actions;

export default precalculationsSlice.reducer;
