import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ProjectListPage from './ProjectListPage';
import i18n from '../../i18n';
import { reloadProjects } from '../../reducers/project';
import store from '../../store';

const ProjectsPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    store.dispatch(reloadProjects(undefined));
  }, []);

  return (
    <React.Fragment>
      <ProjectListPage title={i18n.t('Project Tools')} description={i18n.t('Select a project to manage.')} />
    </React.Fragment>
  );
};

export default ProjectsPage;
