import type { E2U } from '@techlove/easy2use-typings';

import i18n from '../../i18n';
import { regex } from '../../regex/regex';

interface InputProps extends SelectProps {
  isCurrency?: boolean;
  label: string;
  inputType:any;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  placeholder: string;
  register: keyof E2U.V1.Models.Project ;
  requiredMessage?: string;
  maxLength?: {
    value: number;
    message: string;
  } ;
  minLength?: {
    value: number;
    message: string;
  };
  min?: {
    value: number;
    message: string;
  };
  max?: {
    value: number;
    message: string;
  };
  pattern?: {
    value: RegExp;
    message: string;
  };
  validate?: (value: string) => string | boolean | undefined;
}

interface SelectProps {
    placeholder?: string;
    value?: string;
}

export const EDIT_PROJECT_INPUT_LIST: InputProps[] = [
  {
    isCurrency: false,
    label: i18n.t('Project name'),
    placeholder: i18n.t('Enter project name'),
    register: 'name',
    inputType: 'text',
    inputMode: 'text',
    requiredMessage: i18n.t('Project name is required'),
    maxLength: { value: 50, message: i18n.t('Project name is too long'), },
    minLength: { value: 3, message: i18n.t('Project name is too short'), },
  },
];

export const CURRENCY_INPUT_LIST: InputProps[] = [
  {
    label: i18n.t('BTA') + ' | ' + i18n.t('Total area ') + `(${i18n.t('sqm')})`,
    inputType: 'text',
    inputMode: 'numeric',
    placeholder: i18n.t('Enter total area'),
    register: 'total_area' as keyof E2U.V1.Models.Project,
    requiredMessage: i18n.t('Total area is required'),
    maxLength: { value: 50, message: i18n.t('Total area is too long'), },
    minLength: { value: 0, message: i18n.t('Total area is too short'), },
    pattern: { value: regex.regex_numeric, message: i18n.t('Must be numerical'), }
  },
  {
    label: i18n.t('LOA') + ' | ' + i18n.t('Other area ') + `(${i18n.t('sqm')})`,
    placeholder: i18n.t('Quantity'),
    register: 'other_area' as keyof E2U.V1.Models.Project,
    inputType: 'text',
    inputMode: 'numeric',
    requiredMessage: i18n.t('Other area is required'),
    max: { value: 50, message: i18n.t('Other area is too long'), },
    min: { value: 0, message: i18n.t('Other area is too short'), },
    pattern: { value: regex.regex_numeric, message: i18n.t('Must be numerical'), },
  },
  {
    label: i18n.t('BOA') + ' | ' + i18n.t('Living area ') + `(${i18n.t('sqm')})`,
    inputType: 'text',
    inputMode: 'numeric',
    placeholder: i18n.t('Enter living area'),
    register: 'living_area' as keyof E2U.V1.Models.Project,
    requiredMessage: i18n.t('Living area is required'),
    max: { value: 50, message: i18n.t('Living area is too long'), },
    min: { value: 0, message: i18n.t('Living area is too short'), },
    pattern: { value: regex.regex_numeric, message: i18n.t('Must be numerical'), },
  },
];
