import { IonCol, IonGrid, IonLoading, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';
import type { SelectOption } from '../../UI/Select/interface';

export const columnNames: SelectOption[] = [{
  id: 'project',
  value: i18n.t('Project'),
}, {
  id: 'cost_center',
  value: i18n.t('Cost center'),
}, {
  id: 'your_reference',
  value: i18n.t('Your reference'),
}, {
  id: 'our_reference',
  value: i18n.t('Our reference'),
}];

interface ProjectMappingFormProps {
  integrationId: string;
  projectMapping?: E2U.V1.Models.FortnoxProjectIntegrationMapping;
  onSaved?: () => void;
  resetProjectMapping?: () => void;
}
const ProjectMappingForm: React.FC<ProjectMappingFormProps> = (props) => {
  const { t } = useTranslation();
  const methods = useForm<E2U.V1.Models.FortnoxProjectIntegrationMapping>({
    defaultValues: {
      column_name: columnNames[0].id,
      search_value: ''
    }
  });
  const isSaving = useAppSelector((state) => state.loading.isLoading.savingProjectMapping);
  const selectedColumnName = methods.watch('column_name');

  const saveProjectMapping: SubmitHandler<E2U.V1.Models.FortnoxProjectIntegrationMapping> = (data) => {
    const dataWithIntegrationId = {
      ...data,
      fortnox_project_integration_id: props.integrationId
    };
    const networkCall = typeof data.id === 'string'
      ? networking.put(`/api/v1/fortnox_project_integration_mappings/${data.id}`, dataWithIntegrationId)
      : networking.post('/api/v1/fortnox_project_integration_mappings', dataWithIntegrationId);
    networkCall
      .then((res: E2U.V1.Response.Success<E2U.V1.Models.FortnoxProjectIntegrationMapping>) => {
        toasters.success(t('Project mapping saved successfully.'));
        if (typeof props.onSaved === 'function') {
          props.onSaved();
        }
      })
      .catch(() => {
        toasters.error(t('Failed to save project mapping. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ isLoading: false, key: 'savingProjectMapping' }));
      });
  };

  const checkIfProjectMappingExists = () => {
    if (!props.projectMapping) {
      methods.reset({
        column_name: columnNames[0].id,
        search_value: ''
      });
    } else {
      methods.reset(props.projectMapping);
    }
  };

  useEffect(() => {
    checkIfProjectMappingExists();
  }, []);

  useEffect(() => {
    checkIfProjectMappingExists();
  }, [props.projectMapping]);

  return (
    <>
      {isSaving
        ? <IonLoading />
        : <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(saveProjectMapping)}>
            <IonGrid className={'ion-no-padding'}>
              <IonRow>
                <IonCol>
                  <BigUp.Input
                    register={'search_value'}
                    label={t('Value to match')}
                    placeholder={t('Enter search value')}
                    validation={{ required: t('Search value is required') }}
                  />
                </IonCol>
                <IonCol>
                  <BigUp.Select
                    register={'column_name'}
                    validation={{
                      required: t('Column name is required')
                    }}
                    data={columnNames}
                    label={t('Fortnox property')}
                    selected={selectedColumnName ?? ''}
                    handleSelection={(val: string) => methods.setValue(
                      'column_name',
                      val
                    )}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={'ion-margin-top'}>
                <IonCol>
                  {(props.resetProjectMapping && props.projectMapping) && <BigUp.Buttons.Regular
                    title={t('Cancel')}
                    color={'primary'}
                    onClick={() => props.resetProjectMapping && props.resetProjectMapping()}
                  />}
                  <BigUp.Buttons.Regular
                    title={t('Save')}
                    color={'success'}
                    type={'submit'}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </FormProvider>}
    </>
  );
};

export default ProjectMappingForm;
