import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

interface DragAndDropProps {
  onFilesSelect: (files: File[]) => void;
  multiple?: boolean;
  files?: File[];
  children: React.ReactNode;
}

const DragAndDrop = (
  { children, multiple = true, onFilesSelect }: DragAndDropProps
) => {
  const { acceptedFiles, getInputProps, getRootProps, inputRef } = useDropzone({
    multiple,
    maxSize: 1073741824
  });

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onFilesSelect(acceptedFiles);
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [acceptedFiles]);
  return (
    <div {...getRootProps()} style={{ cursor: 'pointer' }}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default DragAndDrop;
