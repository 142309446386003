import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

import BigUp from '..';
import i18n from '../../../i18n';
import type { IonicThemeColours } from '../variables';

interface AppSettingsProps {
  title?: string;
  color?: IonicThemeColours;
}
const AppSettings = (props: AppSettingsProps) => {
  const openAppSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App
    });
  };

  return (
    <BigUp.Buttons.Regular
      color={props.color || 'primary'}
      title={props.title || i18n.t('App settings')}
      onClick={openAppSettings}
    />
  );
};

export default AppSettings;
