import type { IonIcon } from '@ionic/react';
import { mail, notifications, text } from 'ionicons/icons';
import type { ComponentProps } from 'react';

import type { DateIDs, DefaultValues, NotificationTypes } from './form.interface';
import { NotificationMethod } from './form.interface';
import i18n from '../../../i18n';

const default_levels: DefaultValues['default_levels'] = [
  { id: 'info', value: i18n.t('Information') },
  { id: 'warning', value: i18n.t('Warning') },
  { id: 'danger', value: i18n.t('Danger') },
];

const access_filter_data: DefaultValues['access_filter_data'] = [
  { id: 'onsite', value: i18n.t('Onsite') },
  { id: 'last 7 days', value: i18n.t('Last week') },
  { id: 'last 14 days', value: i18n.t('Last two weeks') },
  { id: 'ever', value: i18n.t('Ever') }
];

const default_dates = {
  publish_at: new Date().toISOString(),
  expires_at: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString(),
};

export const notificationKeyValues: NotificationTypes[] = [
  { key: 'EMAIL', label: i18n.t('Email'), icon: mail },
  { key: 'SMS', label: i18n.t('Text message'), icon: text },
  { key: 'PUSH', label: i18n.t('Push notification'), icon: notifications }
];

export const notice_default = { access_filter_data, default_levels, default_dates };

export const getSelectedMethodsLabels = (number: number) => {
  const selectedMethods: { label: string; icon: ComponentProps<typeof IonIcon>['icon'] }[] = [];

  for (const notificationType of notificationKeyValues) {
    if ((number & NotificationMethod[notificationType.key]) !== 0) {
      selectedMethods.push({
        label: notificationType.label,
        icon: notificationType.icon as ComponentProps<typeof IonIcon>['icon']
      });
    }
  }

  return selectedMethods;
};
