import {
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonListHeader,
  IonModal,
  IonRow
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import {
  camera,
  documentText,
  trashBin
} from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { DetailProps } from './DetailTypes';
import { networking } from '../../../../../../api/networking';
import Toast from '../../../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../../../hooks';
import i18n from '../../../../../../i18n';

const Detail: React.FC<DetailProps> = (props) => {
  const { t } = useTranslation();

  const observation: E2U.V1.Models.ObservationReport | undefined =
    useAppSelector((state) => state.reports.selectedObservation);

  const observationTime: Date | undefined = observation?.observation_time
    ? new Date(observation?.observation_time)
    : undefined;

  const unlinkFile = (file: E2U.V1.Models.File) => {
    networking
      .delete(
        `/api/v1/observation_reports/${observation?.id}/files/${file.id}`
      )
      .then(() => {
        if (props.onUpdateObservation) {
          props.onUpdateObservation();
        }
      })
      .catch((error: E2U.V1.Response.Error) => {
        console.log(error);
        Toast(t('Failed to remove file relationship'), 'error');
      });
  };

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeXl="6" sizeLg="6" className="ion-no-margin">
          <IonList color="none" className="ion-no-padding">
            <IonListHeader color="none">{t('Name')}:</IonListHeader>
            <IonItem color="none">{observation?.name}</IonItem>
          </IonList>
        </IonCol>

        <IonCol size="12" sizeXl="6" sizeLg="6">
          <IonList className="ion-no-padding">
            <IonListHeader color="none">{t('Description')}:</IonListHeader>
            <IonItem color="none">{observation?.description}</IonItem>
          </IonList>
        </IonCol>

        <IonCol size="12" sizeXl="6" sizeLg="6">
          <IonList className="ion-no-padding">
            <IonListHeader color="none">
              {t('Time of observation')}
            </IonListHeader>
            {observationTime
              ? (
                <IonItem color="none">
                  <IonDatetimeButton
                    slot="start"
                    datetime="report_time"
                    disabled={true}
                  />
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      id="report_time"
                      preferWheel={true}
                      value={observationTime?.toISOString()}
                    ></IonDatetime>
                  </IonModal>
                </IonItem>
              )
              : (
                <IonItem color="none">{t('No time given')}</IonItem>
              )}
          </IonList>
        </IonCol>

        <IonCol size="12" sizeXl="6" sizeLg="6">
          <IonList className="ion-no-padding">
            <IonListHeader color="none">
              {t('Does this effect the schedule?')}
            </IonListHeader>
            <IonItem color="none">
              {observation?.effects_schedule ? i18n.t('Yes') : i18n.t('No')}
            </IonItem>
          </IonList>
        </IonCol>

        <IonCol size="12">
          <IonList className="ion-no-padding">
            <IonListHeader color="none">{t('Report')}</IonListHeader>
            <IonItem color="none">{observation?.report}</IonItem>
          </IonList>
        </IonCol>

        <IonCol size="12">
          <IonList className="ion-no-padding">
            <IonListHeader color="none">{t('Files')}:</IonListHeader>
            <IonItem color="none">
              {observation
                ? (
                  observation?.files?.map((file, index) => {
                    return (
                      <IonItemSliding key={index} color="none">
                        <IonItem lines="none" color="none">
                          <IonIcon
                            {...(file.type === 'image'
                              ? { icon: camera }
                              : { icon: documentText })}
                            slot="start"
                          ></IonIcon>
                          {file.name}

                          <IonImg src={file.download_url}></IonImg>
                        </IonItem>
                        <IonItemOptions color="none">
                          <IonItemOption
                            color="danger"
                            onClick={() => {
                              unlinkFile(file);
                            }}
                          >
                            <IonIcon icon={trashBin} slot="start"></IonIcon>
                            {t('Delete')}
                          </IonItemOption>
                        </IonItemOptions>
                      </IonItemSliding>
                    );
                  })
                )
                : (
                  <IonItem>{t('No files uploaded')}</IonItem>
                )}
            </IonItem>
          </IonList>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Detail;
