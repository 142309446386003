import { IonPage, IonRouterOutlet } from '@ionic/react';

import AssessmentList from './AssessmentList';
import PreparationForm from './PreparationForm';
import { NetworkInterceptor } from '../../components/NetworkInterceptors/NetworkInterceptor';
import { matchUuid } from '../../constants/matchUuid';
import ProtectedRoute from '../../routes/ProtectedRoute';

const WorkPreparationPage: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <NetworkInterceptor />
        <ProtectedRoute exact path={'/work-preparation'} component={PreparationForm} />
        <ProtectedRoute path={`/work-preparation/:uuid(${matchUuid})`} component={PreparationForm} />
        <ProtectedRoute path={`/work-preparation/(risk-assessment|equipment|preparation|preparation_history|preparations|follow-up)`} component={AssessmentList} />
      </IonRouterOutlet>
    </IonPage>

  );
};

export default WorkPreparationPage;
