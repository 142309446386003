import {
  IonButton,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonText,
  IonTextarea,
  IonToggle
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { add, addCircleOutline, camera, documentText, trashBin } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';

import styles from './Edit.module.scss';
import type { EditProps } from './EditTypes';
import { networking } from '../../../../../../api/networking';
import Toast from '../../../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../../../hooks';
import i18n from '../../../../../../i18n';
import { setIsLoading } from '../../../../../../reducers/loading';
import DocumentForm from '../../../../../Document/DocumentForm';
import ObservationForm from '../../ObservationForm';

const Edit: React.FC<EditProps> = (props) => {
  const { t } = useTranslation();
  const store = useStore();
  const observation: E2U.V1.Models.ObservationReport | undefined =
    useAppSelector((state) => state.reports.selectedObservation);
  const observationTime: Date | undefined = observation?.observation_time
    ? new Date(observation?.observation_time)
    : undefined;
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [files, setFiles] = useState<E2U.V1.Models.File[]>(
    structuredClone(observation ? observation.files : []) as E2U.V1.Models.File[]
  );
  const isLoading = useAppSelector(
    (state) => state.loading.isLoading.observations
  );

  const [removeFiles, setRemoveFiles] = useState<string[]>([]);
  const [addFiles, setAddFiles] = useState<string[]>([]);
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });

  const handleSave: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setIsLoading({ name: 'observation', value: true }));
    const isUpdating = !!(observation && observation.id);
    delete data.observation_time;
    const saveRequest = isUpdating
      ? networking.put(`/api/v1/observation_reports/${observation?.id}`, data)
      : networking.post('/api/v1/observation_reports', data);

    saveRequest
      .then(
        (
          newObservationData: E2U.V1.Response.Success<E2U.V1.Models.ObservationReport>
        ) => {
          const newObservation = newObservationData.data.data;
          console.log('To Add: files', addFiles);
          console.log('To Remove: files', removeFiles);
          const requests = addFiles.map((addFile) => {
            return networking.post(
              `/api/v1/observation_reports/${newObservation?.id}/files/${addFile}`
            );
          });

          requests.push(
            ...removeFiles.map((removeFile) => {
              return networking.delete(
                `/api/v1/observation_reports/${newObservation?.id}/files/${removeFile}`
              );
            })
          );

          networking
            .all(requests)
            .then(() => {
              Toast(
                isUpdating
                  ? t('Successfully saved observation report')
                  : i18n.t('Successfully created observation report'),
                'success'
              );
            })
            .catch((errors) => {
              console.warn(errors);
              Toast(
                isUpdating
                  ? t('Failed saving observation report')
                  : i18n.t('Failed creating observation report'),
                'error'
              );
            });
        }
      )
      .catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.ObservationReport>) => {
          console.warn(error);
          Toast(
            isUpdating
              ? t('Failed saving observation report')
              : i18n.t('Failed creating observation report'),
            'error'
          );
        }
      )
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'observation', value: false }));
        props.onSave();
      });
  };

  useEffect(() => {
    if (typeof observation !== 'undefined') {
      Object.entries(observation).forEach(([key, value]) => {
        console.log(key, value);
        switch (key) {
          case 'effects_schedule':
            setValue(key, !!value);
            break;
          case 'observation_time':
            setValue(key, new Date(String(value)).toISOString());
            break;
          default:
            setValue(key, value);
            break;
        }
      });
    }
  }, []);

  const addFile = (file: E2U.V1.Models.File) => {
    if (file.id) {
      if (files.findIndex((doc) => doc.id === file.id) !== -1) {
        return;
      }

      const newFiles = structuredClone(files);
      newFiles.push(file);

      setFiles(newFiles);
      addFiles.push(file.id);
      setAddFiles(addFiles);
      console.log('Add Files: ', addFiles);
    }

    setUploadingFile(false);
  };

  const deleteFile = (fileId: string) => {
    const index = files.findIndex((file) => file.id === fileId);

    if (index !== -1) {
      removeFiles.push(fileId);
      const newFiles = structuredClone(files);
      delete newFiles[index];
      setFiles(newFiles);
      setRemoveFiles(removeFiles);
    }
  };

  return (
    <React.Fragment>
      {isLoading ? <IonLoading isOpen /> : <ObservationForm />}

      <React.Fragment>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="12" sizeMd="10" sizeLg="10">
              <form onSubmit={handleSubmit(handleSave)}>
                <IonGrid>
                  <IonRow
                    className={classNames(
                      styles['input-container'],
                      'ion-justify-content-center'
                    )}
                  >
                    <IonCol size="12">
                      <IonLabel>{t('Name')}:</IonLabel>
                      <IonInput
                        {...register('name', {
                          required: true,
                          maxLength: 100
                        })}
                        color="dark"
                        placeholder={i18n.t('name')}
                      ></IonInput>
                      <IonLabel>{t('Description:')}</IonLabel>

                      <IonTextarea
                        {...register('description', {
                          required: true,
                          maxLength: 500
                        })}
                        color="dark"
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                      <IonLabel>{t('Time of observation:')}</IonLabel>
                      <IonItem lines="none">
                        <Controller
                          control={control}
                          name="observation_time"
                          render={({
                            field: { onBlur, onChange, ref, value }
                          }) => (
                            <React.Fragment>
                              <IonDatetimeButton
                                slot="start"
                                datetime="report_time"
                                disabled={false}
                              />
                              <IonModal keepContentsMounted={true}>
                                <IonDatetime
                                  preferWheel={true}
                                  id="report_time"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                />
                              </IonModal>
                            </React.Fragment>
                          )}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonLabel>{t('Does this affect the schedule?')}</IonLabel>
                <IonItem color="white" lines="none">
                  <IonToggle {...register('effects_schedule')} />
                  <IonLabel slot="end">{t('Yes / No')}</IonLabel>
                </IonItem>

                <IonCol>
                  <IonItemGroup>
                    <IonItem>{t('TEMP. DUMMY DATA')}</IonItem>
                  </IonItemGroup>
                </IonCol>

                <IonFabButton color="primary">
                  <IonIcon icon={addCircleOutline}></IonIcon>
                </IonFabButton>
                <IonLabel>{t('Report')}</IonLabel>
                <IonItem>
                  <IonText>{t('TEMP. DUMMY DATA')}</IonText>
                </IonItem>
                <IonLabel> {t('Attached files')}</IonLabel>
                <IonList lines="full">
                  {files.map((file, index) => {
                    return (
                      <IonItemSliding key={index}>
                        <IonItem lines="full">
                          <IonIcon
                            {...(file.type === 'image'
                              ? { icon: camera }
                              : { icon: documentText })}
                            slot="start"
                          ></IonIcon>
                          <IonText>{t(`${file.name}`)}</IonText>
                        </IonItem>
                        <IonItemOptions>
                          <IonItemOption
                            color="danger"
                            onClick={() => {
                              return file.id
                                ? deleteFile(file.id)
                                : null;
                            }}
                          >
                            <IonIcon icon={trashBin} slot="start"></IonIcon>
                            {t('Delete')}
                          </IonItemOption>
                        </IonItemOptions>
                      </IonItemSliding>
                    );
                  })}
                </IonList>
                <IonFab
                  onClick={() => {
                    setUploadingFile(true);
                  }}
                  vertical="bottom"
                  horizontal="end"
                >
                  <IonFabButton>
                    <IonIcon icon={add} />
                  </IonFabButton>
                </IonFab>
                <IonRow>
                  <IonCol>
                    <IonButton type="submit">{t('Submit')}</IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={uploadingFile}
          onDidDismiss={() => {
            setUploadingFile(false);
          }}
        >
          <DocumentForm handleUploadedFile={addFile} />
        </IonModal>
      </React.Fragment>
    </React.Fragment>
  );
};

export default Edit;
