import * as Sentry from '@sentry/capacitor';
import { useHistory } from 'react-router-dom';

import { networking } from '../api/networking';
import { setProfilePicture } from '../reducers/authentication';
import { setProjects } from '../reducers/project';
import storage from '../storage';
import store from '../store';

const useLogout = () => {
  const history = useHistory();

  const resetState = () => {
    store.dispatch({ type: 'RESET' });
    store.dispatch(setProfilePicture(undefined));
    store.dispatch(setProjects(undefined));
  };

  const clearUserDetails = () => {
    storage.get('push_token')
      .then((token) => {
        if (token) {
          networking.post('/api/v1/me/device_tokens/deregister', {
            type: 'fcm',
            token
          })
            .catch((error) => {
              Sentry.captureException(error);
            });
        }
      }).then(() => {
        networking.delete(`/api/v1/logout`)
          .then(async () => {
            const promises: Promise<any>[] = [];
            promises.push(storage.remove('access_token'));
            promises.push(storage.remove('refresh_token'));
            promises.push(storage.remove('expires_at'));
            await Promise.all(promises);
          })
          .catch((error) => Sentry.captureException(error))
          .finally(() => {
            history.push('/login');
            resetState();
          });
      });
  };
  return clearUserDetails;
};

export default useLogout;
