import { IonItem, IonLabel, IonNote, IonText } from '@ionic/react';
import { truncate } from 'lodash';

import type MetadataItemProps from './metadataItem.d';
import styles from './MetadataItem.module.scss';

const MetadataItem: React.FC<MetadataItemProps> = (props) => {
  return (
    <IonItem
      {...props.itemProps}
      button={true}
      detail={true}
      className={`ion-no-padding ${styles['metadata-item-container']}`}
      onClick={props.onItemClick}
    >
      {props.indicator?.hasIndicator &&
        <div className={styles['unread-indicator-wrapper']} slot="start">
          <div className={styles['unread-indicator']} style={{ background: props.indicator.color || 'var(--ion-color-primary)' }}></div>
        </div>
      }
      <IonLabel className='ion-no-margin ion-margin-vertical'>
        <strong>{props.subject}</strong>
        <IonText style={{ color: props.ionTextProps?.color || 'var(--ion-color-dark)' }}>
          {props.ionTextProps?.IonTextProps.children}
        </IonText>
        <br />
        <IonNote color="medium" className="ion-text-wrap">
          {truncate(props.message, { length: props.truncate || 100 })}
        </IonNote>
      </IonLabel>
      <div className={styles['metadata-end-wrapper']} slot="end">
        <IonNote color="medium">{props.toprightContent}</IonNote>
      </div>
    </IonItem>
  );
};

export default MetadataItem;
