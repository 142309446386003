import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { newspaper } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import NoticeAccessFilter from './NoticeAccessFilter';
import EntitySelect from './NoticeEntitySelect';
import NoticeLevel from './NoticeLevel';
import NoticeMessage from './NoticeMessage';
import NoticeNotifications from './NoticeNotifications';
import NoticePublishHandling from './NoticePublishHandling';
import NoticeSubject from './NoticeSubject';
import NoticeVisibilityHandling from './NoticeVisibilityHandling';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import BigUp from '../../../components/UI';
import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import { ValidationBadge } from '../../../components/UI/Inputs/ValidationBadge';
import LayoutRowCol from '../../../components/UI/Layout/LayoutRowCol';
import ListTitle from '../../../components/UI/Titles/ListTitle';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import { useBreadcrumbConfig } from '../../../hooks/useBreadcrumbConfig';
import i18n from '../../../i18n';

const NoticeFormPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const breadcrumbs = useBreadcrumbConfig();
  const { notice_uuid } = useParams<{ notice_uuid: string | undefined }>();
  const [notice, setNotice] = useState<E2U.V1.Models.Notice | undefined>(undefined);
  const project = useAppSelector(state => state.project.selectedProject);
  const selectedProjectID = useAppSelector(state => state.project.selectedProject?.id);

  const getSelectedNotice = () => {
    if (!notice_uuid) return;
    networking.get(`/api/v1/notices/${notice_uuid}?with=teams,users`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Notice>) => {
        setNotice(response.data.data as E2U.V1.Models.Notice);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const methods = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      subject: '',
      publish_at: new Date().toISOString(),
      level: 'info',
      message: '',
      expire_at: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString(),
      project_access_filter: 'onsite',
      notification_methods: 0,
      teams: [],
      users: [],
      global: false,
    },
  });

  const handleTeamSubmit = (teams: E2U.V1.Models.Team[], noticeId: E2U.V1.Models.Notice['id']) => {
    return Promise.all(
      teams.map((team: E2U.V1.Models.Team) =>
        networking.post(`/api/v1/notices/${noticeId}/teams/${team.id}`)
      )
    );
  };

  const handleUserSubmit = (users: E2U.V1.Models.User[], noticeId: E2U.V1.Models.Notice['id']) => {
    return Promise.all(
      users.map((user: any) =>
        networking.post(`/api/v1/notices/${noticeId}/users/${user.id}`)
      )
    );
  };
  const zone = DateTime.local().zoneName;

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const saveRequest = notice_uuid
      ? networking.put(`/api/v1/notices/${notice_uuid}`, data)
      : networking.post('/api/v1/notices', {
        ...data,
        project_id: selectedProjectID,
        expire_at: DateTime.fromISO(data.expire_at, { zone }).toISO(),
        publish_at: DateTime.fromISO(data.publish_at, { zone }).toISO()
      });
    saveRequest.then((response: E2U.V1.Response.Success<E2U.V1.Models.Notice>) => {
      const newNoticeId = response.data.data.id;
      Promise.all([
        handleTeamSubmit(data.teams, newNoticeId),
        handleUserSubmit(data.users, newNoticeId)
      ])
        .then(() => {
          methods.reset();
          toasters.createToast({
            message: i18n.t('Successfully created {notice}', 'Successfully created {notice}', { notice: response.data.data.subject }),
            background: 'var(--ion-color-light)',
            icon: newspaper
          }, 'success');
          history.push(`/notice-board/${selectedProjectID}`);
        })
        .catch((error) => {
          Sentry.captureException(error);
          toasters.createToast({
            message: i18n.t('Notice could not be saved due to an unknown error. Try again later.'),
            background: 'var(--ion-color-light)',
          }, 'error');
        });
    })
      .catch((error) => {
        Sentry.captureException(error);
        if (error.response?.data?.data) {
          Object.entries(error.response.data.data).forEach(([fieldName, fieldErrors]) => {
            const errorMessage = (fieldErrors as string[])[0];
            methods.setError(fieldName as any, {
              type: 'server',
              message: errorMessage,
            });
          });
        }
        toasters.createToast({
          message: i18n.t('Notice could not be saved due to an unknown error. Try again later.'),
          background: 'var(--ion-color-light)',
        }, 'error');
      });
  };

  const listTitle = notice_uuid ? t('Edit notice') : i18n.t('Create notice');

  const handleNoticeChange = (notice: E2U.V1.Models.Notice) => {
    methods.setValue('subject', notice.subject);
    methods.setValue('level', notice.level ?? '');
    methods.setValue('message', notice.message);
    if (notice.publish_at) {
      methods.setValue('publish_at', DateTime.fromISO(notice.publish_at, { zone }).toISO());
    }
    if (notice.expire_at) {
      methods.setValue('expire_at', DateTime.fromISO(notice.expire_at, { zone }).toISO());
    }
    methods.setValue('project_access_filter', notice.project_access_filter);
    methods.setValue('notification_methods', notice.notification_methods);
    if (notice.teams?.length) {
      methods.setValue('teams', notice.teams);
    }
    if (notice.users?.length) {
      methods.setValue('users', notice.users);
    }
    methods.setValue('global', notice.global);
  };

  useEffect(() => {
    if (notice) {
      handleNoticeChange(notice);
    }
  }, [notice]);

  useEffect(() => {
    if (typeof notice === 'undefined' && notice_uuid) {
      getSelectedNotice();
    }
  }, [notice_uuid, selectedProjectID]);

  return (
    <DesktopWrapper width='var(--ion-desktop-mid-width)' alignment={'center'}>
      <Breadcrumbs data={breadcrumbs.noticeFormBreadcrumbs} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <IonGrid className='ion-padding'>
            <h1>{notice?.subject}</h1>
            <LayoutRowCol row={{ className: 'ion-margin-bottom' }}>
              <ListTitle label={listTitle} />
            </LayoutRowCol>
            <LayoutRowCol><NoticeSubject /></LayoutRowCol>
            <LayoutRowCol><NoticeMessage /></LayoutRowCol>
            <LayoutRowCol><NoticeLevel /></LayoutRowCol>
            <LayoutRowCol><NoticeAccessFilter></NoticeAccessFilter></LayoutRowCol>
            <LayoutRowCol>
              <EntitySelect
                model="teams"
                fieldLabel={i18n.t('Teams to notify')}
                label={i18n.t('Select teams')}
                modalTitle={i18n.t('Select teams')}
              />
            </LayoutRowCol>
            <LayoutRowCol>
              <EntitySelect
                model="users"
                fieldLabel={i18n.t('Users to notify')}
                label={i18n.t('Select users')}
                modalTitle={i18n.t('Select users')}
              />
            </LayoutRowCol>

            <LayoutRowCol><NoticeVisibilityHandling /></LayoutRowCol>
            {(methods.formState.errors && (
              methods.formState.errors.teams || methods.formState.errors.users || methods.formState.errors.global
            )) && <div className='ion-no-margin ion-margin-bottom' style={{ height: 20, paddingTop: 5, display: 'flex' }}>
              <ValidationBadge >
                {i18n.t('At least one team or user is required.')}
              </ValidationBadge>
            </div>}
            <LayoutRowCol><NoticePublishHandling /></LayoutRowCol>
            <LayoutRowCol><NoticeNotifications /></LayoutRowCol>
            <IonGrid className='ion-padding-horizontal'>
              <IonRow className='ion-justify-content-between'>
                <IonCol size='4' className='ion-text-left'>
                  <BigUp.Buttons.Regular
                    expand='block'
                    color={'light'}
                    title={i18n.t('Cancel')}
                    onClick={() => history.goBack()}
                  />
                </IonCol>
                <IonCol size='4' className='ion-text-right'>
                  <BigUp.Buttons.Regular
                    expand='block'
                    color={'secondary'}
                    title={i18n.t('Save')}
                    type='submit'
                    disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                  />
                </IonCol>
              </IonRow>

            </IonGrid>
          </IonGrid>
        </form>
      </FormProvider>
    </DesktopWrapper>
  );
};

export default NoticeFormPage;
