import { IonDatetime, IonDatetimeButton, IonItem, IonModal } from '@ionic/react';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { type DateTimeContentProps } from './form.interface';
import InputLabel from '../../../components/UI/InputLabel';
import i18n from '../../../i18n';

const NoticePublishHandling: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='ion-margin-bottom'>
        <InputLabel margin={'ion-no-margin'} stacked>{t('Publish date')}</InputLabel>
        <IonItem className='ion-no-padding' lines='none'>
          <IonDatetimeButton slot='start' datetime="notice-publish" className='ion-margin-top' />
        </IonItem>
      </div>
      <div className='ion-margin-bottom'>
        <InputLabel margin={'ion-no-margin'} stacked>{t('Unpublish date')}</InputLabel>
        <IonItem className='ion-no-padding' lines='none'>

          <IonDatetimeButton datetime="notice-unpublish" className='ion-margin-top' />
        </IonItem>
      </div>
      <IonModal keepContentsMounted={true}>
        <DateTimeContent id='notice-publish' dateRegister='publish_at' />
      </IonModal>

      <IonModal keepContentsMounted={true}>
        <DateTimeContent id='notice-unpublish' dateRegister='expire_at' />
      </IonModal>
    </>
  );
};

const DateTimeContent: React.FC<DateTimeContentProps> = ({ dateRegister, id }) => {
  const methods = useFormContext();

  const zone = DateTime.local().zoneName;
  const locale = DateTime.local().locale;

  const adjustDateForTimezone = (date: DateTime) => {
    return date.setZone(zone, { keepLocalTime: true }).toISO();
  };

  const findCurrentYear = () => {
    const now = DateTime.local().setZone(zone);
    return now.plus({ years: 10 }).endOf('year').toJSDate();
  };

  const formatDefaultValues = (date: string) => {
    return DateTime.fromISO(date).toISO();
  };

  const minDate = adjustDateForTimezone(DateTime.local().setZone(zone).startOf('day'));
  const maxDate = adjustDateForTimezone(DateTime.fromJSDate(findCurrentYear()).setZone(zone).endOf('day'));

  return (
    <IonDatetime
      onIonChange={(e) => {
        const date = e.detail.value as string;
        methods.setValue(dateRegister, date);
      }}
      value={methods.getValues(dateRegister) ? formatDefaultValues(methods.getValues(dateRegister)) : ''}
      showDefaultButtons
      locale={locale}
      doneText={i18n.t('OK')}
      cancelText={i18n.t('Cancel')}
      presentation='time-date'
      min={minDate ?? ''}
      max={maxDate ?? ''}
      id={id}
    />
  );
};

export default NoticePublishHandling;
