import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import CalculateCashFlow from './CalculateCashFlow/CalculateCashFlow';
import styles from './CashFlow.module.scss';
import CashFlowRows from './CashFlowRows/CashFlowRows';
import { networking } from '../../../../api/networking';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import SwiperController from '../../../../components/Swiper/SwiperController';
import toasters from '../../../../components/Toasts/Toasts';
import { handleEmptyListStates } from '../../../../components/UI/EmptyList';
import { emptyListMessages } from '../../../../constants/messages';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import { setManualCashFlow, setSelectedIndex, setSuggestedCashFlow } from '../../../../reducers/cashflow';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';

interface CashFlowProps {
  onCalculated?: () => void
}

const CashFlow: React.FC<CashFlowProps> = ({ onCalculated }) => {
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const [cashFlowDifference, setCashFlowDifference] = useState<E2U.V1.Models.CashFlow>();
  const [swiperController, setSwiperController] = useState<SwiperController>(new SwiperController());
  const isLoadingManualCashFlow: boolean = useAppSelector((state) => state.loading.isLoading.manualCashFlow);
  const isLoadingSuggestedCashFlow: boolean = useAppSelector((state) => state.loading.isLoading.suggestedCashFlow);
  const manualCashFlow: E2U.V1.Models.CashFlow | undefined = useAppSelector((state) => state.cashFlow.manualCashFlow);
  const suggestedCashFlow: E2U.V1.Models.CashFlow | undefined = useAppSelector((state) => state.cashFlow.suggestedCashFlow);

  const getCashflowType = (type: string) => {
    return type === 'suggested_cash_flow' ? 'suggestCashFlow' : 'manualCashFlow';
  };

  const fetchCashFlow = (type: 'suggested_cash_flow' | 'manual_cash_flow', updateLoading: boolean) => {
    if (updateLoading) {
      const name = getCashflowType(type);
      store.dispatch(setIsLoading({ name, value: false }));
    }

    toasters.promise(networking.get(`/api/v1/projects/${uuid}/${type}?with=rows`), { error: i18n.t('Failed to load cash flow.') })
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<
        E2U.V1.Models.CashFlow>>) => {
        if (response.data.data.records.length > 0) {
          if (type === 'suggested_cash_flow') {
            store.dispatch(setSuggestedCashFlow(response.data.data.records[0]));
          } else {
            store.dispatch(setManualCashFlow(response.data.data.records[0]));
          }
        } else {
          store.dispatch(setSuggestedCashFlow(undefined));
          store.dispatch(setManualCashFlow(undefined));
        }
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => {
        if (updateLoading) {
          const name = getCashflowType(type);
          store.dispatch(setIsLoading({ name, value: false }));
        }
      });
  };

  const refreshCashFlows = (updateIndex = true) => {
    fetchCashFlow('suggested_cash_flow', updateIndex);
    fetchCashFlow('manual_cash_flow', updateIndex);

    if (updateIndex) {
      store.dispatch(setSelectedIndex(0));
    }
  };

  const messages = {
    empty: {
      title: emptyListMessages.cash_flow.empty_title,
      message: emptyListMessages.cash_flow.empty_message
    },
    error: {
      message: ''
    }
  };

  const compareManualToSuggestedCashFlow = () => {
    if (!suggestedCashFlow || !manualCashFlow) {
      return;
    }
    const projectManagementDifference = manualCashFlow.project_management_total - suggestedCashFlow.project_management_total;
    const netCostDifference = manualCashFlow.net_cost_total - suggestedCashFlow.net_cost_total;
    const feeDifference = manualCashFlow.fee_total - suggestedCashFlow.fee_total;
    const totalPaymentDifference = manualCashFlow.grand_total - suggestedCashFlow.grand_total;

    setCashFlowDifference({
      project_management_total: projectManagementDifference,
      net_cost_total: netCostDifference,
      fee_total: feeDifference,
      grand_total: totalPaymentDifference
    } as E2U.V1.Models.CashFlow
    );
  };

  useEffect(() => {
    compareManualToSuggestedCashFlow();
  }, [suggestedCashFlow, manualCashFlow]);

  useEffect(() => {
    refreshCashFlows();
    setSwiperController(swiperController);
  }, [uuid]);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'center' }}>
        <div className={styles['cashflow-accordion-container']}>
          <IonGrid>
            <IonRow>
              <IonCol size={'12'} sizeLg='12'>
                <CalculateCashFlow refresh={refreshCashFlows} onCalculated={onCalculated ?? refreshCashFlows} />
              </IonCol>
            </IonRow>
          </IonGrid>
          {!suggestedCashFlow ||
            !manualCashFlow
            ? (handleEmptyListStates({
              isEmpty: true,
              error: '',
              messages,
            }))
            : <>

              <div slot="content">
                {isLoadingManualCashFlow || isLoadingSuggestedCashFlow
                  ? <SkeletonTextThreeLines />
                  : (<React.Fragment>
                    {suggestedCashFlow && (
                      <CashFlowRows
                        swiperController={swiperController}
                        cashFlow={suggestedCashFlow}
                        editable={false}
                        title={i18n.t('Suggested')}
                        refreshCashFlows={onCalculated ?? refreshCashFlows}
                      />
                    )}
                    {manualCashFlow && (
                      <CashFlowRows
                        fetchCashFlow={() => fetchCashFlow('manual_cash_flow', true)}
                        swiperController={swiperController}
                        cashFlow={manualCashFlow}
                        editable={true}
                        title={i18n.t('Manual')}
                        differences={cashFlowDifference}
                        refreshCashFlows={onCalculated ?? refreshCashFlows}
                      />
                    )}
                  </React.Fragment>)
                }
              </div>
            </>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default CashFlow;
