import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import classNames from 'classnames';
import { alertCircleOutline, phonePortraitOutline, settingsSharp, thumbsUpOutline } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import styles from './Support.module.scss';
import DesktopWrapper from '../../components/DesktopWrapper';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';
import AppLayout from '../../layout/AppLayout';
import MenuLessAppLayout from '../../layout/MenuLessAppLayout';

interface SupportList {
    icon: string;
    directTo: string;
    title: string;
    description: string;
    targetBlank?: boolean;
    isActualLink?: boolean;
}

const Support: React.FC = () => {
  const { t } = useTranslation();

  const SUPPORT_LIST: SupportList[] = [
    {
      icon: phonePortraitOutline,
      directTo: 'tel:+46122343456',
      title: i18n.t('Call us'),
      description: i18n.t('Talk directly with support'),
      isActualLink: true
    },
    {
      icon: thumbsUpOutline,
      directTo: `/support/report-error` as string,
      title: i18n.t('Feedback'),
      description: i18n.t('Feedback on your app experience'),
      targetBlank: true
    },
    {
      icon: alertCircleOutline,
      directTo: `/support/report-error` as string,
      title: i18n.t('Complaint'),
      description: i18n.t('When an error has occured'),
      targetBlank: true
    },
    {
      icon: settingsSharp,
      directTo: '/reset',
      title: i18n.t('Forgot your password?'),
      description: i18n.t('Reset password')
    }
  ];

  const user = useAppSelector((state) => state.authentication.user);
  const Wrapper = useMemo(() => {
    return (user && user.id) ? AppLayout : MenuLessAppLayout;
  }, [user]);
  const history = useHistory();

  return (
    <Wrapper title={i18n.t('Support')}>
      <IonItem
        className={
          classNames(
            styles['support-header'], 'ion-no-padding ion-margin-top'
          )
        }
        color={'none'}
        lines='none'>
        <h1>{t('Support')}</h1>
      </IonItem>
      <IonList className={
        classNames(
          'ion-padding',
          styles['support-container']
        )}>
        {SUPPORT_LIST.map((item) => {
          return (
            <IonItem
              className='ion-margin-top'
              detail={true}
              key={item.title}>
              <IonIcon
                icon={item.icon}
                size='large'
                color='medium'
              />
              {item.isActualLink
                ? <a href={item.directTo} target={'_blank'}>
                  <IonLabel className='ion-no-margin ion-margin-start'>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </IonLabel>
                </a>
                : <div onClick={() => history.push(item.directTo)}>
                  <IonLabel className='ion-no-margin ion-margin-start'>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </IonLabel>
                </div>}
            </IonItem>
          );
        })}
      </IonList>
    </Wrapper>
  );
};

export default Support;
