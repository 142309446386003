import {
  IonContent,
  IonLoading, IonPage
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import styles from './AcceptInvitationPage.module.scss';
import { networking } from '../../../api/networking';
import Toast from '../../../components/Toasts/Toast';
import { useAppSelector } from '../../../hooks';
import { setInvite } from '../../../reducers/invite';
import { setIsLoading } from '../../../reducers/loading';
import { setSelectedTeam } from '../../../reducers/team';
import CreateUserFromInviteForm from '../CreateUserFromInviteForm';
import JoinTeamForm from '../JoinTeamForm';

const AcceptInvitationPage: React.FC = () => {
  const store = useStore();
  const { t } = useTranslation();
  const isFetchingInvitation: boolean = useAppSelector((state) => state.loading.isLoading.loadingInvitation);
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const history = useHistory();
  const invite: E2U.V1.Models.Invite | undefined = useAppSelector((state) => state.invite.invite);

  const fetchInvitation = () => {
    if (typeof uuid === 'undefined' || uuid === '') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'loadingInvitation', value: true }));
    networking.get(`/api/v1/invite/${uuid}`).then((response: E2U.V1.Response.Success<E2U.V1.Models.Invite>) => {
      store.dispatch(setInvite(response.data.data));
    }).catch((error: E2U.V1.Response.Error) => {
      console.log('E2U Errors', error);
      Toast(t('Could not find invitation'), 'error');
      history.push('/login');
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'loadingInvitation', value: false }));
    });
  };

  useEffect(
    () => {
      return () => {
        store.dispatch(setInvite(undefined));
        store.dispatch(setSelectedTeam(undefined));
      };
    }
    , []);

  useEffect(
    () => fetchInvitation()
    , [uuid]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <section className={styles['accept-invitation-page']}>
          {isFetchingInvitation ? <IonLoading isOpen={true} /> : ((invite && invite.type && invite.type === 'team') ? <JoinTeamForm /> : <CreateUserFromInviteForm />)}
        </section>
      </IonContent>
    </IonPage>
  );
};

export default AcceptInvitationPage;
