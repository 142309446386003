import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { cubeSharp } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../api/networking';
import SkeletonItem from '../../../components/SkeletonComponents/SkeletonItem';
import EmptyList from '../../../components/UI/EmptyList';
import ItemShadowNoIcons from '../../../components/UI/Items/components/ItemShadowNoIcons';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import type { ProjectId, TeamsPromises } from '../interfaces';

export const AssociatedProjects: React.FC<TeamsPromises> = ({ fetchTeam }) => {
  const [deletingItemId, setDeletingItemId] = useState<string | undefined>(undefined);
  const history = useHistory();
  const { t } = useTranslation();
  const [deleteAlert] = useIonAlert();
  const { team_id } = useParams<{ uuid: string, team_id: string }>();
  const team = useAppSelector(state => state.team.selectedTeam);

  const handleDeleteProject = (id: ProjectId) => {
    setDeletingItemId(id);
    const endpoint = `/api/v1/teams/${team_id}/projects/${id}`;
    networking.delete(endpoint)
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        fetchTeam && fetchTeam();
        setDeletingItemId(undefined);
      });
  };

  const confirmDelete = (id: ProjectId) => {
    deleteAlert({
      header: i18n.t('Delete team'),
      message: i18n.t('Are you sure you want to delete this team?'),
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel',
        },
        {
          text: i18n.t('Delete'),
          handler: () => handleDeleteProject(id)
        }
      ]
    });
  };

  const redirectToProject = (id: ProjectId) => {
    history.push(`/project-tools/${id}`);
  };

  return (
    <>
      <IonGrid>
        {typeof team !== 'undefined' && team?.projects.map((e: E2U.V1.Models.Project) => {
          return (
            <React.Fragment key={e.id}>
              {deletingItemId === e.id
                ? <div style={{ height: 40 }}><SkeletonItem amount={3} /></div>
                : (
                  <div>
                    <IonRow className='ion-align-items-center '>
                      <IonCol size='12'>
                        <ItemShadowNoIcons
                          label={e.name || ''}
                          description={e.description}
                          onItemClick={() => redirectToProject(e.id as ProjectId)}
                          subLabel={''}
                          hasIconLeft
                          iconLeft={cubeSharp}
                          deleteHandler={() => confirmDelete(e.id as ProjectId)}
                        />
                      </IonCol>
                    </IonRow>
                  </div>
                )
              }
            </React.Fragment>
          );
        })
        }
        {team?.projects.length === 0 &&
          <IonRow>
            <IonCol>
              <EmptyList
                title={i18n.t('No projects are associated with this project.')}
                message={i18n.t('Create one by selecting projects from the list.')}
              />
            </IonCol>
          </IonRow>
        }
      </IonGrid>
    </>

  );
};
