import type { CameraPermissionState } from '@capacitor/camera';
import { Camera } from '@capacitor/camera';
import type { PermissionState } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { PushNotifications } from '@capacitor/push-notifications';
import { IonCol, IonGrid, IonIcon, IonItem, IonRow, isPlatform } from '@ionic/react';
import { AndroidSettings, NativeSettings } from 'capacitor-native-settings';
import { checkmarkSharp, closeSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import PermissionListWrapper from './PermissionListWrapper';
import BigUp from '../../../components/UI';
import i18n from '../../../i18n';

const PermissionsList: React.FC = () => {
  const [cameraPermissions, setCameraPermissions] = useState<Record<string, CameraPermissionState | ''>>({
    camera: '',
    photos: ''
  });
  const [notificationPermissions, setNotificationPermissions] = useState<PermissionState | ''>('');
  const [geoLocationPermissions, setGeoLocationPermissions] = useState<PermissionState | ''>('');

  const openNotificationSettings = (type?: 'notifications' | 'geolocation') => {
    if (isPlatform('android')) {
      if (type === 'notifications') {
        NativeSettings.openAndroid({ option: AndroidSettings.AppNotification });
      }
      if (type === 'geolocation') {
        NativeSettings.openAndroid({ option: AndroidSettings.Location });
      }
    }
  };

  const checkNotificationPermissions = () => {
    PushNotifications.checkPermissions()
      .then((permission) => {
        setNotificationPermissions(permission.receive);
      });
  };

  const checkCameraPermissions = () => {
    Camera.checkPermissions()
      .then((permission) => {
        setCameraPermissions({
          camera: permission.camera,
          photos: permission.photos
        });
      });
  };

  const checkGeoLocationPermissions = async () => {
    const permission = await Geolocation.checkPermissions();
    setGeoLocationPermissions(permission.location);
  };

  const check = {
    camera: checkCameraPermissions,
    notifications: checkNotificationPermissions,
    geolocation: checkGeoLocationPermissions
  };

  const settings = {
    notifications: () => openNotificationSettings('notifications'),
    geolocation: () => openNotificationSettings('geolocation')
  };

  const renderIcon = (type: string) => {
    if (type === 'denied' || type === 'prompt') {
      return <IonIcon aria-hidden="true" icon={closeSharp} slot="start"></IonIcon>;
    } else {
      return <IonIcon aria-hidden="true" icon={checkmarkSharp} slot="start"></IonIcon>;
    }
  };

  useEffect(() => {
    check.camera();
    check.notifications();
    check.geolocation();
  }, []);

  return (
    <IonGrid className='ion-padding-horizontal'>
      <PermissionListWrapper title={i18n.t('Notifications')}>
        <IonItem
          button={isPlatform('android') && !isPlatform('mobileweb')}
          className='ion-no-padding'
          onClick={() => isPlatform('android') && settings.notifications()}
        >
          {renderIcon(notificationPermissions)}
          <BigUp.Label.Regular label={i18n.t('Push notifications')} />
        </IonItem>
      </PermissionListWrapper>
      <PermissionListWrapper title={i18n.t('Camera and photos')}>
        <IonItem
          className='ion-no-padding'>
          {renderIcon(cameraPermissions.photos)}
          <BigUp.Label.Regular label={i18n.t('Photos')} />
        </IonItem>
        <IonItem className='ion-no-padding'>
          {renderIcon(cameraPermissions.camera)}
          <BigUp.Label.Regular label={i18n.t('Camera')} />
        </IonItem>
      </PermissionListWrapper>

      <PermissionListWrapper title={i18n.t('Geolocation')}>
        <IonItem
          button={isPlatform('android') && !isPlatform('mobileweb')}
          onClick={() => settings.geolocation()}
          className='ion-no-padding'>
          {renderIcon(geoLocationPermissions)}
          <BigUp.Label.Regular label={i18n.t('Geolocation')} />
        </IonItem>
      </PermissionListWrapper>

      <IonRow className='ion-margin-vertical'>
        <IonCol className='ion-text-end'>
          <BigUp.Buttons.AppSettings color='secondary' />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PermissionsList;
