import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { networking } from '../../api/networking';
import { matchUuid } from '../../constants/matchUuid';
import { useAppSelector } from '../../hooks';
import usePermissionHandler from '../../hooks/usePermissions';
import i18n from '../../i18n';
import { setSelectedProject } from '../../reducers/project';
import store from '../../store';
import SelectOptions from '../UI/Select/SelectOption';

interface ProjectSelectorProps {
  currentTab: string | undefined;
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({ currentTab }) => {
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const location = useLocation();

  const isValidUUID = (id: string) => {
    const uuidRegex = new RegExp(`^${matchUuid}$`, 'i');
    return uuidRegex.test(id);
  };

  const navigateWithNewUUID = (projectId: string) => {
    const newPath = location.pathname.replace(uuid, projectId);
    history.push(newPath);
  };

  const selectProject = (projectId: string) => {
    if (!projectId) return;
    if (isValidUUID(projectId)) {
      networking.get(`/api/v1/projects/${projectId}`)
        .then((response) => {
          store.dispatch(setSelectedProject(response.data.data));
        });
    }
  };

  useEffect(() => {
    if (selectedProject?.id && selectedProject.id !== uuid) {
      navigateWithNewUUID(selectedProject.id);
    }
  }, [selectedProject, uuid]);

  return (
    <SelectOptions
      placeholder={i18n.t('Select project')}
      selectedColour='var(--ion-color-light)'
      selectedTextSize='18px'
      itemPadding='ion-no-padding'
      options={checkPermissionsForProjectAndTools('read', 'read') || []}
      getId={(option) => option.id}
      getName={(option) => option.name}
      selectedOption={selectedProject}
      setSelectedOption={(option) => selectProject(option?.id || '')}
      childrenPosition='top'
    >
    </SelectOptions>
  );
};

export default ProjectSelector;
