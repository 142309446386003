import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';

import { networking } from '../api/networking';
import toasters from '../components/UI/Toasts';
import i18n from '../i18n';
import { setIsLoading } from '../reducers/loading';
import { setPaginationData, setPrecalculations } from '../reducers/precalculations';
import store from '../store';

const useFetchBasePrecalculations = () => {
  const handleBaseFetch: (
    model: string, id: string, page?: number, search?: string, rows?: boolean,
  ) => void = (
    model: string, id: string, page = 1, search?: string, rows = false,
  ) => {
    store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
    const filters = new URLSearchParams();
    filters.append('page', page.toString());
    filters.append('search', search ?? '');
    filters.append('direction', 'desc');
    if (rows) {
      filters.append('with', 'rows');
    }
    networking.get(`/api/v1/${model}/${id}/precalculations?${filters.toString()}`)
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Precalculation>>) => {
          store.dispatch(setPrecalculations(response.data.data.records));
          store.dispatch(setPaginationData({
            currentPage: page,
            totalPages: response.data.data.total_pages
          }));
        }
      )
      .catch((error) => {
        toasters.createToast({
          message: i18n.t('Error fetching precalculations'),
          background: 'var(--ion-color-light)',
        }, 'error');
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
      });
  };
  return handleBaseFetch;
};

export default useFetchBasePrecalculations;
