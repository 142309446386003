import { IonLabel } from '@ionic/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Tiles.module.scss';
import type { PostedCostProps } from './TilesTypings';
import formatNumber from '../../../../tools/formatNumber';

const PostedCostTile: React.FC<PostedCostProps> = ({ row }) => {
  const [hasPostedCosts, setHasPostedCost] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (row && row.posted_cost_total) {
      /* TODO: Change to posted costs */
      setHasPostedCost(true);
    } else {
      setHasPostedCost(false);
    }
  }, [row]);

  return (
    <div className={styles.tile}>
      <div className={classNames(styles.container, styles.postedCosts)}>
        <IonLabel className={classNames(styles.title, styles['posted-costs'], (
          hasPostedCosts
            ? styles['has-posted-costs']
            : ''))}>{t('Posted cost')}
        </IonLabel>
        <p className={classNames(styles.value)}>
          {formatNumber(row?.posted_cost_total ?? 0)} {/* TODO: Change to posted costs, can't find this value in the typings or network request */}
        </p>
      </div>
    </div>
  );
};

export default PostedCostTile;
