
import AppLayout from '../../../layout/AppLayout';
import UserSettings from '../../User/UserSettings';

const SettingsPage: React.FC = () => {
  return (
    <AppLayout scroll={true}>
      <UserSettings />
    </AppLayout>
  );
};

export default SettingsPage;
