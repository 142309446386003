import { IonCol, IonRow } from '@ionic/react';

import type { LayoutRowColProps } from './interface';

const LayoutRowCol: React.FC<LayoutRowColProps> = ({ children, col, row }) => {
  return (
    <IonRow {...row}>
      <IonCol {...col}>
        {children}
      </IonCol>
    </IonRow>
  );
};

export default LayoutRowCol;
