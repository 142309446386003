import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import RowListView from '../../../../components/ListsComponents/rowList/RowListView';
import toasters from '../../../../components/Toasts/Toasts';
import { useAppSelector } from '../../../../hooks';
import useAlert from '../../../../hooks/useAlert';
import useFetchData from '../../../../hooks/useFetch';
import i18n from '../../../../i18n';
import { buildOptionalQuery } from '../../../../tools/queryBuilder';

const Changes: React.FC = () => {
  const [changes, setChanges] = React.useState<E2U.V1.Models.EconomyChange[]>([]);

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [perPage, setPerPage] = useState<number>(10);
  // TODO: Paginate or virtualize scroll.
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { alert } = useAlert();

  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { fetchData, isLoading } = useFetchData<E2U.V1.Models.EconomyMonitoring, any | undefined>({
    initialData: null
  });

  const economyMonitoring: E2U.V1.Models.EconomyMonitoring | undefined = useAppSelector(
    (state) => state.economyMonitoring.economyMonitoring
  );

  const changesRowRoute = `/api/v1/projects/${uuid}/changes?${buildOptionalQuery(search, sortBy, currentPage, perPage, { with: 'rows' }
  )}`;

  const onThen = (response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.EconomyChange>>) => {
    setChanges(response.data.data.records);
  };
  const onError = (error: E2U.V1.Response.Error<E2U.V1.Objects.PaginatedData<E2U.V1.Models.EconomyChange>>) => {
    Sentry.captureException(error);
  };

  const deleteChange = (id: string | undefined) => {
    toasters.promise(networking.delete(`/api/v1/economy_changes/${id}`), {
      success: i18n.t('Change deleted'),
      error: i18n.t('Could not delete change'),
    })
      .finally(() => {
        fetchData(changesRowRoute, onThen, onError);
      });
  };

  const confirmDeleteChange = (id: string | undefined) => {
    alert(
      t(`Deleting change`),
      t(`Are you sure you want to delete these changes?`),
      () => deleteChange(id),
      t('Cancel'),
      t('Delete')
    );
  };

  useEffect(() => {
    fetchData(changesRowRoute, onThen, onError);
  }, [search, sortBy, perPage, currentPage, economyMonitoring]);

  return (
    <RowListView
      isLoading={isLoading}
      data={changes}
      type={'changes'}
      search={search}
      setSearch={setSearch}
      setSort={setSortBy}
      confirmDelete={confirmDeleteChange}
      totalPages={undefined}
      currentPage={0}
      pageStep={(step) => { throw new Error('Not implemented yet'); }} // TODO: Implement proper pagination for this component.
    />
  );
};

export default Changes;
