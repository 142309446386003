import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { Link } from 'react-router-dom';

import ItemShadowNoIcons from '../../components/UI/Items/components/ItemShadowNoIcons';
import { ionicColours } from '../../components/UI/variables';

interface ProjectCardProps {
  project: E2U.V1.Models.Project;
  onClick?: (project: E2U.V1.Models.Project) => void;
  href?: any;

}

const ProjectCard: React.FC<ProjectCardProps> = ({ href, onClick, project }: ProjectCardProps) => {
  return (
    <Link to={href || `/tools/${project.id}`} style={{ textDecoration: 'none', width: '100%' }}>
      <ItemShadowNoIcons
        hasBorder={true}
        borderColour={project.has_site_access
          ? `5px solid ${project.checked_in && project.has_site_access
            ? ionicColours.success
            : ionicColours.danger
          }`
          : `5px solid ${ionicColours.medium}`}
        label={project.description}
        description={project.name}
        subLabel={''} />
    </Link>
  );
};

export default ProjectCard;
