import {
  IonContent, IonLoading,
  IonPage
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { networking } from '../../../api/networking';
import Toast from '../../../components/Toasts/Toast';
import { useAppSelector } from '../../../hooks';
import { setPasswordReset } from '../../../reducers/authentication';
import { setIsLoading } from '../../../reducers/loading';
import ConsumePasswordResetForm from '../ConsumePasswordResetForm';

interface ConsumePasswordResetProps { }

const ConsumePasswordResetPage: React.FC<ConsumePasswordResetProps> = () => {
  const store = useStore();
  const { t } = useTranslation();
  const isFetchingPasswordReset: boolean = useAppSelector((state) => state.loading.isLoading.loadingPasswordReset);
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const history = useHistory();

  const fetchPasswordReset = () => {
    if (typeof uuid === 'undefined' || uuid === '') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'loadingPasswordReset', value: true }));
    networking.get(`/api/v1/users/reset_password/${uuid}`).then((response: E2U.V1.Response.Success<any>) => { // todo:fix typing
      store.dispatch(setPasswordReset(response.data.data));
    }).catch((error: E2U.V1.Response.Error) => {
      console.log('E2U Errors', error);
      Toast(t('Could not find invitation'), 'error');
      history.push('/login');
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'loadingPasswordReset', value: false }));
    });
  };

  useEffect(
    () => fetchPasswordReset()
    , []);

  useEffect(
    () => fetchPasswordReset()
    , [uuid]);

  return (
    <IonPage>
      <IonContent fullscreen>
        {isFetchingPasswordReset ? <IonLoading isOpen={true} /> : <ConsumePasswordResetForm />}
      </IonContent>
    </IonPage>
  );
};

export default ConsumePasswordResetPage;
