import ActionSheet from './ActionSheets';
import Buttons from './Buttons/Buttons';
import Checkbox from './Checkboxes/Checkbox';
import ColorPicker from './Inputs/Colorpicker';
import Input from './Inputs/Input';
import MetadataItem from './Items/MetadataItems/MetadataItem';
import Label from './Labels/Label';
import ItemList from './List/ItemList';
import BottomSheet from './Modal/BottomSheet';
import Controls from './Pannable/Controls/Controls';
import Popover from './Popovers';
import ReorderableList from './Reorder/Reorder';
import CountrySelect from './Select/CountrySelect';
import Select from './Select/Select';
import Textarea from './Textareas/Textarea';
import Title from './Titles/Title';
import toasters from './Toasts';
import DarkmodeToggle from '../Toggle/DarkmodeToggle';
import MediaUploader from './Buttons/MediaUploader';

export const BigUp = {
  ItemList,
  Input,
  toasters,
  Textarea,
  Select,
  Checkbox,
  DarkmodeToggle,
  ReorderableList,
  Label,
  Buttons,
  CountrySelect,
  MetadataItem,
  Title,
  Pannable: {
    Controls
  },
  MediaUploader,
  BottomSheet,
  ColorPicker,
  Popover,
  ActionSheet
};

export default BigUp;
