import createToast from './CreateToast';
import promiseToast from './Promise';

export const toasters = {
  /**
   * Promises to display different types of toasts based on the state of the provided Promise.
   * @param promise The Promise to be monitored.
   * @param options The options to configure the appearance of different types of toasts based on responses form server (error, success, pending).
   * @returns A Promise that resolves with the result of the provided Promise.
   */
  promiseToast,
  /**
   * Creates and displays a toast notification with the specified content and type.
   * @param content The content of the toast including message, icon, background and icon color.
   * @param type The type of toast: 'info', 'warning', 'error', or 'success'.
   */
  createToast,
};
export default toasters;
