import { IonButton, IonContent, IonItem, IonList, IonLoading, IonPage } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './DocumentsListPage.module.scss';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import { setFiles, setFilesPaginationData } from '../../../reducers/file';
import { setIsLoading } from '../../../reducers/loading';
import DocumentsListTable from '../DocumentsListTable';

const DocumentsListPage: React.FC = () => {
  const store = useStore();
  const { t } = useTranslation();

  const isLoadingFiles: boolean = useAppSelector((state) => state.loading.isLoading.files);

  const fetchFiles = (pageNumber = 0, perPage = 0) => {
    store.dispatch(setIsLoading({ name: 'files', value: true }));
    networking.get('/api/v1/files').then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.File>>) => {
      store.dispatch(setFiles(response.data.data.records));
      store.dispatch(setFilesPaginationData(response.data.data));
    }).catch((error: E2U.V1.Response.Error) => {
      console.warn('Errors while getting files', error);
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'files', value: false }));
    });
  };

  useEffect(() => {
    fetchFiles();
    return () => {
      store.dispatch(setFiles(undefined));
    };
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <section className={styles['file-list-page']}>
          <h1>{t('Files list')}</h1>
          {isLoadingFiles
            ? <IonLoading isOpen={true} />
            : <IonList>
              <DocumentsListTable />
              <Link to={'/document/upload'}><IonButton>{t('Upload new file')}</IonButton></Link>
            </IonList>}
        </section>

      </IonContent>
    </IonPage>
  );
};

export default DocumentsListPage;
