import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonText
} from '@ionic/react';
import {
  add,
  camera,
  chevronForwardSharp,
  documentTextSharp
} from 'ionicons/icons';
import { useState } from 'react';

import styles from './IncidentReport.module.scss';
import Icons from '../../../components/IconComponent';

const fakeData = [
  {
    id: 1,
    title: 'Broken pipe - West corridor',
    badge: 1
  },
  {
    id: 2,
    title: 'Broken cables - North corridor',
    badge: 2
  },
  {
    id: 3,
    title: 'Broken lights - South corridor',
    badge: 125
  }
];
export interface cardTypes {
  id: number
  title: string
  badge: number
}

interface typeHeader {
  title: string
}

const IncidentReport: React.FC<typeHeader> = ({ title }) => {
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <IonPage>
      <IonContent>
        <IonText>{title}</IonText>
        {fakeData.map((item: cardTypes) => (
          <IonCard key={item.id}>
            <IonCardContent className={styles['flex-content']}>
              <p>{item.title}</p>
              <section className={styles['icons-content']}>
                <div>
                  <IonBadge color="success" slot="start">
                    {item.badge}
                  </IonBadge>
                  <Icons
                    icon={camera}
                    width={'27px'}
                    isActive={isActive}
                    link={'#'}
                  />
                </div>
                <div>
                  <IonBadge color="success" slot="start">
                    {item.badge}
                  </IonBadge>
                  <Icons
                    link={''}
                    icon={documentTextSharp}
                    width={'27px'}
                    isActive={isActive}
                  />
                </div>
                <div className={styles['arrow-divider']}>
                  <Icons
                    link={''}
                    icon={chevronForwardSharp}
                    width={'30px'}
                    isActive={isActive}
                  />
                </div>
              </section>
            </IonCardContent>
          </IonCard>
        ))}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton href="/new-observation">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default IncidentReport;
