import { IonGrid, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import WorksiteForm from './WorksiteForm/WorksiteForm';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import DeleteRecord from '../../../components/UI/DeleteRecord';
import { useAppSelector } from '../../../hooks';
import { useBreadcrumbConfig } from '../../../hooks/useBreadcrumbConfig';
import i18n from '../../../i18n';
import { setSelectedWorksite } from '../../../reducers/worksites';
import store from '../../../store';
import LayerSection from '../Layer/LayerSection';

const WorkSiteFormPage: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbConfig();
  const history = useHistory();
  const [alert] = useIonAlert();
  const [worksite, setWorksite] = useState<E2U.V1.Models.WorkSite>();
  const { worksite_id } = useParams<{ worksite_id: string | undefined }>();
  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const getWorksiteById = async () => {
    if (!worksite_id) return;
    try {
      const response = await networking.get(`/api/v1/work_sites/${worksite_id}?with[]=layers`);
      setWorksite(response.data.data);
      store.dispatch(setSelectedWorksite(response.data.data));
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const deleteWorksite = () => {
    networking.delete(`/api/v1/work_sites/${worksite_id}`)
      .then(() => history.push(`/project-tools/${selectedProject?.id}/worksites`));
  };

  const deleteConfimation = () => {
    alert({
      header: i18n.t('Delete category'),
      message: i18n.t('Are you sure you want to delete category {name}?', 'Are you sure you want to delete category {name}?', {
        name: worksite?.name
      }),
      buttons: [
        { text: i18n.t('Cancel'), role: 'cancel', cssClass: 'secondary' },
        { text: i18n.t('Yes'), handler: deleteWorksite }
      ]
    });
  };

  useEffect(() => {
    getWorksiteById();
  }, []);

  return (
    <DesktopWrapper>
      <WorksiteForm worksite={worksite} />
      {worksite?.id && (
        <>
          <LayerSection refetch={getWorksiteById} />
          <IonGrid>
            <DeleteRecord
              record={worksite?.name ?? ''}
              onDelete={deleteConfimation}
              label={i18n.t('Do you want to delete this worksite?')}
            />
          </IonGrid>
        </>
      )}
    </DesktopWrapper>
  );
};

export default WorkSiteFormPage;
