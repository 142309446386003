import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';

import type { PanelProps } from './interface';

const Panel: React.FC<PanelProps> = (props) => {
  return (
    <IonItem detail={false} button style={{ cursor: 'pointer' }} type="button" className='ion-no-margin ion-margin-top' onClick={props.onClick}>
      <IonIcon
        className='ion-no-margin ion-margin-end' size='medium'
        color={props.icon.start.color || 'medium'}
        icon={props.icon.start.icon}
        {...props.icon.start.rest}
        slot="start"
      />
      <IonLabel style={{ fontSize: '18px ' }} className="ion-no-margin">
        {props.panelTitle}
      </IonLabel>
      <IonIcon
        icon={props.icon.end?.icon || chevronForward}
        slot="end"
        color={props.icon.end?.color || 'medium'}
        {...props.icon.end?.rest}
      />
    </IonItem>
  );
};

export default Panel;
