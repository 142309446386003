import { IonCol, IonGrid, IonIcon, IonLabel, IonRow, IonSelectOption, IonSpinner, isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { arrowDownOutline, arrowUpOutline, duplicate, shareSocial, statsChartOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import EconomyMonitoringRows from './EconomyMonitoringRows/EconomyMonitoringRows';
import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import PieChart from '../../../../components/Graphs/PieChart';
import PaginateData from '../../../../components/Pagination/PaginationData';
import SwiperController from '../../../../components/Swiper/SwiperController';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import { handleEmptyListStates } from '../../../../components/UI/EmptyList';
import SearchAndSortRow from '../../../../components/UI/SearchAndSort/SearchAndSortRow';
import { emptyListMessages } from '../../../../constants/messages';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import { setProjectLiquidity } from '../../../../reducers/cashflow';
import { setEconomyMonitoring } from '../../../../reducers/economyMonitoring';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import { buildOptionalQuery } from '../../../../tools/queryBuilder';
import { scrollToSection } from '../../../../tools/scrollToSection';
import shareUrl from '../../../../tools/shareUrl';

const EconomyMonitoring: React.FC = () => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslation();
  const scrollToId = 'not-posted-costs-rows-top';
  const [shareEconomyMonitoring, setShareEconomyMonitoring] = useState<any | undefined>();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [swiperController, setSwiperController] = useState<SwiperController>(new SwiperController());
  const [economyMonitoringRows, setEconomyMonitoringRows] = useState<E2U.V1.Models.NotPostedRow[]>([]);
  const [error, setError] = useState<string | undefined>();
  const economyMonitoring: E2U.V1.Models.NotPosted | undefined = useAppSelector(state => state.economyMonitoring.economyMonitoring);
  const selectedProject: E2U.V1.Models.Project | undefined = useAppSelector(state => state.project.selectedProject);
  const isLoadingEconomyMonitoring: boolean = useAppSelector(state => state.loading.isLoading.economyMonitoring);
  const projectLiquidity: E2U.V1.Objects.ProjectLiquidity | undefined = useAppSelector(state => state.cashFlow.projectLiquidity);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const fetchEconomyMonitoring = () => {
    store.dispatch(setIsLoading({ name: 'economyMonitoring', value: true }));
    toasters
      .promise(
        networking.get(`/api/v1/projects/${uuid}/not_posted`),
        { error: i18n.t('Error while loading economy monitoring.') }
      )
      .then((response: any) => store.dispatch(setEconomyMonitoring(response.data.data.records[0])))
      .catch((error: E2U.V1.Response.Error) => Sentry.captureException(error))
      .finally(() => store.dispatch(setIsLoading({ name: 'economyMonitoring', value: false })));
  };

  const fetchRows = (page?: number) => {
    if (!economyMonitoring?.id) {
      return;
    }
    if (typeof page !== 'undefined') {
      setCurrentPage(page);
    }
    networking.get(`/api/v1/not_posteds/${economyMonitoring?.id}/rows?${buildOptionalQuery(
      search,
      sortBy,
      typeof page !== 'undefined' ? page : currentPage,
      perPage,
      { 'with[]': 'costItems' }
    )}`)
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.NotPostedRow>>) => {
          setEconomyMonitoringRows(response.data.data.records);
          setTotalPages(response.data.data.total_pages);
          setCurrentPage(response.data.data.current_page);
        })
      .catch((error) => {
        setError(error.message);
        Sentry.captureException(error);
      });
  };

  const fetchProjectLiquidity = (projectId: string) => {
    networking.get(`/api/v1/projects/${projectId}/liquidity`).then(
      (response: E2U.V1.Response.Success<E2U.V1.Objects.ProjectLiquidity>) => {
        store.dispatch(setProjectLiquidity(response.data.data));
      });
  };

  const shareEconomyMonitorings = () => {
    networking.get(`/api/v1/not_posteds/${economyMonitoring?.id}/share`)
      .then((response) => {
        setShareEconomyMonitoring(response.data.data.url);
        shareUrl(
          `${t('Economy monitoring for project')} ${selectedProject?.name}.`,
          `${t('Here is the link to the economy monitoring for project ')}${selectedProject?.name}: ${isPlatform('ios') ? shareEconomyMonitoring : ''}`,
          `${shareEconomyMonitoring}`,
          t(`Let me know if there is something you are wondering about.`,),
          'share'
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  const numberOfResults = (resultPerPage: number, startPage: number) => {
    setPerPage(resultPerPage);
    setCurrentPage(startPage);
  };

  const requestGenerate = () => {
    toasters.promise(
      networking.post(`/api/v1/not_posteds/${economyMonitoring?.id}/report`),
      {
        pending: i18n.t('Generating economy monitoring.'),
        success: i18n.t('Generated successfully.'),
        error: i18n.t('Error while generating economy monitoring.'),
      }
    );
  };

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      fetchProjectLiquidity(selectedProject.id);
    }
  }, []);

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      fetchProjectLiquidity(selectedProject.id);
    }
  }, [selectedProject]);

  useEffect(() => {
    fetchEconomyMonitoring();
    setSwiperController(swiperController);
  }, [uuid]);

  useEffect(() => {
    fetchRows(1);
  }, [search, sortBy]);

  useEffect(() => {
    fetchRows();
    return () => {
      setEconomyMonitoringRows([]);
    };
  }, [perPage, economyMonitoring?.id, currentPage]);

  useEffect(() => {
    fetchRows();
    return () => {
      setEconomyMonitoringRows([]);
    };
  }, [currentPage]);

  useEffect(() => {
    swiperController.updateSliders();
  }, [swiperController, economyMonitoring]);

  const liquidityPieData = useMemo(() => {
    if (projectLiquidity) {
      return [
        {
          name: i18n.t('Costs'),
          value: projectLiquidity.outgoing,
          color: '#dc3545'
        },
        {
          name: i18n.t('Income'),
          value: projectLiquidity.incoming,
          color: '#198754'
        },
      ];
    } else {
      return undefined;
    }
  }, [projectLiquidity]);

  const messages = {
    empty: {
      title: emptyListMessages.economy_monitoring.empty_title,
      message: emptyListMessages.economy_monitoring.empty_message,
    },
    error: {
      message: error || '',
    },
    loading: isLoadingEconomyMonitoring,
  };
  return (
    <React.Fragment>
      <div id='not-posted-costs-rows-top'>
        <div className={'ion-padding-bottom ion-padding-horizontal ion-no-padding'} slot="content">
          <React.Fragment>
            <DesktopWrapper>
              <IonGrid className={'ion-padding'} >
                <IonRow className='ion-justify-content-between ion-align-items-center w-100'>
                  {(liquidityPieData && liquidityPieData.length > 0
                  )
                    ? <IonCol className={'ion-text-right ion-padding-end'} size={'auto'}>
                      <PieChart
                        data={liquidityPieData}
                      />
                    </IonCol>
                    : <IonCol className={'ion-text-right ion-padding-end'} >
                      <h4 style={{ color: 'var(--ion-color-medium)' }}>
                        <IonIcon icon={statsChartOutline} size='small'></IonIcon> {t('No data to display')}
                      </h4>
                    </IonCol>
                  }

                  <IonCol className='ion-text-left' size={'auto'}>
                    <SearchAndSortRow
                      onSearch={setSearch}
                      onSort={setSortBy}
                      value={search}
                      horizontal={false}
                    >
                      <IonSelectOption value={'name'}>
                        {t('Name')}
                      </IonSelectOption>
                      <IonSelectOption value={'created_at'}>
                        {t('Created at')}
                      </IonSelectOption>
                      <IonSelectOption value={'updated_at'}>
                        {t('Last modified')}
                      </IonSelectOption>
                    </SearchAndSortRow>
                    <IonRow className={'ion-no-padding ion-no-margin'}>
                      <IonCol className='ion-text-left' size={'auto'}>
                        <BigUp.Buttons.Responsive
                          isDesktop={isDesktop}
                          expand='block'
                          padding={20}
                          title={i18n.t('Share')}
                          color={'none'}
                          icon={{ icon: shareSocial, color: 'secondary' }}
                          onClick={shareEconomyMonitorings}
                        />
                      </IonCol>
                      <IonCol className='ion-text-left' size={'auto'}>
                        <BigUp.Buttons.Responsive
                          isDesktop={isDesktop}
                          expand='block'
                          padding={20}
                          title={i18n.t('Generate')}
                          color={isDesktop ? 'secondary' : 'none'}
                          icon={{ icon: duplicate, color: 'secondary' }}
                          onClick={requestGenerate}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className='ion-margin-horizontal'>
                <IonRow className='ion-justify-content-end ion-align-items-center'>
                  <IonCol>
                    <IonLabel style={{
                      margin: '0',
                      textAlign: 'left'
                    }}>
                      {t('Page')} {currentPage === 0 ? 1 : currentPage} {t('of')} {totalPages}
                    </IonLabel>
                  </IonCol>

                  <IonCol size='6' className='ion-text-right'>
                    {
                      perPage >= 9999
                        ? <BigUp.Buttons.Regular
                          icon={{
                            icon: arrowUpOutline,
                            color: 'medium',
                            slot: 'end'
                          }}
                          title={i18n.t('Show less')} onClick={() => numberOfResults(10, 1)} color={'medium'} fill='outline' className='ion-no-margin ' />
                        : <BigUp.Buttons.Regular
                          icon={{
                            icon: arrowDownOutline,
                            color: 'medium',
                            slot: 'end'
                          }}
                          title={i18n.t('Show all')} onClick={() => numberOfResults(9999, 0)} color={'medium'} fill='outline' className='ion-no-margin ' />

                    }
                  </IonCol>
                </IonRow>
              </IonGrid>
            </DesktopWrapper>
            {swiperController && !swiperController.removeSwiper
              ? <IonSpinner />
              : <React.Fragment>
                {economyMonitoringRows.length > 0 &&
                  <EconomyMonitoringRows
                    rows={economyMonitoringRows}
                    swiperController={swiperController}
                    economyMonitoring={economyMonitoring}
                    fetchEconomyMonitoring={fetchRows}
                  />
                }
                {handleEmptyListStates({
                  isEmpty: economyMonitoringRows.length === 0,
                  error,
                  messages,
                  search,
                  isLoading: isLoadingEconomyMonitoring,
                })}
              </React.Fragment>
            }
            {economyMonitoringRows.length > 0 &&
              <PaginateData
                scrollToId='not-posted-costs-rows-top'
                currentPage={currentPage}
                totalPages={totalPages}
                pageStepper={pageStepper}
              />}
          </React.Fragment>

        </div>
      </div>
    </React.Fragment>

  );
};

export default EconomyMonitoring;
