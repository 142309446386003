import { IonButton, IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useHistory } from 'react-router';

import type { ColourVariables } from '../variables';

export interface ReturnInterfaceExtraInfo {
  subpanel?: string;
  subpanelCategory?: string;
  subpanelCategoryUuid?: string;
}

interface ReturnInterface {
  color: string;
  text: string;
  fill: any;
  routeTo?: string;
  extraInfo?: ReturnInterfaceExtraInfo;
  textColour?: ColourVariables;
}

const ReturnChevronText: React.FC<ReturnInterface> = ({ color, extraInfo, fill, routeTo, text, textColour }) => {
  const history = useHistory();

  return (
    <IonButton
      mode='ios'
      color={color}
      onClick={() => routeTo ? history.push(routeTo, extraInfo || {}) : history.go(-1)}
      fill={fill}>
      <IonIcon icon={chevronBack} style={{ color: textColour || 'black' }} />
      <p className='ion-no-margin' style={{ color: textColour || 'black' }}>
        {text}
      </p>
    </IonButton>
  );
};

export default ReturnChevronText;
