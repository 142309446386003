import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';

import { BigUp } from '..';
import AppSettings from './AppSettings';
import styles from './Buttons.module.scss';
import type { ButtonProps, CustomCSSProperties, IconButtonProps } from './interface';
import BankIdAuthenticate from '../../ButtonComponent/BankIdAuthenticate';

const Regular: React.FC<ButtonProps> = ({ title, ...props }) => {
  return (
    <IonButton {...props}>
      {props.icon?.slot === 'start' && <IonIcon slot='start' {...props.icon} />}
      {title === undefined ? props.children : title}
      {props.customIcon}
      {props.icon?.slot === 'end' && <IonIcon slot='start' {...props.icon} />}
    </IonButton>
  );
};

const Responsive: React.FC<ButtonProps & IconButtonProps> = ({ title, ...props }) => {
  return (
    <>
      {props.isDesktop ? <Regular title={title} {...props} /> : <Icon {...props} />}
    </>

  );
};

const Icon: React.FC<IconButtonProps> = ({ ...props }) => {
  const default_padding: IconButtonProps['padding'] = props.padding || 28;
  const default_fontSize = props.icon?.style?.fontSize || 20;

  const default_styles: CustomCSSProperties = {
    '--padding-start': default_padding,
    '--padding-end': default_padding,
    '--padding-top': default_padding,
    '--padding-bottom': default_padding,
    width: default_padding * 2 || '50px',
    height: default_padding * 2 || '50px',
  };

  return (
    <div className={styles.roundButtonWrapper}>
      {props.title && <BigUp.Label.Regular label={props.title} className='ion-no-margin' />}
      <IonButton {...props} shape={props.shape || 'round'} style={{ ...default_styles, ...props.style }} mode={props.mode || 'md'}>
        {props.icon && (
          <IonIcon slot='icon-only' {...props.icon} style={{ ...props.style, fontSize: default_fontSize }} />
        )}
        {props.customIcon && props.customIcon}
      </IonButton>
    </div>
  );
};

const Buttons = {
  Regular,
  Icon,
  Responsive,
  AppSettings,
  BankIdAuthenticate
};

export default Buttons;
