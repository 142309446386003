import { IonBadge, IonCol, IonContent, IonGrid, IonLabel, IonRow, IonText, IonToggle, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import React, { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';

import styles from './PrecalculationForm.module.scss';
import { networking } from '../../../../../api/networking';
import RelatedMultiSelectEdit from '../../../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import RelatedSelect from '../../../../../components/Search/RelatedSelect/RelatedSelect';
import type { Result } from '../../../../../components/Search/RelatedSelect/RelatedSelectInterface';
import SectionLoader from '../../../../../components/SectionLoader/SectionLoader';
import toasters from '../../../../../components/Toasts/Toasts';
import StepButtons from '../../../../../components/Toolbar/StepButtons';
import { BigUp } from '../../../../../components/UI';
import InputLabel from '../../../../../components/UI/InputLabel';
import CurrencyInput from '../../../../../components/UI/Inputs/CurrencyInput';
import HeaderBorderLeft from '../../../../../components/UI/modals/HeaderBorderLeft';
import { ionicColours } from '../../../../../components/UI/variables';
import { useAppSelector } from '../../../../../hooks';
import i18n from '../../../../../i18n';
import { setIsLoading } from '../../../../../reducers/loading';
import formatNumber from '../../../../../tools/formatNumber';

interface EditProps {
  onDismiss(): void;
  onRowSave: () => void;
  deletePrecalculationRow: () => void;
  selectedRow: Partial<E2U.V1.Models.PrecalculationRow>;
}

const PrecalculationForm: React.FC<EditProps> = (props) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const [securedCost, setSecuredCost] = useState<boolean>(props.selectedRow.secured_cost ?? false);
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[]; }>({});
  const [_, setHandleSelectedActivityCode] = useState<Result | null>(null);
  const [selectedActivityCodes, setSelectedActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const store = useStore();
  const isLoading = useAppSelector(state => state.loading.isLoading.row);
  const selectedPrecalculation = useAppSelector(state => state.precalculations.selectedPrecalculation?.id);
  const precalculationRow: Partial<E2U.V1.Models.PrecalculationRow> = props.selectedRow;
  const formDefaults = {
    activity_code_id: props.selectedRow.activity_code?.id,
    cost_per_unit: props.selectedRow.cost_per_unit,
    created_at: props.selectedRow.created_at,
    id: props.selectedRow.id,
    precalculation_id: props.selectedRow.precalculation_id,
    quantity: props.selectedRow.quantity,
    secured_cost: props.selectedRow.secured_cost ?? false,
    unit: props.selectedRow.unit,
    updated_at: props.selectedRow.updated_at,
    name: props.selectedRow.name,
    description: props.selectedRow.description
  };
  const methods = useForm<E2U.V1.Models.PrecalculationRow>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: formDefaults
  });

  const activityCodeSelectToasters = {
    pending: i18n.t('Loading default values from activity code'),
    success: i18n.t('Successfully imported default values'),
    error: i18n.t('Unable to template from activity code')
  };

  const isUpdating = Boolean(precalculationRow && precalculationRow.id);
  const submitToasters = isUpdating
    ? {
      pending: i18n.t('Saving precalculation'),
      success: i18n.t('Successfully saved precalculation'),
      error: i18n.t('Failed saving precalculation')
    }
    : {
      pending: i18n.t('Creating precalculation'),
      success: i18n.t('Successfully created precalculation'),
      error: i18n.t('Failed creating precalculation')
    };

  const onActivityCodeSelect = (data: Result) => {
    setHandleSelectedActivityCode(data);

    methods.setValue('activity_code_id', data.id);
    if (!props.selectedRow.id) {
      toasters.promise(networking.get(`/api/v1/precalculation_rows/template/${data.id}`), activityCodeSelectToasters)
        .then(
          (response: E2U.V1.Response.Success<E2U.V1.Models.PrecalculationRow>) => {
            const template = response.data.data;
            (Object.keys(template) as (keyof E2U.V1.Models.PrecalculationRow)[]).forEach((key) => {
              if (methods.getValues()[key]) {
                return;
              }
              methods.setValue(key, template[key]);
            });
          }
        );
    }
  };

  const handleSecuredCostToggle = () => {
    methods.setValue('secured_cost', !securedCost);
    setSecuredCost(!securedCost);
  };

  const handleSave: SubmitHandler<E2U.V1.Models.PrecalculationRow> = (data: E2U.V1.Models.PrecalculationRow) => {
    store.dispatch(setIsLoading({ name: 'precalculationRow', value: true }));

    const requestAttributes: E2U.V1.Models.PrecalculationRow[] = [];

    selectedActivityCodes.forEach(activityCode => {
      requestAttributes.push({
        ...data,
        activity_code_id: activityCode.id
      });
    });

    const saveRequest = isUpdating
      ? networking.put(`/api/v1/precalculation_rows/${precalculationRow?.id}`, data)
      : networking.post('/api/v1/precalculation_rows/bulk', requestAttributes);

    toasters
      .promise(saveRequest, submitToasters)
      .then(() => {
        props.onRowSave();

        if (!isUpdating) {
          methods.reset();
          setSelectedActivityCodes([]);
        }
      })
      .catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.PrecalculationRow>) => {
          if (networking.isAxiosError(error)) {
            if (
              error &&
              error.response?.data &&
              error.response.data.message &&
              error.response.data.message === 'Validation Failed'
            ) {
              setValidationErrors(error.response.data.data);
              return;
            }
          }
          Sentry.captureException(error);
        }
      )
      .finally(() => {
        store.dispatch(
          setIsLoading({ name: 'precalculationRow', value: false })
        );
      });
  };
  return (
    <React.Fragment>
      <HeaderBorderLeft
        title={precalculationRow.id ? t('Edit / Delete') : i18n.t('Add new')}
        borderColour={precalculationRow.id ? ionicColours.secondary : ionicColours.secondary}
        clickHandler={props.onDismiss}
      />

      <IonContent className={classNames(styles['precalculation-form-container'], 'ion-padding')}>
        {isLoading
          ? <SectionLoader />
          : (
            <React.Fragment>
              <FormProvider {...methods}>
                <form
                  style={{ paddingTop: '20px ' }}
                  onSubmit={methods.handleSubmit(handleSave)}
                  className={styles['precalculation-form']}
                >
                  <input type="hidden" {...methods.register('precalculation_id')} value={selectedPrecalculation} />
                  <IonGrid>
                    <IonRow className="ion-justify-content-between">
                      <IonCol size="12">
                        <InputLabel margin={'ion-no-margin'} stacked> {t('Activity code')}:</InputLabel>
                        {
                          precalculationRow.id
                            ? (
                              <RelatedSelect
                                color='secondary'
                                infiniteScroll={true}
                                value={props.selectedRow.activity_code?.id}
                                model="activity_codes"
                                onSelect={onActivityCodeSelect}
                                title={i18n.t('Select activity code')}
                                displayFields={['code', 'name']}
                                fetchFields={['activityCategory', 'code_suffix']}
                              />
                            )
                            : (
                              <RelatedMultiSelectEdit
                                model="activity_codes"
                                button="solid"
                                onChange={(results: E2U.V1.Models.ActivityCode[]) => setSelectedActivityCodes(results)}
                                value={selectedActivityCodes}
                                displayFields={['code', 'name']}
                                label={
                                  selectedActivityCodes.length
                                    ? t('{amount} selected', '{amount} selected', {
                                      amount: selectedActivityCodes.length
                                    })
                                    : i18n.t('Select activity codes')
                                }
                                hideSelected={true}
                                modalTitle={i18n.t('Select activity code')}
                                ionButtonProps={{
                                  color: 'secondary'
                                }}
                              />
                            )
                        }
                      </IonCol>
                      <IonCol size="12">
                        <BigUp.Input
                          id='name'
                          name='name'
                          clearInput
                          label={i18n.t('Name')}
                          labelPlacement='stacked'
                          className='ion-no-padding ion-no-margin'
                          register={'name'}
                          placeholder={i18n.t('Name')}
                          type='text'
                          inputMode='text'
                          validation={{
                            required: {
                              value: true,
                              message: i18n.t('Name is required.')
                            }
                          }}
                        />
                      </IonCol>
                      <IonCol size="12" className='ion-margin-top'>
                        <CurrencyInput
                          id='quantity'
                          color='light'
                          padding='ion-no-padding'
                          margin='ion-no-margin'
                          label={i18n.t('Quantity')}
                          register={'quantity'}
                          requiredMessage={i18n.t('Quantity is required.')}
                          inputMode={'numeric'}
                          inputType={'text'}
                          placeholder={i18n.t('Quantity')}
                          min={{
                            value: 0,
                            message: i18n.t('Quantity can\'t be smaller than 0')
                          }}
                        />
                      </IonCol>

                      <IonCol size="12" >
                        <BigUp.Input
                          id='unit'
                          name='unit'
                          clearInput
                          label={i18n.t('Unit')}
                          labelPlacement='stacked'
                          className='ion-no-padding ion-no-margin'
                          register={'unit'}
                          placeholder={i18n.t('Unit')}
                          type='text'
                          inputMode='text'
                          validation={{
                            required: {
                              value: true,
                              message: i18n.t('Unit is required.')
                            }
                          }}
                        />
                        {validationErrors && validationErrors.unit && (
                          <IonBadge color="danger">
                            {validationErrors.unit.join(' .')}
                          </IonBadge>
                        )}
                      </IonCol>

                      <IonCol size="12">
                        <CurrencyInput
                          id='cost_per_unit'
                          padding='ion-no-padding'
                          margin='ion-no-margin'
                          label={i18n.t('Cost per unit')}
                          register={'cost_per_unit'}
                          requiredMessage={i18n.t('Cost per unit is required.')}
                          inputMode={'numeric'}
                          inputType={'text'}
                          placeholder={i18n.t('Cost per unit')}
                          min={{
                            value: 0,
                            message: i18n.t('Cost per unit can\'t be smaller than 0')
                          }} />

                        {validationErrors && validationErrors.cost_per_unit && (
                          <IonBadge color="danger">
                            {validationErrors.cost_per_unit.join(' .')}
                          </IonBadge>
                        )}
                      </IonCol>

                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <BigUp.Textarea
                          id='description'
                          name='description'
                          autoCapitalize='sentences'
                          itemProps={{ className: 'ion-no-padding' }}
                          label={i18n.t('Description')}
                          register={'description'}
                          placeholder={i18n.t('Add description to precalculation')}
                          inputMode='text'
                          autoGrow
                          validation={{
                            required: i18n.t('Description is required.'),
                          }}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className={classNames('ion-justify-content-between ion-align-items-center ion-margin', styles['confirm-cost'])} >
                      <IonCol sizeMd='3' sizeXs='6' className='ion-text-left'>
                        <IonText>
                          <p>{t('Secured cost?')}</p>
                        </IonText>
                      </IonCol>
                      <IonCol sizeMd='9' sizeXs='6' className='ion-text-right'>
                        <IonToggle
                          mode='md'
                          color={'primary'}
                          name='secured_cost'
                          onClick={handleSecuredCostToggle}
                          checked={securedCost}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  {precalculationRow.id
                    ? (
                      <section style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                        <IonLabel
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                            opacity: 0.7
                          }}
                          className='ion-no-margin'>
                          {t('Summary')}:
                        </IonLabel>

                        <IonLabel className='ion-no-margin'>{formatNumber(props.selectedRow.total || 0)}</IonLabel>
                      </section>
                    )
                    : (
                      <React.Fragment />
                    )}
                  <StepButtons
                    rightDisabled={
                      !methods.formState.isValid ||
                      (!isUpdating && !selectedActivityCodes) ||
                      isLoading
                    }
                    leftOnClick={props.onDismiss}
                    rightOnClick={() => { }}
                    leftTitle={t('Cancel')}
                    leftIonColor='none'
                    rightIonColor='secondary'
                    rightTitle={precalculationRow.id
                      ? t('save edit')
                      : i18n.t('add new')
                    }
                    rightSubmit={'submit'}
                  />
                </form>
              </FormProvider>
              {precalculationRow.id &&
                <IonGrid >
                  <IonRow>
                    <IonCol size='8'>
                      <IonLabel color={'medium'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 800 }}>
                        {t('Do you want to delete this precalculation?')}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size='8'>
                      <IonLabel
                        className={'ion-no-margin'}
                        style={{ fontSize: 15, fontWeight: 800 }}
                        color={'danger'}
                        onClick={() =>
                          presentAlert({
                            header: i18n.t('Are you sure you want to delete this precalculation?'),
                            message: precalculationRow.activity_code_id && precalculationRow.name,
                            buttons: [
                              {
                                text: i18n.t('Cancel'),
                                role: 'cancel'
                              },
                              {
                                text: i18n.t('OK'),
                                role: 'confirm',
                                handler: () => {
                                  props.deletePrecalculationRow();
                                }
                              }
                            ]
                          })}>
                        {t('Delete')}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            </React.Fragment>

          )}

      </IonContent>
    </React.Fragment>
  );
};

export default PrecalculationForm;
