import { useTranslation } from 'react-i18next';

import TeamInviteForm from './TeamInviteForm';
import ListTitle from '../../../components/UI/Titles/ListTitle';
import i18n from '../../../i18n';
import type { TeamsPromises } from '../interfaces';

const TeamInvite: React.FC<TeamsPromises> = ({ fetchTeam }) => {
  const { t } = useTranslation();
  return (
    <section className='ion-no-padding'>
      <ListTitle label={i18n.t('Invite user')} />
      <TeamInviteForm fetchTeam={fetchTeam} />
    </section>
  );
};

export default TeamInvite;
