import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import styles from './App.module.scss';
import { BankIdProvider } from './bankid/useBankId';
import AppUrlListener from './components/AppUrlListener';
import MediaChangeHandler from './components/Injectors/MediaChangeHandler';
import RedirectHandler from './components/Injectors/RedirectHandler';
import { NetworkInterceptor } from './components/NetworkInterceptors/NetworkInterceptor';
import useLogin from './hooks/useLogin';
import NotificationListener from './NotificationListener';
// import Smartlook from './Smartlook';
import ProfilePage from './pages/ProfilePage';
import WorkPreparationPage from './pages/WorkPreparation';
import RouteContent from './routes/index';
import ProtectedRoute from './routes/ProtectedRoute';
import store from './store';

setupIonicReact();

const App: React.FC = () => {
  const login = useLogin();
  return (
    // <Smartlook>
    <BankIdProvider
      baseURL={process.env.REACT_APP_API_BASE_URL ?? ''}
      handleToken={(token) => login.handleBankIdLogin(token)}
    >
      <Provider store={store}>
        <IonApp>
          <IonReactRouter>
            <NetworkInterceptor />
            <AppUrlListener />
            <RedirectHandler />
            <MediaChangeHandler />
            <Suspense fallback="Loading...">
              <IonRouterOutlet>
                <ProtectedRoute path="/profile" >
                  <ProfilePage />
                </ProtectedRoute>
                <ProtectedRoute path="/work-preparation" >
                  <WorkPreparationPage />
                </ProtectedRoute>
                <RouteContent />
              </IonRouterOutlet>
            </Suspense>
            <NotificationListener />
          </IonReactRouter>
        </IonApp>
        <ToastContainer className={styles['toast-holder']} />
      </Provider>
    </BankIdProvider>
    // </Smartlook>
  );
};

export default App;
