import i18n from '../i18n';

const filters = new Map([
  ['stamped_activity_code', i18n.t('Activity Code')],
  ['stamped_activity_code_desc', i18n.t('Activity Code (highest first)')],
  ['name', i18n.t('Name (A-Z)')],
  ['name_desc', i18n.t('Name (Z-A)')],
  ['created_at', i18n.t('Created at (newest first)')],
  ['created_at_desc', i18n.t('Created at (oldest first)')],
  ['updated_at', i18n.t('Last modified')],
  ['updated_at_desc', i18n.t('Last modified (most recent)')],
]);

export default filters;
