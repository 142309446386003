import { IonCol, IonDatetime, IonDatetimeButton, IonGrid, IonLabel, IonModal, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { calculator, shareSocial } from 'ionicons/icons';
import moment from 'moment';
import React from 'react';
import type { FieldValues, SubmitHandler, UseFormRegisterReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import styles from './CalculateCashFlow.module.scss';
import { networking } from '../../../../../api/networking';
import type SwiperController from '../../../../../components/Swiper/SwiperController';
import toasters from '../../../../../components/Toasts/Toasts';
import BigUp from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';
import i18n from '../../../../../i18n';
import { setIsLoading } from '../../../../../reducers/loading';
import store from '../../../../../store';
import { formatToYYYYMMDD } from '../../../../../tools/formatDates';
import shareUrl from '../../../../../tools/shareUrl';

type DateIDs = 'project-start' | 'project-finish'
type DateRegisters = 'start' | 'finish'

type DatetimeRegisterReturn = Omit<Omit<UseFormRegisterReturn, 'max'>, 'min'> & {
  max?: string;
  min?: string;
};

interface FetchCashFlow {
  onCalculated: () => void | undefined;
  swiperController?: SwiperController | undefined;
  refresh: () => void;
}

const CalculateCashFlow: React.FC<FetchCashFlow> = (props: FetchCashFlow) => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslation();
  const location = useLocation();
  const cashflow = useAppSelector(state => state.cashFlow.manualCashFlow);
  const selectedProject: E2U.V1.Models.Project | undefined = useAppSelector(state => state.project.selectedProject);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const shareURL = `${process.env.REACT_APP_SHARE_BASE_URL + location.pathname}`;

  function registerDatetime(register: UseFormRegisterReturn) {
    return register as DatetimeRegisterReturn;
  }

  const { handleSubmit, register } = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      start: cashflow?.date_start || moment().format('YYYY-MM-DD'),
      finish: cashflow?.date_finish || moment().format('YYYY-MM-DD')
    }
  });

  const calculateCashFlow: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setIsLoading({ name: 'cashflow', value: true }));
    toasters
      .promise(
        networking.post(`/api/v1/projects/${uuid}/cash_flows/generate`, data),
        {
          pending: i18n.t('pending'),
          success: i18n.t('success'),
          error: i18n.t('error')
        }
      )
      .then(() => {
        if (props.onCalculated) {
          props.onCalculated();
        }
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        props.refresh();
      });
  };

  const handleSharing = () => {
    shareUrl(
      `${t('Cash flow analysis for {project}', 'Cash flow analysis for {project}', { project: selectedProject?.name })}.`,
      `${t('Here is the link to the cash flow analysis for project {project}', 'Here is the link to the cash flow analysis for project {project}', { project: selectedProject?.name })} `,
      `${shareURL}`,
      t(`Let me know if there is something you are wondering about.`),
      'share'
    );
  };

  const findDefaultValues = (date: DateIDs) => {
    if (date === 'project-start') {
      return formatToYYYYMMDD(cashflow?.date_start || new Date().toISOString());
    } else {
      return formatToYYYYMMDD(cashflow?.date_finish || new Date().toISOString());
    }
  };

  const findCurrentYear = () => {
    const year = new Date().getFullYear();
    return new Date(year + 10, 11, 31);
  };

  const dateTimeContent = (id: DateIDs, dateRegister: DateRegisters) => {
    return (
      <IonDatetime
        showDefaultButtons
        doneText={i18n.t('OK')}
        cancelText={i18n.t('Cancel')}
        style={{ color: 'var(--ion-color-dark)' }}
        presentation="date"
        max={findCurrentYear().toISOString()}
        value={findDefaultValues(id as 'project-start' | 'project-finish')}
        id={id}
        {...registerDatetime(register(dateRegister))}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit(calculateCashFlow)}>
      <IonGrid className={styles['cashflow-calculate']}>
        <IonRow class="ion-align-items-center">
          <IonCol size="4" sizeMd='3'>
            <IonLabel color={'medium'}>{t('Project start')}</IonLabel>
            <IonDatetimeButton datetime="project-start" />
          </IonCol>

          <IonModal keepContentsMounted={true}>
            {dateTimeContent('project-start', 'start')}
          </IonModal>

          <IonCol size="4" sizeMd='3' class='ion-text-left'>
            <IonLabel color={'medium'}>{t('Project finish')}</IonLabel>
            <IonDatetimeButton datetime="project-finish" />
          </IonCol>

          <IonModal keepContentsMounted={true}>
            {dateTimeContent('project-finish', 'finish')}
          </IonModal>

          <IonCol size="1" sizeMd='2' className='ion-text-center ion-margin-horizontal'>

            {/*  <ButtonResponsive
                type="submit"
                mobileButton={{
                  label: i18n.t('Calc'),
                  children: <IonIcon size='large' icon={calculator} color='primary' />,
                  ionicButton: { shape: 'round', color: 'none' }
                }}
                desktopButton={{
                  children: (
                    <>
                      <IonIcon icon={calculator} />
                      <IonLabel>{t('Calculate')}</IonLabel>
                    </>
                  ),
                  ionicButton: { color: 'primary' },
                }}
              /> */}
            <BigUp.Buttons.Responsive
              padding={20}
              expand='block'
              isDesktop={isDesktop}
              color={isDesktop ? 'primary' : 'none'}
              title={i18n.t('Calculate')}
              icon={{ icon: calculator, color: 'primary', size: 'large' }}
              onClick={handleSubmit(calculateCashFlow)}
            />
          </IonCol>
          <IonCol size="1" sizeMd='2' class='ion-text-right  ion-margin-horizontal'>
            {/*       <ButtonResponsive
              type="button"
              onClick={handleSharing}
              mobileButton={{
                label: i18n.t('Share'),
                children: <IonIcon size='large' icon={shareSocial} color='secondary' />,
                ionicButton: { shape: 'round', color: 'none' }
              }}
              desktopButton={{
                children: (
                  <>
                    <IonIcon icon={shareSocial} />
                    <IonLabel>{t('Share')}</IonLabel>
                  </>
                ),
                ionicButton: { color: 'secondary' },
              }}
            /> */}
            <BigUp.Buttons.Responsive
              padding={20}
              expand='block'
              isDesktop={isDesktop}
              color={isDesktop ? 'secondary' : 'none'}
              title={i18n.t('Share')}
              icon={{ icon: shareSocial, color: 'secondary' }}
              onClick={handleSharing}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};

export default CalculateCashFlow;
