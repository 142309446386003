import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { ellipsisHorizontalSharp, personCircleSharp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import SkeletonItem from '../../../components/SkeletonComponents/SkeletonItem';
import DeleteRecord from '../../../components/UI/DeleteRecord';
import EmptyList from '../../../components/UI/EmptyList';
import ItemShadowNoIcons from '../../../components/UI/Items/components/ItemShadowNoIcons';
import PermissionsModal from '../../../components/UI/Permissions/PermissionsModal';
import { useAppSelector } from '../../../hooks';
import useModal from '../../../hooks/useModal';
import i18n from '../../../i18n';
import type { TeamsPromises } from '../interfaces';

const TeamMembers: React.FC<TeamsPromises> = ({ fetchAllTeams }) => {
  const [deletingItemId, setDeletingItemId] = useState<string | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<E2U.V1.Models.User>();
  const [profileImages, setProfileImages] = useState<string[] | undefined | any[]>([]);
  const isMounted = useRef<boolean>(true);

  const modal = useModal();
  const [alert] = useIonAlert();
  const { t } = useTranslation();
  const team = useAppSelector(state => state.team.selectedTeam);

  const findTeamMembersProfileImage = async () => {
    team?.users.forEach((user: E2U.V1.Models.User | any) => {
      if (user.profile_picture_id !== null) {
        networking.get(`api/v1/files/${user.profile_picture_id}`)
          .then((res) => {
            if (isMounted.current) {
              setProfileImages((prevProfileImages: any) => [
                ...prevProfileImages, {
                  userId: user.id,
                  imageUrl: `${res.data.data.export_url}?base64=true`
                }
              ]);
            }
          });
      }
    });
  };

  const handleDeleteMember = (id: string | undefined) => {
    setDeletingItemId(id);
    networking.delete(`api/v1/teams/${team?.id}/users/${id}`)
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        fetchAllTeams && fetchAllTeams();
        setDeletingItemId(undefined);
        modal.closeModal();
      });
  };

  const deleteConfimation = (id: string | undefined) => {
    alert({
      header: i18n.t('Delete member'),
      message: i18n.t('Are you sure you want to delete {name} from the team?', 'Are you sure you want to delete {name} from the team?', { name: selectedUser?.first_name }),
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: i18n.t('Delete'),
          handler: () => handleDeleteMember(id)
        }
      ]
    });
  };

  const findUser = (id: string | undefined) => {
    return team?.users.find(user => user.id === id);
  };

  const permissionSelection = (id: string | undefined) => {
    const user = findUser(id);
    setSelectedUser(user);
    modal.openModal();
  };

  useEffect(() => {
    if (team?.users.length) {
      findTeamMembersProfileImage();
    }
  }, [team?.users]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <IonGrid>
        {team?.users.map((user: E2U.V1.Models.User | any) => {
          const findImage = profileImages?.find((img: any) => img.userId === user.id)?.imageUrl;
          return (
            <IonRow key={user.id} className='ion-align-items-center'>
              <IonCol>
                {deletingItemId === user.id
                  ? <div style={{ height: 40 }}><SkeletonItem amount={1} /></div>
                  : (
                    <>
                      <div style={{ height: 40, marginBottom: findImage && 10 }}>
                        <ItemShadowNoIcons
                          label={`${user.first_name} ${user.last_name}`}
                          subLabel={user.email}
                          description=''
                          hasIconLeft
                          backgroundLeft={
                            (user?.profile_picture_id) !== null
                              ? findImage
                              // TODO: Get gravatar otherwise? /* user.profile_picture_id === null ? getGravatarPicture(user && user.email) : '' */
                              : null
                          }
                          deleteIcon={ellipsisHorizontalSharp}
                          iconLeft={personCircleSharp}
                          deleteHandler={() => permissionSelection(user.id)}
                        />
                      </div>

                      {/* TODO: Possible solution for displaying permissions.
                          Implement when permissions are available from server
                      */}
                      {/*   <section className='ion-margin-top' style={{
                        flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', gap: 10
                      }}>
                        <div>
                          <InputLabel margin='ion-no-margin'>Economy tools: Read</InputLabel>
                        </div>
                        <div>
                          <InputLabel margin='ion-no-margin'>Economy tools: Read</InputLabel>
                        </div>
                      </section> */}
                    </>
                  )}
              </IonCol>
            </IonRow>
          );
        })}

        {team?.users.length === 0 &&
          <IonRow>
            <IonCol>
              <EmptyList
                title={i18n.t('No members are associated with this project.')}
                message={i18n.t('Invite members below.')}
              />
            </IonCol>
          </IonRow>
        }
      </IonGrid>

      <PermissionsModal
        data={selectedUser as E2U.V1.Models.User}
        modal={{
          modalIsOpen: modal.isModalOpen,
          modalDidDismiss: modal.closeModal
        }}
      >
        <div style={{ borderBottom: '1px solid var(--ion-color-medium)', opacity: 0.5 }} className='ion-margin-top' />
        <DeleteRecord
          record={''}
          onDelete={() => deleteConfimation(selectedUser?.id)}
          label={i18n.t(`Do you want to delete {name} from the team?`, { name: selectedUser?.first_name })}
        />
      </PermissionsModal>
    </>
  );
};

export default TeamMembers;
