
import { IonCol, IonIcon, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import type { PasswordInputsProps, PasswordType } from './types';
import { BigUp } from '../../components/UI';
import type { InputProps } from '../../components/UI/Inputs/Input.interface';
import i18n from '../../i18n';
import { regex } from '../../regex/regex';

const password_pattern = {
  value: regex.regex_password,
  message: i18n.t('Must contain at least one number, one symbol, one uppercase and lowercase letter')
};

const password_length = {
  value: 8,
  message: i18n.t('Password must be at least 8 characters')
};

const passwordHelperText = i18n.t('Must contain at least one number, one symbol, one uppercase and lowercase letter. Min 8 char.');

const PasswordInputs: React.FC<PasswordInputsProps> = ({ onKeyUp, required }) => {
  const [handleShownPassword, setHandleShownPassword] = React.useState({
    password: false,
    confirmPassword: false
  });

  const methods = useFormContext();

  const validatePassword = (value: string) => {
    const watchPassword = methods.getValues('password');
    return value === watchPassword || i18n.t('The entered passwords do not match');
  };

  const passwordToggle = (type: PasswordType) => {
    setHandleShownPassword({ ...handleShownPassword, [type]: !handleShownPassword[type] });
  };

  const passwordIconHandler = (type: PasswordType) => {
    return handleShownPassword[type] ? eyeOffOutline : eyeOutline;
  };

  const passwordToggleProps = (type: PasswordType) => {
    return {
      icon: passwordIconHandler(type),
      style: { fontSize: 24, marginRight: 10 },
      role: 'button',
      onClick: () => passwordToggle(type),
    };
  };

  const password_input: InputProps<E2U.V1.Models.User> = {
    label: i18n.t('Password'),
    type: handleShownPassword.password ? 'text' : 'password',
    inputMode: 'text',
    enterkeyhint: 'next',
    placeholder: i18n.t('Password'),
    onKeyUp,
    register: required ? 'password' : undefined,
    helperText: passwordHelperText,
    validation: {
      required: required ? i18n.t('Please enter a password') as keyof E2U.V1.Models.User : false,
      minLength: { value: password_length.value, message: password_length.message },
      pattern: { value: password_pattern.value, message: password_pattern.message },
    },
  };

  const confirm_password_input: InputProps<E2U.V1.Models.User> = {
    label: i18n.t('Confirm password'),
    type: handleShownPassword.confirmPassword ? 'text' : 'password',
    inputMode: 'text',
    enterkeyhint: 'done',
    placeholder: i18n.t('Confirm password'),
    onKeyUp,
    helperText: i18n.t('Please confirm your password'),
    register: required ? 'password_confirmation' : undefined,
    validation: {
      required: required ? i18n.t('Please confirm your password') as keyof E2U.V1.Models.User : false,
      minLength: { value: password_length.value, message: password_length.message },
      pattern: { value: password_pattern.value, message: password_pattern.message },
      validate: (value) => validatePassword(value) as boolean,
    },
  };
  const ariaLabel = (type: PasswordType) => {
    return handleShownPassword[type] ? 'Hide password' : 'Show password';
  };
  return (
    <>
      <IonRow className='ion-align-items-center'>
        <IonCol size='10' className='ion-margin-bottom'>
          <BigUp.Input {...password_input} />
        </IonCol>
        <IonCol className='ion-margin-bottom ion-text-center'>
          <IonIcon aria-label={ariaLabel('password')} {...passwordToggleProps('password')} />
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center'>
        <IonCol size='10' className='ion-margin-bottom'>
          <BigUp.Input {...confirm_password_input} />
        </IonCol>
        <IonCol className='ion-margin-bottom ion-text-center'>
          <IonIcon aria-label={ariaLabel('confirmPassword')}{...passwordToggleProps('confirmPassword')} />
        </IonCol>
      </IonRow>
    </>
  );
};

export default PasswordInputs;
