import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronBack, chevronForward } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { SwiperSlide } from 'swiper/react';

import styles from './MonitoringSwiper.module.scss';
import ActivityCodeTile from './Subpanels/ActivityCodeTile';
import Column from './SwiperTiles/Column';
import ChangesTile from './SwiperTiles/tiles/ChangesTile';
import PostedCostTile from './SwiperTiles/tiles/CostTile';
import DeviationTile from './SwiperTiles/tiles/DeviationTile';
import EconomyMonitoringCostsTile from './SwiperTiles/tiles/EconomyMonitoringTile';
import StandardTile from './SwiperTiles/tiles/StandardTile';
import WorkupRateTile from './SwiperTiles/tiles/WorkupRateTile';
import DesktopWrapper from '../../components/DesktopWrapper';
import SwiperContainer from '../../components/Swiper/SwiperContainer';
import SwiperController from '../../components/Swiper/SwiperController';
import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';

interface EconomyMonitoringProps {
  swiperController: SwiperController | undefined;
  economyMonitoring: E2U.V1.Models.EconomyMonitoring | E2U.V1.Models.NotPosted | undefined;
  fetchEconomyMonitoring: () => void;
  rows: any[];
  canAdd: boolean;
}

const MonitoringSwiper: React.FC<EconomyMonitoringProps> = (props) => {
  const { t } = useTranslation();
  const { monitoring_uuid, uuid } = useParams<{ uuid: string | undefined, monitoring_uuid: string | undefined }>();
  const location = useLocation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const [swiperController, setSwiperController] = useState<SwiperController>(new SwiperController());
  const [currentIndex, setCurrentIndex] = useState<number>(swiperController.getCurrentIndex());
  const [compLoaded, setCompLoaded] = useState<boolean>(true);

  const setCurrentIndexWrapper = (prop: any) => {
    if (compLoaded) {
      setCurrentIndex(prop);
    }
  };

  swiperController?.addIndexHook(setCurrentIndexWrapper);

  const onUpdate = () => {
    if (props.fetchEconomyMonitoring) {
      props.fetchEconomyMonitoring();
    }
  };

  const nextIdx = () => {
    if (isDesktop) {
      swiperController?.goTo(props.rows.length);
      return;
    }
    swiperController?.nextSlide();
  };

  const prevIdx = () => {
    if (isDesktop) {
      swiperController?.goToStart();
      return;
    }
    swiperController?.previousSlide();
  };

  useEffect(() => {
    setCompLoaded(true);
    return () => setCompLoaded(false);
  }, []);

  useEffect(() => {
    setSwiperController(swiperController);
  }, []);

  return (
    <DesktopWrapper>
      <IonGrid style={{ width: '100%' }} className={styles['economy-monitoring-row-container']}>
        <IonRow className='ion-margin-bottom ion-justify-content-end'>
          <IonCol sizeXs='2'>
            <BigUp.Buttons.Responsive
              padding={20}
              disabled={swiperController.isAtStart()}
              expand='block'
              isDesktop={isDesktop}
              color={isDesktop ? 'light' : 'none'}
              title={i18n.t('Back')}
              icon={{ icon: chevronBack, color: 'secondary' }}
              onClick={() => prevIdx()}
            />
          </IonCol>
          <IonCol sizeXs='2'>
            <BigUp.Buttons.Responsive
              padding={20}
              expand='block'
              disabled={!!(swiperController.isAtEnd() && currentIndex! > 0)}
              isDesktop={isDesktop}
              color={isDesktop ? 'secondary' : 'none'}
              title={i18n.t('Next')}
              icon={{ icon: chevronForward, color: 'secondary' }}
              onClick={() => nextIdx()}
            />
          </IonCol>

        </IonRow>
        <IonRow>
          <IonCol {...isDesktop ? { size: '2' } : { size: '4' }}>
            {props.rows.map((r) => {
              return (
                <div key={r.id} className='ion-margin-bottom'>
                  <ActivityCodeTile
                    activityCode={r.activity_code}
                    summary={'Summary costs'}
                    minHeight="60px"
                    name={r.name}
                  />
                </div>
              );
            })}
          </IonCol>

          <IonCol
            {...isDesktop ? { size: '10' } : { size: '8' }}
            className={styles['economy-monitoring-rows-swiper']}
            class="ion-text-right"
          >

            <SwiperContainer controller={swiperController} slidesPerView={isDesktop ? 5 : 3} spaceBetween={6}>
              <SwiperSlide key={1}>
                <Column
                  rows={props.rows}
                  component={
                    <StandardTile label={i18n.t('Prod budget')} field={'production_budget_total'} />
                  }
                />
              </SwiperSlide>

              <SwiperSlide key={2} >
                <Column
                  rows={props.rows}
                  component={
                    <ChangesTile row={props.rows as any} clickable={props.canAdd} fetchEconomyMonitoring={onUpdate} />
                  }
                />
              </SwiperSlide>

              <SwiperSlide key={3} >
                <Column
                  rows={props.rows}
                  component={
                    <DeviationTile row={props.rows as any} clickable={props.canAdd} fetchEconomyMonitoring={onUpdate} />
                  }
                />
              </SwiperSlide>

              <SwiperSlide key={4} >
                <Column
                  rows={props.rows}
                  component={
                    <StandardTile label={i18n.t('Fin. forecast')} field={'final_forecast_total'} background="#C5FAD7" />
                  }
                />
              </SwiperSlide>

              <SwiperSlide key={5} >
                <Column
                  rows={props.rows}
                  component={
                    <StandardTile label={i18n.t('Accrued cost')} field={'accrude_cost_total'} />
                  }
                />
              </SwiperSlide>

              {location.pathname !== `/economy-tools/${uuid}/monitoring/${monitoring_uuid}` && (
                <React.Fragment>
                  <SwiperSlide key={9}>
                    <Column
                      rows={props.rows}
                      component={
                        <PostedCostTile />
                      }
                    />
                  </SwiperSlide>
                  <SwiperSlide key={7}>
                    <Column
                      rows={props.rows}
                      component={
                        <EconomyMonitoringCostsTile row={props.rows as any} clickable={props.canAdd} fetchEconomyMonitoring={onUpdate} />
                      }
                    />
                  </SwiperSlide>
                  <SwiperSlide key={8}>
                    <Column
                      rows={props.rows}
                      component={
                        <StandardTile whiteSpace={'normal'} label={i18n.t('Previous Not Posted')} field={'previous_not_posted_cost_total'} />
                      }
                    />
                  </SwiperSlide>
                </React.Fragment>
              )}

              <SwiperSlide key={6}>
                <Column
                  rows={props.rows}
                  component={
                    <WorkupRateTile />
                  }
                />
              </SwiperSlide>
            </SwiperContainer>
          </IonCol>
        </IonRow>
      </IonGrid>
    </DesktopWrapper>
  );
};
export default MonitoringSwiper;
