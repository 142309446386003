import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PageMenuHeader from '../../components/PageMenuHeader/PageMenuHeader';
import { ionicColours } from '../../components/UI/variables';

const PageNotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleSupportRedirect = () => {
    history.push('/support');
  };

  return (
    <IonPage>
      <PageMenuHeader />
      <IonContent className="ion-padding">
        <div className="ion-text-center">
          <h1>{t('Page not found')}</h1>
          <p>
            {t('The page you are looking for does not exist.')}
            <br />
            {t('This could be due to a removed entity.')}
            <br />
          </p>
          <h3 color='secondary'>
            {t('If the problem persists feel free to contact our ')}
            <i
              style={{
                borderBottom: '1px solid #000000',
                cursor: 'pointer',
                color: ionicColours.secondary
              }}
              onClick={() => {
                handleSupportRedirect();
              }}>
              {t('support')}
            </i>
          </h3>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PageNotFound;
