import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { BreadcrumbData } from '../components/UI/Breadcrumbs/Breadcrumbs';
import { useAppSelector } from '../hooks';
import i18n from '../i18n';

export const useBreadcrumbConfig = () => {
  // PARAMS
  const { notice_uuid, team_id, worksite_id } = useParams<{
    uuid: string, team_id: string | undefined,
    worksite_id: string | undefined,
    notice_uuid: string | undefined
  }>();
  // REDUCERS
  const team = useAppSelector(state => state.team.selectedTeam);
  const worksite = useAppSelector(state => state.worksites.selectedWorksite);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  // HOOKS
  const { t } = useTranslation();
  // VARIABLES
  const checkIfTeamExists = team && typeof team.id !== 'undefined';
  const teamFormTitle = checkIfTeamExists ? t('Edit team') : i18n.t('Create team');

  // BREADCRUMBS
  const registerBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Home'),
      path: `/`,
    },
    {
      label: i18n.t('Register'),
      path: `/registration`,
      disabled: true,
    },
  ];

  const documentBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Document'),
      path: `/project-tools/${selectedProject?.id}/documents`,
    },
    {
      label: i18n.t('Document'),
      disabled: true,
      path: `/project-tools/${selectedProject?.id}/document/${team_id}`,
    },
  ];

  const inviteBreadCrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Teams'),
      path: `/project-tools/${selectedProject?.id}/settings/teams`,
    },
    {
      label: team?.name || '',
      path: `/project-tools/${selectedProject?.id}/settings/team/${team_id}`,
    },
    {
      label: i18n.t('Invite'),
      path: `/project-tools/${selectedProject?.id}/settings/team/${team_id}/invite`,
    },
  ];

  const teamBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Teams'),
      path: `/project-tools/${selectedProject?.id}/settings/teams`,
    },
    {
      label: team?.name || '',
      disabled: true,
      path: `/project-tools/${selectedProject?.id}/settings/team/${team?.id}`,

    },
  ];

  const teamFormBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Teams'),
      path: `/project-tools/${selectedProject?.id}/settings/teams`,
    },
    {
      label: checkIfTeamExists ? team?.name : '',
      path: `/project-tools/${selectedProject?.id}/settings/team/${team?.id}`,
    },
    {
      label: teamFormTitle,
      path: `/project-tools/${selectedProject?.id}/settings/team/${team?.id}/edit`,
      disabled: true,
    },
  ];

  const projectInformationBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Economy tools'),
      path: `/economy-tools/${selectedProject?.id}`,
    },
    {
      label: i18n.t('Project information'),
      path: `/economy-tools/${selectedProject?.id}/project-information`,
      disabled: true,
    },
  ];
  const precalculationsBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Economy tools'),
      path: `/economy-tools/${selectedProject?.id}`,
    },
    {
      label: i18n.t('Precalculations'),
      path: `/economy-tools/${selectedProject?.id}/precalculations`,
      disabled: true,
    },
  ];
  const economyMonitoringBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Economy tools'),
      path: `/economy-tools/${selectedProject?.id}`,
    },
    {
      label: i18n.t('Economy reports'),
      path: `/economy-tools/${selectedProject?.id}/economy-reports`,
      disabled: true,
    },
  ];

  const worksiteBreadcrumb: BreadcrumbData[] = [
    {
      label: i18n.t('Worksite'),
      path: `/project-tools/${selectedProject?.id}/worksites`,
    },
    {
      label: worksite?.name || t('Create new'),
      path: `/project-tools/${selectedProject?.id}/worksites/add`,
      disabled: true,
    },
  ];
  const worksiteLayerBreadcrumb: BreadcrumbData[] = [
    {
      label: i18n.t('Worksite'),
      path: `/project-tools/${selectedProject?.id}/worksites`,
    },
    {
      label: worksite?.name || t('Create new'),
      path: worksite_id
        ? `/project-tools/${selectedProject?.id}/worksites/${worksite_id}`
        : `/project-tools/${selectedProject?.id}/worksites/add`,
    },
    {
      label: i18n.t('Layers'),
      disabled: true,
      path: `/project-tools/${selectedProject?.id}/worksites/${worksite?.id}/layer`,
    },
  ];

  const noticeDetailedBreadcrumbs: BreadcrumbData[] = [
    {
      label: i18n.t('Notice board'),
      path: `/notice-board/${selectedProject?.id}`,
    },
    {
      label: i18n.t('Notice'),
      path: ``,
      disabled: true,
    },
  ];

  const noticeFormBreadcrumbs: BreadcrumbData[] = [
    {
      label: notice_uuid ? t('Notice') : i18n.t('Notice board'),
      path: notice_uuid ? `/notice-board/${selectedProject?.id}/${notice_uuid}` : `/notice-board/${selectedProject?.id}`,
    },
    {
      label: notice_uuid ? t('Edit notice') : i18n.t('Create Notice'),
      path: ``,
      disabled: true,
    },

  ];

  return {
    documentBreadcrumbs,
    inviteBreadCrumbs,
    teamBreadcrumbs,
    teamFormBreadcrumbs,
    economyMonitoringBreadcrumbs,
    noticeDetailedBreadcrumbs,
    noticeFormBreadcrumbs,
    registerBreadcrumbs,
    worksiteBreadcrumb,
    worksiteLayerBreadcrumb,
    projectInformationBreadcrumbs,
    precalculationsBreadcrumbs
  };
};
