import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../api/networking';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';
import BigUp from '../UI';

const JoinProjectForm: React.FC = () => {
  const methods = useForm<{ code: string }>({
    defaultValues: {
      code: ''
    }
  });

  const isLoading = useAppSelector(state => state.loading.isLoading.joinProjectForm);
  const { t } = useTranslation();

  const doJoinProject = (data: { code: string }) => {
    store.dispatch(setIsLoading({ name: 'joinProjectForm', value: true }));
    networking.post('/api/v1/projects/join', data)
      .then((response) => {
        // FIXME: handle response
      })
      .catch((err) => {
        if (networking.isAxiosError(err)) {
          console.log('error', err.response?.data);
        } else {
          console.log('error', err);
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'joinProjectForm', value: false }));
      });
  };

  return (
    <IonCard className={'ion-no-margin'}>
      <IonCardContent>
        <BigUp.Label.Thick label={ t('Join project using access code') } color={'primary'} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(doJoinProject)}>
            <IonGrid>
              <IonRow className={'ion-align-items-center'}>
                <IonCol>
                  <BigUp.Input
                    label={t('Access code')}
                    register={'code'}
                    validation={{
                      required: t('Access code is required')
                    }}
                    labelPlacement={'floating'}
                  />
                </IonCol>
                <IonCol size={'auto'}>
                  <BigUp.Buttons.Regular type={'submit'} disabled={isLoading} title={ t('Join') } />
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </FormProvider>
      </IonCardContent>
    </IonCard>
  );
};

export default JoinProjectForm;
