import type { RouterDirection } from '@ionic/react';
import { IonBreadcrumb, useIonRouter } from '@ionic/react';
import { useLocation } from 'react-router';

import BreadcrumbsWrapper from './BreadcrumbsWrapper';

export type BreadcrumbData = {
  label: string;
  path: string;
  disabled?: boolean;
  direction?: RouterDirection;
}

interface BreadcrumbProps {
  data: BreadcrumbData[];
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ data }) => {
  const router = useIonRouter();
  const location = useLocation();
  return (
    <BreadcrumbsWrapper>
      {data.map((item, index) => {
        return (
          <IonBreadcrumb
            key={index}
            style={{ cursor: 'pointer' }}
            disabled={location.pathname === item.path || item.disabled}
            onClick={() => router.push(item.path, item.direction || 'forward')}
          >
            {item.label}
          </IonBreadcrumb>
        );
      })}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
