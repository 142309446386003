import { IonCol, IonItemGroup, IonList, IonRow } from '@ionic/react';

import BigUp from '../../../components/UI';

const PermissionListWrapper = ({ children, title }: { children: React.ReactNode, title: string }) => {
  return (
    <IonRow className='ion-margin-bottom'>
      <IonCol>
        <IonList>
          <BigUp.Label.Thick label={title} />
          <IonItemGroup>
            {children}
          </IonItemGroup>
        </IonList>
      </IonCol>
    </IonRow>
  );
};

export default PermissionListWrapper;
