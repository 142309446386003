import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n';
import { BigUp } from '../../UI';
import ItemShadowNoIcons from '../../UI/Items/components/ItemShadowNoIcons';
interface FileListProps {
  allFiles: E2U.V1.Models.File[],
  previewImage: number[],
  setPreviewImage: React.Dispatch<React.SetStateAction<number[]>>,
  toggleState: (setter: React.Dispatch<React.SetStateAction<number[]>>, i: number) => void,
  typeLabel: string
}

export const FileList = ({ allFiles, previewImage, setPreviewImage, toggleState, typeLabel }: FileListProps) => {
  const { t } = useTranslation();
  return (
    <>
      <IonRow>
        <IonCol>
          {allFiles.length > 0 && (
            <BigUp.Label.Thick label={i18n.t('Uploaded files')} />
          )}

          <IonGrid>
            <IonRow className={'ion-align-items-start '}>
              {allFiles?.map((file: E2U.V1.Models.File, index) => (
                <React.Fragment key={index}>
                  <IonCol size={'12'}>
                    <ItemShadowNoIcons
                      key={index}
                      subLabel={''}
                      label={typeLabel}
                      description={file.name}
                      hasBorder={false}
                      noMarginTop={true}
                    />
                  </IonCol>
                </React.Fragment>
              ))}
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
    </>
  );
};
