import { IonCol, IonGrid, IonIcon, IonItem, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { close, pencil, save, trash } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../components/UI';
import i18n from '../../../i18n';
import type { TagListProps } from '../interfaces';

const TagList: React.FC<TagListProps> = ({ onTagDelete, onTagSave, tags }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const pickerRef = useRef<HTMLInputElement>(null);

  const methods = useForm<E2U.V1.Models.Tag>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      color: ''
    }
  });

  const handleTagEditTrigger = (tag: E2U.V1.Models.Tag) => {
    if (isEditing === tag.id) {
      methods.reset();
      setIsEditing(null);
    } else {
      methods.reset({
        id: tag.id,
        name: tag.name,
        color: tag.color
      });
      setIsEditing(tag.id ?? null);
    }
  };

  return (
    <IonGrid className='ion-padding ion-margin-bottom'>
      {tags && tags.map((tag: E2U.V1.Models.Tag, i: number) => (
        <FormProvider {...methods} key={i}>
          <form onSubmit={methods.handleSubmit((data) => onTagSave(tag.id, data))}>
            <IonRow className='ion-align-items-center ion-justify-content-between'>
              <IonCol size='2'>
                {isEditing === tag.id
                  ? (
                    <Controller
                      name='color'
                      control={methods.control}
                      render={({ field }) => (
                        <BigUp.ColorPicker
                          value={field.value}
                          width='50px'
                          pickerId={`color_${i}`}
                          pickerRef={pickerRef}
                          onChange={(e) => field.onChange((e.target as HTMLInputElement).value)}
                        />
                      )}
                    />
                  )
                  : <div style={{ background: tag.color, width: 30, height: 30, borderRadius: '50%' }} />
                }
              </IonCol>
              <IonCol size='7' className='ion-text-start'>
                {isEditing === tag.id
                  ? (
                    <BigUp.Input
                      register='name'
                      validation={{
                        required: {
                          value: true,
                          message: i18n.t('Tag name is required')
                        },
                        minLength: {
                          value: 3,
                          message: i18n.t('Tag name must be at least 3 characters long'),
                        },
                        maxLength: {
                          value: 255,
                          message: i18n.t('Tag name must be at most 255 characters long'),
                        },
                      }}
                    />
                  )
                  : (
                    <IonItem className='ion-no-padding' lines='none'>
                      <BigUp.Label.Regular className='ion-no-margin ion-text-nowrap' label={tag.name} title={tag.name} />
                    </IonItem>
                  )}
              </IonCol>
              <IonCol size='1' className='ion-text-center'>
                {isEditing === tag.id
                  ? <IonIcon
                    icon={save}
                    size='large'
                    color='success'
                    onClick={() => {
                      onTagSave(tag.id, methods.getValues());
                      setIsEditing(null);
                    }}
                  />
                  : <IonIcon icon={pencil} size='large' color='primary' onClick={() => handleTagEditTrigger(tag)} />
                }
              </IonCol>
              <IonCol size='1' className='ion-text-center'>
                {isEditing === tag.id
                  ? <IonIcon icon={close} size='large' color='medium' onClick={() => handleTagEditTrigger(tag)} />
                  : <IonIcon icon={trash} size='large' color='danger' onClick={() => onTagDelete(tag.id)} />
                }
              </IonCol>
            </IonRow>
          </form>
        </FormProvider>
      ))}
    </IonGrid>
  );
};

export default TagList;
