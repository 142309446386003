import { IonTextarea } from '@ionic/react';

import BigUp from '../../../../components/UI';
import type { TextareaProps } from '../../../../components/UI/Textareas/interface';
import i18n from '../../../../i18n';

const inputProps: TextareaProps<any>[] = [
  {
    label: i18n.t('Company'),
    placeholder: i18n.t('Company'),
    labelPlacement: 'start',
    required: true,
    register: 'description',
  },
];

const Description: React.FC = () => {
  const items = inputProps.map((_, index) => ({
    id: (index + 1).toString(),
    content: <IonTextarea label={i18n.t('Comments')} label-placement="floating" rows={5}></IonTextarea>
  }));
  return <BigUp.ItemList header={{ title: i18n.t('Description') }} items={items} />;
};

export default Description;
