import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import BigUp from '..';
import type { CurrencyInputProps } from './Input.interface';
import { ValidationBadge } from './ValidationBadge';
import i18n from '../../../i18n';
import formatNumber from '../../../tools/formatNumber';

const CurrencyInput: React.FC<CurrencyInputProps<string>> = (input) => {
  const { formState: { errors }, register, setValue, trigger } = useFormContext<FieldValues>();
  return (
    <React.Fragment>
      <BigUp.Input
        validation={{
          required: input.requiredMessage,
          maxLength: input.maxLength,
          minLength: input.minLength,
          pattern: input.pattern,
          setValueAs: (value) => {
            if (input.inputMode === 'numeric') {
              return value.toString().replace(/[ ]/g, '');
            }
            return value;
          },
        }}
        register={input.register}
        label={i18n.t(input.label)}
        labelPlacement='stacked'
        className='ion-no-padding'
        disabled={input.disabled}
        autoCapitalize='sentences'
        aria-required
        clearInput
        {...input.ionicProps}
        type={input.inputType}
        inputmode={input.inputMode}
        placeholder={input.placeholder}
        onIonInput={(e) => {
          setValue(input.register, e.detail.value, { shouldValidate: true });
          trigger(input.register);
          const currentTarget = e.currentTarget as HTMLInputElement;
          if (currentTarget) {
            const value = currentTarget.value.replace(/[ ]/g, '');
            if (isNaN(parseFloat(value))) {
              setValue(input.register, (currentTarget.value && currentTarget.value[0] === '0') ? '0' : '');
              return;
            }

            if (value === '' || value === '0') {
              setValue(input.register, (currentTarget.value && currentTarget.value[0] === '0') ? '0' : value);
            } else {
              setValue(input.register, formatNumber(parseFloat(value)));
            }
          }
        }}
      />
    </React.Fragment>
  );
};

export default CurrencyInput;
