import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import appStyles from '../App.module.scss';
import DesktopWrapper from '../components/DesktopWrapper';
import HamburgerMenu from '../components/Menu/HamburgerMenu';
import PageMenuHeader from '../components/PageMenuHeader/PageMenuHeader';
import type { ColourVariables } from '../components/UI/variables';

interface AppLayoutProps {
  scroll?: boolean;
  headerTitle?: string;
  children: React.ReactNode;
  contentColour?: ColourVariables;
  content_id?: string;
  menu_id?: string;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  content_id,
  contentColour = 'var(--ion-color-light)',
  headerTitle,
  menu_id,

  scroll = false,
}) => {
  return (
    <React.Fragment>
      <HamburgerMenu />
      <IonPage id={'main-content'}>
        <PageMenuHeader />
        <IonContent
          style={{ '--background': contentColour, hyphens: 'var(--ion-hyphen)' }}
          id={appStyles['fade-in-page']}
          className={appStyles[scroll ? 'has-scroll' : 'remove-scroll']}
          fullscreen
        >
          <DesktopWrapper alignment={'center'} width={'500px'}>
            {children}
          </DesktopWrapper>
        </IonContent>
      </IonPage>
    </React.Fragment>
  );
};

export default AppLayout;
