import { IonButton, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import i18n from '../../../i18n';
import { setSelectedTeam } from '../../../reducers/team';
import store from '../../../store';

const TeamDelete: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { team_id, uuid } = useParams<{ uuid: string | undefined, team_id: string | undefined }>();
  const [alert] = useIonAlert();

  const confirmDelete = () => {
    alert({
      header: i18n.t('Delete team'),
      message: i18n.t('Are you sure you want to delete this team?'),
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel',
        },
        {
          text: i18n.t('Delete'),
          handler: () => deleteSelectedTeam()
        }
      ]
    });
  };
  const deleteSelectedTeam = () => {
    toasters.promise(networking.delete(`/api/v1/teams/${team_id}`), {
      error: i18n.t('Could not delete team'),
      success: i18n.t('Team deleted!')
    })
      .then(() => {
        store.dispatch(setSelectedTeam(undefined));
        history.push(`/project-tools/${uuid}/settings/teams`);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };

  return (
    <IonButton onClick={() => confirmDelete()} color={'none'} style={{ color: 'var(--ion-color-medium)' }}>
      {t('Delete')}
    </IonButton>
  );
};

export default TeamDelete;
