import { IonCol, IonIcon, IonItem, IonNote, IonRow } from '@ionic/react';
import { colorPaletteSharp } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../../components/UI';
import i18n from '../../../../i18n';
import { capitalize } from '../../../../tools/capitalizeString';

const CategoryForm: React.FC = () => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const colorRef = React.useRef<HTMLInputElement>(null);
  const colourKey = 'color';
  const watchHex = methods.watch(colourKey) || '';

  useEffect(() => {
    methods.getValues();
  }, [methods]);

  return (
    <>
      <IonRow className="ion-align-items-center ion-justify-content-space-between">
        <IonCol size="12">
          <BigUp.Input
            label={capitalize(t('Name'))}
            labelPlacement='stacked'
            register='name'
            type='text'
            disabled={methods.formState.isSubmitting}
            inputMode='text'
            placeholder={i18n.t('Enter the name of your type')}
            validation={{
              required: {
                value: true,
                message: i18n.t('Name is required')
              },
              maxLength: {
                value: 20,
                message: i18n.t('Name is too long'),
              }
            }}
          />
        </IonCol>
      </IonRow>
      <IonItem
        lines='none'
        className='ion-no-padding ion-margin-top'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          '--inner-padding-end': '0px',
        }}>
        <IonIcon
          style={{ color: watchHex }}
          icon={colorPaletteSharp} size='large'
          className='ion-margin-end'
        />
        <BigUp.Label.Regular label={''} className='ion-no-margin'>
          <h3>{t('Type colour')}</h3>
          <IonNote color="medium">{t('Select a color for your type')}</IonNote>
        </BigUp.Label.Regular>
        <BigUp.ColorPicker
          pickerRef={colorRef}
          width={'100px'}
          pickerId={colourKey}
          onChange={(e: any) => methods.setValue(colourKey, e.target.value)}
          value={watchHex}
          defaultColor={watchHex}
        />
      </IonItem>
    </>
  );
};

export default CategoryForm;
