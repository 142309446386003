import { IonSelect, IonSelectOption } from '@ionic/react';
import { t } from 'i18next';
import { caretDownSharp } from 'ionicons/icons';

import i18n from '../../../i18n';

interface LanguageList {
  value: string;
  label: string
}

const languages: LanguageList[] = [
  { value: 'sv', label: 'Swedish' },
  { value: 'en', label: 'English' }
];

const LanguageSelect: React.FC = () => {
  return (
    <IonSelect
      justify="space-between"
      className="always-flip"
      toggleIcon={caretDownSharp}
      interface="popover"
      label={i18n.t('Language')}
      placeholder={i18n.t('Language')}
      value={i18n.language}
      onIonChange={(e) => {
        i18n.changeLanguage(e.detail.value);
      }}
    >
      {languages.map((language, i) => {
        return (
          <IonSelectOption key={i} value={language.value}>{t(language.label)}</IonSelectOption>
        );
      })}
    </IonSelect>
  );
};

export default LanguageSelect;
