import { IonItem, IonTextarea } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useController, useFormContext } from 'react-hook-form';

import type { TextareaProps } from './interface';
import { ValidationBadge } from '../Inputs/ValidationBadge';

const Textarea: React.FC<TextareaProps<E2U.V1.Models.User | string>> = ({ withValidation = true, ...props }) => {
  const methods = useFormContext();
  const control = methods.control;
  const { field } = useController({
    name: props.register,
    control,
    rules: props.validation,
  });
  const label = props.label || '';
  return (
    <>
      <IonItem {...props.itemProps}>
        <IonTextarea
          {...props}
          label={label}
          labelPlacement={props.labelPlacement || 'stacked'}
          autoCapitalize='sentences'
          aria-required
          aria-aria-valuemax={props.maxlength}
          aria-aria-valuemin={props.minlength}
          placeholder={props.placeholder}
          onIonInput={(e) => {
            field.onChange(e.detail.value);
          }}
          {...methods.register(props.register, {
            ...props.validation,
          })}
        />
      </IonItem>
      {withValidation && (
        <div className='ion-no-margin' style={{ height: 20, paddingTop: 5, display: 'flex' }}>
          {methods.formState.errors[props.register] && (
            <ValidationBadge >
              {methods.formState.errors[props.register]?.message}
            </ValidationBadge>
          )}
        </div>
      )}
    </>
  );
};

export default Textarea;
