import { addSharp } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';

const AddNewButton: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const project = useAppSelector(state => state.project.selectedProject);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const shared_button_props = {
    icon: addSharp,
    onClick: () => history.push(`/notice-board/${project?.id}/add`),
  };

  return (isDesktop
    ? (
      <BigUp.Buttons.Regular
        title={i18n.t('Add new')}
        onClick={shared_button_props.onClick}
        icon={{ slot: 'end', icon: shared_button_props.icon }}
        color={'secondary'}
      />
    )
    : (
      <BigUp.Buttons.Icon
        color={'none'}
        icon={{ icon: shared_button_props.icon, color: 'secondary' }}
        onClick={shared_button_props.onClick}
      />
    )
  );
};

export default AddNewButton;
