import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import JourneySelection from '../../components/ClientAccount/JourneySelection';
import DesktopWrapper from '../../components/DesktopWrapper';
import JoinProjectForm from '../../components/JoinProjectForm';
import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';

const IntroductionPage: React.FC = () => {
  const user = useAppSelector(state => state.authentication.user);
  const { t } = useTranslation();
  return (
    <DesktopWrapper width='600px' alignment={'center'}>
      <div className={'ion-padding'}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <BigUp.Title color={'primary'}>{ t('Welcome to BigUp, {first_name}!', { first_name: user?.first_name }) }</BigUp.Title>
              <p className={'ion-no-margin ion-margin-bottom'}>{ t('To get started, you need access to a project. To gain access, ask your project administrator for the unique access code.') }</p>
            </IonCol>
          </IonRow>
          <IonRow className={'ion-margin-bottom'}>
            <IonCol>
              <JoinProjectForm />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          <IonRow>
            <IonCol className={'ion-margin-top'}>
              <BigUp.Label.Thick label={ t('Are you an project admin looking to get started with BigUp? Look no further!') } />
              <p className={'ion-no-margin ion-margin-bottom'}>{ t('Get started using BigUp for your projects with our simple onboarding process. Choose between getting a call-up within 30 seconds, or supplying necessary information in your own pace.') }</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <JourneySelection />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </DesktopWrapper>
  );
};

export default IntroductionPage;
