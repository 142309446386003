import i18n from '../i18n';
import type { HintCode } from './typings/pendingLog';
export const handleHintcodes = (code: HintCode) => {
  const hintcodesMap = new Map<HintCode, string>([
    ['outstandingTransaction', i18n.t('Start your BankID app.')],
    ['noClient', i18n.t('Start your BankID app.')],
    ['started', i18n.t('BankID app started.')],
    ['userSign', i18n.t('Sign in progress.')],
    ['unknown', i18n.t('Identification or signing in progress.')],
    ['cancelled', i18n.t('Cancelled')],
    ['complete', i18n.t('Complete')],
    ['failed', i18n.t('Failed')],
    ['expiredTransaction', i18n.t('Expired transaction')],
    ['certificateErr', i18n.t('Certificate error')],
    ['userCancel', i18n.t('User cancel')],
    ['cancelled', i18n.t('Cancelled')],
    ['startFailed', i18n.t('Start failed')],
    ['requestErr', i18n.t('Request error')],
    ['unauthorized', i18n.t('Unauthorized')],
    ['internalErr', i18n.t('Internal error')],
    ['alreadyInProgress', i18n.t('Already in progress')],
    ['invalidParameters', i18n.t('Invalid parameters')],
    ['notFound', i18n.t('Not found')],
    ['alreadyComplete', i18n.t('Already complete')],
    ['expiredRequest', i18n.t('Expired request')],
  ]);

  return hintcodesMap.get(code) ?? i18n.t('Unknown error');
};
