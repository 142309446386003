import { IonIcon, IonItem, IonLabel, IonList, IonLoading, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { AxiosResponse } from 'axios';
import { checkmark } from 'ionicons/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory, useParams } from 'react-router';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { validate as isValidUUID } from 'uuid';

import BottomButtons from './BottomButtons';
import styles from './WorksiteMaps.module.scss';
import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import SearchbarUnderlined from '../../../components/UI/SearchAndSort/Search/SearchbarUnderlined';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import { setShouldHideMenu } from '../../../reducers/project';
import storage from '../../../storage';
import store from '../../../store';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const WorksiteMapPage: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [worksites, setWorksites] = React.useState<(E2U.V1.Models.WorkSite & {
    layers: E2U.V1.Models.WorkSiteLayer[];
  })[]>([]);
  const [selectedLayer, setSelectedLayer] = React.useState<E2U.V1.Models.WorkSiteLayer | null>(null);
  const [search, setSearch] = useState<string>('');
  const [file, setFile] = React.useState<E2U.V1.Models.File | null>(null);
  const [fileContent, setFileContent] = React.useState<string>('');
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const history = useHistory();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const { uuid, worksite_layer_id } = useParams<{ uuid: string, worksite_layer_id: string }>();
  const isLoadingWorksiteLayer = useAppSelector(state => state.loading.isLoading.work_site_layer);
  const [presentAlert] = useIonAlert();
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const isDesktop = window.innerWidth > 768;
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  const fetchSelectedFile = (file_id: string) => {
    networking.get(`/api/v1/files/${file_id}`).then(
      (response: E2U.V1.Response.Success<E2U.V1.Models.File>) => {
        setFile(response.data.data);
      }
    ).catch((error) => {
      Sentry.captureException(error);
      toasters.error(t('Failed to fetch map file data'));
    });
  };

  const fetchSelectedLayer = (layer_id: string) => {
    if (!isValidUUID(layer_id)) {
      /**
       * Avoid request failure in case of layer_id being ':worksite_layer_id'
       */
      return;
    }

    store.dispatch(setIsLoading({ name: 'work_site_layer', value: true }));
    const searchParams = new URLSearchParams();
    searchParams.append('with[]', 'file');
    networking.get(`/api/v1/work_site_layers/${layer_id}?${searchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkSiteLayer>) => {
        setFile(response.data.data.file);
        setSelectedLayer(response.data.data);
        getFileContent(response.data.data.file);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to fetch map file data'));
        history.push(`/project-tools/${selectedProject?.id}/map`);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'work_site_layer', value: false }));
      });
  };

  const fetchAvailableWorksites = () => {
    if (!selectedProject || uuid !== selectedProject.id) return;
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('with[]', 'layers');
    urlSearchParams.append('search', search);
    networking.get(`/api/v1/projects/${selectedProject.id}/work_sites?${urlSearchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<(E2U.V1.Models.WorkSite & {
        layers: E2U.V1.Models.WorkSiteLayer[];
      })>>) => {
        setWorksites(response.data.data.records);
        if (response.data.data.records.length === 0) {
          toasters.info(t('No map available for search {search}', 'No map available for search {search}', {
            search
          }));
          return;
        }
        storage.get(`selected_layer_for_${selectedProject?.id}`)
          .then((selectedLayerId) => {
            if (selectedLayerId && !worksite_layer_id) {
              history.push(`/project-tools/${selectedProject?.id}/map/${selectedLayerId}`);
            }
          });
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to fetch worksite data'));
      });
  };

  const getFileContent = (file: E2U.V1.Models.File) => {
    networking.get(`/api/v1/files/${file.id}/export?base64=true`)
      .then((response: AxiosResponse<string>) => {
        setFileContent(`data:application/pdf;base64,${response.data}`);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to fetch file content'));
      });
  };

  const handleSelectedLayer = (layer: E2U.V1.Models.WorkSiteLayer) => {
    storage.set(`selected_layer_for_${selectedProject?.id}`, layer.id);
    history.push(`/project-tools/${selectedProject?.id}/map/${layer.id}`);
  };

  useEffect(() => {
    fetchAvailableWorksites();
  }, [selectedProjectId, search]);

  useEffect(() => {
    if (selectedLayer) {
      if (!worksite_layer_id) {
        /**
         * Quite weird but can happen due to the way the "map" is
         * linked (it requires layer, which is not present
         * on non-"working location" page).
         */
        handleSelectedLayer(selectedLayer);
        return;
      }

      if (!file || file.id !== selectedLayer.file_id) {
        fetchSelectedFile(selectedLayer.file_id);
      }
    }
  }, [selectedLayer]);

  useEffect(() => {
    store.dispatch(setShouldHideMenu(true));
    fetchAvailableWorksites();

    if (worksite_layer_id) {
      fetchSelectedLayer(worksite_layer_id);
    }

    return () => {
      store.dispatch(setShouldHideMenu(false));
    };
  }, [worksite_layer_id]);

  const handleProtectedPdf = (callback: (password: string) => void) => {
    presentAlert({
      header: i18n.t('Password required'),
      subHeader: i18n.t('This document is password protected'),
      message: i18n.t('Please enter the password to view the document'),
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: i18n.t('Password')
        }
      ],
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: i18n.t('Ok'),
          handler: (data) => {
            callback(data.password);
          }
        }
      ]
    });
  };

  if (isLoadingWorksiteLayer) {
    return <IonLoading isOpen={true} />;
  }

  return (
    <>
      <div className={styles.mapContainer}>
        <TransformWrapper pinch={{ step: 5 }} ref={transformComponentRef} initialScale={1.5} >
          {(utils) => (
            <React.Fragment>
              <TransformComponent wrapperStyle={{ width: '100%', height: '65vh' }}>
                {(file && file.type === 'image')
                  ? <img src={fileContent} alt={file.name} />
                  : ((file && file.filetype === 'pdf')
                    ? <Document
                      onLoadSuccess={onDocumentLoadSuccess}
                      file={fileContent}
                      options={options}
                      onPassword={(callback) => handleProtectedPdf(callback)}
                    >
                      <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
                    </Document>
                    : <p>{t('Document type not supported')}</p>
                  )
                }
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
        <BottomButtons pageNumber={pageNumber} setPageNumber={setPageNumber} numPages={numPages ?? 0} selectedProject={selectedProject} />
      </div>

      <BigUp.BottomSheet
        {...isDesktop && { style: { height: '100%' } }}
        bottomSheetTrigger='worksites-open-modal' bottomSheetRef={modal}>
        <div className='ion-margin-top ion-padding-horizontal'>
          <SearchbarUnderlined value={search} onSearch={setSearch} />
        </div>
        <IonList className='ion-padding'>
          {worksites.filter((w) => w.layers.length).map((worksite, i) => (
            <React.Fragment key={i} >
              {worksite.layers.map((layer: E2U.V1.Models.WorkSiteLayer, layerI) => {
                return (
                  <>
                    {layer.is_visible
                      ? (
                        <IonItem className='ion-no-padding' key={layerI} onClick={() => handleSelectedLayer(layer)}>
                          <IonLabel>{worksite.name}: {layer.name}</IonLabel>
                          {layer.id === selectedLayer?.id && <IonIcon aria-hidden="true" icon={checkmark} slot="end" />}
                        </IonItem>
                      )
                      : <></>
                    }
                  </>
                );
              })}
            </React.Fragment>
          ))}
        </IonList>
      </BigUp.BottomSheet>
    </>
  );
};

export default WorksiteMapPage;
