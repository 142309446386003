import type { SelectChangeEventDetail } from '@ionic/react';
import { IonCol, IonGrid, IonIcon, IonModal, IonRow, IonSelect, IonSelectOption, isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import axios, { type CancelTokenSource } from 'axios';
import { add, chevronDown, chevronUp, ellipsisHorizontal } from 'ionicons/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';

import DuplicateButton from './DuplicateButton';
import PrecalculationForm from './Form/PrecalculationForm';
import PrecalculationRows from './PreCalculationRows';
import styles from './Precalculations.module.scss';
import { networking } from '../../../../api/networking';
import FadeInContainer from '../../../../components/Animated/FadeInContainer';
import PieChart from '../../../../components/Graphs/PieChart';
import RelatedMultiSelectEdit from '../../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import BigUp from '../../../../components/UI';
import type { ActionSheetItem } from '../../../../components/UI/ActionSheets';
import SummaryRows from '../../../../components/UI/Layout/SummaryRows';
import type { PopoverItem } from '../../../../components/UI/Popovers';
import SearchAndSortRow from '../../../../components/UI/SearchAndSort/SearchAndSortRow';
import toasters from '../../../../components/UI/Toasts';
import filters from '../../../../constants/filters';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import i18n from '../../../../i18n';
import { setIsLoading } from '../../../../reducers/loading';
import { setSelectedPrecalculation, setSelectedVersion } from '../../../../reducers/precalculations';
import store from '../../../../store';
import formatNumber from '../../../../tools/formatNumber';
import shareUrl from '../../../../tools/shareUrl';

const PrecalculationDetail: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('created_at');

  const { historicalPrecalculationId, precalculationId, uuid } = useParams<{
    precalculationId: string | undefined, uuid: string | undefined, historicalPrecalculationId: string | undefined
  }>();
  const [filterParam, setFilterParam] = useState<null | string>(null);
  const [selectedPrecalculationRow, setSelectedPrecalculationRow] = useState<Partial<E2U.V1.Models.PrecalculationRow>>({});
  const [selectedActivityCodes, setSelectedActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const [showChart, setShowChart] = useState<boolean>(false);
  const [precalculationVersions, setPrecalculationVersions] = useState<any>([]);
  const [nameUpdateCancelToken, setNameUpdateCancelToken] = useState<CancelTokenSource | null>(null);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);
  const selectedVersion = useAppSelector(state => state.precalculations.selectedVersion);
  const [shouldRefetchRows, setShouldRefetchRows] = useState<boolean>(false);

  const { t } = useTranslation();
  const location = useLocation();
  const { closeModal, isModalOpen, openModal } = useModal();
  const onModalDismiss = () => closeModal();

  const methods = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      id: '',
    }
  });
  const name = methods.watch('name');
  const id = methods.watch('id');

  const selectedPrecalculationId = useMemo(() => {
    if (selectedVersion?.precalculation_id) {
      return selectedVersion.precalculation_id;
    } else {
      return selectedPrecalculation?.id;
    }
  }, [selectedVersion, selectedPrecalculation]);

  const searchParams = new URLSearchParams();
  searchParams.append('sort_by', 'iteration');
  searchParams.append('direction', 'desc');
  searchParams.append('per_page', '9999');
  searchParams.append('page', '1');
  searchParams.append('with', 'rows');

  const getPrecalculation = async () => {
    if (precalculationId) {
      store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
      networking.get(`api/v1/precalculations/${precalculationId}`)
        .then((response) => {
          store.dispatch(setSelectedPrecalculation(response.data.data));
          store.dispatch(setSelectedVersion(undefined));
        })
        .catch((error) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
        });
    } else {
      networking.get(`api/v1/precalculation_histories/${historicalPrecalculationId}`)
        .then((response) => {
          store.dispatch(setSelectedVersion(response.data.data));
          networking.get(`api/v1/precalculations/${response.data.data.precalculation_id}`)
            .then((res) => {
              store.dispatch(setSelectedPrecalculation(res.data.data));
            });
        })
        .finally(() => {
          store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
        });
    }
  };

  const getPrecalculationVersions = () => {
    store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
    networking.get(`/api/v1/precalculations/${selectedPrecalculationId}/versions?${searchParams}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Precalculation[]>>) => {
        setPrecalculationVersions(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
      });
  };

  const remainingTotal = {
    precalculation: {
      value: (selectedPrecalculation?.total ?? 0) - (selectedPrecalculation?.secured_costs ?? 0),
      color: filterParam === '1' ? 'var(--ion-color-medium)' : 'var(--ion-color-primary)',
      param: '0'
    },
    historical: {
      value: (selectedVersion?.total ?? 0) - (selectedVersion?.secured_costs ?? 0),
      color: filterParam === '1' ? 'var(--ion-color-medium)' : 'var(--ion-color-primary)',
      param: '0'
    }
  };

  const securedTotal = {
    precalculation: {
      value: selectedPrecalculation?.secured_costs,
      color: filterParam === '0' ? 'var(--ion-color-medium)' : 'var(--ion-color-secondary)',
      param: '1'
    },
    historical: {
      value: selectedVersion?.secured_costs,
      color: filterParam === '0' ? 'var(--ion-color-medium)' : 'var(--ion-color-secondary)',
      param: '1'
    }
  };

  const pieChartData = {
    precalculation: [
      {
        value: remainingTotal.precalculation.value,
        name: i18n.t('Remaining'),
        color: remainingTotal.precalculation.color,
        onClick: () => setFilterParam(remainingTotal.precalculation.param)
      },
      {
        value: securedTotal.precalculation.value,
        name: i18n.t('Secured Costs'),
        color: securedTotal.precalculation.color,
        onClick: () => setFilterParam(securedTotal.precalculation.param)
      }
    ],
    historical: [
      {
        value: remainingTotal.historical.value,
        name: i18n.t('Remaining'),
        color: remainingTotal.historical.color,
        onClick: () => setFilterParam(remainingTotal.historical.param)
      },
      {
        value: securedTotal.historical.value,
        name: i18n.t('Secured Costs'),
        color: securedTotal.historical.color,
        onClick: () => setFilterParam(securedTotal.historical.param)
      }
    ]
  };

  const history = useHistory();
  const shareURL = process.env.REACT_APP_SHARE_BASE_URL + location.pathname;

  const handleSelectedVersion = (id: string, e: CustomEvent<SelectChangeEventDetail<any>>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const selectedVersion = precalculationVersions.find((version: E2U.V1.Models.Precalculation) => version.id === id);
    const isVersion = Boolean(selectedVersion);

    const projectPrecalculationPaths = isVersion
      ? `/economy-tools/${uuid}/precalculation_history/${id}`
      : `/economy-tools/${uuid}/precalculation/${id}`;

    const userPrecalculationPaths = isVersion
      ? `/economy-tools/precalculation_history/${id}`
      : `/economy-tools/precalculation/${id}`;
    const checkPath = uuid ? projectPrecalculationPaths : userPrecalculationPaths;
    history.push(checkPath);
  };

  const handleExport = () => {
    networking.get(
      `/api/v1/precalculations/${selectedPrecalculation?.id}/share`
    )
      .then((response) => {
        shareUrl(
          `${t('Precalculation for {projectName}.', 'Precalculation for {projectName}.', { projectName: selectedProject?.name })}`,
          `${t('Here is the link to the precalculation for {projectName}: {url}.', 'Here is the link to the precalculation for {projectName}: {url}.', {
            projectName: selectedProject?.name, url: isPlatform('ios') ? response.data.data.url : ''
          })}`,
          `${response.data.data.url}`,
          t(`Let me know if there is something you are wondering about.`),
          'export'
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleShareRevision = () => {
    shareUrl(
      `${t('Share revision for {projectName}.', 'Share revision for {projectName}.', { projectName: selectedProject?.name })}`,
      `${t('Here is the link for revision')}: ${isPlatform('ios') ? shareURL : ''}`,
      `${process.env.REACT_APP_SHARE_BASE_URL + location.pathname}`,
      t(`Let me know if there is something you are wondering about.`),
      'share'
    );
  };

  const handleShareAndExportOfPrecalculation = (handler: 'export' | 'share') => {
    if (handler === 'export') return handleExport();
    if (handler === 'share') return handleShareRevision();
  };

  const deletePrecalculationRow = () => {
    store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
    toasters
      .promiseToast(networking.delete(`/api/v1/precalculation_rows/${selectedPrecalculationRow.id}`), {
        pending: i18n.t('Deleting precalculation row'),
        success: i18n.t('Successfully deleted precalculation row'),
        error: i18n.t("Couldn't delete precalculation row")
      })
      .then(() => {
        getPrecalculation()
          .then(() => setShouldRefetchRows(true));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
        getPrecalculation();
        onModalDismiss();
      });
  };

  const updatePrecalculationOnNameChange = useCallback((data: FieldValues) => {
    const pushNameDataToReducer = {
      ...selectedPrecalculation,
      name: data.name,
      project_id: selectedProject?.id,
    };

    if (nameUpdateCancelToken) {
      nameUpdateCancelToken.cancel('Canceling previous request');
    }

    const source = axios.CancelToken.source();
    setNameUpdateCancelToken(source);

    networking.put(`/api/v1/precalculations/${selectedPrecalculation?.id}`, pushNameDataToReducer, {
      cancelToken: source.token
    }).then((response: E2U.V1.Response.Success<E2U.V1.Models.Precalculation>) => {
      store.dispatch(setSelectedPrecalculation(response.data.data));
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, [selectedPrecalculation, name, selectedProject?.id]);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!selectedPrecalculation) return;
    if (selectedPrecalculation?.name === name) return;

    if (name !== '' || name !== selectedPrecalculation?.name) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        updatePrecalculationOnNameChange(methods.getValues());
      }, 300);
    }
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [name]);

  const submitPrecalculation: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const pushNameDataToReducer = {
      ...selectedPrecalculation,
      name: data.name,
      project_id: selectedProject?.id,
    };
    const endpoint = historicalPrecalculationId
      ? `api/v1/precalculations/${historicalPrecalculationId}/restore`
      : `/api/v1/precalculations/${precalculationId}/submit`;
    const networkRequest = networking.post(`${endpoint}`, pushNameDataToReducer);

    toasters.promiseToast(networkRequest, {
      pending: {
        message: i18n.t('Submitting precalculation'),
      },
      success: {
        message: i18n.t('Successfully submitted precalculation'),
      },
      error: {
        message: i18n.t("Couldn't submit precalculation"),
      }
    })
      .then((response: E2U.V1.Response.Success<{
        precalculation_id: string;
        precalculation_iteration: number;
        precalculation_history_id: string;
        precalculation_history_iteration: number;
      }>) => {
        history.push(
          uuid
            ? `/economy-tools/${uuid}/precalculation/${response.data.data.precalculation_id}`
            : `/economy-tools/precalculation/${response.data.data.precalculation_id}`
        );
        if (!historicalPrecalculationId) {
          getPrecalculationVersions();
        }
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };

  const openPrecalculationFormModal = (row: Partial<E2U.V1.Models.PrecalculationRow>) => {
    setSelectedPrecalculationRow(row);
    openModal();
  };

  const onRowSave = () => {
    getPrecalculation()
      .then(() => setShouldRefetchRows(!shouldRefetchRows));

    closeModal();
  };

  useEffect(() => {
    if (selectedPrecalculation?.id !== id) {
      methods.setValue('id', selectedPrecalculation?.id);
      methods.setValue('name', selectedPrecalculation?.name);
    }
  }, [selectedPrecalculation]);

  useEffect(() => {
    const requestAttributes: any[] = [];
    const identifier: string | undefined = selectedPrecalculationId;

    if (!identifier || !selectedActivityCodes.length) {
      return;
    }

    selectedActivityCodes.forEach(activityCode => {
      requestAttributes.push({
        activity_code_id: activityCode.id,
        precalculation_id: identifier,
        cost_per_unit: 0,
        quantity: 0,
        description: null,
        unit: 'sqm',
        living_unit: 'sqm',
        secured_cost: false,
      });
    });

    const saveRequest = networking.post('/api/v1/precalculation_rows/bulk', requestAttributes);

    saveRequest
      .then(() => {
        getPrecalculation()
          .then(() => {
            setShouldRefetchRows(!shouldRefetchRows);
          })
          .catch(
            (error: E2U.V1.Response.Error<E2U.V1.Models.PrecalculationRow>) => {
              if (networking.isAxiosError(error)) {
                if (
                  error &&
                  error.response?.data &&
                  error.response.data.message &&
                  error.response.data.message === 'Validation Failed'
                ) {
                  return;
                }
              }
              Sentry.captureException(error);
            }
          ).finally(() => {
            setSelectedActivityCodes([]);
          });
      });
  }, [selectedActivityCodes, selectedPrecalculationId]);

  useEffect(() => {
    getPrecalculation();
  }, [precalculationId, historicalPrecalculationId]);

  useEffect(() => {
    if (selectedPrecalculationId) {
      getPrecalculationVersions();
    } else {
      setPrecalculationVersions([]);
    }
  }, [selectedPrecalculationId]);

  const checkRemaingOrSecuredValues = () => {
    if (selectedPrecalculation) {
      if (selectedPrecalculation.secured_costs !== 0 || selectedPrecalculation.total !== 0) {
        return true;
      }
    }
  };

  const actionButtonList: ActionSheetItem[] | PopoverItem[] = [
    {
      value: 'share',
      label: i18n.t('Share'),
      onClick: () => {
        handleShareAndExportOfPrecalculation('share');
      }
    },
    {
      value: 'export',
      label: i18n.t('Export'),
      onClick: () => {
        handleShareAndExportOfPrecalculation('export');
      }
    },
    {
      value: 'duplicate',
      label: i18n.t('Duplicate'),
      onClick: () => {
        history.push(`/economy-tools/precalculation/${selectedPrecalculation?.id}/duplicate`);
      }
    },
    ...(historicalPrecalculationId
      ? []
      : [{
        value: 'create-project',
        label: i18n.t('Create project'),
        onClick: () => {
          history.push({
            pathname: `/economy-tools/precalculation/${selectedPrecalculation?.id}/create-project`,
          });
        }
      }]),
    {
      value: 'cancel',
      label: 'Cancel',
      onClick: () => { }
    },
  ];

  return (
    <div >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitPrecalculation)}>
          <div className='ion-padding' {...isDesktop && { className: styles['precalculations-desktop-wrapper'] }} >
            <IonGrid>
              <IonRow style={{ maxWidth: 500 }}>
                <IonCol
                  size='6'
                  sizeMd='4'
                  sizeLg='12'
                  className=' ion-text-left'>
                  <IonRow className='ion-align-items-center'>
                    <IonCol size='10'>
                      <IonSelect
                        value={historicalPrecalculationId || precalculationId}
                        interfaceOptions={{ cssClass: 'select-option-interface' }}
                        placeholder={i18n.t('Select version')}
                        onIonChange={(e) => handleSelectedVersion(e.detail.value, e)}
                        interface={isDesktop ? 'alert' : 'action-sheet'}
                      >
                        <IonSelectOption value={selectedPrecalculation?.id}>
                          {t('{precalculationName} (Current)', '{precalculationName} (Current)', {
                            precalculationName: selectedPrecalculation?.name
                          })}
                        </IonSelectOption>

                        {precalculationVersions.map((version: E2U.V1.Models.Precalculation) => (
                          <IonSelectOption key={version.id} value={version.id}>
                            {version.name} ({version.iteration ? version.iteration + 1 : ''})
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonCol>
                    {isDesktop && (
                      <IonCol size='2' className='ion-text-center'>
                        <BigUp.Buttons.Regular
                          title={undefined}
                          fill='clear'
                          id="open-precalculation-actions">
                          <IonIcon size='large' icon={ellipsisHorizontal} />
                        </BigUp.Buttons.Regular>
                        <BigUp.Popover items={actionButtonList} actionId="open-precalculation-actions" />
                      </IonCol>
                    )}
                  </IonRow>
                  <IonCol size='12'>
                    <BigUp.Input
                      disabled={!!historicalPrecalculationId}
                      clearInput
                      label={precalculationId
                        ? i18n.t('Name')
                        : i18n.t('Current name')}
                      labelPlacement='stacked'
                      className='ion-no-padding ion-no-margin'
                      register={'name'}
                      placeholder={i18n.t('Name')}
                      type='text'
                      inputMode='text'
                      validation={{
                        required: {
                          value: true,
                          message: i18n.t('Name is required.')
                        }
                      }}
                    />
                  </IonCol>
                  <IonGrid>
                    <IonRow className=' ion-align-items-center ion-no-margin ion-margin-bottom'>
                      <IonCol className='ion-text-left' size='4' sizeLg='12'>
                        <RelatedMultiSelectEdit
                          button={'solid'}
                          icon={{ icon: add }}
                          model={'activity_codes'}
                          onChange={(results: E2U.V1.Models.ActivityCode[]) => setSelectedActivityCodes(results)}
                          label={i18n.t('Select activity codes')}
                          value={[]}
                          displayFields={['code', 'name']}
                          modalTitle={i18n.t('Select activity codes')}
                          hideSelected={true}
                          ionButtonProps={{
                            color: 'primary'
                          }}
                          leftIcon={false}
                        />
                      </IonCol>
                      {!isDesktop && (
                        <IonCol size='6' className='ion-text-right'>
                          <BigUp.Buttons.Icon id="open-precalculation-actions" icon={{ icon: ellipsisHorizontal }} padding={20} color={'light'} />
                          <BigUp.ActionSheet items={actionButtonList} actionId="open-precalculation-actions" />
                        </IonCol>
                      )}
                    </IonRow>
                    {selectedPrecalculation && checkRemaingOrSecuredValues() && (
                      <IonRow className='ion-align-items-center'>
                        <IonCol>
                          <SummaryRows
                            value={formatNumber(selectedPrecalculation?.total ?? 0)}
                            label={i18n.t('Total')}
                          />
                          <SummaryRows
                            value={selectedPrecalculation?.secured_costs !== 0 ? formatNumber(selectedPrecalculation?.secured_costs ?? 0) : ''}
                            label={i18n.t('Secured')}
                          />
                          <SummaryRows
                            value={formatNumber((selectedPrecalculation?.total ?? 0) - (selectedPrecalculation?.secured_costs ?? 0))}
                            label={i18n.t('Remaining')}
                          />
                        </IonCol>

                        {isDesktop && (
                          <IonCol className='ion-text-end' size='3'>
                            <IonIcon
                              size='large'
                              {...showChart
                                ? { icon: chevronUp }
                                : { icon: chevronDown }
                              }
                              onClick={() => setShowChart(!showChart)}
                            ></IonIcon>
                          </IonCol>
                        )}
                      </IonRow>
                    )}
                  </IonGrid>
                </IonCol>

                {checkRemaingOrSecuredValues()
                  ? (
                    <IonCol size='6' sizeSm='12'
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                      {isDesktop
                        ? (
                          <FadeInContainer isVisible={showChart}>
                            {selectedPrecalculation && precalculationId && (
                              <React.Fragment>
                                <PieChart height={250} data={pieChartData.precalculation} />
                              </React.Fragment>
                            )}
                            {selectedVersion && historicalPrecalculationId && (
                              <React.Fragment>
                                <PieChart height={250} data={pieChartData.historical} />
                              </React.Fragment>
                            )}
                          </FadeInContainer>
                        )
                        : (
                          <>
                            {selectedPrecalculation && precalculationId && (
                              <React.Fragment>
                                <PieChart height={250} data={pieChartData.precalculation} />
                              </React.Fragment>
                            )}

                            {selectedVersion && historicalPrecalculationId && (
                              <React.Fragment>
                                <PieChart height={250} data={pieChartData.historical} />
                              </React.Fragment>
                            )}

                          </>
                        )}
                    </IonCol>
                  )
                  : null
                }
              </IonRow>
              <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-left ion-margin-right ion-margin-top' style={{ maxWidth: 500 }}>
                <IonCol size='6' className='ion-text-center'>
                  <BigUp.Buttons.Regular
                    expand='block'
                    color={'secondary'}
                    disabled={!name || methods.formState.isSubmitting}
                    type="submit"
                    title={historicalPrecalculationId ? i18n.t('Restore') : i18n.t('Save')}
                  >
                  </BigUp.Buttons.Regular>
                </IonCol>
                <IonCol size='6' className='ion-text-center'>
                  <DuplicateButton
                    title={t('Duplicate')}
                    disabled={!name || methods.formState.isSubmitting}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <div {...isDesktop && { style: { width: '60%' } }}>
              <div className='ion-padding-top ion-padding-end'>
                <SearchAndSortRow onSearch={setSearch} onSort={setSortBy} value={search}>
                  {Array.from(filters).map((filter) => (
                    <IonSelectOption key={filter[0]} value={filter[0]}>
                      {filter[1]}
                    </IonSelectOption>
                  ))}

                </SearchAndSortRow>
              </div>
              <div className='ion-no-padding'>

                <>
                  {precalculationId
                    ? (
                      <PrecalculationRows
                        selectedPrecalculation={selectedPrecalculation}
                        onRowClick={openPrecalculationFormModal}
                        search={search}
                        sortBy={sortBy}
                        filterParam={filterParam}
                        shouldRefetchRows={shouldRefetchRows}
                        setShouldRefetchRows={setShouldRefetchRows}
                        type={'precalculations'}
                      />
                    )
                    : (
                      <PrecalculationRows
                        selectedPrecalculation={selectedVersion}
                        onRowClick={openPrecalculationFormModal}
                        search={search}
                        sortBy={sortBy}
                        filterParam={filterParam}
                        shouldRefetchRows={shouldRefetchRows}
                        setShouldRefetchRows={setShouldRefetchRows}
                        type={'precalculation_histories'}
                      />
                    )
                  }
                </>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>

      <IonModal
        className={styles['precalc-form-modal']}
        isOpen={isModalOpen}
        onIonModalDidDismiss={onModalDismiss}
      >
        <PrecalculationForm
          deletePrecalculationRow={deletePrecalculationRow}
          onDismiss={onModalDismiss}
          selectedRow={selectedPrecalculationRow}
          onRowSave={onRowSave}
        />
      </IonModal>
    </div >
  );
};

export default PrecalculationDetail;
