import { IonCol, IonGrid, IonItem, IonList, IonListHeader, IonRow } from '@ionic/react';
import { addSharp } from 'ionicons/icons';

import BigUp from '..';
import type { ItemListProps } from './types';

const ItemList: React.FC<ItemListProps> = ({ actionButton, children, extraRow, header, headerOptions, items }) => {
  return (
    <IonList inset={true} className='ion-no-margin'>
      {header && (
        <IonListHeader className='ion-no-padding' style={{ paddingLeft: 8, paddingBottom: 4, fontSize: '18px' }}>
          <BigUp.Label.Thick label={header.title} color={header.color || 'medium'} />
        </IonListHeader>
      )}
      {items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        const borders = {
          borderTopLeftRadius: isFirst ? 10 : 0,
          borderTopRightRadius: isFirst ? 10 : 0,
          borderBottomLeftRadius: isLast && !extraRow?.hasExtraRow ? 10 : 0,
          borderBottomRightRadius: isLast && !extraRow?.hasExtraRow ? 10 : 0,
        };

        return (
          <IonItem key={item.id} color="light" style={borders}>
            {item.content}
          </IonItem>
        );
      })}

      {extraRow?.hasExtraRow && (
        <IonItem
          {...extraRow.item}
          color="light"
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          {extraRow.content}
        </IonItem>
      )}

      {actionButton && (
        <IonGrid className='ion-no-padding'>
          <IonRow>
            <IonCol className='ion-text-end'>
              <BigUp.Buttons.Regular
                title={actionButton.title}
                onClick={actionButton.onClick}
                fill='clear'
                size='small'
                icon={{
                  icon: addSharp,
                  slot: 'start'
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {children}
    </IonList>
  );
};

export default ItemList;
