import { IonInput, IonItem } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SearchbarUnderlined.module.scss';
import type { SearchBarInterface } from '../SearchAndSort';

const SearchbarUnderlined: React.FC<SearchBarInterface> = (
  {
    debounceInterval = 500,
    onSearch,
    placeholder,
    style,
    value
  }
) => {
  const { t } = useTranslation();
  const placeholderText = placeholder ?? t('Search');

  return (
    <React.Fragment>
      <IonItem className='ion-no-padding'>
        <IonInput
          clearInput={true}
          className={styles['underlined-searchbar']}
          placeholder={placeholderText}
          style={style}
          debounce={debounceInterval}
          value={value}
          onIonInput={(event) => { onSearch(event.detail.value ?? ''); event.preventDefault(); }}
        />
      </IonItem>
    </React.Fragment >
  );
};

export default SearchbarUnderlined;
