import { IonGrid, IonSpinner } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import EditCategory from './form/EditCategory';
import NewCategory from './form/NewCategory';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import EmptyList from '../../../components/UI/EmptyList';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';

const CategoryPage: React.FC = () => {
  const [categories, setCategories] = useState<E2U.V1.Models.Type[]>([]);
  const { category_id } = useParams<{ category_id: string }>();
  const { t } = useTranslation();
  const isLoadingCategories: boolean = useAppSelector((state) => state.loading.isLoading.categories);
  const selectedProject: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);

  const getDocumentCategories = () => {
    if (!selectedProject || !selectedProject.id) {
      return;
    }
    store.dispatch(setIsLoading({ name: 'types', value: true }));
    networking.get(`/api/v1/projects/${selectedProject.id}/document_types?per_page=9999`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Type>>) => {
        setCategories(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'types', value: false }));
      });
  };

  useEffect(() => {
    getDocumentCategories();
  }, []);

  useEffect(() => {
    getDocumentCategories();
  }, [selectedProject]);

  return (
    <DesktopWrapper>
      {!category_id && (
        <NewCategory handleListRefresh={() => getDocumentCategories()} />
      )}
      <IonGrid className="ion-padding" style={{ height: '90%' }}>
        {isLoadingCategories || category_id
          ? <IonSpinner name="crescent" />
          : <EditCategory categories={categories} />
        }
        {(categories.length === 0 && !isLoadingCategories) &&
          <EmptyList title={i18n.t('No categories could be found')} message={i18n.t('Create a new category to get started')} />
        }
      </IonGrid>
    </DesktopWrapper>
  );
};

export default CategoryPage;
