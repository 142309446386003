import type { Photo } from '@capacitor/camera';
import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow, IonText, IonToggle } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { checkmark, close } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { networking } from '../../../../../api/networking';
import FileList from '../../../../../components/FileList/FileList';
import toasters from '../../../../../components/Toasts/Toasts';
import StepButtons from '../../../../../components/Toolbar/StepButtons';
import BigUp from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';
import useCameraUpload from '../../../../../hooks/useCameraUpload';
import useFileUpload from '../../../../../hooks/useFileUpload';
import i18n from '../../../../../i18n';
import FileSelectionButton from '../../ControlOfExecution/AddFilesModal/FileSelectionButton';
import TakePhotoButton from '../../ControlOfExecution/AddFilesModal/TakePhotoButton';
import styles from '../WorkingEnvironment.module.scss';

interface WorkingEnvironmentReportProps {
  closeModal: () => void;
  onReportSaved?: () => void;
  environment?: E2U.V1.Models.WorkingEnvironment | undefined;
  disabled?: boolean;
  readonly?: boolean;
}

const WorkingEnvironmentEntryReport: React.FC<WorkingEnvironmentReportProps> = ({
  closeModal,
  disabled,
  environment,
  onReportSaved,
  readonly
}) => {
  const [reportWithFiles, setReportWithFiles] = React.useState<E2U.V1.Models.WorkingEnvironmentEntryReport>();
  const { t } = useTranslation();

  const selectedEntry = useAppSelector(state => state.workingEnvironmentEntry.workingEnvironmentEntry);

  const methods = useForm<E2U.V1.Models.WorkingEnvironmentItem | any>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      id: undefined,
      entry_id: selectedEntry?.id,
      location: '',
      description: '',
      assignees: [],
      files: [],
      requires_supervisor: false,
    },
  });

  const { getUploadedPhotos, handleTakenPhoto, removePhoto, setUploadedPhotos, uploadSelectedPhotos } = useCameraUpload();
  const { getUploadedFiles, handleFileSelection, removeFile, setUploadedFiles, uploadSelectedFiles } = useFileUpload();

  const { control, formState, setValue, watch } = methods;
  const { assignees, description, id, location } = watch();
  const files = getUploadedFiles() ?? [];
  const photos = getUploadedPhotos() ?? [];
  const history = useHistory();
  const project = useAppSelector(state => state.project.selectedProject);
  const getReportWithFiles = (entryId: E2U.V1.Models.WorkingEnvironmentItem['id']) => {
    const params = new URLSearchParams();
    params.append('with[]', 'files');
    params.append('with[]', 'assignees');

    networking.get(`/api/v1/working_environment_entry_reports/${entryId}?${params.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkingEnvironmentEntryReport>) => {
        setReportWithFiles(response.data.data);
      }
      )
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      }
      );
  };

  useEffect(() => {
    if (typeof selectedEntry?.reports !== 'undefined' && selectedEntry?.reports.length > 0) {
      getReportWithFiles(selectedEntry.reports[0].id!);
    }
  }, []);

  const handlePost: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const isUpdating = typeof id !== 'undefined';

    const request = !isUpdating
      ? networking.post(`api/v1/working_environment_entry_reports`, data)
      : networking.put(`api/v1/working_environment_entry_reports/${selectedEntry!.reports![0].id}`, data);

    toasters.promise(new Promise<any>((resolve, reject) => {
      request
        .then((saveRequest: E2U.V1.Response.Success<
          E2U.V1.Models.WorkingEnvironmentItem
        >) => {
          const finishedPromises: Promise<any>[] = [];

          if (environment?.assigned_user && [environment.assigned_user].length) {
            const assignedUserIds = [environment.assigned_user]
              .map((user: E2U.V1.Models.User) => user.id);

            const assignedUserIdsToRemove: E2U.V1.Models.User[] = assignees
              .filter((user: E2U.V1.Models.User) => !assignedUserIds.includes(user.id))
              .map((user: E2U.V1.Models.User) => user.id);

            finishedPromises.push(
              Promise.all(
                assignedUserIdsToRemove.map(
                  (user: E2U.V1.Models.User) => networking.delete(
                    `/api/v1/working_environment_entry_reports/${saveRequest.data.data.id}/assignees/${user.id}`
                  )
                    .catch((error: E2U.V1.Response.Error) => {
                      Sentry.captureException(error);
                      toast(t('Error while removing assignees'), { type: 'error' }
                      );
                    })
                )
              )
            );
          }

          if (data.assignees.length) {
            finishedPromises.push(
              Promise.all(
                data.assignees.map((assignees: E2U.V1.Models.User) => networking.post(
                  `/api/v1/working_environment_entry_reports/${saveRequest.data.data.id}/assignees/${assignees.id}`
                )
                  .catch((error: E2U.V1.Response.Error) => {
                    Sentry.captureException(error);
                    toast(t('Error while adding assignees'), { type: 'error' });
                  })
                )
              )
            );
          }

          Promise.all(finishedPromises)
            .finally(() => {
              resolve(undefined);
            });
          Promise.allSettled([
            uploadSelectedFiles(
              `/api/v1/working_environment_entry_reports`,
              saveRequest.data.data.id ?? ''
            ),
            uploadSelectedPhotos(
              `/api/v1/working_environment_entry_reports`,
              saveRequest.data.data.id ?? ''
            )
          ]).finally(() => {
            if (onReportSaved) onReportSaved();
            closeModal();
          });
        })
        .catch((error: E2U.V1.Response.Error) => {
          Sentry.captureException(error);
          reject(error);
        });
    }), {
      success: i18n.t('Saved Report'),
      error: i18n.t('Error while saving Report'),
    });
  };

  const checkReportFiles = (report: E2U.V1.Models.WorkingEnvironmentEntryReport | undefined) => {
    if (reportWithFiles && reportWithFiles.files) {
      const photos = reportWithFiles.files.filter((file) => file.type === 'image');
      const files = reportWithFiles.files.filter((file) => file.type !== 'image');
      setUploadedPhotos(photos);
      setUploadedFiles(files);
    }
  };

  const redirectToDocuments = () => {
    history.push(`/project-tools/${project?.id}/documents`);
  };

  useEffect(() => {
    methods.setValue('requires_supervisor', reportWithFiles?.requires_supervisor);
    checkReportFiles(reportWithFiles);
  }, [reportWithFiles]);

  useEffect(() => {
    if (selectedEntry && selectedEntry.reports && selectedEntry.reports.length > 0) {
      const selectedEntryReports: any = selectedEntry.reports[0];
      checkReportFiles(selectedEntryReports);
      if (selectedEntryReports.assigned_user) {
        methods.setValue('assignees', [selectedEntryReports.assigned_user], {
          shouldDirty: true
        });
      }
      if (selectedEntryReports.id) {
        methods.setValue('id', selectedEntryReports.id, {
          shouldDirty: true
        });
      }
      if (selectedEntryReports.description) {
        methods.setValue('description', selectedEntryReports.description, {
          shouldDirty: true
        });
      }
      if (selectedEntryReports.project_id) {
        methods.setValue('project_id', selectedEntryReports.project_id, {
          shouldDirty: true
        });
      }
      if (selectedEntryReports.location) {
        methods.setValue('location', selectedEntryReports.location, {
          shouldDirty: true
        });
      }
    }
  }, [selectedEntry]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handlePost)}>
        <IonGrid>
          <IonRow>
            <IonCol size={'12'} className="ion-no-padding">
              <IonItem className='ion-no-padding ion-padding-bottom'>
                <IonText>
                  <h2 className='ion-no-margin'>
                    {selectedEntry?.item?.name}
                  </h2>
                  <IonLabel className="ion-no-margin">
                    {DateTime.now().toLocaleString(DateTime.DATE_MED)}
                  </IonLabel>
                </IonText>
              </IonItem>
            </IonCol>

            <IonCol size={'12'} className="ion-margin-bottom ion-no-padding">
              {readonly
                ? (
                  <IonItem className='ion-no-padding'>
                    <IonLabel className='ion-no-margin' style={{ width: '100%' }}>
                      {location}
                    </IonLabel>
                  </IonItem>
                )
                : (
                  <Controller
                    control={control}
                    name='location'
                    render={() => (
                      <BigUp.Input
                        label={readonly
                          ? t('Location')
                          : i18n.t('Add a location')}
                        counter
                        counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
                        id='location'
                        name='location'
                        enterkeyhint='enter'
                        autoCorrect='on'
                        autocorrect='on'
                        value={location}
                        maxlength={40}
                        autoCapitalize='sentences'
                        placeholder={i18n.t('Add a location')}
                        onIonInput={(e) => setValue('location', e.detail.value)}
                        register={'location'}
                        validation={{
                          required: `${t('This field is required')}`,
                          max: {
                            value: 40,
                            message: `${t('Location can\'t be longer than 40 characters')}`
                          },
                          min: {
                            value: 1,
                            message: `${t('This field is required')}`
                          }
                        }}
                      />
                    )}
                    rules={{ required: false }}
                  />
                )}
            </IonCol>

            <IonCol size={'12'} className="ion-margin-bottom ion-no-padding">
              <IonItem className='ion-no-margin ion-no-padding'>
                <IonLabel position="stacked" className='ion-no-margin'>
                  {t('Files & Images')}
                </IonLabel>
                {readonly && reportWithFiles && reportWithFiles?.files && (
                  <IonItem className='ion-no-padding'>
                    <IonLabel className='ion-no-margin' style={{ width: '100%' }}>
                      {t('No uploaded files')}
                    </IonLabel>
                  </IonItem>
                )}
              </IonItem>
            </IonCol>

            {!readonly &&
              <IonCol size='6' className='ion-text-center ion-margin-bottom'>
                <FileSelectionButton expand='block' onFilesSelect={(files: File[]) => handleFileSelection(files)} />
              </IonCol>}

            {!readonly &&
              <IonCol size='6' className='ion-text-center ion-margin-bottom'>
                <TakePhotoButton expand='block' onTakePhotoClick={(photos: Photo[]) => handleTakenPhoto(photos)} />
              </IonCol>}

            <FileList
              files={files}
              photos={photos}
              removeFile={removeFile}
              removePhoto={removePhoto}
            />

            <IonCol size={'12'}>
              <IonItem className='ion-no-padding'>
                <IonLabel className='ion-no-margin' position='stacked'>
                  {readonly ? t('Assignees') : i18n.t('Who should take action on this?')}
                </IonLabel>
              </IonItem>
            </IonCol>

            {readonly
              ? <IonRow className='ion-align-items-center'>
                <IonCol>
                  <IonList lines='none'>
                    {reportWithFiles?.assignees?.map((assignee: E2U.V1.Models.User & { pivot: E2U.V1.Objects.ControlOfExecutionInspectorPivot }, i: any) => {
                      return (
                        <IonItem key={i} className={'ion-no-padding'}>
                          <IonIcon
                            icon={(assignee.pivot.status !== 'attested') ? close : checkmark}
                            size={'large'}
                            className={classNames(styles['working-environment-vam'], 'ion-padding-vertical ion-padding-end')}
                            {...(assignee.pivot.status === 'attested')
                              ? { color: 'success' }
                              : { color: 'danger' }
                            }
                          />
                          <p className='ion-no-margin'>
                            {assignee.first_name} {assignee.last_name}
                          </p>
                        </IonItem>
                      );
                    })}
                  </IonList>
                </IonCol>
              </IonRow>
              : <IonRow className='ion-align-items-center'>
                <p className={'ion-margin-end'}>{t('Me')}</p>
                <Controller
                  control={control}
                  name='requires_supervisor'
                  render={({ field: { onChange, value } }) => (
                    <IonToggle
                      checked={value}
                      onIonChange={({ detail: { checked } }) => {
                        onChange(checked ? 1 : 0);
                      }}
                    />
                  )}
                />
                <p className={'ion-margin-start'}>
                  {t('Supervisor')}
                </p>
              </IonRow>}

            <IonCol size={'12'} className="ion-margin-bottom">
              {(!readonly) && <Controller
                control={control}
                name='description'
                render={({ field: { onChange } }) => (
                  <BigUp.Textarea
                    counter
                    counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
                    label='Description'
                    id='description'
                    labelPlacement={readonly ? 'floating' : 'stacked'}
                    name='description'
                    enterkeyhint='enter'
                    autoCorrect='on'
                    value={description}
                    maxlength={140}
                    autoGrow={true}
                    placeholder={i18n.t('Add a comment')}
                    style={{
                      '--background': '#f2f2f2',
                      minHeight: '150px',
                      width: '100%'
                    }}
                    onIonInput={({ detail: { value: newValue } }) => {
                      if (typeof newValue === 'string') onChange(newValue);
                    }}
                    register={'description'}
                    validation={{
                      required: `${t('This field is required')}`,
                      max: {
                        value: 140,
                        message: `${t('Description can\'t be longer than 140 characters')}`
                      },
                      min: {
                        value: 1,
                        message: `${t('This field is required')}`
                      }
                    }}
                  />
                )}
                rules={{ required: true }}
              />}

              {(readonly) && <IonItem lines={'none'} className={'ion-no-padding'}>
                <IonText>
                  <p className='ion-no-margin'>
                    {description}
                  </p>
                </IonText>
              </IonItem>}
            </IonCol>

          </IonRow>
        </IonGrid>
        {(readonly)
          ? <IonRow className={'ion-text-end'}>
            <IonCol>
              <BigUp.Buttons.Regular title={i18n.t('Close')} onClick={closeModal} />
            </IonCol>
          </IonRow>
          : <StepButtons
            leftIonColor='none'
            leftTitle={i18n.t('previous')}
            leftOnClick={redirectToDocuments}
            leftDisabled={disabled}
            rightSubmit={'submit'}
            rightTitle={i18n.t('submit')}
            rightIonColor={'secondary'}
            rightOnClick={() => { }}
            rightDisabled={formState.isSubmitting || !formState.isValid}
          />}
      </form>
    </FormProvider>
  );
};

export default WorkingEnvironmentEntryReport;
