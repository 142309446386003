import { useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import RowListView from '../../../../components/ListsComponents/rowList/RowListView';
import PaginateData from '../../../../components/Pagination/PaginationData';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import SwiperController from '../../../../components/Swiper/SwiperController';
import toasters from '../../../../components/Toasts/Toasts';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import { setEconomyReport } from '../../../../reducers/economyReport';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import { scrollToSection } from '../../../../tools/scrollToSection';

const EconomyReport: React.FC = () => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const scrollToId = 'economy-report-rows-top';
  const [presentAlert] = useIonAlert();

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [swiperController, setSwiperController] = useState<SwiperController>(new SwiperController());
  const [economyReportList, setEconomyReportList] = useState<(E2U.V1.Models.EconomyReport)[]>([]);

  const economyMonitoring = useAppSelector(state => state.economyMonitoring);
  const isLoadingEconomyReport: boolean = useAppSelector(state => state.loading.isLoading.economyReport);

  const fetchEconomyReport = (page?: number) => {
    store.dispatch(setIsLoading({
      name: 'economyReport',
      value: true
    }));

    if (!economyReportList) {
      return;
    }
    if (typeof page !== 'undefined') {
      setCurrentPage(page);
    }

    const searchParameters = new URLSearchParams();
    searchParameters.append('page', currentPage.toString());
    searchParameters.append('per_page', perPage.toString());
    searchParameters.append('search', search);
    searchParameters.append('total_pages', totalPages?.toString() || '');
    searchParameters.append('sort_by', sortBy);

    toasters
      .promise(
        networking.get(`/api/v1/projects/${uuid}/economy_monitoring?${searchParameters.toString()}`),
        {
          error: i18n.t('Error while loading economy report.')
        }
      )
      .then((response: any) => {
        store.dispatch(setEconomyReport(response.data.data.records));
        setEconomyReportList(response.data.data.records);
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(
          setIsLoading({
            name: 'economyReport',
            value: false
          })
        );
      });
  };

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  const deleteHandler = (id: string | undefined) => {
    presentAlert({
      header: i18n.t('Delete report'),
      message: i18n.t('Are you sure you want to delete this report?'),
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: i18n.t('Delete'),
          role: 'confirm',
          handler: () => deleteRow(id)
        },
      ],
    });
  };

  const deleteRow = (id: string | undefined) => {
    toasters
      .promise(
        networking.delete(
          `/api/v1/economy_monitorings/${id}`
        ),
        {
          success: i18n.t('Successfully deleted report.'),
          pending: i18n.t('Deleting report...'),
          error: i18n.t("Couldn't delete report")
        }
      )
      .finally(() => {
        fetchEconomyReport();
      });
  };

  useEffect(() => {
    if (search || sortBy) {
      fetchEconomyReport(1);
    } else {
      fetchEconomyReport();
    }
    uuid && setSwiperController(swiperController);

    return () => {
      setEconomyReportList([]);
    };
  }, [uuid, economyMonitoring, search, sortBy, perPage, currentPage]);

  return (
    <>
      {isLoadingEconomyReport
        ? <SkeletonTextThreeLines />
        : <React.Fragment>
          <RowListView
            data={economyReportList}
            search={search}
            setSearch={setSearch}
            setSort={setSortBy}
            type={'monitoring'}
            pageStep={pageStepper}
            currentPage={currentPage}
            totalPages={totalPages}
            isLoading={isLoadingEconomyReport}
            confirmDelete={(id: string | undefined) => deleteHandler(id)} />
          {economyReportList.length > 0 &&
            <PaginateData
              currentPage={currentPage}
              totalPages={totalPages}
              pageStepper={pageStepper}
            />}
        </React.Fragment>
      }
    </>
  );
};
export default EconomyReport;
