import { useIonRouter } from '@ionic/react';
import { useFormContext } from 'react-hook-form';

import type { WorkPreparation } from '..';
import BigUp from '../../../../components/UI';
import i18n from '../../../../i18n';

interface EquipmentProps {
  id: string;
  name: string;
  value: string;
}
const equipment: EquipmentProps[] = [
  {
    id: '1',
    name: 'Equipment 1',
    value: 'Equipment 1',
  },
  {
    id: '2',
    name: 'Equipment 2',
    value: 'Equipment 2',
  },
  {
    id: '3',
    name: 'Equipment 3',
    value: 'Equipment 3',
  },
];
const Equipment: React.FC = () => {
  const methods = useFormContext<WorkPreparation>();
  const selectedEquipment = methods.watch('equipment', []);
  const router = useIonRouter();
  const handleSelectedEquipment = (equipment: string) => {
    let updatedValues = [];
    if (selectedEquipment.includes(equipment)) {
      updatedValues = selectedEquipment.filter((selectedEquipment) => selectedEquipment !== equipment);
    } else {
      updatedValues = [...selectedEquipment, equipment];
    }
    methods.setValue('equipment', updatedValues);
  };

  const items = equipment.map((inputProp, index) => ({
    id: (index + 1).toString(),
    content: <BigUp.Checkbox
      style={{ marginRight: 8 }}
      labelPlacement="start"
      labelProps={{
        label: inputProp.name ?? '',
      }}
      handleCheckbox={() => handleSelectedEquipment(inputProp.value)}
    />,
  }));
  return <BigUp.ItemList
    header={{ title: i18n.t('Equipment') }}
    items={items}
    actionButton={{
      title: i18n.t('Add equipment'),
      onClick: () => router.push('/work-preparation/equipment')
    }}
  />;
};

export default Equipment;
