import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonLoading, IonModal, IonToolbar, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { downloadOutline, eye, refresh, shareSocial, trash } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { config } from './config';
import styles from './FileList.module.scss';
import { networking } from '../../api/networking';
import useModal from '../../hooks/useModal';
import i18n from '../../i18n';
import BigUp from '../UI';

interface FileButtonsProps {
  edit: boolean;
  file: E2U.V1.Models.File;
  historicalFile?: E2U.V1.Models.FileHistory;
  downloadFile: () => void;
  shareFile: () => void;
  refetch?: () => void;
  handleFileNameChanged?: (file: E2U.V1.Models.File, newFileName: string) => void;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const FileButtons: React.FC<FileButtonsProps> = (props) => {
  const { downloadFile, edit, file, handleFileNameChanged, historicalFile, refetch, shareFile } = props;
  const modal = useModal();
  const { t } = useTranslation();
  const [deleteAlert] = useIonAlert();
  const [passwordProtectedAlert] = useIonAlert();
  const [fileContent, setFileContent] = useState<string>('');
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const getFileContent = (id: string) => {
    const endpoint = historicalFile
      ? `/api/v1/file_histories/${id}/export?base64=true`
      : `/api/v1/files/${id}/export?base64=true`;
    networking.get(endpoint)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.FileHistory>) => {
        setFileContent(`data:${response.headers['content-type']};base64,${response.data}`);
      })
      .catch(() => {
        Sentry.captureMessage('Failed to get file content');
        setFileContent('');
      });
  };

  const handleFilePreview = (id: string) => {
    modal.openModal();
    if (historicalFile) {
      getFileContent(id);
    }
    if (file) {
      getFileContent(id);
    }
  };

  // TODO: Create a hook and/or component for handling this password protected alert
  const handleProtectedPdf = (callback: (password: string) => void) => {
    passwordProtectedAlert({
      header: i18n.t('Password required'),
      subHeader: i18n.t('This document is password protected'),
      message: i18n.t('Please enter the password to view the document'),
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: i18n.t('Password')
        }
      ],
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: i18n.t('Ok'),
          handler: (data) => {
            callback(data.password);
          }
        }
      ]
    });
  };

  const restoreHistoricalFile = (file: E2U.V1.Models.File, file_history?: E2U.V1.Models.FileHistory) => {
    if (!file_history) {
      return;
    }
    networking.post(`/api/v1/file_histories/${file_history.id}/restore`)
      .then(() => {
        refetch && refetch();
        handleFileNameChanged && handleFileNameChanged(file, file_history.name);
      });
  };

  const handleFileDelete = (historicalFileId: E2U.V1.Models.FileHistory['id'], type: 'latest' | 'historical') => {
    if (type === 'latest') {
      networking.delete(`/api/v1/files/${file.id}`)
        .then(() => {
          config.fileToasts('success', t('{name} deleted', '{name} deleted', {
            name: file.name
          })).createToast();
        });
    } else if (historicalFile) {
      networking.delete(`/api/v1/file_histories/${historicalFileId}`)
        .then(() => {
          config.fileToasts('success', t('{name} deleted', '{name} deleted', {
            name: historicalFile.name
          })).createToast();

          refetch && refetch();
        });
    }
  };

  const confirmDelete = (historicalFileId: E2U.V1.Models.FileHistory['id']) => {
    const deleteFileHandler = () => {
      handleFileDelete(historicalFileId, 'historical');
    };

    const deleteAllVersionsHandler = () => {
      handleFileDelete(file.id ?? '', 'latest');
    };

    if (historicalFileId) {
      deleteAlert(config.alertOptions(config.deleteFileMessage, deleteFileHandler));
    } else {
      deleteAlert(config.alertOptions(config.deleteAllVersionsMessage, deleteAllVersionsHandler));
    }
  };

  return (
    <>
      <IonButtons slot="end" className='ion-margin-start ion-padding-start'>
        <IonButton fill='clear' onClick={() => handleFilePreview(historicalFile?.id ?? file.id ?? '')}>
          <IonIcon {...config.iconProps(eye).icon} />
        </IonButton>
        {edit
          ? (
            <>
              {historicalFile && (
                <IonButton fill='clear' onClick={() => restoreHistoricalFile(file, historicalFile)}>
                  <IonIcon {...config.iconProps(refresh).icon} />
                </IonButton>
              )}
              <IonButton fill='clear' onClick={() => confirmDelete(historicalFile?.id ?? '',)}>
                <IonIcon {...config.iconProps(trash).icon} />
              </IonButton>
            </>
          )
          : (
            <>
              <IonButton fill='clear' onClick={downloadFile}>
                <IonIcon {...config.iconProps(downloadOutline).icon} />
              </IonButton>
              <IonButton fill='clear' onClick={shareFile}>
                <IonIcon {...config.iconProps(shareSocial).icon} />
              </IonButton>
            </>
          )
        }
      </IonButtons>

      <IonModal isOpen={modal.isModalOpen} className={styles['preview-modal-container']} onIonModalDidDismiss={modal.closeModal}>
        <IonHeader>
          <IonToolbar>
            <BigUp.Title className='ion-padding' label={historicalFile ? historicalFile?.name : file.name} />
            <IonButtons slot="end" >
              <IonButton onClick={modal.closeModal}>{t('Close')}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-no-padding'>
          <div className={styles['preview-container']}>
            <TransformWrapper pinch={{ step: 5 }} ref={transformComponentRef} initialScale={1} >
              <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
                {(historicalFile ? historicalFile.type === 'pdf' : file.type === 'pdf') && (
                  <Document
                    loading={<IonLoading isOpen={true} />}
                    file={fileContent}
                    options={options}
                    onPassword={(callback) => handleProtectedPdf(callback)}
                  >
                    <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
                  </Document>
                )}
                {(historicalFile ? historicalFile.type === 'image' : file.type === 'image') && (
                  <IonImg
                    className={styles['preview-image']}
                    src={`${fileContent}`}
                    alt={i18n.t('File: {name}', 'File: {name}', { name: historicalFile?.name || file.name })}
                  />
                )}
              </TransformComponent>
            </TransformWrapper>
          </div>
        </IonContent>
      </IonModal >
    </>
  );
};

export default FileButtons;
