import { IonCol, IonGrid, IonLabel, IonList, IonModal, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import {
  cog,
  constructSharp,
  documentsSharp, idCard,
  keySharp,
  layers,
  map
} from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router';

import ControlOfExecution from './ControlOfExecution';
import ControlOfExecutionDetailed from './ControlOfExecution/ControlOfExecutionDetailed';
import FortnoxPage from './FortnoxPage';
import ProjectMappingPage from './FortnoxPage/ProjectMappingPage';
import PendingSiteAccessRequest from './PendingSiteAccessRequests';
import ProjectDocuments from './ProjectDocuments/ProjectDocuments';
import RejectedSiteAccessRequest from './RejectedSiteAccessRequest';
import SettingsPage from './SettingsPage';
import SiteAccessRequests from './SiteAccessRequests';
import UnsubmittedSiteAccessRequest from './UnsubmittedSiteAccessRequest';
import WorkingEnviroment from './WorkingEnviroment';
import WorkingEnvironmentReport from './WorkingEnviroment/WorkingEnvironmentReport';
import Panel from '../../../components/Panels/Panel';
import ProgressBar from '../../../components/UI/Loaders/ProgressBar';
import type { ReturnInterfaceExtraInfo } from '../../../components/UI/Return/ReturnChevronText';
import SplitpaneContent from '../../../components/UI/SplitPane/SplitPaneContent';
import { matchUuid } from '../../../constants/matchUuid';
import { useAppSelector } from '../../../hooks';
import useCustomModalAnimations from '../../../hooks/useCustomModalAnimation';
import useModal from '../../../hooks/useModal';
import usePermissionHandler from '../../../hooks/usePermissions';
import i18n from '../../../i18n';
import { setSelectedProject } from '../../../reducers/project';
import store from '../../../store';
import type { SubpanelItem } from '../../DesktopCards';
import DocumentPage from '../../Document';
import CategoryPage from '../../Document/Category';
import EditDocumentTypePage from '../../Document/Category/EditDocumentTypePage';
import DocumentsEditPage from '../../Document/DocumentEditPage';
import modalStyles from '../../Onboarding/Components/containers/styles/ModalContainers.module.scss';
import OnboardingStart from '../../Onboarding/Components/modals/OnboardingStart';
import TagPage from '../../Tags/TagPage';
import TagsPage from '../../Tags/TagsPage';
import Teams from '../../Teams';
import TeamInvite from '../../Teams/Invite/TeamInvite';
import Team from '../../Teams/Team/Team';
import TeamForm from '../../Teams/TeamForm/TeamForm';
import WorksitePage from '../../Worksite';
import WorkSiteFormPage from '../../Worksite/form';
import LayerUpload from '../../Worksite/form/LayerUpload/LayerUpload';
import WorksiteMapPage from '../../Worksite/map';
import ProjectList from '../ProjectList';
import SiteAccessRequestsSettings from './SiteAccessRequests/Settings/Settings';
import ReturnChevronText from '../../../components/UI/Return/ReturnChevronText';

const ProjectPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<ReturnInterfaceExtraInfo>();
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string | undefined, worksite_id: string | undefined }>();
  const { closeModal, isModalOpen, openModal } = useModal();
  const { dismiss, enterAnimation, leaveAnimation, modal } = useCustomModalAnimations();

  const projects = useAppSelector((state) => state.project.projects);
  const selected: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.onboarding.selectedProject);
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const projectId = useMemo(() => project?.id ?? '', [project]);

  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const isLoadingProject: boolean = useAppSelector((state) => state.loading.isLoading.project);
  const permission = usePermissionHandler();
  const selectedProject = selected || project;

  const PROJECT_TOOLS_ACCORDION_LIST: SubpanelItem[] = [
    {
      subpanelTitle: i18n.t('Control of execution', 'Control of execution'),
      subpanelIcon: keySharp,
      subpanelActiveColour: 'var(--ion-color-primary)',
      subpanelDisabled: false,
      route: `${project?.id}/control-of-execution`,
      value: 'control-of-execution',
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Working enviroment'),
      subpanelIcon: constructSharp,
      subpanelActiveColour: 'var(--ion-color-warning)',
      subpanelDisabled: false,
      route: `${project?.id}/working-environment`,
      value: 'working-environment',
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Site Access Requests'),
      subpanelIcon: idCard,
      subpanelActiveColour: 'var(--ion-color-success)',
      subpanelDisabled: false,
      route: `${project?.id}/site-access-requests`,
      value: 'site-access-requests',
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Documents'),
      subpanelIcon: documentsSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${project?.id}/documents`,
      value: 'documents',
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Worksites'),
      subpanelIcon: layers,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${project?.id}/worksites`,
      value: 'worksites',
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Map'),
      subpanelIcon: map,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${project?.id}/map/:worksite_layer_id?`,
      value: 'map/:worksite_layer_id?',
      only_routing: true
    },
    {
      subpanelTitle: i18n.t('Settings'),
      subpanelIcon: cog,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${project?.id}/settings`,
      value: 'settings',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },
  ];

  const toggleStart = () => {
    const project = projects?.find((project) => project.id === uuid);
    store.dispatch(setSelectedProject(project));
    openModal();
  };
  const mobilePanels = (
    <IonList className='ion-padding-end ion-margin-top'>
      {location.pathname === `/project-tools/${projectId}` && (
        PROJECT_TOOLS_ACCORDION_LIST.map((item, index) => (
          <Panel
            key={index}
            onClick={() => history.push(item.route ?? '')}
            panelTitle={item.subpanelTitle}
            icon={{
              start: {
                icon: item.subpanelIcon,
                color: 'primary'
              },
              end: {
                color: 'primary'
              }

            }}
          />
        ))
      )}
    </IonList>
  );
  const redirectTo = isDesktop ? `/project-tools/${project?.id}/control-of-execution` : `/project-tools/${project?.id}`;
  return (
    <React.Fragment>
      {location.pathname === '/project-tools' && (
        <ProjectList />
      )}
      {(isLoadingProject || typeof projectId === 'undefined')
        ? <ProgressBar />
        : (
          <React.Fragment>
            {(!isDesktop || !PROJECT_TOOLS_ACCORDION_LIST.length) && mobilePanels}
            {location.pathname === `/project-tools/${uuid}` && selectedProject?.has_site_access === false && (
              <IonGrid className='ion-padding'>
                <IonRow className='ion-justify-content-center ion-align-items-center'>
                  <IonCol sizeMd='6' >
                    <IonLabel color={'medium'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 800 }}>
                      <RejectedSiteAccessRequest onClick={toggleStart} />
                      <UnsubmittedSiteAccessRequest onClick={toggleStart} />
                      <PendingSiteAccessRequest />
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
          </React.Fragment>
        )}
      {(!isDesktop && location.pathname !== `/project-tools/${uuid}`) && (
        <ReturnChevronText text={t('Back')} color={'none'} fill={'none'} />
      )}
      {/* CONTROL OF EXECUTION */}
      <Route path={`/project-tools/:uuid(${matchUuid})/control-of-execution/:coe_id(${matchUuid})`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <ControlOfExecutionDetailed />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/control-of-execution/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <ControlOfExecution />
        </SplitpaneContent>
      </Route>
      {/* WORKING ENVIRONMENT */}
      <Route path={`/project-tools/:uuid(${matchUuid})/working-environment/`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <WorkingEnviroment />
        </SplitpaneContent>
      </Route>
      {/* SITE ACCESS REQUESTS */}
      <Route path={`/project-tools/:uuid(${matchUuid})/site-access-requests/settings`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <SiteAccessRequestsSettings />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/site-access-requests/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <SiteAccessRequests />
        </SplitpaneContent>
      </Route>

      {/* DOCUMENTS */}
      <Route path={`/project-tools/:uuid(${matchUuid})/documents/reports/working_environment/:working_environment_uuid(${matchUuid})/:entry_uuid(${matchUuid})?`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <WorkingEnvironmentReport />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/${selectedProject?.id}/documents/reports/working_environment_entry/:working_environment_entry_uuid(${matchUuid})`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <WorkingEnvironmentReport />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})/edit`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <DocumentsEditPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <DocumentPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/documents/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <ProjectDocuments />
        </SplitpaneContent>
      </Route>

      {/* WORKSITES */}
      <Route path={`/project-tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}/upload)`} >
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <LayerUpload />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}|add)`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>

          <WorkSiteFormPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/worksites`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <WorksitePage />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/map/:worksite_layer_id?`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST}>
          <WorksiteMapPage />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/settings`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} >
          <SettingsPage />
        </SplitpaneContent>
      </Route>

      {/* TAGS */}
      <Route path={`/project-tools/:uuid(${matchUuid})/settings/tags/:tag_category_id(${matchUuid})`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <TagPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/settings/tags/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <TagsPage />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/settings/document-types/:category_id(${matchUuid})/`} exact >
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <EditDocumentTypePage />
        </SplitpaneContent>
      </Route>
      {/* CATEGORIES */}
      <Route path={`/project-tools/:uuid(${matchUuid})/settings/document-types/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <CategoryPage />
        </SplitpaneContent>
      </Route>
      {/* TEAM */}
      <Route path={`/project-tools/:uuid(${matchUuid})/settings/team/:team_id(${matchUuid})`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <Team />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/team/:team_id(${matchUuid})/invite`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <TeamInvite />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/settings/team/:team_id(${matchUuid})/edit`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <TeamForm />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/settings/team/create`} exact >
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <TeamForm />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/settings/teams`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <Teams />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/settings/fortnox`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <FortnoxPage />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/settings/fortnox/integration/:integration_id(${matchUuid})`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={PROJECT_TOOLS_ACCORDION_LIST} canReturn>
          <ProjectMappingPage />
        </SplitpaneContent>
      </Route>

      <IonModal
        isOpen={isModalOpen}
        onIonModalDidDismiss={() => closeModal()}
        ref={modal}
        className={modalStyles['onboarding-start-modal']}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
      >
        <OnboardingStart dismiss={dismiss} />
      </IonModal>

    </React.Fragment>
  );
};

export default ProjectPage;
