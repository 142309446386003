import { IonProgressBar } from '@ionic/react';

const ProgressBar: React.FC = () => {
  return (
    <IonProgressBar
      style={{
        width: '80%',
        height: '6px',
        borderRadius: '50%',
        opacity: 0.6
      }}
      type="indeterminate"
    />
  );
};
export default ProgressBar;
