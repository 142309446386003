import { IonItem, IonText } from '@ionic/react';
import type { TFunction } from 'i18next';
import type { ComponentProps } from 'react';

import styles from './Notice.module.scss';
import BigUp from '../../components/UI';

interface NoticeItemProps extends ComponentProps<typeof IonItem> {
  label: TFunction | string;
  children: React.ReactNode;
}
const NoticeItem: React.FC<NoticeItemProps> = ({ children, label, ...props }) => {
  return (
    <IonItem {...props} className='ion-margin-bottom'>
      <IonText className={styles.noticeTextContainer}>
        <BigUp.Label.Thick label={label} />
        {children}
      </IonText>
    </IonItem>
  );
};

export default NoticeItem;
