import { IonCol, IonGrid, IonRow, IonSelectOption } from '@ionic/react';
import type { EconomyChange } from '@techlove/easy2use-typings/src/V1/Models/EconomyChange';
import type { EconomyReport } from '@techlove/easy2use-typings/src/V1/Models/EconomyReport';
import { add, information, shareSocial, trashBin } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { buttonTextMap, handleSource, types } from './handlers';
import type { DeviationsAndChangesProps, Types } from './interface';
import { emptyListMessages } from '../../../constants/messages';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import ActivityCodeTile from '../../../pages/Economy/Subpanels/ActivityCodeTile';
import shareUrl from '../../../tools/shareUrl';
import BigUp from '../../UI';
import { handleEmptyListStates } from '../../UI/EmptyList';
import ProgressBar from '../../UI/Loaders/ProgressBar';
import SearchAndSortRow from '../../UI/SearchAndSort/SearchAndSortRow';

const RowListView: React.FC<DeviationsAndChangesProps> = ({
  confirmDelete, currentPage, data, isLoading, pageStep, search, setSearch, setSort,
  totalPages, type
}) => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslation();
  const history = useHistory();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const source = handleSource(type, uuid);
  const pushToRoute = (id: string | undefined, action: 'create' | 'details') => {
    const routes = typeof source === 'object' &&
      'routes' in source
      ? source.routes
      : {};

    const routeKey = action === 'create' ? 'create' : 'base';

    const finalRoute = id
      ? `${routes[routeKey]}/${id}`
      : routes[routeKey];

    return history.push(String(finalRoute || source));
  };

  const addNewRow = () => {
    const buttonText = buttonTextMap[type] || t('Add');
    return (
      types.includes(type) &&
      <IonCol size='6' className='ion-margin-bottom ion-text-right'>
        <BigUp.Buttons.Regular
          title={buttonText}
          color={'secondary'}
          onClick={() => pushToRoute(undefined, 'create')}
          icon={{ icon: add, slot: 'end' }}
        />
      </IonCol>
    );
  };

  const title = typeof source === 'object' && 'name' in source ? source.name : source;

  const messages = {
    empty: {
      title: emptyListMessages.amendments.empty_title(title.toLowerCase()),
      message: emptyListMessages.amendments.empty_message(title.toLowerCase()),
    },
    error: {
      message: '',
    },
  };
  return (
    <div {...isDesktop && { className: 'ion-padding', }}>
      <IonGrid>
        {isLoading && <div ><ProgressBar /></div>}
        <IonRow className='ion-justify-content-start'>
          <IonCol size='12'>
            <SearchAndSortRow onSearch={setSearch} onSort={setSort} value={search}>
              <IonSelectOption value={'name'}>{t('Name')}</IonSelectOption>
              <IonSelectOption value={'created_at'}>{t('Created at')}</IonSelectOption>
              <IonSelectOption value={'updated_at'}>{t('Last modified')}</IonSelectOption>
            </SearchAndSortRow>
          </IonCol>
        </IonRow>

        {data.length !== 0 && !isLoading
          ? data.map((item: Types) => (
            <IonRow key={item.id} className='ion-justify-content-evenly ion-align-items-center ion-margin-bottom' >
              <IonCol size='6' sizeLg='6' className='ion-margin-end'>
                <div onClick={() => pushToRoute(item.id, 'details')}>
                  <ActivityCodeTile
                    label={i18n.t('Reference')}
                    name={`${(type === 'deviations' || type === 'changes') ? (item as EconomyChange).name : (item as EconomyReport).document_name}`}
                  />
                </div>
              </IonCol>
              <IonCol className='ion-text-center'>
                <BigUp.Buttons.Responsive
                  padding={20}
                  expand={'block'}
                  isDesktop={isDesktop}
                  color={isDesktop ? 'secondary' : 'none'}
                  title={i18n.t('Share')}
                  onClick={() => shareUrl(
                    undefined,
                    `${t('Here is the link to {type} {name}', 'Here is the link to {type} {name}', { type: type === 'deviations' ? 'deviation' : 'change', name: (item as EconomyChange).name })}`,
                    `${process.env.REACT_APP_SHARE_BASE_URL}/economy-tools/${type === 'monitoring' ? 'monitoring' : uuid + '/' + type}/${item.id}`,
                    t(`Let me know if there is something you are wondering about.`),
                    'share'
                  )}
                  icon={{ icon: shareSocial, color: 'secondary' }}
                />
              </IonCol>
              <IonCol className='ion-text-center'>
                <BigUp.Buttons.Responsive
                  padding={20}
                  expand={'block'}
                  isDesktop={isDesktop}
                  color={'danger'}
                  title={i18n.t('Delete')}
                  onClick={() => confirmDelete(item.id)}
                  icon={{ icon: trashBin }}
                />
              </IonCol>
              <IonCol className='ion-text-center'>
                <BigUp.Buttons.Responsive
                  padding={20}
                  expand={'block'}
                  isDesktop={isDesktop}
                  color={isDesktop ? 'primary' : 'none'}
                  title={i18n.t('Details')}
                  onClick={() => pushToRoute(item.id, 'details')}
                  icon={{ icon: information, color: 'primary' }}
                />
              </IonCol>
            </IonRow>
          ))
          : (
            <IonRow className='ion-justify-content-center ion-margin-top'>
              <IonCol size='12' className='ion-text-center'>
                {!isLoading &&
                  <h2>
                    {handleEmptyListStates({
                      isEmpty: !data.length,
                      error: '',
                      messages,
                      search
                    })}
                  </h2>}
              </IonCol>
            </IonRow>
          )}
      </IonGrid>
    </div>
  );
};

export default RowListView;
