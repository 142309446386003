import { IonItem } from '@ionic/react';
import { useFormContext } from 'react-hook-form';

import BigUp from '../../../components/UI';
import InputLabel from '../../../components/UI/InputLabel';
import i18n from '../../../i18n';

const NoticeVisibilityHandling: React.FC = () => {
  const methods = useFormContext();
  return (
    <IonItem className='ion-no-padding ion-margin-bottom'
      style={{
        '--inner-padding-end': '8px',
      }}>
      <InputLabel stacked margin={'ion-no-margin'}>{i18n.t('Visibility')}</InputLabel>
      <BigUp.Checkbox
        className='ion-margin-end'
        labelPlacement='start'
        checked={methods.getValues('global')}
        handleCheckbox={(checked) => methods.setValue('global', !!checked)}
      >
        {i18n.t('Visible for all participants')}
      </BigUp.Checkbox>
    </IonItem>
  );
};

export default NoticeVisibilityHandling;
