import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './Login.module.scss';
import LoginSegment from './LoginSegment';
import AppVersion from '../../components/AppVersion';
import Avatar from '../../components/Avatar';
import LoginForm from '../../components/LoginForm';
import LogoThumbnail from '../../components/LogoThumbnail';
import BankID from '../../components/UI/BankID/BankID';
import { setUser } from '../../reducers/authentication';
import store from '../../store';

const Login: React.FC = () => {
  const [loginMethod, setLoginMethod] = useState<'email' | 'bankID'>('email');

  useEffect(() => {
    store.dispatch(setUser(null));
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid className={classNames('ion-justify-content-start ion-align-items-center', styles['vertical-aligned-grid'])}>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size={'auto'}>
              <LogoThumbnail />
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center'>
            <IonCol sizeMd='6' sizeXs='12'>
              <LoginSegment loginMethod={loginMethod} setLoginMethod={setLoginMethod} />
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center ion-align-items-center'>
            <IonCol size='12' style={{ height: '70dvh' }}>
              {loginMethod === 'email' && (
                <>
                  <div className={styles['login-form-container']}>
                    <Avatar.AvatarImage style={{ width: 100, height: 100 }} avatarSource='placeholder' />
                    <LoginForm />
                  </div>
                  <div className={styles['app-version-container-login-page']}>
                    <AppVersion />
                  </div>
                </>
              )}

              {loginMethod === 'bankID' && (
                <div style={{ width: 350 }}>
                  <BankID.BankIdLogin />
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
