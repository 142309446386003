import { IonCheckbox, IonCol, IonGrid, IonItem, IonRow, IonText } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { arrowForward } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import CertificateToggle from './CertificateToggle';
import type { Onboarding } from './StageTwoTypings';
import FileUpload from './Upload/FileUpload';
import StepButtons from '../../../../../../components/Toolbar/StepButtons';
import { ionicColours } from '../../../../../../components/UI/variables';
import { useAppSelector } from '../../../../../../hooks';
import i18n from '../../../../../../i18n';
import { setOnboardingStageTwo } from '../../../../../../reducers/onboarding';
import store from '../../../../../../store';
import type { OnboardingInterface } from '../../../../interfaces/Onboarding.interface';
import OnboardingHeader from '../../../header/FormHeaderTitle';
import styles from '../../styles/OnboardingStageTwo.module.scss';

const CertificateTypeList: Onboarding.StageTwo.CertificateType[] = [
  'hot_work',
  'work_from_lift',
  'heavy_lifting'
];

const OnboardingStageTwo: React.FC<OnboardingInterface> = () => {
  const { t } = useTranslation();
  const [hasPendingFiles, setHasPendingFiles] = useState<boolean>();

  const onboardingStageTwo: Partial<E2U.V1.Objects.ProjectAccessRequest> = useAppSelector(
    (store) => store.onboarding.onboardingStageTwo
  );

  const {
    control,
    formState: { isValid },
    getValues,
    handleSubmit,
    setValue,
    watch
  } = useForm<FieldValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: onboardingStageTwo
  });

  const fields: Partial<Onboarding.StageTwo.CertfificateFields> = {};

  CertificateTypeList.forEach(
    (field) => {
      fields[field] = {
        checked: watch(field) as boolean,
        certificates: watch(`${field}_certificates`) as Onboarding.StageTwo.PendingFile[]
      };
    }
  );

  const toggleWork = (type: Onboarding.StageTwo.CertificateType) => {
    setValue(type, !getValues()[type]);
  };

  const other_certificates: Onboarding.StageTwo.PendingFile[] = watch('other_certificates', []);

  const history = useHistory();
  const submitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setOnboardingStageTwo(data));
    history.push('./project-files');
  };

  const checkHasPendingFiles = () => {
    const combinedFields = { ...fields, other: { certificates: other_certificates } };
    setHasPendingFiles(
      Object.values(combinedFields).findIndex(
        (item) => {
          return item.certificates.findIndex(
            (doc) => !doc.uploaded && !doc.error
          ) !== -1;
        }
      ) !== -1
    );
  };

  const goPrevious = () => {
    const saveData: Partial<E2U.V1.Objects.ProjectAccessRequest> = structuredClone(onboardingStageTwo);
    saveData.confirm_stage_two = getValues().confirm_stage_two;
    store.dispatch(setOnboardingStageTwo(saveData));
    history.go(-1);
  };

  useEffect(() => {
    checkHasPendingFiles();
  }, [fields]);

  return (
    <React.Fragment>
      <div className={styles['onboarding-stage-two']}>
        <OnboardingHeader title={i18n.t('Will you perform any of the following during the project?')} />

        <form onSubmit={handleSubmit(submitForm)}>
          {(Object.keys(fields) as Onboarding.StageTwo.CertificateType[]).map((field, key) => {
            if (!fields[field] || typeof fields[field] === 'undefined') {
              return null;
            }
            return (
              <React.Fragment key={key}>
                <CertificateToggle
                  name={i18n.t(field)}
                  toggle={() => toggleWork(field)}
                  control={control}
                  watchName={field}
                />
                {field
                  ? <div className={styles['fade-in-upload-section']}>
                    <FileUpload
                      existingFiles={(fields[field] ?? { certificates: [] as Onboarding.StageTwo.PendingFile[] }).certificates}
                      setValue={(
                        files
                      ) => setValue(`${field}_certificates`, files)}
                    />
                  </div>
                  : <React.Fragment />
                }
              </React.Fragment>
            );
          })}
          <IonItem className={'ion-no-padding'}>
            <IonGrid>
              <IonRow className="ion-justify-content-between ion-align-items-center">
                <IonText style={{ fontWeight: 600, color: ionicColours.tertiary, opacity: '.8', }}>
                  <p>{t('Add other certificate')}</p>
                </IonText>
              </IonRow>
            </IonGrid>
          </IonItem>
          <FileUpload
            existingFiles={other_certificates}
            setValue={(files) => setValue('other_certificates', files)}
          />
          <IonGrid className='ion-margin-bottom ion-margin-top'>
            <IonRow className="ion-align-items-center ion-text-left">
              <IonCol size="10">
                <p>
                  {t('I do solemnly swear that the information above is correct')}
                </p>
              </IonCol>

              <IonCol size="2" className="ion-text-center">
                <Controller
                  name="confirm_stage_two"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <IonCheckbox
                        checked={value}
                        onIonChange={({ detail: { checked } }) => { onChange(checked); }}
                      />
                    );
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <StepButtons
            leftTitle={i18n.t('previous')}
            leftIonColor={'none'}
            leftOnClick={goPrevious}
            rightTitle={i18n.t('next')}
            rightIonColor={'secondary'}
            rightDisabled={!isValid}
            rightIcon={arrowForward}
          />
          {hasPendingFiles ? t('Please wait for files to upload') : ''}
        </form>
      </div>
    </React.Fragment>
  );
};

export default OnboardingStageTwo;
