import type { CSSProperties } from 'react';

const content: CSSProperties = {
  borderRadius: '50px',
  background: 'var(--ion-color-light)',
  margin: 0,
  padding: 5,
  paddingLeft: 15,
  paddingRight: 15,
  marginTop: 28,
  minHeight: 20,
};

const body: CSSProperties = {
  marginTop: 0
};

const closeButton: CSSProperties = {
  marginRight: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styles = {
  content,
  closeButton,
  body
};

export default styles;
