import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonLoading,
  IonPage, useIonAlert
} from '@ionic/react';
import { useParams } from 'react-router';

import Toast from '../../components/Toasts/Toast';

const ProjectReportsPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  // const isLoadingReports: boolean = useAppSelector((state) => state.loading.isLoading.reports);

  // const fetchSelectedProject = () => {
  //     if (typeof uuid === 'undefined' || uuid === '') {
  //         history.push('/projects');
  //         return;
  //     }
  //     store.dispatch(setIsLoading({name: 'team', value: true}));
  //     networking.get(`/api/v1/teams/${uuid}`).then((response: E2U.V1.Response.Success<E2U.V1.Models.Team>) => {
  //         store.dispatch(setSelectedTeam(response.data.data));
  //         fetchRolesForTeam();
  //     }).catch((error: E2U.V1.Response.Error) => {
  //         console.log('E2U Errors', error);
  // Toast('Could not find team', 'error');
  //
  //         fetchChildren();
  //         //history.push('/teams')
  //     }).finally(() => {
  //         store.dispatch(setIsLoading({name: 'team', value: false}));
  //     })
  // }

  return (
    <IonPage>
      <IonContent fullscreen>
        {/* <section className={styles["team-container"]}>
                    {isLoadingTeam ? <IonLoading isOpen={true} /> : <>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>{t('Team')} {typeof team !== 'undefined' && team.name}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonButton
                                    href={`/teams`}
                                >{t('Go to teams list')}</IonButton>
                                {(team && team.parent_id) && <IonButton
                                    color={'warning'}
                                    href={`/team/${team.parent_id}`}
                                >{t('Go to parent')}</IonButton>}
                                <InviteForm />
                                {(team && typeof team.id !== 'undefined') && <TeamForm parent_id={team.id} />}
                                <IonButton
                                    color={'danger'}
                                    onClick={() => handleDeleteButtonClicked()}
                                >{t('Delete team')}</IonButton>
                                <IonButton href={`/team/${team?.id}/edit`}>{t('Edit team')}</IonButton>
                            </IonCardContent>
                        </IonCard>
                    </>}
                </section> */}
      </IonContent>
    </IonPage>
  );
};

export default ProjectReportsPage;
