import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import BigUp from '../../../../components/UI';
import type { ButtonProps } from '../../../../components/UI/Buttons/interface';
import i18n from '../../../../i18n';

const DuplicateButton = (
  props?: ButtonProps
) => {
  const { historicalPrecalculationId, precalculationId } = useParams<{
    precalculationId: string | undefined,
    uuid: string | undefined,
    historicalPrecalculationId: string | undefined
  }>();
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();

  const linkTo = useMemo(() => {
    const type = historicalPrecalculationId ? 'precalculation_history' : 'precalculation';
    const id = historicalPrecalculationId || precalculationId;
    if (!uuid) {
      return `/economy-tools/${type}/${id}/duplicate`;
    }
    return `/economy-tools/${uuid}/${type}/${id}/duplicate`;
  }, [historicalPrecalculationId, precalculationId, uuid]);

  return (
    <BigUp.Buttons.Regular
      {...props}
      title={props?.title || i18n.t('Duplicate')}
      expand='block'
      color='success'
      onClick={() => history.push(linkTo)}
    />
  );
};

export default DuplicateButton;
