import { IonToggle } from '@ionic/react';

import type { DarkmodeToggleProps } from './interface';
import useDarkMode from '../../hooks/useDarkMode';

const DarkmodeToggle: React.FC<DarkmodeToggleProps> = (props) => {
  const toggleTheme = useDarkMode();
  const checkEnvironment = process.env.NODE_ENV === 'development';

  return (
    <>
      {checkEnvironment && (
        <IonToggle
          {...props}
          slot={props.slot || 'end'}
          color={props.color || 'success'}
          checked={toggleTheme.storedTheme === 'dark'}
          onIonChange={toggleTheme.toggleTheme}
          className={props.className || 'space-between'}
        />
      )}
    </>
  );
};

export default DarkmodeToggle;
