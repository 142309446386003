import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonModal, IonRow, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import { closeSharp, flagOutline, logOutOutline, peopleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import styles from './HamburgerMenu.module.scss';
import TeamsAccordion from './TeamsAccordion';
import { networking } from '../../api/networking';
import e2cWhiteLogo from '../../assets/images/e2c_vit.png';
import { useAppSelector } from '../../hooks';
import useLogout from '../../hooks/useLogout';
import useModal from '../../hooks/useModal';
import usePermissionHandler from '../../hooks/usePermissions';
import i18n from '../../i18n';
import modalStyle from '../../pages/Onboarding/Components/containers/styles/ModalContainers.module.scss';
import PersonalOverview from '../../pages/Onboarding/Components/PersonalOverview/PersonalOverview';
import AppVersion from '../AppVersion';
import Avatar from '../Avatar';
import { MENU_LIST } from '../PageMenuHeader/navLinks';
import BigUp from '../UI';
import BankID from '../UI/BankID/BankID';
import LanguageSelect from '../UI/Select/LanguageSelect';

interface HamburgerMenuProps {
  menu_id?: string;
  content_id?: string;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ content_id, menu_id }) => {
  const [teams, setTeams] = useState<E2U.V1.Models.Team[]>();

  const clearUserDetails = useLogout();
  const toggleOnboardingModal = useModal();
  const { uuid } = useParams<{ uuid: string }>();
  const user = useAppSelector(state => state.authentication.user);
  const permission = usePermissionHandler();

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const fetchMyTeams = async () => {
    try {
      const response = await networking.get(`/api/v1/users/${user?.id}/teams`);
      setTeams(response.data.data.records);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!user?.id) return;
    if (!teams) {
      fetchMyTeams();
    }
  }, [user?.id, uuid]);

  useEffect(() => {
    if (!user?.id) return;
    if (!teams) {
      fetchMyTeams();
    }
  }, [user?.id, uuid]);

  return (
    <>
      <IonMenu
        {...isPlatform('ios' || 'android' || 'mobile') && { type: 'push' }}
        menuId='header'
        contentId={content_id || 'main-content'}
        side={'end'}
        style={{
          '--max-width': '600px',
          ...!isDesktop
            ? { '--width': '90%' }
            : { '--width': '30%' }
        }}>
        <IonHeader className='ion-no-border'>
          <IonToolbar className='ion-margin-top'>
            <IonButtons slot='end'>
              <IonMenuToggle >
                <IonButton>
                  <IonIcon slot='start' icon={closeSharp} />
                </IonButton>
              </IonMenuToggle>
            </IonButtons>
            <IonTitle size='large' style={{ fontWeight: 600 }}>
              {t('Profile')}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow className='ion-justify-content-center ion-align-items-center'>
              <IonCol size='4'>
                <Avatar.AvatarImage
                  onClick={toggleOnboardingModal.openModal}
                  avatarSource='userImage'
                  style={{ width: 100, height: 100, }}
                />
              </IonCol>
              <IonCol size='8'>
                <IonRow className='ion-justify-content-center ion-align-items-center'>
                  <IonCol>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <h2 style={{ fontWeight: 600 }}>{t('Name')}:</h2>
                        <p style={{ fontSize: 16 }}>{`${user?.first_name} ${user?.last_name}`}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <h2 style={{ fontWeight: 600 }}>{t('Email')}:</h2>
                        <p style={{ fontSize: 16 }}>{`${user?.email}`}</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size={'12'}>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonList>
            {MENU_LIST.map(item => (
              <IonItem key={item.linkText} lines='full'>
                <IonIcon icon={item.itemIcon} className='ion-margin-end'></IonIcon>
                <IonLabel className='ion-no-margin'>
                  <Link to={`${item.directTo}`} style={{ textDecoration: 'none', color: 'var(--ion-color-dark)' }}>
                    {item.linkText}
                  </Link>
                </IonLabel>
              </IonItem>
            ))}
            {permission.checkPermission('project_tools_access', 'admin') && uuid && (
              <TeamsAccordion
                data={teams}
                route={`/project-tools/${uuid}/settings/team`}
                icon={peopleOutline}
                title={i18n.t('Teams')}
              />
            )}

            <IonItem style={{ '--inner-padding-end': '8px' }} lines='full'>
              <IonIcon icon={flagOutline} className='ion-margin-end' />
              <LanguageSelect />
            </IonItem>
            {/**
             * TODO: Comment back out once we are ready to start working on darkmode (functionality works).
            <IonItem style={{ '--inner-padding-end': '8px' }} lines='full'>
              <IonIcon icon={contrastOutline} className='ion-margin-end' />
              <IonLabel className='ion-no-margin' color={'dark'}>{t('Dark mode')}</IonLabel>
              <DarkmodeToggle />
            </IonItem>
            */}
            <IonItem lines='full' button onClick={clearUserDetails}>
              <IonIcon icon={logOutOutline} className='ion-margin-end' />
              <IonLabel className='ion-no-margin' color={'dark'}>{t('Logout')}</IonLabel>
            </IonItem>
          </IonList>

          {!user?.ssn && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0' }}>
              <BigUp.Buttons.BankIdAuthenticate />
            </div>
          )}

          {user?.ssn && (
            <IonGrid>
              <IonRow className='ion-align-items-center ion-justify-content-center'>
                <BigUp.Label.Regular label={i18n.t('Authenticated with ')} style={{ fontSize: 18 }} /><BankID.BankIdIcon color='black' width={60} />
              </IonRow>
            </IonGrid>
          )}
          <div className={styles['app-version-container']}>
            <IonImg src={e2cWhiteLogo} className={styles['e2u-logo-black']} />
            <AppVersion />
          </div>
        </IonContent >
      </IonMenu >

      <IonModal
        isOpen={toggleOnboardingModal.isModalOpen}
        className={modalStyle['personaloverview-modal']}
        onIonModalDidDismiss={toggleOnboardingModal.closeModal}
      >
        <PersonalOverview togglePersonalOverview={toggleOnboardingModal.toggleModal} />
      </IonModal >
    </>
  );
};

export default HamburgerMenu;
