import { IonCol, IonGrid, IonList, IonRow, useIonRouter } from '@ionic/react';
import { fileTrayFullSharp, paperPlane, peopleSharp } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import fortnoxLogo from '../../../../assets/fortnox/logo.svg';
import Panel from '../../../../components/Panels/Panel';
import BigUp from '../../../../components/UI';
import { useAppSelector } from '../../../../hooks';
import usePermissionHandler from '../../../../hooks/usePermissions';
import i18n from '../../../../i18n';

const SettingsPage: React.FC = () => {
  const permission = usePermissionHandler();
  const router = useIonRouter();
  const { t } = useTranslation();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);

  const PROJECT_TOOLS_LIST_ADMIN = [
    {
      subpanelTitle: i18n.t('Tags'),
      subpanelIcon: paperPlane,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `settings/tags`,
      value: 'tags',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Document types'),
      subpanelIcon: fileTrayFullSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `settings/document-types`,
      value: 'document-types',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Teams'),
      subpanelIcon: peopleSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `settings/teams`,
      value: 'teams',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },
    {
      subpanelTitle: i18n.t('Fortnox'),
      subpanelIcon: fortnoxLogo,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `settings/fortnox`,
      value: 'fortnox',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },
  ];

  return (
    <div className={'ion-padding'}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <BigUp.Title label={t('Project Settings')} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList className='ion-padding-end'>
        {location.pathname === `/project-tools/${selectedProject?.id}/settings` && (
          PROJECT_TOOLS_LIST_ADMIN.filter((i) => !i.only_routing).map((item, index) => (
            <React.Fragment key={index}>
              <Panel
                icon={{
                  start: {
                    icon: item.subpanelIcon,
                    color: 'primary'
                  },
                  end: {
                    color: 'primary'
                  }
                }}
                onClick={() => router.push(item.route ?? '')}
                panelTitle={item.subpanelTitle}
              />
            </React.Fragment>
          ))
        )}
      </IonList>
    </div>
  );
};

export default SettingsPage;
