import { IonCol, IonContent, IonGrid, IonItem, IonPage, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { ribbon, warning } from 'ionicons/icons';
import moment from 'moment/moment';
import { useEffect } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import PasswordHandler from './PasswordHandler';
import styles from './Registration.module.scss';
import RegistrationInputs from './RegistrationInputs';
import { networking, setAxiosAccessToken } from '../../api/networking';
import { useBankIdContext } from '../../bankid/bankid.context';
import DesktopWrapper from '../../components/DesktopWrapper';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import StepButtons from '../../components/Toolbar/StepButtons';
import BankID from '../../components/UI/BankID/BankID';
import HeaderBackground from '../../components/UI/Header/HeaderBackground';
import toasters from '../../components/UI/Toasts';
import useFocusNextInput from '../../hooks/useFocusNextInput';
import useLogin from '../../hooks/useLogin';
import i18n from '../../i18n';
import { setUser } from '../../reducers/authentication';
import storage from '../../storage';
import store from '../../store';
import { capitalize } from '../../tools/capitalizeString';

const Registration: React.FC = () => {
  const login = useLogin();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const bankid = useBankIdContext();

  const userBankIdData = (location.state as { user?: E2U.V1.Models.User })?.user;

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      ...userBankIdData,
      terms_and_conditions: false
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    networking.post('/api/v1/users/registration', data)
      .then((result: E2U.V1.Response.Success<{
        token: E2U.V1.Objects.Token,
        user: E2U.V1.Models.User
      }>) => {
        // TODO: Set created user token, login and push user to dashboard
        /*
          login.handleLogin({
          username: data.email,
          password: data.password
          }, undefined);
        */

        const promises: Promise<any>[] = [];
        promises.push(storage.set('access_token', result.data.data.token.access_token));
        promises.push(storage.set('refresh_token', result.data.data.token.refresh_token));
        promises.push(storage.set('access_token_expires_at', moment().add(result.data.data.token.expires_in, 'seconds').toISOString()));

        Promise.all(promises)
          .then(() => {
            setAxiosAccessToken(result.data.data.token.access_token);
            networking.post(`/api/v1/user`, {})
              .then((result: E2U.V1.Response.Success<E2U.V1.Models.User>) => {
                store.dispatch(setUser(result.data.data));
                history.push('/');
                toasters.createToast({
                  message: i18n.t('Welcome to BigUp {name}!', 'Welcome to BigUp {name}!', {
                    name: capitalize(result.data.data.first_name)
                  }),
                  icon: ribbon,
                  background: 'var(--ion-color-light)',
                }, 'success');
              })
              .catch((error) => {
                Sentry.captureException(error);
              });
          });
      })
      .then(() => {
        history.push('/');
      })
      .catch((error: E2U.V1.Response.Error<E2U.V1.Models.User>) => {
        if (error.response?.data.message === 'SSN already exists') {
          toasters.createToast({
            message: i18n.t('This SSN is already registered. Please login using Bank-ID'),
            icon: warning,
            background: 'var(--ion-color-warning)',
          }, 'info');
        } else {
          const errorData = error.response?.data.data;
          if (errorData) {
            Object.entries(errorData).forEach(([fieldName, fieldErrors]) => {
              if (fieldName === 'backtrace') return;
              const errorMessage = (fieldErrors as string[])[0];
              methods.setError(fieldName as any, {
                type: 'server',
                message: errorMessage
              });
            });
          }
        }
        Sentry.captureException(error);
      });
  };

  const handleRegisterWithBankId = () => {
    history.push({
      pathname: '/login',
      state: { bankid: true }
    });
    bankid?.authenticate.initiateBankIdAuthentication();
  };

  useEffect(() => {
    login.clearStorage();
    bankid?.abortBankIdAuthentication();
  }, []);

  const { focusNextInput, formRef } = useFocusNextInput(); // Use the custom hook

  const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === 'Enter') {
      focusNextInput(e.target as HTMLInputElement);
    }
  };

  return (
    <IonPage>
      <HeaderBackground
        title={userBankIdData
          ? t('Welcome {name}!', 'Welcome {name}!', {
            name: capitalize(userBankIdData.first_name)
          })
          : i18n.t('Register')}
        subtitle={i18n.t('Fill in your information below to register')}
      />
      <IonContent className={`ion-padding ion-margin-top ${styles['content-background']}`}>

        <DesktopWrapper width='600px' alignment={'center'}>
          {!userBankIdData && (
            <IonGrid>
              <IonRow className='ion-justify-content-end'>
                <IonCol size='12' className='ion-text-end'>
                  <IonItem detail lines={'none'} type={'button'} onClick={handleRegisterWithBankId}>
                    <BankID.BankIdIcon color='standard' width={60} />{i18n.t('Want to sign up using BankID?')}
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className={styles['fade-in']} ref={formRef}>
              {/*
              // TODO: Implement this avatar upload at a later stage
              <RegistrationAvatar />
            */}
              <RegistrationInputs isBankId={userBankIdData} onKeyUp={handleNextInput} />
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <PasswordHandler isBankId={userBankIdData} onKeyUp={handleNextInput} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <TermsAndConditions />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <StepButtons
                rightSubmit={'submit'}
                rightDisabled={
                  !methods.formState.isValid ||
                  methods.formState.isSubmitting ||
                  !methods.watch('terms_and_conditions')
                }
                rightIonColor='secondary'
                rightOnClick={() => { }}
                leftTitle={i18n.t('Cancel')}
                leftOnClick={() => history.push('/login')}
                leftIonColor='none'
              />
            </form>
          </FormProvider>

        </DesktopWrapper >
      </IonContent>
    </IonPage>
  );
};

export default Registration;
