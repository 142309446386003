import { IonCol, IonGrid, IonRow, IonSearchbar, IonSpinner, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { close } from 'ionicons/icons';
import { truncate } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styles from './Precalculations.module.scss';
import { networking } from '../../../../api/networking';
import PaginateData from '../../../../components/Pagination/PaginationData';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import ItemShadowNoIcons from '../../../../components/UI/Items/components/ItemShadowNoIcons';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import useFetchBasePrecalculations from '../../../../hooks/useFetchBasePrecalculations';
import i18n from '../../../../i18n';
import { setPaginationData, setSelectedPrecalculation } from '../../../../reducers/precalculations';
import store from '../../../../store';
import { formatToYYYYMMDD } from '../../../../tools/formatDates';

interface Lock {
  isLocked: boolean;
  isLockable: boolean;
}

const PrecalculationsList: React.FC<Lock> = (props: Lock) => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const { t } = useTranslation();
  const history = useHistory();
  const precalculations = useAppSelector((state) => state.precalculations.precalculations);
  const user = useAppSelector((state) => state.authentication.user);
  const currentPage = useAppSelector(state => state.precalculations.currentPage);
  const totalPages = useAppSelector(state => state.precalculations.totalPages);
  const isLoading = useAppSelector(state => state.loading.isLoading.precalculations);
  const [search, setSearch] = React.useState<string>('');
  const [presentAlert] = useIonAlert();

  const fetchBasePrecalculations = useFetchBasePrecalculations();

  const fetchProjectPrecalculations = (step?: number, search?: string) => {
    fetchBasePrecalculations('projects', uuid ?? '', step ?? 1, search);
  };

  const fetchUserPrecalculations = (step?: number, search?: string) => {
    fetchBasePrecalculations('users', user?.id ?? '', step ?? 1, search);
  };

  const handleSearch = useCallback((event: CustomEvent) => {
    setSearch(event.detail.value);
  }, []);

  const handleCreatePrecalculation = () => {
    networking.post('/api/v1/precalculations', {
      name: t('Calculation'),
      owner_id: user?.id,
      project_id: uuid ?? null,
    })
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Precalculation>) => {
          store.dispatch(setSelectedPrecalculation(response.data));
          if (uuid) {
            history.push(`/economy-tools/${uuid}/precalculation/${response.data.data.id}`);
          } else {
            history.push(`/economy-tools/precalculation/${response.data.data.id}`);
          }
        }
      ).catch((error) => {
        toasters.createToast({
          message: i18n.t('Error creating precalculation'),
          background: 'var(--ion-color-light)'
        }, 'error');
        Sentry.captureException(error);
      });
  };

  const handlePagination = (step: number) => {
    store.dispatch(setPaginationData({ currentPage: currentPage + step, totalPages }));
  };
  const fetchBasedOnUuid = () => {
    if (uuid) {
      fetchProjectPrecalculations(currentPage, search);
    } else {
      fetchUserPrecalculations(currentPage, search);
    }
  };
  const sendDeleteRequest = (id: string | undefined) => {
    networking.delete(`/api/v1/precalculations/${id}`)
      .then(() => {
        fetchBasedOnUuid();
        toasters.createToast({
          message: i18n.t('Precalculation deleted successfully'),
          background: 'var(--ion-color-light)'
        }, 'success');
      }
      ).catch((error) => {
        toasters.createToast({
          message: i18n.t('Error deleting precalculation'),
          background: 'var(--ion-color-light)'
        }, 'error');
        Sentry.captureException(error);
      });
  };
  const handleDelete = (id: string | undefined) => {
    presentAlert({
      header: i18n.t('Are you sure you want to delete this precalculation?'),
      message: i18n.t('This action cannot be undone.'),
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: i18n.t('OK'),
          role: 'confirm',
          handler: () => {
            sendDeleteRequest(id);
          }
        }
      ]
    });
  };

  useEffect(() => {
    if (uuid) {
      fetchProjectPrecalculations(currentPage, search);
    } else {
      fetchUserPrecalculations(currentPage, search);
    }
    const unlisten = history.listen(() => {
      store.dispatch(setPaginationData({ currentPage: 1, totalPages: 1 }));
    });
    return unlisten;
  }, [uuid, currentPage, search]);

  return (
    <div {...isDesktop && {
      className: styles['precalculations-accordion-container']
    }}>
      <div className='ion-padding' {...isDesktop && { className: styles['precalculations-desktop-wrapper'] }} >
        <IonGrid>
          <IonRow className={'ion-align-items-center'}>
            <IonCol>
              <BigUp.Title label={uuid ? t('Project precalculations') : t('My precalculations')} />
            </IonCol>
            <IonCol size={'auto'}>
              <BigUp.Buttons.Regular size='small' title={t('Create')} onClick={() => handleCreatePrecalculation()} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSearchbar
                showClearButton='focus'
                className='ion-no-padding ion-margin-vertical'
                placeholder={t('Search precalculations')}
                debounce={500}
                onIonInput={handleSearch}
                value={search}
              />
            </IonCol>
          </IonRow>
          {isLoading
            ? <IonSpinner />
            : ((precalculations && precalculations.length)
              ? (
                <>
                  <IonRow>
                    <IonCol>
                      <BigUp.Label.Regular label={t('Select precalculation to edit')} className={'ion-no-margin'} />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {precalculations.map((precalculation: E2U.V1.Models.Precalculation, i: number) => {
                      return (
                        <IonCol key={precalculation.id} sizeXs='12' size='6' className='ion-margin-bottom'>
                          <ItemShadowNoIcons
                            deleteHandler={() => handleDelete(precalculation.id)}
                            onItemClick={() => {
                              store.dispatch(setSelectedPrecalculation(precalculation));
                              if (uuid) {
                                history.push(`/economy-tools/${uuid}/precalculation/${precalculation.id}`);
                              } else {
                                history.push(`/economy-tools/precalculation/${precalculation.id}`);
                              }
                            }}
                            deleteIcon={close}
                            label={precalculation.name}
                            description={truncate(precalculation.description, { length: isDesktop ? 100 : 40 })}
                            subLabel={formatToYYYYMMDD(precalculation.created_at)}
                          />
                        </IonCol>
                      );
                    }
                    )}
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <PaginateData
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pageStepper={handlePagination}
                      />
                    </IonCol>
                  </IonRow>
                </>
              )
              : (
                <IonRow>
                  <IonCol>
                    <EmptyList
                      title={t('No precalculations')}
                      message={t('Create a new precalculation to get started.')}
                    />
                  </IonCol>
                </IonRow>
              )
            )}
        </IonGrid>
      </div>
    </div>
  );
};
export default PrecalculationsList;
