import { IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { closeSharp, settingsSharp } from 'ionicons/icons';
import React, { useEffect } from 'react';

import ItemShadow from './ItemShadow';
import BigUp from '../..';
import { networking } from '../../../../api/networking';
import { useAppSelector } from '../../../../hooks';
import type { ItemNoShadow } from '../Items';
import styles from '../Items.module.scss';

const ItemShadowNoIcons: React.FC<ItemNoShadow> = ({
  backgroundLeft,
  borderColour,
  deleteHandler,
  deleteIcon = closeSharp,
  description,
  hasBorder,
  hasIconLeft,
  iconColour,
  iconLeft,
  label,
  noMarginTop,
  onItemClick,
  optionalHandler,
  subLabel,
}) => {
  const [backgroundImage, setBackgroundImage] = React.useState<string | undefined>(undefined);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const shortenDescrtiption = isDesktop ? 80 : 30;

  const getBackgroundImage = () => {
    if (backgroundLeft) {
      if (backgroundLeft.startsWith('data')) {
        setBackgroundImage(backgroundLeft);
      } else {
        networking.get(backgroundLeft).then((res) => {
          setBackgroundImage(`data:${res.headers['content-type']};base64,${res.data}`);
        });
      }
    }
  };

  const handleDeleteClick: React.MouseEventHandler<HTMLIonButtonElement> = (e) => {
    if (deleteHandler) {
      e.stopPropagation();
      deleteHandler(e);
    }
  };

  const handleOptionalClick: React.MouseEventHandler<HTMLIonButtonElement> = (e) => {
    if (optionalHandler) {
      e.stopPropagation();
      optionalHandler(e);
    }
  };

  const contentWidth = () => {
    if (deleteHandler && optionalHandler) {
      return '7';
    }
    if (deleteHandler) {
      return '9';
    }
    return '11';
  };

  useEffect(() => {
    getBackgroundImage();
  }, []);

  useEffect(() => {
    getBackgroundImage();
  }, [backgroundLeft]);

  return (
    <ItemShadow
      className='ion-text-nowrap ion-margin-bottom'
      hasBorderLeft={hasBorder || false}
      borderLeftColour={borderColour}
      clickHandler={onItemClick}
      noMarginTop={noMarginTop}
    >
      <IonGrid className={styles['item-no-icon-grid']} style={{ overflow: 'auto' }}>
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          {hasIconLeft
            ? <IonCol size='1' className='ion-no-padding'>
              {backgroundImage
                ? <span
                  className={styles['item-background-preview']}
                  style={{ backgroundImage: `url("${backgroundImage}")`, marginTop: '5px', marginBottom: '5px' }}
                />
                : <IonIcon icon={iconLeft} color={iconColour || 'medium'} size='large' />
              }
            </IonCol>
            : <React.Fragment />
          }

          <IonCol size={contentWidth()} className='ion-text-left'>
            <IonLabel className='ion-no-margin ion-margin-start ion-text-nowrap' style={{ fontSize: '12px', fontWeight: '600', letterSpacing: '.5px' }} color={'medium'} >
              {label}
            </IonLabel>
            <BigUp.Label.Regular label={description}></BigUp.Label.Regular>
            <IonLabel className='ion-no-margin ion-margin-start ion-text-nowrap' style={{ fontSize: '12px', fontWeight: '600', letterSpacing: '.5px' }} color={'medium'}>
              {subLabel}
            </IonLabel>
          </IonCol>
          {optionalHandler
            ? (
              <IonCol size='2' className='ion-align-items-center ion-justify-content-end ion-text-right' style={{ display: 'flex' }}>
                <IonButton color={'none'} mode='ios' onClick={(e) => handleOptionalClick(e)} className={'ion-no-padding'}>
                  <IonIcon icon={settingsSharp} size={'large'} color={'medium'} />
                </IonButton>
              </IonCol>
            )
            : <React.Fragment />
          }
          {deleteHandler
            ? (
              <IonCol size='2' className='ion-align-items-center ion-justify-content-end' style={{ display: 'flex' }}>
                <IonButton color={'none'} mode='ios' onClick={(e) => handleDeleteClick(e)} className={'ion-no-padding'}>
                  <IonIcon icon={deleteIcon} size={'large'} color={'medium'} />
                </IonButton>
              </IonCol>
            )
            : <React.Fragment />
          }
        </IonRow>
      </IonGrid>
    </ItemShadow>
  );
};

export default ItemShadowNoIcons;
