import { IonCol, IonGrid, IonItem, IonLabel, IonList, IonModal, IonRow, isPlatform } from '@ionic/react';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import type { E2U } from '@techlove/easy2use-typings';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CashFlowRows.module.scss';
import DifferenceBadge from './DifferenceBadge';
import Edit from './Edit/Edit';
import SwiperContainer from '../../../../../components/Swiper/SwiperContainer';
import type SwiperController from '../../../../../components/Swiper/SwiperController';
import SwiperNav from '../../../../../components/Swiper/SwiperNav';
import BigUp from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';
import useModal from '../../../../../hooks/useModal';
import i18n from '../../../../../i18n';
import formatNumber from '../../../../../tools/formatNumber';

interface CashFlowRowsProps {
  cashFlow: E2U.V1.Models.CashFlow;
  editable: boolean;
  title: string;
  refreshCashFlows: () => void;
  swiperController: SwiperController | undefined;
  differences?: E2U.V1.Models.CashFlow;
  fetchCashFlow?: () => void;
}

const CashFlowRows: React.FC<CashFlowRowsProps> = (props: CashFlowRowsProps) => {
  const [selectedRow, setSelectedRow] = useState<E2U.V1.Models.CashFlowRow | undefined>(undefined);
  const { closeModal, isModalOpen, openModal } = useModal();
  const { t } = useTranslation();
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const modalSize = {
    '--height': '60%',
    '--min-height': '600px',
    '--max-height': '900px',
    '--width': '90%',
    '--min-width': '200px',
    '--max-width': '400px',
    '--border-radius': '20px',
  };
  const totalIndexes: number = props.cashFlow.rows.length - 1;
  if (props.swiperController) {
    props.swiperController.setTotalIndexes(totalIndexes);
  }

  const rowEdit = (row: E2U.V1.Models.CashFlowRow) => {
    if (props.editable === true) {
      setSelectedRow(row);
      openModal();
    }
  };

  const differenceBadgeProps = [
    {
      label: i18n.t('Proj. management'),
      value: props.cashFlow.project_management_total,
      difference: props.editable ? props.differences?.project_management_total ?? 0 : null,
      id: 'project-management'
    },
    {
      label: i18n.t('Net cost'),
      value: props.cashFlow.net_cost_total,
      difference: props.editable ? props.differences?.net_cost_total ?? 0 : null,
      id: 'net-cost'
    },
    {
      label: i18n.t('Fee'),
      value: props.cashFlow.fee_total,
      difference: props.editable ? props.differences?.fee_total ?? 0 : null,
      id: 'fee'
    },
    {
      label: i18n.t('Total payment'),
      value: props.cashFlow.grand_total,
      difference: props.editable ? props.differences?.grand_total ?? 0 : null,
      id: 'total'
    }
  ];

  return (
    <React.Fragment>
      <IonGrid>
        <IonRow className='ion-align-items-center'>
          <IonGrid>
            <IonRow><BigUp.Label.Thick label={props.title} /></IonRow>
            <IonRow>{t('Select a column to edit')}</IonRow>
          </IonGrid>
          <SwiperNav
            slideBtnClassName={'cash-flow-slide-btn'}
            navColSize={'6'}
            iconColor={'secondary'}
            controller={props.swiperController!}
            labelText={''}
            sliderIndexDesc={'Month'}
          />
        </IonRow>
      </IonGrid>

      <div className={styles[`${props.title}-line-border`]}>
        <IonGrid>
          <IonRow
            {...isPlatform('desktop') && { className: 'ion-justify-content-flex-start' }}
            {...isPlatform('mobile') && { className: 'ion-justify-content-space-between' }}
          >
            <IonCol size='6' sizeLg='3'>
              <IonList className={styles['cash-flow-prop-list']}>
                {differenceBadgeProps.map((props, i) => {
                  return (
                    <DifferenceBadge
                      key={i}
                      label={props.label}
                      value={props.value}
                      difference={props.difference}
                      id={props.id as 'project-management' | 'net-cost' | 'fee' | 'total' | ''}
                    />
                  );
                })}
              </IonList>
            </IonCol>

            <IonCol size='6' sizeLg="9">
              <SwiperContainer controller={props.swiperController!} slidesPerView={isDesktop ? 4 : 1}>
                {props.cashFlow.rows.map((row, i) => {
                  const row_list = [
                    formatNumber(row.project_management_amount),
                    formatNumber(row.net_cost_amount),
                    formatNumber(row.fee_amount),
                    formatNumber(row.grand_total)
                  ];
                  return (
                    <SwiperSlide key={i} className={styles[`${props.title}-hover`]}>
                      <IonList
                        className={styles[`cash-flow-row-list-${isDesktop && (i % 2 ? 'odd' : 'even')}`]}
                        onClick={() => rowEdit(row)}
                      >
                        <IonGrid className='ion-no-padding'>
                          <IonRow className='ion-align-items-center ion-justify-content-center'>
                            <IonCol className='ion-no-padding ion-no-margin'>
                              <BigUp.Label.Thick label={moment(row.due_date).format('YYYY-MM-DD')} />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                        {row_list.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              <IonItem>{item || 0}</IonItem>
                              <IonLabel><br /></IonLabel>
                            </React.Fragment>
                          );
                        })}
                      </IonList>
                      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', ...isDesktop && { marginBottom: 15 } }}>
                        <IonLabel style={{ fontSize: '16px' }} className='ion-no-margin'>
                          {Math.round(row.percent_of_total)} %
                        </IonLabel>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </SwiperContainer>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      <IonModal style={{ ...modalSize }} isOpen={isModalOpen} onIonModalDidDismiss={closeModal}>
        <Edit
          onModalDismiss={closeModal}
          refreshCashFlow={() => props.fetchCashFlow!()}
          row={selectedRow}
          rowIndex={props.cashFlow.rows.findIndex((row) => row.id === selectedRow?.id)}
          rowCount={props.cashFlow.rows.length}
        />
      </IonModal>
    </React.Fragment>
  );
};
export default CashFlowRows;
