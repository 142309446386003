import { IonAccordion, IonAccordionGroup, IonIcon, IonItem, IonLabel } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import { truncate } from 'lodash';
import type { ComponentProps } from 'react';
import { useHistory } from 'react-router';

import styles from './HamburgerMenu.module.scss';

interface TeamsAccordionItemProps {
  data?: E2U.V1.Models.Team[] | E2U.V1.Models.Project[];
  route: string;
  icon: ComponentProps<typeof IonIcon>['icon'];
  title: string;
}
const TeamsAccordion: React.FC<TeamsAccordionItemProps> = ({
  data, icon, route, title
}) => {
  const history = useHistory();
  return (
    <IonItem style={{ '--inner-padding-end': '0px' }} lines='full'>
      <IonAccordionGroup className='ion-no-padding' style={{ width: '100%' }}>
        <IonAccordion value='teams' className='ion-no-padding'>
          <IonItem button style={{ '--inner-padding-end': '0px' }} slot="header" lines='none' className={classNames([styles.accordionHeader, 'ion-no-padding'])}>
            <IonIcon icon={icon} className='ion-margin-end' />
            <IonLabel className='ion-no-margin' color={'dark'}>{title}</IonLabel>
          </IonItem>
          {data?.map((item, index) => {
            return (
              <div
                onClick={() => history.push(`${route}/${item.id}`)}
                key={item.id}
                className="ion-no-padding ion-margin-top"
                slot="content"
                style={{
                  background: index % 2 === 0
                    ? 'var(--ion-color-light)'
                    : 'var(--ion-color-light-shade)',
                  padding: '5px',
                  paddingLeft: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <p className='ion-no-margin'>{truncate(item.name, { length: 40 })}</p>
                <IonIcon icon={arrowForwardCircleOutline} size={'large'} color='medium' />
              </div>
            );
          })}
        </IonAccordion>
      </IonAccordionGroup>
    </IonItem>
  );
};

export default TeamsAccordion;
