import { useIonRouter } from '@ionic/react';

import BigUp from '../../../../components/UI';
import type { InputProps } from '../../../../components/UI/Inputs/Input.interface';
import i18n from '../../../../i18n';

const inputProps: InputProps<any>[] = [
  {
    label: i18n.t('Company'),
    placeholder: i18n.t('Company'),
    labelPlacement: 'start',

    validation: {
      required: true,
    },
  },
  {
    label: i18n.t('Contact person'),
    placeholder: i18n.t('Contact person'),
    labelPlacement: 'start',

    validation: {
      required: true,
    },
  },
];
const FollowUp: React.FC = () => {
  const router = useIonRouter();
  const items = inputProps.map((inputProp, index) => ({
    id: (index + 1).toString(),
    content: <BigUp.Checkbox
      labelPlacement="start"
      labelProps={{
        label: inputProp.label ?? '',
      }}
      handleCheckbox={() => console.log(index)}
    />,
  }));
  return <BigUp.ItemList
    color='primary'
    header={{
      title: i18n.t('Follow-up'),
      color: 'medium'
    }}
    items={items}
    actionButton={{
      title: i18n.t('Add follow-up'),
      onClick: () => router.push('/work-preparation/follow-up')
    }}
  />;
};

export default FollowUp;
