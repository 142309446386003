import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import TeamDelete from './TeamDelete';
import styles from './TeamForm.module.scss';
import TeamInputs from './TeamInputs';
import { networking } from '../../../api/networking';
import ListTitle from '../../../components/UI/Titles/ListTitle';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';

const TeamForm: React.FC = () => {
  const [getTeam, setGetTeam] = useState<E2U.V1.Models.Team | undefined>(undefined);
  const team = useAppSelector(state => state.team.selectedTeam);
  const user = useAppSelector(state => state.authentication.user);
  const history = useHistory();
  const { t } = useTranslation();
  const { team_id, uuid } = useParams<{
    uuid: string | undefined, team_id: string | undefined
  }>();
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: getTeam?.name || '',
      description: getTeam?.description || '',
    },
  });
  const checkIfTeamExists = team && typeof team.id !== 'undefined';
  const handleTeamState = (checkIfTeamExists as boolean || getTeam as E2U.V1.Models.Team);

  const pageTitle = handleTeamState
    ? i18n.t('Edit team')
    : i18n.t('Create team');

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    const postData: FieldValues = { ...data };

    const request = team && typeof team.id !== 'undefined'
      ? networking.put(`/api/v1/teams/${team.id}`, postData)
      : networking.post('/api/v1/teams', postData);

    request.then((response: E2U.V1.Response.Success<E2U.V1.Models.Team>) => {
      Promise.all([
        networking.post(`/api/v1/teams/${response.data.data.id}/projects/${uuid}`),
        networking.post(`/api/v1/teams/${response.data.data.id}/users/${user?.id}`),
      ])
        .catch((error) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          history.push(`/project-tools/${uuid}/settings/team/${response.data.data.id}`);
        });
    })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };

  const fetchTeamById = () => {
    if (typeof uuid === 'undefined' || uuid === '') {
      history.push('/teams');
      return;
    }
    if (!team_id) {
      return;
    }
    networking.get(`/api/v1/teams/${team_id}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Team>) => {
        !checkIfTeamExists &&
          setGetTeam(response.data.data);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    fetchTeamById();
  }, []);

  return (
    <div className={styles['team-form-wrapper']}>
      <div className={styles['team-section-container']}>
        <IonGrid className='ion-padding'>
          <IonRow>
            <IonCol size='12'>
              <ListTitle label={pageTitle} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)} >
            <IonGrid className='ion-padding'>
              <IonRow>
                <IonCol size='12' className='ion-text-left'>
                  <TeamInputs />
                </IonCol>
              </IonRow>
              <div className={classNames('ion-margin-top', styles['team-form-buttons-container'])}>
                <IonButton
                  disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                  type={'submit'}
                  color={handleTeamState ? 'primary' : 'success'}
                >
                  {handleTeamState ? t('Update') : i18n.t('Create')}
                </IonButton>
                {handleTeamState && <TeamDelete />}
              </div>
            </IonGrid>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default TeamForm;
