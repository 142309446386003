import type { Photo } from '@capacitor/camera';
import { IonCol, IonGrid, IonLoading, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './DocumentForm.module.scss';
import { networking } from '../../../api/networking';
import HistoricalFileList from '../../../components/FileList/HistoricalFileList';
import RelatedSelect from '../../../components/Search/RelatedSelect/RelatedSelect';
import type { Result } from '../../../components/Search/RelatedSelect/RelatedSelectInterface';
import toasters from '../../../components/Toasts/Toasts';
import { BigUp } from '../../../components/UI';
import { customBorder } from '../../../components/UI/variables';
import { useAppSelector } from '../../../hooks';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFileUpload from '../../../hooks/useFileUpload';
import i18n from '../../../i18n';
import { setShouldRefetchDocuments } from '../../../reducers/file';
import { setIsLoading } from '../../../reducers/loading';
import { arraysAreEqual } from '../../../tools/compareArrays';
import { formatFilename } from '../../../tools/formatFilename';
import FileSelectionButton from '../../Projects/ProjectPage/ControlOfExecution/AddFilesModal/FileSelectionButton';
import TakePhotoButton from '../../Projects/ProjectPage/ControlOfExecution/AddFilesModal/TakePhotoButton';
import TagForm from '../Tags/TagForm';

interface UploadDocumentFormProps {
  handleUploadedFile?: (file: E2U.V1.Models.File) => void
  handleCloseModal?: () => void;
}
const DocumentForm: React.FC<UploadDocumentFormProps> = (props: UploadDocumentFormProps) => {
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[] }>({});
  const store = useStore();
  const { t } = useTranslation();

  const { filterPhotosFromFiles, getUploadedPhotos, handleTakenPhoto, removePhoto, setUploadedPhotos, uploadSelectedPhotos } = useCameraUpload();
  const { getUploadedFiles, handleFileSelection, removeFile, setUploadedFiles, uploadSelectedFiles } = useFileUpload();

  const files = getUploadedFiles() ?? [];
  const photos = getUploadedPhotos() ?? [];

  const prevFilesRef = useRef(files);
  const prevPhotosRef = useRef(photos);
  const uploadListCheck = files.concat(photos).length <= 0;
  const filesChanged = !arraysAreEqual(prevFilesRef.current, files);
  const photosChanged = !arraysAreEqual(prevPhotosRef.current, photos);
  const isLoadingDocument: boolean = useAppSelector((state) => state.loading.isLoading.document);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const history = useHistory();
  const document: E2U.V1.Models.Document | undefined = useAppSelector((state) => state.file.selectedFile);

  const methods = useForm<E2U.V1.Models.Document & {
    local_tags: E2U.V1.Models.TagCategory[],
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: undefined,
      description: undefined,
      local_tags: [],
      activity_code_id: undefined,
    }
  });
  const { acceptedFiles } = useDropzone({ multiple: false, maxSize: 1073741824 });

  const triggerIfUploads = async () => {
    if (files.length > 0 || photos.length > 0) {
      await methods.trigger();
    }
  };

  const handleFileSelectUpdate = () => {
    if (acceptedFiles && acceptedFiles[0]) {
      return (
        methods.setValue('name', formatFilename(acceptedFiles[0].name))
      );
    }
  };

  const handleDisableButtons = (button: 'upload' | 'submit') => {
    if (button === 'upload') {
      return ((methods.formState.isSubmitting || isLoadingDocument));
    }
    if (button === 'submit') {
      return (methods.formState.isSubmitting || isLoadingDocument) ||
        (!methods.formState.isValid || Object.keys(validationErrors).length > 0);
    }
  };

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'uploadingFile', value: true }));
    if (selectedProject && selectedProject.id) {
      data.project_id = selectedProject.id;
    }
    if (data.name === '') {
      delete data.name;
    }
    if (data.description === '') {
      delete data.description;
    }
    const request = (document && document.id)
      ? networking.put(`/api/v1/documents/${document.id}`, data)
      : networking.post('/api/v1/documents', data);
    return toasters.promise(new Promise((resolve, reject) => {
      request.then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
          Promise.allSettled([
            uploadSelectedFiles('/api/v1/documents', response.data?.data?.id ?? ''),
            uploadSelectedPhotos('/api/v1/documents', response.data?.data?.id ?? ''),
          ])
            .then(() => {
              const documentId = response.data?.data?.id;
              const selectedTags: E2U.V1.Models.Tag[] = data.local_tags.flatMap((tagCategory: E2U.V1.Models.TagCategory) => tagCategory.tags ?? []);
              const existingSelection = document?.related_tags ?? [];
              const tagsToRemove = existingSelection.filter(
                (tag: E2U.V1.Models.Tag) => !selectedTags.some((st) => st.id === tag.id)
              ).map((tag: E2U.V1.Models.Tag) => networking.delete(`/api/v1/documents/${response.data?.data?.id}/related_tags/${tag.id}`));

              const tagsToAdd = selectedTags.filter(
                (tag: E2U.V1.Models.Tag) => typeof tag.id !== 'undefined' && !existingSelection.some(
                  (est) => est.id === tag.id
                )
              ).map((tag: E2U.V1.Models.Tag) => networking.post(`/api/v1/documents/${response.data?.data?.id}/related_tags/${tag.id}`));

              const tagsToCreate = selectedTags.filter(
                (tag: E2U.V1.Models.Tag) => typeof tag.id === 'undefined'
              ).map((tag: E2U.V1.Models.Tag) => new Promise((resolve, reject) => {
                networking.post(`/api/v1/tags`, {
                  name: tag.name,
                  category_id: tag.category_id,
                  color: '#000000'
                }).then((response: E2U.V1.Response.Success<E2U.V1.Models.Tag>) => {
                  networking.post(`/api/v1/documents/${documentId}/related_tags/${response.data?.data?.id}`).then(() => resolve(response))
                    .catch((error) => reject(error));
                }).catch((error) => reject(error));
              }));

              Promise.allSettled([
                ...tagsToRemove,
                ...tagsToAdd,
                ...tagsToCreate
              ]).then(() => {
                resolve(response);
              }).catch((error) => {
                reject(error);
              });
            })
            .catch((error) => {
              reject(error);
            });
        }
      ).catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.Document>) => {
          if (error &&
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === 'Validation failed'
          ) {
            setValidationErrors(error.response.data.data);
          } else {
            Sentry.captureException(error);
          }
          reject(error);
        }
      );
    }), {
      pending: (document && document.id) ? t('Saving document') : i18n.t('Creating document'),
      success: (document && document.id) ? t('Document saved') : i18n.t('Document created'),
      error: (document && document.id) ? t('Could not save document') : i18n.t('Could not create document')
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'uploadingFile', value: false }));
      if (props.handleCloseModal) {
        store.dispatch(setShouldRefetchDocuments(undefined));
        props.handleCloseModal();
      } else {
        history.push(`/project-tools/${selectedProject?.id}/documents/${document?.id}`);
      }
    });
  };

  useEffect(() => {
    if (acceptedFiles) {
      handleFileSelectUpdate();
    }
    if (filesChanged || photosChanged) {
      triggerIfUploads();
    }
    prevFilesRef.current = files;
    prevPhotosRef.current = photos;
  }, [files, photos, methods.trigger]);

  const handleExistingDocument = () => {
    if (typeof document !== 'undefined') {
      if (methods.formState.defaultValues?.name !== '') { methods.setValue('name', document.name); }
      methods.setValue('description', document.description);
      if (document.files && document.files.length) {
        filterPhotosFromFiles(document.files, setUploadedFiles);
      }
      if (document.related_tags && document.related_tags.length) {
        const tagsPerCategory: E2U.V1.Models.TagCategory[] = document.related_tags.reduce((acc: E2U.V1.Models.TagCategory[], tag: E2U.V1.Models.Tag) => {
          const existingCategory = acc.find((tc) => tc.id === tag.category_id);
          if (existingCategory) {
            existingCategory.tags?.push(tag);
          } else {
            acc.push({
              id: tag.category?.id,
              name: tag.category?.name ?? '',
              tags: [tag],
              color: tag.category?.color ?? '#000000'
            });
          }
          return acc;
        }, []);
        methods.setValue('local_tags', tagsPerCategory);
      }
    }
  };

  useEffect(() => {
    handleExistingDocument();
  }, []);

  useEffect(() => {
    handleExistingDocument();
  }, [document]);

  const handleFileNameChanged = (file: E2U.V1.Models.File, newName: string, type?: 'files' | 'photos') => {
    if (type === 'files') {
      setUploadedFiles(files.map((f) => {
        if (f.id === file.id) {
          f.name = newName;
        }
        return f;
      }));
    } else {
      setUploadedPhotos(photos.map((p) => {
        if (p.id === file.id) {
          p.name = newName;
        }
        return p;
      }));
    }
  };

  return (
    <section className={styles['file-form']}>
      {(isLoadingDocument)
        ? <IonLoading isOpen={true} />
        : <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <Controller
                    control={methods.control}
                    name='activity_code_id'
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <RelatedSelect
                        infiniteScroll={true}
                        value={value || document?.activity_code_id}
                        model="activity_codes"
                        onSelect={(data: Result) => onChange(data.id)}
                        title={i18n.t('Select activity code')}
                        displayFields={['code', 'name']}
                        fetchFields={['activityCategory', 'code_suffix']}
                      />
                    )}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={'ion-margin-top'}>
                <IonCol size='2' sizeMd={'6'}>
                  <FileSelectionButton
                    disabled={handleDisableButtons('upload')}
                    onFilesSelect={(files: File[]) => handleFileSelection(files)} />
                </IonCol>
                <IonCol size='2' sizeMd={'6'} className='ion-text-left'>
                  <TakePhotoButton
                    disabled={handleDisableButtons('upload')}
                    onTakePhotoClick={(photos: Photo[]) => handleTakenPhoto(photos)} />
                </IonCol>
              </IonRow>
              <FileSectionHeader title={i18n.t('Files')} />

              <IonRow className={'ion-margin-top'}>
                <IonCol>
                  <HistoricalFileList
                    files={files}
                    photos={photos}
                    is_editing={true}
                    handleFileNameChanged={handleFileNameChanged}
                  />
                </IonCol>
              </IonRow>
              <FileSectionHeader title={i18n.t('Document details')} />
              <IonRow className='ion-align-items-center ion-justify-content-center'>
                <IonCol size='12'>
                  <BigUp.Input
                    validation={{
                      required: false,
                      minLength: 3,
                      maxLength: 255,
                    }}
                    inputMode='text'
                    autoCapitalize='sentences'
                    label={i18n.t('Document name')}
                    labelPlacement='stacked'
                    placeholder={i18n.t('Enter name of document')}
                    register={'name'}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <BigUp.Textarea
                    itemProps={{ className: 'ion-no-padding', }}
                    inputMode='text'
                    autoCapitalize='sentences'
                    label={i18n.t('Comment')}
                    labelPlacement='stacked'
                    placeholder={i18n.t('Enter comment')}
                    register={'description'}
                  />
                </IonCol>
              </IonRow>

              <FileSectionHeader title={i18n.t('Document tags')} />
              <TagForm model={'local_tags'} />

              <IonRow className='ion-justify-content-between ion-margin-top ion-padding-horizontal'>
                <IonCol className='ion-text-left' size='4'>
                  <BigUp.Buttons.Regular
                    color={'light'}
                    expand='full'
                    onClick={() => props.handleCloseModal && props.handleCloseModal()}
                    title={i18n.t('Cancel')}
                  />
                </IonCol>
                <IonCol className='ion-text-right' size='4'>
                  <BigUp.Buttons.Regular
                    expand='full'
                    type='submit'
                    color={'secondary'}
                    disabled={uploadListCheck || handleDisableButtons('submit')}
                    onClick={() => { }}
                    title={(document && document.id) ? t('Save') : i18n.t('Create')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </FormProvider>
      }
    </section >
  );
};

const FileSectionHeader: React.FC<{ title: string }> = ({ title }) => {
  return (
    <IonRow className='ion-margin-top ion-margin-bottom'>
      <IonCol style={{ borderLeft: customBorder.borderColourSecondary }}>
        <h2 color={'dark'} className='ion-padding-start ion-no-margin'>{title}</h2>
      </IonCol>
    </IonRow>
  );
};

export default DocumentForm;
