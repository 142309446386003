import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import RowListView from '../../../../components/ListsComponents/rowList/RowListView';
import toasters from '../../../../components/Toasts/Toasts';
import useAlert from '../../../../hooks/useAlert';
import useFetchData from '../../../../hooks/useFetch';
import i18n from '../../../../i18n';
import { buildOptionalQuery, buildQuery } from '../../../../tools/queryBuilder';
import { scrollToSection } from '../../../../tools/scrollToSection';

const Deviations: React.FC = () => {
  const [deviations, setDeviations] = React.useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [perPage, setPerPage] = useState<number>(10);
  // TODO: Paginate or virtualize scroll.
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { alert } = useAlert();
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { fetchData, isLoading } = useFetchData<E2U.V1.Models.EconomyMonitoring, any | undefined>({
    initialData: null
  });

  const deviationRowRoute = `/api/v1/projects/${uuid}/deviations?${buildOptionalQuery(search, sortBy, currentPage, perPage, { with: 'rows' })}`;

  const scrollToId = 'deviations';
  const onThen = (response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.EconomyMonitoring>>) => {
    setDeviations(response.data.data.records);
    setTotalPages(response.data.data.total_pages);
    setCurrentPage(response.data.data.current_page);
  };
  const onError = (error: E2U.V1.Response.Error<E2U.V1.Objects.PaginatedData<E2U.V1.Models.EconomyMonitoring>>) => {
    Sentry.captureException(error);
  };

  const deleteDeviation = (id: string | undefined) => {
    toasters.promise(networking.delete(`/api/v1/economy_deviations/${id}`), {
      success: i18n.t('Deviation deleted'),
      error: i18n.t('Could not delete deviation'),
    })
      .finally(() => {
        fetchData(deviationRowRoute, onThen, onError);
      });
  };

  const confirmDeleteDeviation = (id: string | undefined) => {
    alert(
      i18n.t('Are you sure you want to delete this deviation?'),
      i18n.t('Delete deviation'),
      () => deleteDeviation(id),
      i18n.t('Cancel'),
      i18n.t('Delete')
    );
  };
  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  useEffect(() => {
    fetchData(deviationRowRoute, onThen, onError);
  }, [search, sortBy, perPage, currentPage]);

  return (
    <RowListView
      pageStep={pageStepper}
      currentPage={currentPage}
      totalPages={totalPages}
      search={search}
      setSearch={setSearch}
      setSort={setSortBy}
      isLoading={isLoading}
      data={deviations}
      type={'deviations'}
      confirmDelete={confirmDeleteDeviation}
    />
  );
};

export default Deviations;
