import { IonCol, IonGrid, IonList, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { addCircle, closeCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import { BigUp } from '../../../components/UI';
import FilterChips from '../../../components/UI/Chips/FilterChips';
import EmptyList from '../../../components/UI/EmptyList';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { regex } from '../../../regex/regex';

interface TagFormProps {
  model?: string;
}

const TagForm: React.FC<TagFormProps> = (props) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const tags = methods.watch('local_tags') as E2U.V1.Models.TagCategory[];
  const [availableTagCategories, setAvailableTagCategories] = useState<E2U.V1.Models.TagCategory[]>([]);
  const [newTagInput, setNewTagInput] = useState<{ [key: string]: string }>({});

  const getDocumentTags = () => {
    if (!selectedProject) {
      return;
    }
    networking.get(`/api/v1/projects/${selectedProject?.id}/tag_categories?with=tags`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.TagCategory>>) => {
        setAvailableTagCategories(response.data.data.records);
        if (tags.length === 0) {
          methods.setValue('local_tags', response.data.data.records.map(
            (tagCategory) => ({
              ...tagCategory,
              tags: []
            })
          ));
        }
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error.message);
      });
  };

  const handleTags = (categoryId: string, tagValue: {
    id?: string,
    name: string,
    color: string
  }, action: 'add' | 'remove') => {
    const tagIndex = tags.findIndex((tag: E2U.V1.Models.TagCategory) => tag.id === categoryId);
    if (action === 'add') {
      tags[tagIndex].tags?.push({
        id: tagValue?.id ?? undefined,
        name: tagValue.name,
        category_id: categoryId,
        color: tagValue.color
      });
    } else {
      tags[tagIndex].tags = tags[tagIndex].tags?.filter((tag) => tag !== tagValue);
      if (!tags[tagIndex].tags) {
        tags[tagIndex].tags = [];
      }
    }
    methods.setValue(props.model ?? 'tags', tags);
  };

  const updateTags = (data: { id: string, value: E2U.V1.Models.Tag, key: string, category_id: string }[]) => {
    const newTagCategories: E2U.V1.Models.TagCategory[] = [...tags.map(
      (tagCategory) => ({
        ...tagCategory,
        tags: (tagCategory.tags ?? []).filter(
          (tag) => !tag.id
        )
      })
    )];
    data.forEach((tag) => {
      const categoryIndex = newTagCategories.findIndex((t) => t.id === tag.category_id);
      if (categoryIndex === -1) {
        newTagCategories.push({
          id: tag.category_id,
          name: tag.key,
          tags: [tag.value],
          color: '#000000'
        });
      } else if (!newTagCategories[categoryIndex]?.tags) {
        newTagCategories[categoryIndex].tags = [tag.value];
      } else if (!newTagCategories[categoryIndex]?.tags?.some((t) => t.id === tag.value.id)) {
        newTagCategories[categoryIndex]?.tags?.push(tag.value);
      }
    });
    methods.setValue(props.model ?? 'tags', newTagCategories);
  };

  const handleNewTag = (tag: string, categoryId: string) => {
    const newAvailableTags = [...availableTagCategories];
    const index = newAvailableTags.findIndex((t) => t.id === categoryId);
    newAvailableTags[index].tags?.push({
      name: tag,
      category_id: categoryId,
      color: '#000000'
    });
    handleTags(categoryId, { name: tag, color: '#000000' }, 'add');
    setAvailableTagCategories(newAvailableTags);
  };

  useEffect(() => {
    if (selectedProject) {
      getDocumentTags();
    }
  }, [selectedProject]);

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol className='ion-text-right'>
            <RelatedMultiSelectEdit
              button='solid'
              modalTitle={i18n.t('Select tags')}
              shouldSubmit={false}
              displayFields={['', ['value', 'name']]}
              hideSelected
              model='tags'
              label={i18n.t('Select tags')}
              keyField='id'
              category={['key']}
              onChange={(data) => updateTags(data)}
              records={
                (availableTagCategories).flatMap((tagCategory) =>
                  tagCategory.tags?.map((tag) => ({
                    id: tag.id,
                    value: tag,
                    key: tagCategory.name,
                    category_id: tagCategory.id
                  })) ?? []
                )
              }
              value={
                tags.flatMap((tagCategory) =>
                  tagCategory.tags?.map((tag) => ({
                    id: tag.id,
                    value: tag,
                    key: tagCategory.name,
                    category_id: tagCategory.id
                  })) ?? []
                )
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList>
        {availableTagCategories.length === 0 && (
          <EmptyList
            title={i18n.t('No tags')}
            message={i18n.t('No tags have been added yet, select some to get started.')}
          />
        )}
        {availableTagCategories.map((tagCategory, index) => (
          <React.Fragment key={index.toString()}>
            <IonGrid className='ion-margin-top' style={{}}>
              <IonRow className='ion-align-items-center ion-justify-content-between'>
                <IonCol size='7'>
                  <BigUp.Input
                    key={index}
                    label={tagCategory.name}
                    labelPlacement='stacked'
                    placeholder={i18n.t('Create tag')}
                    name={tagCategory?.id ?? ''}
                    value={newTagInput[tagCategory?.id ?? '']}
                    onIonChange={(e) => {
                      const tempNewTagInput = { ...newTagInput };
                      tempNewTagInput[tagCategory?.id ?? ''] = e.target.value as string;
                      setNewTagInput(tempNewTagInput);
                    }}

                    validation={{
                      required: true,
                      pattern: {
                        value: regex.regex_alphabetical,
                        message: i18n.t('Only letters and numbers are allowed')
                      }
                    }}
                  />
                </IonCol>
                <IonCol size='4' className='ion-no-margin ion-text-right'>
                  <BigUp.Buttons.Regular
                    color={'secondary'}
                    disabled={newTagInput[tagCategory?.id ?? ''] === '' ||
                      !Object.hasOwn(newTagInput, tagCategory?.id ?? '')
                    }
                    onClick={() => {
                      handleNewTag(newTagInput[tagCategory?.id ?? ''], tagCategory?.id ?? '');
                      const tempNewTagInput = { ...newTagInput };
                      tempNewTagInput[tagCategory?.id ?? ''] = '';
                      setNewTagInput(tempNewTagInput);
                    }}
                    icon={{ slot: 'start', icon: addCircle }}
                    title={i18n.t('Add')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <FilterChips
              data={(tags && tags.length)
                ? (tags[tags.findIndex((selected) => selected.id === tagCategory.id)]?.tags ?? [])
                : []
              }
              renderChipLabel={(item) => item.name}
              chipIcon={closeCircle}
              onChipClick={(item) => handleTags(tagCategory?.id ?? '', item, 'remove')}
            />
          </React.Fragment>
        ))}
      </IonList>
    </>
  );
};

export default TagForm;
