import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonNote, IonRow } from '@ionic/react';
import { layersSharp, pencilSharp } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import ListTitle from '../../../components/UI/Titles/ListTitle';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import type { Worksite } from '../worksite';

const LayerSection: React.FC<Worksite.LayerSectionProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const worksite = useAppSelector(state => state.worksites.selectedWorksite);

  const redirectHandler = (href: 'upload' | 'map', id?: string) => {
    if (href === 'upload') history.push(`/project-tools/${selectedProject?.id}/worksites/${worksite?.id}/upload`);
    if (href === 'map') history.push(`/project-tools/${selectedProject?.id}/map/${id}`);
  };

  return (
    <>
      <IonGrid className="ion-padding">
        <IonRow>
          <IonCol>
            <ListTitle label={i18n.t('Layers')} />
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-top">
          <IonCol className="ion-text-end">
            <IonButton className='ion-no-margin' color={'secondary'} fill="solid" onClick={() => redirectHandler('upload')}>
              <IonIcon slot="start" icon={pencilSharp} />
              {t('Edit layers')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList className="ion-no-padding">
        {worksite?.layers?.map((layer) => {
          return (
            <IonItem key={layer.id}
              {...layer.is_visible
                ? { button: true, onClick: () => redirectHandler('map', layer.id) }
                : { button: false }
              }
            >
              <IonIcon style={{ color: layer.color }} slot="start" icon={layersSharp} size="large"></IonIcon>
              <IonLabel className='ion-no-margin ion-margin-vertical'>{layer.name}</IonLabel>
              <IonNote slot='end'>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginRight: 5, }}>
                  <div style={{ width: 10, height: 10, borderRadius: '50%', background: layer.is_visible ? 'var(--ion-color-success)' : `var(--ion-color-medium)` }} />
                  {layer.is_visible ? i18n.t('Active') : i18n.t('Inactive')}
                </div>
              </IonNote>
            </IonItem>
          );
        })}
      </IonList>
    </>
  );
};

export default LayerSection;
