import { IonContent, IonItem, IonList, IonPopover } from '@ionic/react';
import React from 'react';

export interface PopoverItem {
  value: string;
  label: string;
  onClick: () => void;
}
interface PopoverHandling {
  items: PopoverItem[];
  actionId: string;
}

const Popover: React.FC<PopoverHandling> = (props) => {
  return (
    <IonPopover side="start" alignment="start" size='auto' trigger={props.actionId} dismissOnSelect={true} triggerAction="click">
      <IonContent scrollY>
        <IonList>
          {props.items.map((item) => (
            <IonItem key={item.label} button={true} detail={false} onClick={item.onClick}>
              {item.label}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default Popover;
