import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CategoryForm from './CategoryForm';
import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';

interface CategoriesProps {
  handleListRefresh: () => void;
}

const NewCategory: React.FC<CategoriesProps> = ({ handleListRefresh }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      color: '#428cff',
    }
  });

  const handleCategorySubmit: SubmitHandler<FieldValues> = async (data) => {
    await networking.post(`/api/v1/document_types`, data)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Type>) => {
        methods.reset();
        networking.post(`/api/v1/projects/${selectedProject?.id}/document_types/${response.data.data.id}`).then(() => {
          handleListRefresh();
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (error.response?.status === 409) {
          toasters.createToast({
            message: i18n.t('Type already exists'),
            background: 'var(--ion-color-light)'
          }, 'error');
          return;
        }
        toasters.createToast({
          message: i18n.t('Something went wrong!'),
          background: 'var(--ion-color-light)'
        }, 'error');
      });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleCategorySubmit)}>
          <h1 className='ion-padding' style={{ fontWeight: 800, paddingTop: '35px' }}>{t('Create type')}</h1>
          <IonGrid className="ion-padding">
            <CategoryForm />
            <IonRow>
              <IonCol className="ion-text-end ion-margin-top">
                <BigUp.Buttons.Regular
                  type="submit"
                  title={i18n.t('Create')}
                  color='secondary'
                  disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewCategory;
