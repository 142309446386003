import { IonActionSheet } from '@ionic/react';

export interface ActionSheetItem {
  value: string;
  label: string;
  onClick: (param?: Event) => void;
}
interface ActionSheetHandling {
  items: ActionSheetItem[];
  actionId: string;
}

const ActionSheet: React.FC<ActionSheetHandling> = (props) => {
  return (
    <IonActionSheet
      trigger={props.actionId}
      header="Actions"
      buttons={
        props.items.map((item) => ({
          text: item.label,
          role: item.value === 'delete' ? 'destructive' : item.value === 'cancel' ? 'cancel' : 'default',
          handler: item.onClick
        }))
      }
    />
  );
};

export default ActionSheet;
