import { IonLabel } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Tiles.module.scss';
import type { WorkupRateProps } from './TilesTypings';
import formatNumber from '../../../../tools/formatNumber';

const WorkupRateTile: React.FC<WorkupRateProps> = ({ row }) => {
  const { t } = useTranslation();
  const checkWorkupValue = row?.workup_rate !== undefined && row?.workup_rate > (row?.production_budget_total ?? 0);

  return (
    <div className={styles.tile} {...checkWorkupValue && { style: { backgroundColor: 'var(--ion-color-danger' } }}>
      <div className={classNames(styles.container, styles.postedCosts)}>
        <IonLabel className={styles.title} {...checkWorkupValue && { style: { color: 'var(--ion-color-light)' } }}>
          {t('Workup rate')}
        </IonLabel>
        <p className={classNames(styles.value)} {...checkWorkupValue && { style: { color: 'var(--ion-color-light)' } }}>
          {row?.workup_rate ? `${row!.workup_rate * 100}%` : '0%'}
        </p>
      </div>
    </div>
  );
};
export default WorkupRateTile;
