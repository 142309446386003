import { Browser } from '@capacitor/browser';
import { IonLoading } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';

interface InitiateAuthorizationProps {
  onBrowserFinished?: () => void;
}

const InitiateAuthorization: React.FC<InitiateAuthorizationProps> = (props) => {
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isInitializing = useAppSelector((state) => state.loading.isLoading.initializingFortnoxAuthorization);
  const { t } = useTranslation();

  const handleInitiateAuthorization = () => {
    store.dispatch(setIsLoading({ isLoading: true, key: 'initializingFortnoxAuthorization' }));
    networking.post(`/api/v1/projects/${selectedProject?.id}/fortnox/initialize_authorization`)
      .then((res: E2U.V1.Response.Success<{ url: string }>) => {
        Browser.open({ url: res.data.data.url });
      })
      .catch(() => {
        toasters.error(t('Failed to initiate Fortnox authorization. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ isLoading: false, key: 'initializingFortnoxAuthorization' }));
      });
  };

  useEffect(() => {
    Browser.addListener('browserFinished', () => {
      if (props.onBrowserFinished) {
        props.onBrowserFinished();
      }
    });
  }, []);

  return (
    <>
      {isInitializing
        ? <IonLoading />
        : <BigUp.Buttons.Regular
          title={t('Authorize Fortnox')}
          onClick={handleInitiateAuthorization}
        />}
    </>
  );
};

export default InitiateAuthorization;
