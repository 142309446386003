import { IonBadge, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonRange, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CASH_FLOW_EDIT_LIST from './EditInterface';
import { networking } from '../../../../../../api/networking';
import toasters from '../../../../../../components/Toasts/Toasts';
import StepButtons from '../../../../../../components/Toolbar/StepButtons';
import { BigUp } from '../../../../../../components/UI';
import CurrencyInput from '../../../../../../components/UI/Inputs/CurrencyInput';
import ItemBorderContainer from '../../../../../../components/UI/Items/components/ItemBorderContainer';
import HeaderBorderLeft from '../../../../../../components/UI/modals/HeaderBorderLeft';
import { ionicColours } from '../../../../../../components/UI/variables';
import i18n from '../../../../../../i18n';
import formatNumber from '../../../../../../tools/formatNumber';

interface EditProps {
  onModalDismiss(): void;
  refreshCashFlow: (updateIndex?: boolean) => void;
  row: E2U.V1.Models.CashFlowRow | undefined;
  rowCount: number;
  rowIndex: number;
}
export const label_styles = {
  fontWeight: 800,
  letterSpacing: '1px',
  fontSize: '12px'
};

const Edit: React.FC<EditProps> = (props) => {
  const { t } = useTranslation();
  const [applyToMonths, setApplyToMonths] = useState<number>(0);
  const [grossTotal, setGrossTotal] = useState('');

  const methods = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      ...props.row,
      project_management_amount: props.row ? formatNumber(props.row.project_management_amount) : 0,
      net_cost_amount: props.row ? formatNumber(props.row.net_cost_amount) : 0,
      fee_amount: props.row ? formatNumber(props.row.fee_amount) : 0,
      grand_total: props.row ? props.row.grand_total : 0,
      apply_to_months: 0,
    }
  });
  const { setValue, watch } = methods;
  const watchedValues = watch(['project_management_amount', 'net_cost_amount', 'fee_amount']);

  const toastMessages = {
    pending: i18n.t('Updating manual cash flow'),
    success: i18n.t('Updated manual cash flow.'),
    error: i18n.t('Could not update cash flow')
  };

  const saveManualCashFlow: SubmitHandler<FieldValues> = (data: FieldValues) => {
    if (Object.keys(methods.formState.errors).length > 0) {
      return;
    }

    toasters.promise(networking.put(`/api/v1/cash_flow_rows/${props.row?.id}`, data), {
      pending: toastMessages.pending,
      success: toastMessages.success,
      error: toastMessages.error
    })
      .then(() => {
        props.refreshCashFlow(false);
      })
      .catch((error: E2U.V1.Response.Error<E2U.V1.Models.CashFlowRow>) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        if (!methods.formState.errors) {
          props.onModalDismiss();
          props.refreshCashFlow(true);
        }
      });
  };

  const onRangeChange = (e: number) => {
    setApplyToMonths(e);
    methods.setValue('apply_to_months', e);
  };

  const [projectManagement, netCost, fee] = watchedValues;
  const netTotal = parseFloat(projectManagement) + parseFloat(netCost) + parseFloat(fee);

  const handleValueChange = () => {
    setGrossTotal((netTotal).toFixed(2));
  };

  useEffect(() => {
    handleValueChange();
  }, [watchedValues, setValue]);

  const notCalculatedGrossTotal = useMemo(() => {
    if (!isNaN(parseFloat(grossTotal))) {
      return formatNumber(Math.round(parseInt(grossTotal)));
    } else {
      return '-';
    }
  }, [grossTotal]);

  return (
    <React.Fragment>
      <HeaderBorderLeft borderColour={ionicColours.secondary} title={i18n.t('Update cashflow')} clickHandler={props.onModalDismiss} />
      <IonContent scrollY={true}>
        <IonGrid className={'ion-padding'}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(saveManualCashFlow)} >
              <IonGrid>
                <input type='text'{...methods.register('cash_flow_id', { required: true })} hidden />
                {CASH_FLOW_EDIT_LIST.map((input) => {
                  return (
                    <IonRow className='ion-margin-bottom ion-align-items-center' key={input.register}>
                      <IonCol style={{ borderLeft: `4px solid ${ionicColours.secondary}` }}>
                        <CurrencyInput
                          placeholder={input.placeholder}
                          label={input.label}
                          register={input.register}
                          requiredMessage={input.requiredMessage}
                          maxLength={input.maxLength}
                          minLength={input.minLength}
                          pattern={input.pattern}
                          inputMode={input.inputMode}
                          inputType={input.inputType}
                          validate={input.validate}
                          disabled={input.disabled}
                        />
                      </IonCol>
                    </IonRow>
                  );
                })}
                <ItemBorderContainer >
                  <BigUp.Label.Thick label={i18n.t('Gross total')} />
                  <IonItem >
                    <p className='ion-no-margin' style={{ color: 'var(--ion-color-medium)' }}>
                      {notCalculatedGrossTotal}
                    </p>
                  </IonItem>
                </ItemBorderContainer>

                <IonRow className='ion-margin-bottom ion-margin-top ion-align-items-center' key={'apply_to_months'}>
                  <IonCol style={{ borderLeft: `4px solid ${ionicColours.secondary}` }}>
                    <IonItem className='ion-no-margin ion-no-padding' lines='none'>
                      <IonLabel className='ion-margin-left ion-items-between' color={'medium'} style={{ ...label_styles }}>
                        {t('Apply to months')}
                      </IonLabel>
                      <IonBadge color='primary' slot='end'>
                        + {applyToMonths} {t('month(s)')}
                      </IonBadge>
                    </IonItem>
                    <IonItem lines='none'>
                      <IonRange
                        aria-required
                        aria-aria-valuemin={1}
                        placeholder={i18n.t('Apply to months')}
                        {...methods.register('apply_to_months', {
                          min: { value: 0, message: i18n.t('You must apply this to at least one month (this month)') },
                          max: { value: props.rowCount - props.rowIndex - 1, message: i18n.t('You cannot apply this to more than 12 months') },
                          required: true,
                        })}
                        min={0}
                        max={props.rowCount - props.rowIndex - 1}
                        onIonChange={(e) => onRangeChange(e.detail.value as number)}
                        pin
                        snaps
                      />

                    </IonItem>
                    {methods.formState.errors.apply_to_months && (
                      <IonLabel color='danger' className='ion-padding-start'>
                        {methods.formState.errors.apply_to_months?.message}
                      </IonLabel>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
              <StepButtons
                rightOnClick={() => undefined}
                leftOnClick={props.onModalDismiss}
                leftTitle={i18n.t('Cancel')}
                rightSubmit={'submit'}
                leftIonColor='none'
                rightIonColor='secondary'
                rightTitle={i18n.t('Save')}
              />
            </form>
          </FormProvider>
        </IonGrid>
      </IonContent>
    </React.Fragment>
  );
};

export default Edit;
