import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './RequestSuccessful.module.scss';
import BigUp from '../../../components/UI';

const RequestSuccessfulPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={'ion-padding'}>
      <IonGrid className={'ion-padding ion-text-center ion-align-items-center ion-justify-content-center'}>
        <IonRow>
          <IonCol>
            <IonIcon icon={checkmarkCircle} color={'success'} className={styles['RequestSuccessful-icon']} />
            <BigUp.Title color={'primary'}>{t('Request sent')}</BigUp.Title>
            <p>{t('We will get back to you within 24 hours to assist you with setting up BigUp for your organisation.')}</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <BigUp.Buttons.Regular title={t('Go to home screen')} routerLink={'/introduction'} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default RequestSuccessfulPage;
