import { IonContent, IonList, IonSplitPane } from '@ionic/react';
import { t } from 'i18next';
import React from 'react';

import styles from './splitpane.module.scss';
import SplitPaneOptions from './SplitPaneCategoryList';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import type { SubpanelItem } from '../../../pages/DesktopCards';
import ReturnChevronText from '../Return/ReturnChevronText';
import { ionicColours } from '../variables';

interface DynamicContentProps {
  uuid: string;
  menuItems: SubpanelItem[];
  baseURL: 'economy-tools' | 'project-tools' | 'notice-board';
  children: React.ReactNode;
  returnRoute?: string;
  canReturn?: boolean;
}

const SplitpaneContent: React.FC<DynamicContentProps> = ({ baseURL, canReturn = false, children, menuItems, returnRoute, uuid }) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  if (!isDesktop) {
    return <>{children}</>;
  }
  return (
    <div style={{ minHeight: '100vh' }}>
      <IonSplitPane contentId="main" style={{ marginTop: '170px' }}>
        <SplitPaneOptions
          toolColour={ionicColours.primary}
          baseUrl={baseURL}
          uuid={uuid}
          label={i18n.t('Project tools')}
          menuItems={menuItems}
        />
        <div id="main" className={styles['split-pane-wrapper']}>
          <IonContent color={'light'}>
            <IonList className={'ion-margin-bottom ion-padding-bottom'}>
              {canReturn && (<ReturnChevronText text={t('Back')} color={'none'} fill={'none'} />)}
              {children}
            </IonList>
          </IonContent>
        </div >
      </IonSplitPane>
    </div>
  );
};

export default SplitpaneContent;
