import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import React from 'react';
import { useLocation } from 'react-router-dom';

import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import usePermissionHandler from '../../../hooks/usePermissions';
import ProjectCard from '../ProjectCard';

const ProjectList: React.FC = () => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  const location = useLocation();

  const generateHref = (id: E2U.V1.Models.Project['id']) => {
    const basePath = location.pathname.includes('economy-tools') ? 'economy-tools' : 'project-tools';
    if (isDesktop) {
      const desktopPath = basePath === 'economy-tools' ? 'project-information' : 'control-of-execution';
      return `/${basePath}/${id}/${desktopPath}`;
    } else {
      return `/${basePath}/${id}`;
    }
  };

  return (
    <div className='ion-padding ion-margin-top' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%', maxWidth: 'var(--ion-desktop-max-width)', ...isDesktop && { gap: 5 }, }}>
      <BigUp.Title label={t('Projects')} />
      {checkPermissionsForProjectAndTools('read', 'read')?.map((project, i) => {
        return (
          <ProjectCard key={i} project={project} href={() => generateHref(project.id)} />
        );
      })}
    </div>
  );
};
export default ProjectList;
