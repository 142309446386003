import i18n from '../i18n';

export const emptyListMessages = {
  amendments: {
    empty_title: (type: string) => i18n.t('No {type} could be found', 'No {type} could be found', { type }),
    empty_message: (type: string) => i18n.t('No {type} have been added to this project. Select your areas to get started.', 'No {type} have been added to this project. Select your areas to get started.', { type }),
  },
  cash_flow: {
    empty_title: i18n.t('No cash flow generated yet.', 'No cash flow generated yet.'),
    empty_message: i18n.t('Go to production budget and lock the project to calculate cash flow.', 'Go to production budget and lock the project to calculate cash flow.'),
  },
  economy_monitoring: {
    empty_title: i18n.t('No economy monitoring could be found', 'No economy monitoring could be found'),
    empty_message: i18n.t('No economy monitoring have been added to this project. Generate one to get started.', 'No economy monitoring have been added to this project. Generate one to get started.')
  },
  payment_schedule: {
    empty_title: i18n.t('No payment schedule', 'No payment schedule'),
    empty_message: i18n.t('No payment schedule has been added to this project. Create a cash flow to continue.', 'No payment schedule has been added to this project. Create a cash flow to continue.'),
  },
  precalculation_rows: {
    empty_title: i18n.t('', ''),
    empty_message: i18n.t('', ''),
  },
  control_of_execution: {
    empty_title: i18n.t('No controls', 'No controls'),
    empty_message: i18n.t('No controls have been added to this project. Add a control to get started.', 'No controls have been added to this project. Add a control to get started.'),
  },
  site_access_requests: {
    empty_title: (type: string) => i18n.t('No {type} requests could be found', 'No {type} requests could be found', { type }),
    empty_message: (type: string) => i18n.t('No {type} requests have been submitted to this project.', 'No {type} requests have been submitted to this project.', { type }),
  },
  working_environment: {
    empty_title: i18n.t('No working environments found', 'No working environments found'),
    empty_message: i18n.t('No working environments have been added to this project. Add one to get started.', 'No working environments have been added to this project. Add one to get started.'),
  },
  working_environment_list: {
    empty_title: i18n.t('No working environments found', 'No working environments found'),
    empty_message: i18n.t('No working environments have been added to this project. Add one to get started.', 'No working environments have been added to this project. Add one to get started.'),
  }
};
